import { yupResolver } from '@hookform/resolvers/yup';
import {
  Avatar,
  Box,
  Button,
  FileButton,
  Flex,
  Grid,
  Group,
  ScrollArea,
  Stack,
  Text,
  TextInput,
  Textarea,
  Title,
  createStyles,
  rem,
} from '@mantine/core';
import { useMediaQuery } from '@mantine/hooks';
import { IconCamera, IconCurrencyDollar, IconUpload } from '@tabler/icons-react';
import { useContext, useEffect, useRef, useState } from 'react';
import { useForm } from 'react-hook-form';
import { HeaderAvatarIcon } from '../../../../../layouts/authLayout/component/styles';
import { createService, deleteService, updateService } from '../../../../../services/service';
import { toastMessageUpdate } from '../../../../../utils/helpers/tosterMessage';
import { serviceUpdateSchema } from '../../../../../utils/schema/formSchema';
import { useCancelButtonStyles } from '../../../Customer/Profile/components/style';
import { FileInputStyle, TextInputStyle, uploadButtonStyle } from '../../Profile/components/style';
import { ServiceContext } from '../servicesContext';
import CustomButton from '../../Profile/components/CustomButton';
import onlyAllowNumbersInput from '../../../../../utils/helpers/onlyAllowNumbersInput';

// { serviceId, serviceTypeId, serviceRate, description }: any
export default function ServiceDiv({ setUserServices }: any) {
  const {
    close,
    opened,
    selectedService,
    buttonType,
    deleteClose,
    deleteOpened,
    createdService,
    setCreatedService,
    setSelectedService,
  } = useContext(ServiceContext);

  const [serviceImage, setServiceImage] = useState<any>(null);

  const { classes: textInputClass } = TextInputStyle();
  const { classes: uploadClass } = uploadButtonStyle();
  const [file, setFile] = useState<File | null>(null);
  const [loading, setLoading] = useState(false);
  const {
    register,
    handleSubmit,
    reset,
    resetField,
    control,
    getValues,
    formState: { errors },
  } = useForm({
    resolver: yupResolver(serviceUpdateSchema),
    mode: 'all',
  });
  const [selectedServiceInfo, setSelectedServiceInfo] = useState<any>(selectedService);
  const { classes: AvatarStyle } = HeaderAvatarIcon();

  useEffect(() => {
    setSelectedServiceInfo(selectedService);
    // setServiceImage(URL.createObjectURL(selectedService?.image));
  }, [selectedService]);

  useEffect(() => {
    // console.log(selectedServiceInfo, createdService);
    reset();
    setFile(selectedServiceInfo?.service_type_id || '');
    reset({
      description: selectedServiceInfo?.description,
      rate_per_hour: selectedServiceInfo?.rate,
      service_type_id: selectedServiceInfo?.service_type_id,
      serviceTitle:
        selectedServiceInfo?.has_service_type?.service || selectedServiceInfo?.service || createdService?.serviceTitle,
    });
  }, [selectedServiceInfo, createdService]);

  const submitServiceHandler = async (data: any) => {
    setLoading(true);
    // console.log(data);
    const serviceData: [string, any] = {
      ...data,
      image: file || '',
      // image: serviceImage || '',
      completion_time: '',
      cover_image: '',
      service_id: selectedServiceInfo?.service_id || null,
      rate_per_hour: data.rate_per_hour,
      description: data.description,
    };

    const formData = new FormData();
    for (const [key, value] of Object.entries(serviceData)) {
      formData.append(key, value);
    }

    if (selectedServiceInfo?.service_id) {
      try {
        const updateServiceData = await updateService(formData);
        toastMessageUpdate('success', 'Service updated successfully.');
        setUserServices((prev: any) => {
          if (prev.length) {
            prev.map((item: any, index: number) => {
              if (item.service_id === updateServiceData.data?.service_id) {
                item.rate = +updateServiceData.data?.rate;
                item.description = updateServiceData.data?.description;
                item.image = updateServiceData.data?.image;
              }
              return item;
            });
          }
          return prev;
        });
        setLoading(false);
      } catch (error) {
        console.log(error);
        setLoading(false);
      }
    } else {
      try {
        const addServiceData = await createService(serviceData);
        // serviceResponse(addServiceData.data);
        console.log('service params', serviceData);
        setCreatedService(serviceData);
        setUserServices((prev: any) => [...prev, addServiceData.data]);
        toastMessageUpdate('success', 'Service created successfully.');
        setLoading(false);
      } catch (error) {
        console.log(error);
        setLoading(false);
      }
    }
    close();
  };

  const deleteServiceHandler = async () => {
    setLoading(true);
    try {
      if (selectedServiceInfo?.service_id) {
        await deleteService(selectedServiceInfo.service_id);
        toastMessageUpdate('success', 'Service deleted successfully.');
        setUserServices((prev: any) => prev.filter((item: any) => item.service_id !== selectedServiceInfo.service_id));
        setLoading(false);
        deleteClose();
      }
    } catch (error: any) {
      console.log(error);
      setLoading(false);
      toastMessageUpdate('error', error?.data?.message || error.statusText || 'Something went wrong.');
      deleteClose();
    }
  };

  const useStyles = createStyles((theme) => ({
    box: {
      borderRadius: '15px',
      height: '100%',
      padding: 16,
      position: 'relative',
      backgroundColor: theme.colorScheme === 'light' ? '#FCF5ED' : theme.colors.dark[7],

      [theme.fn.smallerThan(769)]: {
        backgroundColor: theme.colorScheme === 'light' ? 'white' : theme.colors.dark[7],
      },
    },
  }));

  const { classes } = useStyles();

  const resetRef = useRef<() => void>(null);
  const isTablet = useMediaQuery('(max-width: 768px)');

  return (
    <>
      <Box className={classes.box}>
        <Title mt={15} fw={600} order={3} color='dimmed'>
          {selectedServiceInfo?.service_id ? 'Edit service' : 'Create service'}
        </Title>
        <form onSubmit={handleSubmit(submitServiceHandler)} autoComplete='off'>
          <Stack spacing='lg' mt='lg' mb='md'>
            <TextInput readOnly label='Title' {...register('serviceTitle')} size='md' placeholder='Enter title' />
            <Textarea
              minRows={4}
              spellCheck={false}
              {...register('description')}
              label='Description'
              size='md'
              readOnly={buttonType === 'view' ? true : false}
              placeholder='Description'
            />
            {errors?.description && (
              <Text fz={14} mt={-8} pl={5} fw={500} color='red'>
                {errors?.description?.message}
              </Text>
            )}
            <TextInput
              icon={<IconCurrencyDollar size='1.1rem' />}
              classNames={textInputClass}
              {...register('rate_per_hour')}
              rightSection='/ hr'
              label='Charges'
              size='md'
              readOnly={buttonType === 'view' ? true : false}
              placeholder='Enter charges'
              type='number'
              onKeyDown={onlyAllowNumbersInput}
            />
            {errors?.rate_per_hour && (
              <Text fz={14} mt={-8} pl={5} fw={500} color='red'>
                {errors?.rate_per_hour?.message}
              </Text>
            )}
            <Text size='md' align='left' mt='sm' fw={600}>
              Image
            </Text>

            {buttonType === 'view' ? (
              <Avatar classNames={AvatarStyle} size={100} radius={100} src={selectedServiceInfo?.image} />
            ) : (
              <>
                {!(selectedService?.image || serviceImage) && (
                  <FileButton
                    onChange={(e: any) => {
                      if (!e.type.startsWith('image/')) {
                        toastMessageUpdate('error', 'Only image files are allowed');
                        setFile(null);
                        resetRef.current?.();
                      } else if (+e.size > 3 * 1024 * 1024) {
                        toastMessageUpdate('error', 'Image size should not exceed 3MB');
                        setFile(null);
                        resetRef.current?.();
                      } else {
                        setServiceImage(URL.createObjectURL(e));
                        setFile(e);
                      }
                    }}
                    accept='image/*'>
                    {(props) => (
                      <Button
                        classNames={uploadClass}
                        w={`fit-content`}
                        size='md'
                        {...props}
                        leftIcon={<IconUpload size={rem(18)} />}>
                        Upload
                      </Button>
                    )}
                  </FileButton>
                )}

                {(selectedService?.image || serviceImage) && (
                  <Flex style={{ position: 'relative' }} justify='flex-start'>
                    <Avatar src={serviceImage || selectedService?.image} radius={'50%'} size={'xl'} />
                    {/* <Image src={serviceImage || selectedService?.image} height={100} radius={'50%'} /> */}
                    <Box
                      c='orange'
                      py={3}
                      px={5}
                      style={{
                        position: 'absolute',
                        top: '70%',
                        left: '20%',
                        background: 'rgba(0, 0, 0, 0.3)',
                        borderRadius: '50%',
                      }}>
                      <Flex justify='center'>
                        <FileButton
                          resetRef={resetRef}
                          onChange={(e: any) => {
                            // console.log(e.type.startsWith('image/'));
                            if (!e.type.startsWith('image/')) {
                              toastMessageUpdate('error', 'Only image files are allowed');
                              setFile(null);
                              resetRef.current?.();
                            } else if (+e.size > 3 * 1024 * 1024) {
                              toastMessageUpdate('error', 'Image size should not exceed 3MB');
                              setFile(null);
                              resetRef.current?.();
                            } else {
                              setServiceImage(URL.createObjectURL(e));
                              setFile(e);
                            }
                          }}
                          accept='image/png,image/jpeg'>
                          {(props) => (
                            <IconCamera
                              size={rem(18)}
                              color='white'
                              style={{ cursor: 'pointer' }}
                              {...props}></IconCamera>
                          )}
                        </FileButton>
                      </Flex>
                    </Box>
                  </Flex>
                )}
              </>
            )}

            <Box mt={isTablet ? 0 : 90}>
              {buttonType !== 'view' && (
                <Grid>
                  <Grid.Col lg={6}>
                    <CustomButton variant='gradient' loading={loading} type='submit'>
                      Save
                    </CustomButton>
                  </Grid.Col>
                  <Grid.Col lg={6}>
                    <CustomButton
                      variant='outline'
                      onClick={() => {
                        close();
                        setSelectedService(undefined);
                      }}>
                      Cancel
                    </CustomButton>
                  </Grid.Col>
                </Grid>
              )}
            </Box>
          </Stack>
        </form>
      </Box>
    </>
  );
}
