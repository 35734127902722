import { Button, Text } from '@mantine/core';
import type { ComponentPropsWithoutRef, ReactNode } from 'react';

interface Props {
  children: ReactNode;
  variant: 'filled' | 'light';
  loading?: boolean;
  onClick?: ComponentPropsWithoutRef<'button'>['onClick'];
}

export default function BookingActionButton({ children, variant, loading, onClick }: Props) {
  return (
    <>
      {variant === 'filled' ? (
        <Button
          variant='gradient'
          gradient={{ from: '#fe7720', to: '#fda223' }}
          radius={4}
          fullWidth
          loaderPosition='center'
          onClick={onClick}
          loading={loading}>
          <Text fz={16}>{loading ? null : children}</Text>
        </Button>
      ) : null}

      {variant === 'light' ? (
        <Button variant='light' radius={4} fullWidth onClick={onClick} loading={loading} loaderPosition='center'>
          <Text fz={16}>{loading ? null : children}</Text>
        </Button>
      ) : null}
    </>
  );
}
