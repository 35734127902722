import { Container, createStyles, useMantineTheme } from '@mantine/core';
import { useMediaQuery } from '@mantine/hooks';
import Pusher from 'pusher-js';
import { useContext, useEffect } from 'react';
import { Outlet } from 'react-router-dom';
import { ThemeContext } from '../../context/themeContext';
import { subscriptionLimit } from '../../services/subscription.api';
import { getWebsocketId } from '../../services/websocket.api';
import { toastMessageUpdate } from '../../utils/helpers/tosterMessage';
import { HeaderTop } from './component/HeaderTop';
import MobileMenu from './component/MobileMenu';
// export const banner = document.getElementById('bannerImage') as HTMLElement;
// export const banner = document.createElement('div');
// document.body.appendChild(banner);

// This is functional component of authentication layout.
// Here we render header top as a child component to render every page.

const useContainerStyle = createStyles((theme) => ({
  container: {
    [theme.fn.largerThan(769)]: {
      marginTop: 35,
    },

    [theme.fn.smallerThan(769)]: {
      paddingLeft: 0,
      paddingRight: 0,
      borderRadius: 0,
      marginBottom: 100,
    },
  },
}));

export default function AuthLayout({ removeValue }: any) {
  const mantinetheme = useMantineTheme();
  const mobile = useMediaQuery(`(max-width: ${mantinetheme.breakpoints.sm})`);
  const { classes: containerClasses } = useContainerStyle();

  const { setSubscriptionExhausted, setNotificationRecieved, setPusherData } = useContext(ThemeContext);

  useEffect(() => {
    // Function to make the API call
    const fetchSubscriptionLimit = async () => {
      try {
        // Your API call logic here
        const response = await subscriptionLimit();
        setSubscriptionExhausted(response);
      } catch (error) {
        console.error('Error fetching data:', error);
      }
    };

    // Call the fetchSubscriptionLimit function immediately
    // fetchSubscriptionLimit();

    // Set up an interval to call the API every 5 minutes (e.g., every 5000 milliseconds or 5 seconds)
    // const intervalId = setInterval(fetchSubscriptionLimit, 60000 * 5);

    // Clean up the interval on component unmount to avoid memory leaks
    // return () => clearInterval(intervalId);
  }, []);

  const subscribePusher = async () => {
    try {
      const data: any = await getWebsocketId();
      if (data.success) {
        const wsId = data.ws_id;
        const pusher = new Pusher('VoDZCw.F-7Adg', {
          wsHost: 'realtime-pusher.ably.io',
          httpHost: 'realtime-pusher.ably.io',
          // encrypted: true,
          disableStats: true,
          cluster: 'ap',
        });

        setPusherData({ wsId, pusher });
        const channel = pusher.subscribe(wsId);
        channel.bind(wsId, function (data: any) {
          setNotificationRecieved(data?.message);
          toastMessageUpdate('info', data.message.message);
        });
      }
    } catch (error) {
      console.log(error);
    }
  };

  useEffect(() => {
    subscribePusher();
  }, []);

  return (
    <>
      <HeaderTop removeValue={removeValue} />
      <Container size='xl' className={containerClasses.container}>
        <Outlet />
      </Container>

      {mobile && <MobileMenu />}
    </>
  );
}
