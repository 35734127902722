import { createStyles, getStylesRef, rem } from '@mantine/core';
import { useMediaQuery } from '@mantine/hooks';

export const FileInputStyle = createStyles((theme) => ({
  wrapper: {
    background: theme.colors.orange[2],
    paddingTop: '10px',
    paddingBottom: '10px',
    borderRadius: '10px',
  },
  label: {
    paddingBottom: '15px',
  },
  placeholder: {
    color: `${theme.colors.orange[8]} !important`,
  },
  uploadIcon: {
    color: theme.colors.dark[5],
  },
}));

export const TextInputStyle = createStyles((theme) => ({
  rightSection: {
    color: '#ced4da',
  },
}));

export const uploadButtonStyle = createStyles((theme) => ({
  label: {
    ref: getStylesRef('label'),
    color: theme.colors.dark[4],
  },

  icon: {
    ref: getStylesRef('icon'),
    color: theme.colors.dark[4],
  },

  root: {
    backgroundColor: `rgba(0,0,0,0)`,
    padding: 0,
    '&:hover': {
      backgroundColor: `rgba(0,0,0,0)`,

      [`& .${getStylesRef('label')}`]: {
        color: theme.colors.orange[8],
      },

      [`& .${getStylesRef('icon')}`]: {
        color: theme.colors.orange[8],
      },
    },
  },
}));

export const dividerStyle = createStyles((theme) => ({
  label: {
    color: theme.fn.primaryColor(),
    fontSize: '20px',
  },
}));

export const BoxStyle = createStyles((theme) => ({
  // modal: {
  //   width: '180px',
  //   height: '100px',
  // },

  box: {
    display: 'flex',
    flexDirection: 'column',
    margin: '0 auto',

    [`@media (min-width: 1441px)`]: {
      padding: `0.5em 0.8em 3em 0.8em`,
      width: '70%',
    },
    [`@media (max-width: 1440px)`]: {
      padding: `0.5em 0.8em 2.5em 0.8em`,
      width: '80%',
    },
    [`@media (max-width: 1024px)`]: {
      padding: `0.5em 0.8em 2.5em 0.8em`,
      width: '80%',
    },
    [`@media (max-width: 768px)`]: {
      padding: `0.5em 0.8em 2.5em 0.8em`,
      width: '90%',
    },
  },
}));

export const useBannerStyles = createStyles((theme) => ({
  card: {
    backgroundColor: theme.colorScheme === 'dark' ? theme.colors.dark[7] : theme.white,
    position: 'relative',
    borderRadius: 0,
  },
  info_container: {
    flexDirection: 'row',
    alignItems: 'self-start',
    [theme.fn.smallerThan('xs')]: {
      flexDirection: 'column',
      alignItems: 'center',
    },
  },
  avatar: {
    border: `${rem(7)} solid ${theme.colorScheme === 'dark' ? theme.colors.dark[7] : theme.white}`,
    marginRight: 15,
    boxShadow: theme.shadows.sm,
  },
  icon: {
    color: theme.colorScheme === 'dark' ? theme.colors.dark[3] : theme.colors.dark[5],
  },
  startIcon: {
    color: theme.fn.primaryColor(),
  },
  edit: {
    color: theme.colors.red[6],
  },
}));

// export const useModalStyles = createStyles((theme) => ({

// }));

export const AvatarIcon = createStyles((theme) => ({
  placeholderIcon: {
    height: '2rem !important',
    width: '2rem !important',
  },

  root: {
    minWidth: '0em',

    [`@media (max-width: 1440px)`]: {
      height: '9em',
      width: '9em',
    },

    [`@media (max-width: 768px)`]: {
      height: '8em',
      width: '8em',
    },

    [`@media (max-width: 576px)`]: {
      height: '7em',
      width: '7em',
    },

    background: theme.fn.linearGradient(180, '#fda223', '#fe7720'),
    padding: '0.25em',
  },
  image: {
    borderRadius: '50%',
  },
}));

export const headerImageStyles = createStyles((theme) => ({
  image: {
    borderRadius: '0.5em',
    height: '300px !important',
    [theme.fn.smallerThan(1250)]: {
      height: '250px !important',
    },

    [theme.fn.smallerThan(1000)]: {
      height: '220px !important',
    },

    [theme.fn.smallerThan(850)]: {
      height: '200px !important',
    },

    [theme.fn.smallerThan(650)]: {
      height: '180px !important',
    },

    [theme.fn.smallerThan(768)]: {
      borderRadius: 0,
    },
  },
}));

export const useGridColStyle = createStyles((theme) => ({
  gridCol: {
    [theme.fn.smallerThan('xs')]: {
      flexBasis: '50%',
      flexShrink: 0,
      maxWidth: '50%',
      flexGrow: 0,
    },
  },
}));

export const useUserBannerRefactoredStyles = createStyles((theme) => ({
  flexContainerOne: {
    justifyContent: 'space-between',

    [theme.fn.smallerThan(769)]: {
      flexDirection: 'column',
      alignItems: 'center',
    },
  },

  flexContainerTwo: {
    gap: 16,
    justifyContent: 'center',

    [theme.fn.smallerThan(769)]: {
      flexDirection: 'column',
      alignItems: 'center',
    },
  },

  flexContainerThree: {
    marginTop: 16,
    gap: 4,
    flexDirection: 'column',

    [theme.fn.smallerThan(769)]: {
      marginTop: 0,
      alignItems: 'center',
    },
  },

  editButton: {
    position: 'relative',
    top: -4,
    right: -4,

    svg: {
      width: 17,
      height: 17,
      stroke: '#fe7720',
    },

    [theme.fn.smallerThan(769)]: {
      top: -4,
      right: -4,
    },
  },

  profilePhotoContainer: {
    marginTop: -36,
    marginLeft: 30,

    [theme.fn.smallerThan(769)]: {
      marginLeft: 0,
    },
  },
}));

export const useRenderSkillsStyles = createStyles((theme) => ({
  skillsText: {
    color: '#7b7b7b',
    [theme.fn.largerThan(769)]: {
      display: 'none',
    },
  },

  briefcaseIcon: {
    stroke: '#7f7f7f',
    width: 18,
    minWidth: 18,
    height: 18,
  },

  skillsContainerForLargeScreen: {
    [theme.fn.smallerThan(769)]: {
      display: 'none',
    },
  },

  skillsContainerForSmallScreen: {
    marginInline: 8,
    [theme.fn.largerThan(769)]: {
      display: 'none',
    },
  },
}));

export const useVerificationInfoStyle = createStyles((theme) => ({
  container: {
    backgroundColor: theme.colorScheme === 'light' ? '#F6F6F6' : theme.colors.dark[8],
    marginBottom: 20,
  },

  textGridColumn: {
    order: 1,
    [theme.fn.smallerThan(768)]: {
      order: 2,
    },
  },

  imageGridColumn: {
    order: 2,
    [theme.fn.smallerThan(768)]: {
      order: 1,
    },
  },

  verificationHeaderText: {
    [theme.fn.smallerThan(915)]: {
      fontSize: 15.5,
    },

    [theme.fn.smallerThan(800)]: {
      fontSize: 15,
    },
  },

  imageFlexContainer: {
    justifyContent: 'flex-end',
    width: '100%',
    [theme.fn.smallerThan(768)]: {
      justifyContent: 'center',
    },
  },
}));

export const useVerificationStyle = createStyles((theme) => ({
  header: {
    [theme.fn.smallerThan(577)]: {
      display: 'none',
    },
  },

  verificationHeaderText: {
    [theme.fn.smallerThan(915)]: {
      fontSize: 15.5,
    },

    [theme.fn.smallerThan(800)]: {
      fontSize: 15,
    },
  },

  optionalDrugTestingBox: {
    padding: 10,
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    border: '0.125rem dashed #ced4da',
    borderRadius: '5px',
    height: '100%',
    textDecoration: 'none',
    color: theme.colorScheme === 'light' ? '#5b5b5b' : '#a9a9a9',
    transition: '300ms',

    '&:hover': {
      color: theme.colorScheme === 'light' ? 'black' : 'white',
      transition: '300ms',
    },
  },
}));

export const deleteMyAccountStyles = createStyles((theme) => ({
  buttonGridContainer: {
    WebkitFlexBasis: '50%',
    flexBasis: '50%',
    WebkitFlexShrink: 0,
    flexShrink: 0,
    maxWidth: '50%',
    WebkitFlexGrow: 0,
    flexGrow: 0,
  },

  crossIconButtonContainer: {
    color: '#888888',
    transition: '120ms',
    ':hover': {
      backgroundColor: 'none',
      color: '#fe7720',
      transition: '120ms',
    },

    [`&:hover .${getStylesRef('button')}`]: {
      transition: '120ms',
    },
  },

  crossIconButton: {
    ref: getStylesRef('button'),
    width: 20,
    height: 20,
    transition: '120ms',
  },
}));

export const useUserInfoStyles = createStyles((theme) => ({
  containerForPrevAndNextButtonsIfValid: {
    userSelect: 'none',
    cursor: 'pointer',
    color: '#fe7720',
  },

  containerForPrevAndNextButtonsIfInvalid: {
    userSelect: 'none',
    cursor: 'not-allowed',
    color: '#e1e1e1',
  },

  containerForUserOrProviderProfileOverview: {
    marginTop: rem(20),
    padding: `${rem(16)} !important`,
    backgroundColor: theme.colorScheme === 'light' ? '#fdfdfd' : theme.colors.dark[6],
    boxShadow:
      '0px 4px 4px 0px rgba(0,0,0,0.1),0px -4px 4px 0px rgba(0,0,0,0.1),4px 0px 4px 0px rgba(0,0,0,0.1),-4px 0px 4px 0px rgba(0,0,0,0.1)',

    [theme.fn.smallerThan('sm')]: {
      boxShadow: 'none',
      padding: `${rem(8)} !important`,
    },
  },

  tabsListForMobile: {
    display: 'none',
    [theme.fn.smallerThan('sm')]: {
      display: 'block',
    },
  },
}));

export const useIdentityVerifyStyles = createStyles((theme) => ({
  container: {
    height: 750,

    [theme.fn.smallerThan(992)]: {
      height: 'auto',
    },
  },
}));

export const useUpdateSkillsStyles = createStyles((theme) => ({
  buttonGridContainer: {
    [theme.fn.smallerThan('sm')]: {
      flexBasis: '50%',
      flexShrink: 0,
      maxWidth: '50%',
      flexGrow: 0,
    },
  },
}));

export const useAddUserStyles = createStyles((theme) => ({
  container: {
    height: 750,
  },

  table: {
    thead: {
      backgroundColor: theme.colorScheme === 'light' ? '#f9f9f9' : theme.colors.dark[7],
    },
  },

  noUserFlexContainer: {
    justifyContent: 'center',
    marginTop: 20,
    padding: 20,
    fontSize: 20,
    border: `2px dashed ${theme.colorScheme === 'light' ? '#ececec' : '#5f5f5f'}`,
    borderRadius: 4,
    color: '#8b8b8b',
  },

  addUserButton: {
    borderRadius: 4,
  },

  buttonContainerForEdit: {
    height: rem(28),
    minHeight: rem(28),
    width: rem(28),
    minWidth: rem(28),
    backgroundColor: '#fe7720',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    borderRadius: rem(8),
  },

  buttonContainerForDelete: {
    height: rem(28),
    minHeight: rem(28),
    width: rem(28),
    minWidth: rem(28),
    backgroundColor: '#ececec',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    borderRadius: rem(8),
  },

  buttonFlexContainer: {
    display: 'flex',
    gap: '0.625rem',
    flexDirection: 'row',
  },

  buttonGridContainerForAddCancel: {
    [theme.fn.smallerThan('xs')]: {
      flexBasis: '50%',
      flexShrink: 0,
      maxWidth: '50%',
      flexGrow: 0,
    },
  },

  shareServiceContainerWhenDropdownIsClosed: {
    height: 96,
  },

  shareServiceContainerWhenDropdownIsOpened: {
    height: 280,
  },
}));

export const useAddUserStylesScrollAreaStyles = createStyles((theme) => ({
  root: {
    maxHeight: 690,
  },
}));

export const useCommonUserViewProfileStyles = createStyles((theme) => ({
  firstGridColumnContainer: {
    border: `1px solid ${theme.colorScheme === 'light' ? '#e0e0e0' : '#3b3b3b'}`,
    height: '100%',
    backgroundColor: theme.colorScheme === 'light' ? '#f4f4f4' : theme.colors.dark[6],
    borderRadius: 8,
    margin: 8,
    paddingInline: 16,

    [theme.fn.smallerThan(769)]: {
      borderRadius: 0,
      margin: 0,
    },
  },

  secondGridColumnContainer: {
    height: '100%',
    margin: 8,
    borderRadius: 8,
    backgroundColor: theme.colorScheme === 'light' ? 'white' : theme.colors.dark[6],
  },

  thirdGridColumnContainer: {
    margin: 8,
    height: '100%',
    borderRadius: 8,
    backgroundColor: theme.colorScheme === 'light' ? '#f4f4f4' : theme.colors.dark[6],
  },
}));

export const useProfileCardStyles = createStyles((theme) => ({
  providerIntroContainer: {
    marginTop: 8,
    padding: 4,
    // border: `1px solid ${theme.colorScheme === 'light' ? '#e5e5e5' : '#3b3b3b'}`,
    width: '100%',
    borderRadius: 4,
  },
}));

export const useUserBasicInfoStyles = createStyles((theme) => ({
  flexContainer: {
    svg: {
      color: '#616161',
      width: 20,
      height: 20,
    },
  },
}));
