import { createStyles, rem } from '@mantine/core';

export const useMyBookingRefactoredStyles = createStyles((theme) => ({
  parentGridContainer: {
    // border: '1px solid black',
    height: 'calc(100svh - 140px)',
    padding: 0,
    margin: 0,
  },
}));

export const useAllBookingsScrollAreaStyles = createStyles((theme) => ({
  root: {
    // border: '1px solid black',
    height: '100%',
    maxHeight: 'calc(100svh - 230px)',

    [theme.fn.smallerThan(769)]: {
      maxHeight: 'calc(100svh - 160px)',
    },
  },
}));

export const useAllBookingsStyles = createStyles((theme) => ({
  childGridColOne: {
    height: '100%',
    padding: `${rem(4)} ${rem(4)} ${rem(0)} ${rem(0)}`,
    // transition: '250ms',

    [theme.fn.smallerThan(769)]: {
      padding: 0,
    },
  },
  tabsList: {
    width: 'fit-content',
    display: 'flex',
  },
}));

export const useBookingDetailsStyles = createStyles((theme) => ({
  container: {
    border: `1px solid ${theme.colorScheme === 'light' ? '#ddd' : '#4e4e4e'}`,
    height: '100%',
    backgroundColor: theme.colorScheme === 'light' ? '#f3f3f3' : '#22222d',
    borderRadius: 8,
    padding: 8,

    [theme.fn.smallerThan(769)]: {
      backgroundColor: 'white',
      border: 'none',
      padding: 0,
    },

    '.title': {
      textAlign: 'center',
    },
  },

  innerContainer: {
    // border: '1px solid black',
    marginTop: 44,
    position: 'relative',
    height: 'calc(100svh - 240px)',

    [theme.fn.smallerThan(769)]: {
      height: 'auto',
    },
  },
}));

export const useBookingDetailsScrollAreaStyles = createStyles((theme) => ({
  root: {
    maxHeight: 'calc(100svh - 240px)',
    // border: '1px solid black',

    [theme.fn.smallerThan(769)]: {
      maxHeight: 'calc(100svh - 145px)',
    },
  },
}));

export const useParentGridContainerStyles = createStyles((theme) => ({
  container: {
    // border: '1px solid black',
    height: 'calc(100svh - 140px)',
    padding: 0,
    margin: 0,

    [theme.fn.smallerThan(769)]: {
      marginInline: 8,
    },
  },
}));

export const useBookingCardStyles = createStyles((theme) => ({
  card: {
    padding: 8,
    borderRadius: 4,
    transition: '250ms',
  },

  inactiveCard: {
    backgroundColor: theme.colorScheme === 'light' ? '#f3f3f3' : '#22222d',
    border: `1px solid ${theme.colorScheme === 'light' ? '#ececec' : '#4c4c4c'}`,

    '&:hover': {
      backgroundColor: theme.colorScheme === 'light' ? '#fffdfd' : '#2f2f40',
      borderColor: '#f8ca8a',
      boxShadow: `0px 0px 12px ${theme.colorScheme === 'light' ? '#FECDA6' : '#6f6155'}`,
      transition: '250ms',
    },
  },

  activeCard: {
    backgroundColor: theme.colorScheme === 'light' ? '#ffffff' : '#1e1e1e',
    border: `1px solid ${theme.colorScheme === 'light' ? '#fda223' : '#5e4d36'}`,
    boxShadow: `0px 0px 12px ${theme.colorScheme === 'light' ? '#FECDA6' : '#6f6155'}`,

    '&:hover': {
      backgroundColor: theme.colorScheme === 'light' ? '#ffffff' : '#1e1e1e',
      borderColor: '#fda223',
      transition: '250ms',
    },
  },

  serviceNameAndIconContainerFlex: {
    height: 50,
  },

  serviceNameText: {
    fontSize: 16,
    fontWeight: 600,
    color: theme.colorScheme === 'light' ? '#343434' : '#8C8FA3',
  },

  viewBookingButtonFlexContainer: {
    marginTop: 12,
  },

  icon: {
    width: 14,
  },
}));

export const useShowContactInfoStyles = createStyles((theme) => ({
  noContactInfoContainer: {
    display: 'flex',
    padding: 8,
    justifyContent: 'center',
    border: '2px dashed #ccc',
    borderRadius: 4,
  },
}));

export const useShowStatusBadgeStyles = createStyles((theme) => ({
  noCancellationReasonContainer: {
    display: 'flex',
    justifyContent: 'center',
    border: '2px dashed #d8d8d8',
    color: '#898989',
    padding: 16,
  },
}));

export const useBookingDetailsContainerStyles = createStyles((theme) => ({
  childGridColTwo: {
    height: '100%',
    padding: `${rem(4)} ${rem(0)} ${rem(0)} ${rem(4)}`,
    opacity: 1,
    // transition: '250ms',
  },
}));

export const useGoogleMapComponentStyles = createStyles((theme) => ({
  container: {
    border: '1px solid #ccc',
    borderRadius: 8,
    padding: 8,
    color: '#aaa',
    height: 'calc(100svh - 240px)',
    width: '100%',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
  },
}));

export const useCrossButtonStyles = createStyles((theme) => ({
  button: {
    display: 'block',

    [theme.fn.smallerThan(769)]: {
      display: 'none',
    },
  },
}));

export const useBackButtonStyles = createStyles((theme) => ({
  button: {
    display: 'none',

    [theme.fn.smallerThan(769)]: {
      display: 'block',
    },
  },
}));
