import dayjs from 'dayjs';
import utc from 'dayjs/plugin/utc';
import timezone from 'dayjs/plugin/timezone';
import customParseFormat from 'dayjs/plugin/customParseFormat';

dayjs.extend(utc);
dayjs.extend(timezone);
dayjs.extend(customParseFormat);

export default function getDateTimeDifferenceFromCurrentDateTime(dateTimeValue: string) {
  // console.log(dateTimeValue);

  /**
   * We receive the time in UTC timezone
   *
   * So, first we need to make the current time in UTC and then calculate the difference
   */

  // const sampleDateTime = '2024-05-31 07:39:00';

  const receivedDateTimeInUTC = dayjs(dateTimeValue, { format: 'YYYY-MM-DD HH:mm:ss', utc: true }, true);

  const currentDateTimeInUTC = dayjs(undefined, { utc: true });

  const dayDifference = currentDateTimeInUTC.diff(receivedDateTimeInUTC, 'd');

  const hourDifference = currentDateTimeInUTC.diff(receivedDateTimeInUTC, 'h') % 24;

  const minuteDifference = currentDateTimeInUTC.diff(receivedDateTimeInUTC, 'm') % 60;

  const secondDifference = currentDateTimeInUTC.diff(receivedDateTimeInUTC, 's') % 60;

  let timeDifference = '';

  timeDifference += dayDifference > 0 ? `${dayDifference}d ` : '';

  timeDifference += hourDifference < 10 ? `0${hourDifference}` : hourDifference;
  timeDifference += ':';

  timeDifference += minuteDifference < 10 ? `0${minuteDifference}` : minuteDifference;
  timeDifference += ':';

  timeDifference += secondDifference < 10 ? `0${secondDifference}` : secondDifference;

  // console.log(timeDifference);
  return timeDifference;
}
