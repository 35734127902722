import { Button, Group, Stack, Text, Title } from '@mantine/core';
import { useContext, useState } from 'react';
import { ThemeContext } from '../../../../context/themeContext';
import { VideoContext } from '../../Chat/videoContext';
import { useCancelButtonStyles } from '../../Customer/Profile/components/style';
import { useModalStyles } from './style';

interface IModalDetails {
  opened: boolean;
  close: () => void;
  joinCall: () => void;
}

// video calling aproval modal.
export default function JoinVideoCall({ opened, close, joinCall }: IModalDetails) {
  const { classes } = useModalStyles();
  const [inCall, setInCall] = useState(false);
  const { setStartVideo } = useContext(VideoContext);
  const { setVideoStart } = useContext(ThemeContext);

  const { classes: CancelButtonStyle } = useCancelButtonStyles();

  return (
    <>
      <Stack>
        <Title ta='center' order={3}>
          Start video call
        </Title>
        <Text size={18} align='center' color='dimmed' pt='10px'>
          Do you want to start video call?
        </Text>

        <Group spacing={3} position='center' my='xl' mt='xl'>
          <Button
            size='md'
            w={`49%`}
            radius={4}
            variant='gradient'
            gradient={{ to: '#fda223', from: '#fe7720', deg: 270 }}
            onClick={() => {
              joinCall();
              setStartVideo(true);
              setVideoStart(true);
            }}>
            Yes
          </Button>
          <Button
            size='md'
            w={`49%`}
            variant='light'
            className={CancelButtonStyle.root}
            radius={4}
            onClick={close}
            color='red'>
            No
          </Button>
        </Group>
      </Stack>
    </>
  );
}
