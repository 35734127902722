import { type ColProps } from '@mantine/core';
import type { ReactNode } from 'react';
import { createContext, useContext, useReducer, useState } from 'react';

type GridColStylesState = {
  xl: ColProps['xl'];
  lg: ColProps['lg'];
  md: ColProps['md'];
  sm: ColProps['sm'];
  xs: ColProps['xs'];
};

type AllGridColStylesState = {
  parentLeftGridColumnStyles: GridColStylesState;
  parentRightGridColumnStyles: GridColStylesState;
  childBookingCardGridColumnStyles: GridColStylesState;
  childBookingDetailsInfoGridColumnStyles: GridColStylesState;
  childBookingDetailsMapOrAddItemGridColumnStyles?: GridColStylesState;
};

const allGridColStylesInitialState: AllGridColStylesState = {
  parentLeftGridColumnStyles: {
    xl: 12,
    lg: 12,
    md: 12,
    sm: 12,
    xs: 12,
  },
  parentRightGridColumnStyles: {
    xl: 0,
    lg: 0,
    md: 0,
    sm: 0,
    xs: 0,
  },
  childBookingCardGridColumnStyles: {
    xl: 4,
    lg: 4,
    md: 4,
    sm: 6,
    xs: 6,
  },
  childBookingDetailsInfoGridColumnStyles: {
    xl: 12,
    lg: 12,
    md: 12,
    sm: 12,
    xs: 12,
  },
  childBookingDetailsMapOrAddItemGridColumnStyles: {
    xl: 0,
    lg: 0,
    md: 0,
    sm: 0,
    xs: 0,
  },
};

type AllGridColStylesAction = {
  type:
    | 'SET_GRID_STYLES_FOR_OPENING_REGULAR_BOOKING'
    | 'SET_GRID_STYLES_FOR_OPENING_BOOKING_WITH_MAP'
    | 'SET_STYLES_TO_DEFAULT';
};

const reducerFn = (state: AllGridColStylesState, action: AllGridColStylesAction): AllGridColStylesState => {
  switch (action.type) {
    case 'SET_GRID_STYLES_FOR_OPENING_REGULAR_BOOKING':
      return {
        ...state,
        parentLeftGridColumnStyles: {
          ...state.parentLeftGridColumnStyles,
          xl: 9,
          lg: 9,
          md: 8,
          sm: 6,
        },
        parentRightGridColumnStyles: {
          ...state.parentRightGridColumnStyles,
          xl: 3,
          lg: 3,
          md: 4,
          sm: 6,
        },
        childBookingCardGridColumnStyles: {
          ...state.childBookingCardGridColumnStyles,
          xl: 4,
          lg: 4,
          md: 6,
          sm: 6,
        },
        childBookingDetailsInfoGridColumnStyles: {
          ...state.childBookingDetailsInfoGridColumnStyles,
          xl: 12,
          lg: 12,
          md: 12,
          sm: 12,
        },
      };

    case 'SET_GRID_STYLES_FOR_OPENING_BOOKING_WITH_MAP':
      return {
        ...state,
        parentLeftGridColumnStyles: {
          ...state.parentLeftGridColumnStyles,
          xl: 3,
          lg: 3,
          md: 3,
          sm: 3,
        },
        parentRightGridColumnStyles: {
          ...state.parentRightGridColumnStyles,
          xl: 9,
          lg: 9,
          md: 9,
          sm: 9,
        },
        childBookingCardGridColumnStyles: {
          ...state.childBookingCardGridColumnStyles,
          xl: 12,
          lg: 12,
          md: 12,
        },
        childBookingDetailsInfoGridColumnStyles: {
          ...state.childBookingDetailsInfoGridColumnStyles,
          xl: 4,
          lg: 4,
          md: 4,
        },
        childBookingDetailsMapOrAddItemGridColumnStyles: {
          ...state.childBookingDetailsMapOrAddItemGridColumnStyles,
          xl: 8,
          lg: 8,
          md: 8,
          sm: 8,
          xs: 8,
        },
      };

    case 'SET_STYLES_TO_DEFAULT':
      return {
        ...state,
        parentLeftGridColumnStyles: {
          ...state.parentLeftGridColumnStyles,
          xl: 12,
          lg: 12,
          md: 12,
          sm: 12,
          xs: 12,
        },
        parentRightGridColumnStyles: {
          ...state.parentRightGridColumnStyles,
          xl: 0,
          lg: 0,
          md: 0,
          sm: 0,
          xs: 0,
        },
        childBookingCardGridColumnStyles: {
          ...state.childBookingCardGridColumnStyles,
          xl: 4,
          lg: 4,
          md: 4,
          sm: 4,
          xs: 12,
        },
      };
  }
};

const useBookingContextStore = () => {
  const [allGridColStyles, dispatchGridStyle] = useReducer(reducerFn, allGridColStylesInitialState);
  const [activeTab, setActiveTab] = useState<string | null>('current');
  const [showBookingDetails, setShowBookingDetails] = useState(false);
  const [showMap, setShowMap] = useState(false);
  const [showAddOrApproveItems, setShowAddOrApproveItems] = useState(false);
  const [selectedBooking, setSelectedBooking] = useState<any>(null);

  return {
    activeTab,
    setActiveTab,
    showBookingDetails,
    setShowBookingDetails,
    showMap,
    setShowMap,
    showAddOrApproveItems,
    setShowAddOrApproveItems,
    selectedBooking,
    setSelectedBooking,
    allGridColStyles,
    dispatchGridStyle,
  };
};

type BookingContextType = ReturnType<typeof useBookingContextStore>;

const BookingContext = createContext<BookingContextType | null>(null);

type BookingContextProviderProps = {
  children: ReactNode;
};

export function BookingContextProvider({ children }: BookingContextProviderProps) {
  return <BookingContext.Provider value={useBookingContextStore()}>{children}</BookingContext.Provider>;
}

export function useBookingContext() {
  const bookingCtx = useContext(BookingContext);

  if (bookingCtx === null) {
    throw new Error('BookingContext is null');
  }

  return bookingCtx;
}
