import { Box, Flex, Group, Text, UnstyledButton, createStyles } from '@mantine/core';
import { useProviderListHeaderStyles } from '../style';
import { useProviderListContext } from '../../../../../context/provider-list-context';
import { IconCategory, IconMenu2 } from '@tabler/icons-react';
import { useSearchParams } from 'react-router-dom';

const useStyles = createStyles((theme) => ({
  button: {
    height: 24,
    width: 24,

    svg: {
      height: 24,
      width: 24,
    },
  },

  selectedButton: {
    svg: {
      stroke: '#fe7720',
    },
  },

  unselectedButton: {
    svg: {
      stroke: 'black',
    },
  },
}));

export default function ProviderListHeader() {
  const { classes, cx } = useProviderListHeaderStyles();
  const { classes: buttonClasses, cx: buttonCx } = useStyles();

  const { serviceName, profileViewType, setProfileViewType } = useProviderListContext();
  const [searchParams, setSearchParams] = useSearchParams();

  const handleButtonClick = (type: 'list' | 'grid') => {
    setSearchParams((prev) => {
      const newParams = new URLSearchParams(prev);
      newParams.set('view', type);
      return newParams;
    });
    setProfileViewType(type);
  };

  return (
    <>
      <Box className={classes.container}>
        <Flex justify='space-between'>
          <Text fz={18} fw={600}>
            {serviceName}
          </Text>
          <Group position='center'>
            <UnstyledButton
              onClick={() => handleButtonClick('list')}
              className={buttonCx(buttonClasses.button, {
                [buttonClasses.selectedButton]: profileViewType === 'list',
              })}>
              <IconMenu2 />
            </UnstyledButton>

            <UnstyledButton
              onClick={() => handleButtonClick('grid')}
              className={buttonCx(buttonClasses.button, {
                [buttonClasses.selectedButton]: profileViewType === 'grid',
              })}>
              <IconCategory />
            </UnstyledButton>
          </Group>
        </Flex>
      </Box>
    </>
  );
}
