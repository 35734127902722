import { Text } from '@mantine/core';

export default function Loading() {
  return (
    <>
      <Text fz={14} c='dimmed'>
        {'Loading ...'}
      </Text>
    </>
  );
}
