import { Grid } from '@mantine/core';
import { useContext, useEffect } from 'react';
import { useCookies } from 'react-cookie';
import { useSearchParams } from 'react-router-dom';
import GlobalLoader from '../../../../../components/GlobalLoader';
import { ThemeContext } from '../../../../../context';
import { useBookingContext } from '../../../../../context/booking-context';
import { useFetchAllBookings } from '../../../../../services/booking-refactored';
import getStatusOfTheBooking, { BookingStatus } from '../../../../../utils/helpers/getStatusOfTheBooking';
import { useParentGridContainerStyles } from '../style';
import AllBookings from './AllBookings';
import BookingDetailsContainer from './BookingDetailsContainer';

export default function ParentGridContainer() {
  const { notificationRecieved } = useContext(ThemeContext);
  const { classes, cx } = useParentGridContainerStyles();
  const {
    setShowBookingDetails,
    setSelectedBooking,
    setActiveTab,
    setShowMap,
    setShowAddOrApproveItems,
    dispatchGridStyle,
  } = useBookingContext();
  const [searchParams, setSearchParams] = useSearchParams();
  const [cookie, _] = useCookies(['role']);

  const { data, isLoading, isRefetching, refetch } = useFetchAllBookings();

  const bookingStatusForWhichMapIsOpened = [BookingStatus.APPROVED, BookingStatus.JOURNEY_STARTED];

  useEffect(() => {
    if (notificationRecieved?.type === 'service') {
      refetch();
    }
  }, [notificationRecieved]);

  useEffect(() => {
    /**
     * This block of code
     * 1. sets the 'current' tab as the default tab if it cannot find any 'tab' query param in the URL
     * 2. if it is able to find a query param named 'tab' in the URL, then sets that as the default tab
     * ---
     */
    const previouslySelectedTab = searchParams.get('tab');

    if (previouslySelectedTab !== null) {
      // 'current' and 'history' are the only two valid values
      if (previouslySelectedTab === 'current' || previouslySelectedTab === 'history') {
        setActiveTab(previouslySelectedTab);
      } else {
        // if it is anything apart from 'current' and 'history' (i.e an invalid value) then set it as 'current'
        setActiveTab('current');
        setSearchParams({ tab: 'current' });
      }
    } else {
      setSearchParams({ tab: 'current' });
    }

    /**
     * This block of code checks whether any booking was opened before the refresh
     * if there was any opened booking then it applies appropriate styling
     */
    if (searchParams.has('opened') && searchParams.has('tab')) {
      const idOfThePreviouslyOpenedBooking = searchParams.get('opened');
      const previouslySelectedTab = searchParams.get('tab');

      if (previouslySelectedTab === 'current') {
        const indexOfThePreviouslySelectedBooking = data?.currentBookings?.findIndex(
          (item: any) => item?.booking_id === idOfThePreviouslyOpenedBooking,
        );
        const previouslySelectedBooking = data?.currentBookings[indexOfThePreviouslySelectedBooking];
        setSelectedBooking(previouslySelectedBooking);
        const statusOfThePreviouslySelectedBooking = getStatusOfTheBooking(
          previouslySelectedBooking,
          +cookie.role,
        ).status;

        console.log({ statusOfThePreviouslySelectedBooking });

        if (bookingStatusForWhichMapIsOpened.includes(statusOfThePreviouslySelectedBooking)) {
          dispatchGridStyle({
            type: 'SET_GRID_STYLES_FOR_OPENING_BOOKING_WITH_MAP',
          });
          setShowBookingDetails(true);

          if (statusOfThePreviouslySelectedBooking === BookingStatus.JOURNEY_STARTED) {
            setShowMap(true);
            setShowAddOrApproveItems(false);
          } else if (statusOfThePreviouslySelectedBooking === BookingStatus.APPROVED) {
            // if (providerRole.includes(+cookie.role)) {
            //   setShowMap(false);
            //   setShowAddOrApproveItems(true);
            // }
            setShowMap(false);
            setShowAddOrApproveItems(true);
          }
        } else if (statusOfThePreviouslySelectedBooking === BookingStatus.COMPLETED) {
          setShowBookingDetails(false);
          setShowMap(false);
          setShowAddOrApproveItems(false);
          dispatchGridStyle({
            type: 'SET_STYLES_TO_DEFAULT',
          });
        } else {
          dispatchGridStyle({
            type: 'SET_GRID_STYLES_FOR_OPENING_REGULAR_BOOKING',
          });
          setShowMap(false);
          setShowAddOrApproveItems(false);
          setShowBookingDetails(true);
        }
      } else if (previouslySelectedTab === 'history') {
        const indexOfThePreviouslySelectedBooking = data?.pastBookings?.findIndex(
          (item: any) => item?.booking_id === idOfThePreviouslyOpenedBooking,
        );
        const previouslySelectedBooking = data?.pastBookings[indexOfThePreviouslySelectedBooking];
        setSelectedBooking(previouslySelectedBooking);
        const statusOfThePreviouslySelectedBooking = getStatusOfTheBooking(
          previouslySelectedBooking,
          +cookie.role,
        ).status;
        setShowBookingDetails(true);
        dispatchGridStyle({ type: 'SET_GRID_STYLES_FOR_OPENING_REGULAR_BOOKING' });
      }
    } else {
      setShowBookingDetails(false);
      setSelectedBooking(null);
      dispatchGridStyle({ type: 'SET_STYLES_TO_DEFAULT' });
    }
  }, [data]);

  return (
    <>
      {isRefetching && <GlobalLoader />}
      <Grid className={cx(classes.container)}>
        <AllBookings data={data} isLoading={isLoading} />
        <BookingDetailsContainer refetch={refetch} />
      </Grid>
    </>
  );
}
