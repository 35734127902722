import {
  ActionIcon,
  Anchor,
  Avatar,
  Button,
  Container,
  Group,
  Header,
  Menu,
  Text,
  createStyles,
  rem,
  useMantineColorScheme,
  useMantineTheme,
} from '@mantine/core';
import { useDisclosure, useMediaQuery } from '@mantine/hooks';
import {
  IconArrowBigUpLines,
  IconBriefcase,
  IconCalendar,
  IconListCheck,
  IconLogout,
  IconMoneybag,
  IconMoonStars,
  IconSun,
  IconUser,
} from '@tabler/icons-react';
import { useContext, useEffect, useState } from 'react';
import { useCookies } from 'react-cookie';
import { useLocation, useNavigate } from 'react-router-dom';
import { Logo, LogoLight, orgProfileImage, profileImageFile } from '../../../assets';
import EndVideoCall from '../../../components/EndVideoCall';
import { RoleType } from '../../../utils';
import { AppEvents } from '../../../utils/helpers/sendFIle';
import HeaderErrorBlock from './HeaderErrorBlock';
import HeaderMenu from './HeaderMenu';
import Notification from './Notification';
import { HeaderAvatarIcon, HeaderMenuStyle, MobileMenuStyle } from './styles';
import { getWebsocketId } from '../../../services/websocket.api';
import Pusher from 'pusher-js';
import { ThemeContext } from '../../../context';

// This is functional component of profile menu.
export function ProfileMenu({ removeValue, ongoingVideoCall }: any) {
  const [cookies, setCookie, removeCookie] = useCookies([
    'access_token',
    'role',
    'user_Address',
    'user_Overview',
    'user_Info',
    'peerId',
    'bookingDetails',
    'agora_token',
    'agora_video_token',
  ]);
  const navigate = useNavigate();
  const { classes: AvatarStyle } = HeaderAvatarIcon();
  const [videoCallIn, setVideoCallIn] = useState(false);
  const [opened, { open, close }] = useDisclosure(false);
  const [defaultImage, setDefaultImage] = useState('');
  const { classes: mobilemenuClass } = MobileMenuStyle();
  const { classes: menuClass } = HeaderMenuStyle();
  const mantinetheme = useMantineTheme();
  const mobile = useMediaQuery(`(max-width: ${mantinetheme.breakpoints.sm})`);
  const location = useLocation();
  const { pusherData } = useContext(ThemeContext);

  // logout functionality and remove details from cookies.
  // clear all the cookie and navigate to login page.
  const handleLogout = () => {
    //Unsubscribe pusher
    pusherData.pusher.unsubscribe(pusherData.wsId);

    removeCookie('access_token', { path: '/' });
    removeCookie('role', { path: '/' });
    removeCookie('user_Address', { path: '/' });
    removeCookie('user_Overview', { path: '/' });
    removeCookie('user_Info', { path: '/' });
    removeCookie('peerId', { path: '/' });
    removeCookie('bookingDetails', { path: '/' });
    removeCookie('agora_token', { path: '/' });
    removeCookie('agora_video_token', { path: '/' });
    removeValue();
    navigate(`/login`);
  };

  // check video call status, if any videocall is running or not.
  // If video call is running then send params in ongoing VideoCall.
  useEffect(() => {
    AppEvents.subscribe('videoCallStatus', (params: any) => {
      if (params) {
        setVideoCallIn(params);
        ongoingVideoCall(params);
      } else {
        setVideoCallIn(false);
        ongoingVideoCall(false);
      }
    });
  }, []);

  // set default image based on role.
  useEffect(() => {
    if (cookies?.role === '3' || cookies?.role === '4') {
      setDefaultImage(orgProfileImage);
    } else {
      setDefaultImage(profileImageFile);
    }
  }, []);

  // handle navigation as per condition.
  // As per page type, navigate to user specific page.
  const handleNavigation = (page: string) => {
    if (videoCallIn) {
      open();
      return;
    } else {
      // As per page type string, navigate to user specific page.
      switch (page) {
        case 'booking':
          navigate('/my-bookings-ref');
          break;
        case 'explore_service':
          navigate('/');
          break;
        case 'earning':
          navigate('/partner/earning');
          break;
        case 'jobs':
          navigate('/my-jobs');
          break;
        case 'profile':
          // If user role type is customer or organization service customer then navigate to
          // my-profile page, otherwise navigate to partner/profile page.
          if ([RoleType.customer, RoleType.organization_service_consumer].includes(+cookies.role)) {
            navigate('/my-profile');
          } else {
            navigate('/partner/profile');
          }
          break;
        default:
          navigate('/');
      }
    }
  };
  return (
    <>
      <Menu
        width={200}
        shadow='md'
        position='bottom-end'
        withArrow
        arrowOffset={20}
        arrowSize={15}
        trigger='hover'
        transitionProps={{ transition: 'pop-top-right', duration: 200 }}>
        <Menu.Target>
          <Anchor className={mobile ? mobilemenuClass.footerCommon : menuClass.headerCommon} underline={false}>
            <ActionIcon
              className={
                location.pathname === '/my-profile' ||
                location.pathname === '/partner/profile' ||
                location.pathname === '/my-bookings' ||
                location.pathname === '/partner/earning' ||
                location.pathname === '/'
                  ? menuClass.active
                  : menuClass.icon
              }>
              <IconUser size={rem(20)} />
              {/* <Avatar src={cookies.user_Overview.image} radius='xl' /> */}
            </ActionIcon>
            <Text
              className={
                location.pathname === '/my-profile' ||
                location.pathname === '/partner/profile' ||
                location.pathname === '/my-bookings' ||
                location.pathname === '/partner/earning' ||
                location.pathname === '/'
                  ? menuClass.active
                  : menuClass.text
              }>
              {!mobile && 'Profile'}
            </Text>
          </Anchor>
        </Menu.Target>

        {/* Navigation Menu dropdown list in header top. */}
        <Menu.Dropdown py={10}>
          <Menu.Item
            icon={<IconUser size={rem(18)} />}
            onClick={() => {
              handleNavigation('profile');
            }}>
            <Text
              className={
                location.pathname === '/my-profile' || location.pathname === '/partner/profile'
                  ? menuClass.active
                  : menuClass.text
              }>
              My Profile
            </Text>
          </Menu.Item>
          {[RoleType.customer, RoleType.organization_service_consumer].includes(+cookies.role) && (
            <Menu.Item
              icon={<IconListCheck size={rem(18)} />}
              onClick={() => {
                handleNavigation('explore_service');
              }}>
              <Text className={location.pathname === '/' ? menuClass.active : menuClass.text}>Explore Service</Text>
            </Menu.Item>
          )}
          <Menu.Item
            icon={<IconCalendar size={rem(18)} />}
            onClick={() => {
              handleNavigation('booking');
            }}>
            <Text className={location.pathname === '/my-bookings-ref' ? menuClass.active : menuClass.text}>
              My Bookings
            </Text>
          </Menu.Item>
          {[RoleType.service_provider, RoleType.organization_service_provider].includes(+cookies.role) && (
            // component={NavLink} to='/partner/earning'
            <Menu.Item
              icon={<IconMoneybag size={rem(18)} />}
              onClick={() => {
                handleNavigation('earning');
              }}>
              <Text className={location.pathname === '/partner/earning' ? menuClass.active : menuClass.text}>
                My Earning
              </Text>
            </Menu.Item>
          )}
          {/* {[RoleType.customer, RoleType.organization_service_provider].includes(+cookies.role) && ( */}
          {/*  component={NavLink} to='/partner/earning' */}
          {/* <Menu.Item
            icon={<IconBriefcase size={rem(18)} />}
            onClick={() => {
              handleNavigation('jobs');
            }}>
            <Text className={location.pathname === '/my-jobs' ? menuClass.active : menuClass.text}>My Jobs</Text>
          </Menu.Item> */}
          {/* )} */}
          {/* {mobile && (
            <Menu.Item icon={<IconArrowBigUpLines size={rem(18)} />}>
              <Text onClick={() => navigate('/subscription')}>Upgrade now</Text>
            </Menu.Item>
          )} */}
          <Menu.Item icon={<IconLogout size={rem(18)} />} onClick={handleLogout}>
            Logout
          </Menu.Item>
        </Menu.Dropdown>
      </Menu>

      <EndVideoCall opened={opened} close={close} />
    </>
  );
}

const useStyles = createStyles((theme) => ({
  header: {
    boxShadow: theme.shadows.md,
  },
  inner: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    height: rem(75),
  },
  notify: {
    borderRadius: '4px',
  },
}));

// This is functional component of header top menu.
// Here we use HeaderErrorBlock as a child component.
export function HeaderTop({ removeValue }: any) {
  const [cookies] = useCookies(['role', 'user_Address', 'user_Info', 'user_Overview']);
  const { classes, cx } = useStyles();
  const { colorScheme, toggleColorScheme } = useMantineColorScheme();
  const [videoCallOn, setVideoCallOn] = useState(false);
  const navigate = useNavigate();
  const [opened, { open, close }] = useDisclosure(false);
  const mantinetheme = useMantineTheme();
  const mobile = useMediaQuery(`(max-width: ${mantinetheme.breakpoints.sm})`);

  const [notifications, setNotifications] = useState<Notification[]>([]);

  // const getAllNotification = async () => {
  //   try {
  //     const data: any = await getNotification();
  //     if (data.success) {
  //       setNotifications(data.notifications.data.filter((item: any) => !item.read));
  //     }
  //   } catch (error) {
  //     console.log(error);
  //   }
  // };

  // useEffect(() => {
  //   getAllNotification();
  // }, []);

  // const readNotificationHandler = async (e: any) => {
  //   const formData = { notification_id: e.notification_Id };
  //   const data: any = await readNotification(formData);
  //   if (data.success) {
  //     setNotifications(notifications.filter((item: any) => item.notification_Id !== e.notification_Id));
  //   }
  // };

  // To check any ongoing video call is running or not.
  // If any video call is running then set it on a state.
  useEffect(() => {
    AppEvents.subscribe('videoCallStatus', (params: any) => {
      if (params) {
        setVideoCallOn(params);
      } else {
        setVideoCallOn(false);
      }
    });
  }, []);

  // This is user profile logo on header top corner.
  // To clickon logo we show some nav menu.
  const handleLogo = () => {
    if (videoCallOn) {
      open();
      return;
    } else {
      // If user role type is customer or organization service customer then navigate to
      // my-profile page, otherwise navigate to partner/profile page.
      if ([RoleType.customer, RoleType.organization_service_consumer].includes(+cookies.role)) {
        navigate('/');
      } else {
        navigate('/partner/profile');
      }
    }
  };

  const handleSubscription = () => {
    if (videoCallOn) {
      open();
      return;
    } else {
      navigate('/subscription');
    }
  };

  return (
    <>
      <HeaderErrorBlock />
      {!mobile && (
        <Header className={classes.header} height='auto' withBorder={false}>
          <Container size='xl' className={classes.inner}>
            <Group>
              {/* <ActionIcon variant='default' onClick={handleLogo}> */}
              <Avatar
                style={{ cursor: 'pointer' }}
                onClick={handleLogo}
                size={55}
                src={colorScheme === 'dark' ? Logo : LogoLight}></Avatar>
              {/* </ActionIcon> */}
            </Group>
            {/* <HeaderErrorBlock /> */}

            {!mobile ? (
              <Group>
                <ActionIcon
                  onClick={() => toggleColorScheme()}
                  variant='transparent'
                  sx={(theme) => ({
                    color: theme.colorScheme === 'dark' ? theme.colors.yellow[4] : theme.black,
                  })}>
                  {colorScheme === 'dark' ? <IconSun size='1.2rem' /> : <IconMoonStars size='1.2rem' />}
                </ActionIcon>
                <Notification />

                {/* <Button
                  size='md'
                  type='button'
                  variant='gradient'
                  gradient={{ to: 'orange', from: 'yellow', deg: 160 }}
                  onClick={handleSubscription}>
                  Upgrade Now
                </Button> */}

                <HeaderMenu removeValue={removeValue} />
              </Group>
            ) : null}

            {/* <ProfileMenu ongoingVideoCall={ongoingVideoCall} /> */}
          </Container>

          <EndVideoCall opened={opened} close={close} />
        </Header>
      )}
    </>
  );
}
