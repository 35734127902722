import { useQuery } from '@tanstack/react-query';
import { axiosTokenInst } from './axios.config';
import {
  bookingApprove,
  bookingComplete,
  bookingEndJourney,
  bookingEndRequest,
  bookingStart,
  bookingStartJourney,
  bookingStartRequest,
} from './booking';
import { sendNotification } from './websocket.api';
import { toastMessageUpdate } from '../utils/helpers/tosterMessage';

export const fetchBookings = async () => {
  return await axiosTokenInst.get(`booking/user-specific`);
};

export const useFetchAllBookings = (enabled = true) => {
  return useQuery({
    queryKey: ['fetch-bookings'],
    queryFn: async () => {
      const data = await fetchBookings();
      const currentBookings = data.data?.filter((booking: any) => !booking.completed);
      const pastBookings = data.data?.filter((booking: any) => booking.completed === 1);
      return { currentBookings, pastBookings };
    },
    enabled,
    cacheTime: 2 * 60 * 1000,
  });
};

export const bookingUserSpec = async () => {
  return await axiosTokenInst.get(`booking/user-specific`);
};

type useFetchUserSpecificBookingsArgument = {
  userId: string;
};

export const useFetchUserSpecificBookings = ({ userId }: useFetchUserSpecificBookingsArgument) => {
  return useQuery({
    queryKey: ['fetch-user-specific-bookings', userId],
    queryFn: async () => {
      const data = await bookingUserSpec();
      // console.log(data.data);
      const response = data.data.filter((item: any) => item?.booking_id === userId);
      // console.log(response);
      return response[0];
    },
    enabled: userId !== '',
  });
};

export enum BookingAction {
  PAY = 'PAY',
  CANCEL_AFTER_PAYMENT_COMPLETE = 'CANCEL_AFTER_PAYMENT_COMPLETE',
  CANCEL_AFTER_REJECTED = 'CANCEL_AFTER_REJECTED',
  CANCEL_AFTER_APPROVED = 'CANCEL_AFTER_APPROVED',
  ACCEPT = 'ACCEPT',
  REJECT = 'REJECT',
  START_JOURNEY = 'START_JOURNEY',
  END_JOURNEY = 'END_JOURNEY',
  START_SERVICE = 'START_SERVICE',
  CONFIRM_START_SERVICE = 'CONFIRM_START_SERVICE',
  END_SERVICE = 'END_SERVICE',
  END = 'END',
}

type handleBookingActionArgs = {
  type: BookingAction;
  payload?: any;
};

export const handleBookingAction = async ({ type, payload }: handleBookingActionArgs) => {
  console.log({ type, payload });

  // return;

  const params: Record<string, any> = {};

  switch (type) {
    case BookingAction.ACCEPT:
      params.booking_id = payload?.bookingId;
      params.approval_status = 1;

      try {
        const res = await bookingApprove(params);
        await sendNotification({
          to_user: payload?.serviceTo,
          message: payload?.message,
          type: 'service',
        });
        toastMessageUpdate('success', 'Booking approved successfully.');
      } catch (error) {
        console.error(error);
      }
      return;

    case BookingAction.REJECT:
      params.booking_id = payload?.bookingId;
      params.approval_status = 0;

      try {
        const res = await bookingApprove(params);
        await sendNotification({
          to_user: payload?.serviceTo,
          message: payload?.message,
          type: 'service',
        });
        toastMessageUpdate('success', 'Booking rejected successfully.');
      } catch (error) {
        console.error(error);
      }

      return;

    case BookingAction.START_JOURNEY:
      params.booking_id = payload?.bookingId;
      try {
        await bookingStartJourney(params);
        toastMessageUpdate('success', 'Journey started successfully.');
        await sendNotification({
          to_user: payload?.serviceTo,
          message: payload?.message,
          type: 'service',
        });
      } catch (error) {
        console.error(error);
      }
      return;

    case BookingAction.END_JOURNEY:
      params.booking_id = payload?.bookingId;
      await bookingEndJourney(params);
      toastMessageUpdate('success', 'Journey ended successfully.');
      await sendNotification({
        to_user: payload?.serviceTo,
        message: payload?.message,
        type: 'service',
      });
      return;

    case BookingAction.START_SERVICE:
      params.booking_id = payload?.bookingId;

      await sendNotification({
        to_user: payload?.serviceFrom,
        message: payload?.message,
        type: 'service',
      });
      try {
        await bookingStartRequest(params);
        console.log('done');
      } catch (error) {
        console.log(error);
      }

      return;

    case BookingAction.CONFIRM_START_SERVICE:
      params.booking_id = payload?.bookingId;
      params.approval_status = true;

      try {
        await bookingStart(params);
        toastMessageUpdate('success', 'Service started successfully.');
        await sendNotification({
          to_user: payload?.serviceTo,
          message: payload?.message,
          type: 'service',
        });
      } catch (error) {
        console.error(error);
      }

      return;

    case BookingAction.END_SERVICE:
      params.booking_id = payload?.bookingId;

      try {
        await bookingEndRequest(params);
        toastMessageUpdate('success', 'Service end request sent successfully.');
        await sendNotification({
          to_user: payload?.serviceFrom,
          message: payload?.message,
          type: 'service',
        });
      } catch (error) {
        console.error(error);
      }

      return;

    case BookingAction.END:
      params.booking_id = payload?.bookingId;
      params.approval_status = true;

      try {
        await bookingComplete(params);
        toastMessageUpdate('success', 'Service completed successfully.');
        await sendNotification({
          to_user: payload?.serviceTo,
          message: payload?.message,
          type: 'service',
        });
      } catch (error) {
        console.error(error);
      }
  }
};
