import {
  ActionIcon,
  Button,
  Card,
  Divider,
  Flex,
  Grid,
  Group,
  Loader,
  LoadingOverlay,
  Text,
  createStyles,
  useMantineTheme,
} from '@mantine/core';
import { useMediaQuery } from '@mantine/hooks';
import {
  IconBriefcase,
  IconGenderBigender,
  IconHeart,
  IconHeartFilled,
  IconMapPin,
  IconRocket,
  IconUsers,
} from '@tabler/icons-react';
import { useEffect, useState } from 'react';
import { NavLink, useLocation, useParams, useSearchParams } from 'react-router-dom';
import RelatedProfiles from '../../../components/RelatedProfiles';
import { addFavouriteProvider, deletegetFavouriteProvider, useFavouriteList } from '../../../services/favourite.api';
import { reviewList } from '../../../services/review';
import { useUserServiceById } from '../../../services/service';
import { useUserOverview } from '../../../services/userProfiles';
import { getVerifications } from '../../../services/verification';
import { ReviewType, RoleType } from '../../../utils';
import decodeUserIdFromRandomizedString from '../../../utils/helpers/decodeUserIdFromRandomizedString';
import { toastMessageUpdate } from '../../../utils/helpers/tosterMessage';
import ProfileCard from './components/ProfileCard';
import { TabElementDetails } from './components/TabElementDetails';

const useCardStyles = createStyles((theme) => ({
  card: {
    backgroundColor: theme.colorScheme === 'light' ? '#ECECEC' : theme.colors.dark[8],
    height: '100%',
    [theme.fn.smallerThan(768)]: {
      borderRadius: 0,
    },
  },
}));

export default function UserCommonProfile() {
  const { encodedIdWithRandomString } = useParams();
  const decodedId = decodeUserIdFromRandomizedString(encodedIdWithRandomString);

  const { search } = useLocation();
  console.log(search);

  console.log(search);

  const [searchParams] = useSearchParams();
  const { data: profileData, isLoading, isError, isFetched } = useUserOverview(decodedId || '');
  const fn = (data: any) => console.log(data);
  const { data: serviceData, isLoading: serviceIsLoading } = useUserServiceById(decodedId || '', fn);
  const [verificationStatus, setVerificationStatus] = useState<any>({});
  const [userFeedback, setUserFeedback] = useState<any>([]);
  const theme = useMantineTheme();
  const { data: favList, isLoading: favListLoading, refetch: favRefetch } = useFavouriteList();

  const isMobile = useMediaQuery('(max-width: 576px)');
  const isTablet = useMediaQuery('(max-width: 768px)');
  const [loading, setLoading] = useState(false);
  const [favourites, setFavourites] = useState(false);
  const [favUserDetails, setFavUserDetails] = useState<any>([]);
  const [favLoading, setFavLoading] = useState(false);
  const { classes } = useCardStyles();

  console.log(favourites);

  // fetch user verification and review list.
  useEffect(() => {
    if (decodedId) {
      fetchVerification();
      fetchUserReviewList();
    }
  }, [decodedId]);

  // fetch user verification and its functionality.
  const fetchVerification = async () => {
    try {
      const verification = await getVerifications(decodedId);
      setVerificationStatus(verification.data);
    } catch (error) {
      console.log(error);
    }
  };

  // fetch review list of specific user.
  const fetchUserReviewList = async () => {
    try {
      const review = await reviewList(ReviewType.user, decodedId);
      setUserFeedback(review.data);
    } catch (error) {
      console.log(error);
    }
  };

  const handleFavourite = async (userId: undefined | string) => {
    setFavLoading(true);
    try {
      if (favourites) {
        const formData = {
          favourite_id: favUserDetails?.favourite_id,
        };
        const data: any = await deletegetFavouriteProvider(formData);
        toastMessageUpdate('success', data?.message);
        setFavLoading(false);
        setFavourites(false);
      } else {
        const formData = {
          user_id: userId,
        };
        const data: any = await addFavouriteProvider(formData);
        toastMessageUpdate('success', data?.message);
        setFavLoading(false);
        setFavourites(true);
      }
      favRefetch();
      setLoading(false);
    } catch (error: any) {
      console.log(error);
      toastMessageUpdate('error', error?.data?.message);
      setLoading(false);
      setFavLoading(false);
    }
  };

  useEffect(() => {
    getFavourites();
  }, [favList]);

  const getFavourites = async () => {
    if (favList?.data?.length) {
      const favUserIn = favList?.data?.findIndex((item: any) => item?.fav_user_id == decodedId);
      if (favUserIn > -1) {
        setFavUserDetails(favList?.data[favUserIn]);
        setFavourites(true);
      } else {
        setFavourites(false);
      }
    } else {
      setFavourites(false);
    }
  };

  return (
    <>
      <Grid gutter={5}>
        <LoadingOverlay visible={isLoading || serviceIsLoading || loading} />
        <Grid.Col md={3}>
          <Card className={classes.card}>
            <Flex justify='flex-end' mt={20}>
              {favLoading ? (
                <Loader color='orange' size='sm' />
              ) : (
                <ActionIcon onClick={() => handleFavourite(decodedId)} c='#fe7720'>
                  {favourites ? <IconHeartFilled /> : <IconHeart />}
                </ActionIcon>
              )}
            </Flex>
            <ProfileCard {...profileData?.['data']} verification={verificationStatus} userId={decodedId} />
            <Divider size='xs' my={20} />
            <Flex direction='column' align={isMobile || isTablet ? 'center' : 'start'} gap={16}>
              <Text align='right' c='orange' fw={600} size='lg'>
                Basic info
              </Text>
              {profileData?.data?.user?.type !== RoleType.organization_service_provider && (
                <Group spacing='sm'>
                  <IconGenderBigender />
                  <Text fw={600}>Gender:</Text>
                  <Text color='dimmed'>{profileData?.['data']?.['user_profile']?.['gender'] || 'N/A'}</Text>
                </Group>
              )}
              <Group spacing='sm'>
                <IconBriefcase />
                <Text fw={600}>Experience:</Text>
                <Text color='dimmed'>
                  {profileData?.data?.user_profile?.experience
                    ? profileData?.data?.user_profile?.experience + ' Years'
                    : 'N/A'}
                </Text>
              </Group>
              <Group spacing='sm'>
                <IconMapPin />
                <Text fw={600}>Location:</Text>
                <Text color='dimmed'>{profileData?.['data']?.['has_address']?.['city'] || 'N/A'}</Text>
              </Group>
              <Group spacing='sm'>
                <IconUsers />
                <Text fw={600}>Client:</Text>
                <Text color='dimmed'>{'N/A'}</Text>
              </Group>
              <Group spacing='sm'>
                <IconRocket />
                <Text fw={600}>Completed Project:</Text>
                <Text color='dimmed'>{'N/A'}</Text>
              </Group>
            </Flex>

            {searchParams.get('service_type') && (
              <Flex justify='center' align='center' my={25}>
                <Button
                  size='md'
                  w='60%'
                  variant='gradient'
                  gradient={{ from: '#fda223', to: '#fe7720' }}
                  component={NavLink}
                  to={`/book-services/${encodedIdWithRandomString}${search}`}>
                  Book Now
                </Button>
              </Flex>
            )}
          </Card>
        </Grid.Col>

        <Grid.Col md={searchParams.get('service_type') ? 5 : 9}>
          <Card p={30}>
            <Card.Section>
              {serviceData?.data.length && (
                <TabElementDetails
                  services={serviceData?.data ? serviceData?.data : null}
                  serviceLoading={serviceIsLoading}
                  userFeedback={userFeedback}
                />
              )}
            </Card.Section>
          </Card>
        </Grid.Col>

        {searchParams.get('service_type') && (
          <Grid.Col md={4}>
            <RelatedProfiles title='Related User' id={searchParams.get('service_type')} />
          </Grid.Col>
        )}
      </Grid>
    </>
  );
}
