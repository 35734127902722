import { yupResolver } from '@hookform/resolvers/yup';
import { Box, Button, Card, Flex, Grid, Radio, RangeSlider, Select, Text, TextInput, rem } from '@mantine/core';
import { IconChevronDown } from '@tabler/icons-react';
import { QueryObserverResult, RefetchOptions, RefetchQueryFilters } from '@tanstack/react-query';
import { useEffect, useState } from 'react';
import { Controller, useForm } from 'react-hook-form';
import { InferType } from 'yup';
import { useStateList } from '../../../../../services/location.api';
import { filterProfiles } from '../../../../../services/profiles';
import { useServiceTypeList } from '../../../../../services/service_types.api';
import removeNullishValuesFromObject from '../../../../../utils/helpers/removeNullishValuesFromObject';
import { filterSearchSchema } from '../../../../../utils/schema/formSchema';
import { useSearchButtonStyles, useSearchFilterStyle } from './style';

interface Props {
  getProfileList: (list: any) => void;
  resetData: () => void;
  serviceTypeId: any;
  userMills: string;
  setUserMills: React.Dispatch<React.SetStateAction<string>>;
  refetch: <TPageData>(
    options?: (RefetchOptions & RefetchQueryFilters<TPageData>) | undefined,
  ) => Promise<QueryObserverResult<any, unknown>>;
  setShowFilterProfile: React.Dispatch<React.SetStateAction<boolean>>;
}

export default function SearchFilter({
  getProfileList,
  resetData,
  serviceTypeId,
  userMills,
  setUserMills,
  refetch,
  setShowFilterProfile,
}: Props) {
  const fn = (data: any) => console.log(data);
  const { data } = useServiceTypeList(fn);
  const [serviceCategory, setServiceCategory] = useState<string[] | any>([]);
  const [loading, setLoading] = useState<boolean>(false);
  const [stateList, setStateList] = useState<any>();

  const {
    handleSubmit,
    reset,
    control,
    register,
    formState: { errors },
  } = useForm({
    resolver: yupResolver(filterSearchSchema),
    mode: 'all',
  });

  const filterSubmit = async (data: InferType<typeof filterSearchSchema>) => {
    console.log(data);

    setLoading(true);
    const formattedData = {
      service_type_id: data.category || serviceTypeId,
      state: data.location,
      starting_price: data.priceRateRange ? data.priceRateRange[0] : 1,
      ending_price: data.priceRateRange ? data.priceRateRange[1] : 100,
      experience: data.experience,
      range: data.range,
    };

    // this object will be sent to the API, this will only contain those key-value pairs where the value is not null or undefined
    const queryObject = removeNullishValuesFromObject(formattedData);
    try {
      const profileList = await filterProfiles(queryObject);
      setLoading(false);
      getProfileList(profileList.data);
      // setShowContentFilter(false);
    } catch (error: any) {
      console.log(error);
      setLoading(false);
    }
  };

  const { data: stateDataList, isFetched: isStateListFetched } = useStateList();
  useEffect(() => {
    if (stateDataList) {
      setStateList(stateDataList?.data?.states);
    }
  }, [stateDataList]);

  useEffect(() => {
    if (data?.data?.length) {
      data?.data.map((item: any, key: number) => {
        item.label = item.service.toUpperCase();
        item.value = item.service_type_id;
        return item;
      });
      setServiceCategory(data.data);
    }
  }, [data]);

  const resetAllData = () => {
    resetData();
    reset({
      range: '',
      category: '',
      experience: '',
      location: '',
      priceRateRange: [1, 100],
    });
    setUserMills('20');
    refetch();
    setShowFilterProfile(false);
    // setShowContentFilter(false);
  };

  const { classes } = useSearchButtonStyles();
  const { classes: searchFilterClasses } = useSearchFilterStyle();

  return (
    <>
      <Card mb={10} p={10}>
        <form onSubmit={handleSubmit(filterSubmit)} autoComplete='off' style={{ padding: '0' }}>
          <Box>
            <Flex direction='column' gap={12}>
              <TextInput
                {...register('range')}
                type='number'
                onKeyDown={(e) => {
                  ['e', 'E', '+', '-', '.'].includes(e.key) && e.preventDefault();
                }}
                label='Find service provider'
                placeholder='Search provider in kilometer'
              />

              <Controller
                name='category'
                control={control}
                render={({ field }) => (
                  <Select
                    {...field}
                    withAsterisk
                    label='Category'
                    placeholder='Select category'
                    rightSection={<IconChevronDown size={rem(18)} />}
                    rightSectionWidth={30}
                    data={serviceCategory}
                    // filter={(value, item) => item?.service?.toLowerCase().includes(value?.toLowerCase().trim())}
                  />
                )}
              />

              {isStateListFetched && (
                <Controller
                  name='location'
                  control={control}
                  render={({ field }) => (
                    <Select
                      {...field}
                      searchable
                      clearable
                      label='Location'
                      placeholder='Select location'
                      rightSection={<IconChevronDown size={rem(18)} />}
                      rightSectionWidth={30}
                      transitionProps={{ transition: 'fade', duration: 150, timingFunction: 'ease' }}
                      // data={stateList || []}
                      data={stateDataList?.data.states}
                    />
                  )}
                />
              )}

              <Controller
                name='priceRateRange'
                control={control}
                render={({ field }) => (
                  <>
                    <Text className={searchFilterClasses.label}>Price Rate</Text>
                    <RangeSlider
                      {...field}
                      min={1}
                      max={100}
                      label={(value) => `$${value}/hr`}
                      labelTransitionTimingFunction='ease-out'
                      labelTransitionDuration={250}
                      labelTransition='slide-up'
                    />
                  </>
                )}
              />

              <Controller
                name='experience'
                control={control}
                render={({ field }) => (
                  <Radio.Group {...field}>
                    <Text className={searchFilterClasses.label}>Experience</Text>
                    <Flex direction='column' gap={5} mt={10}>
                      <Radio value='0_1' label='0 - 1 Years' />
                      <Radio value='2_3' label='2 - 3 Years' />
                      <Radio value='4_5' label='4 - 5 Years' />
                      <Radio value='6_7' label='6 - 7 Years' />
                    </Flex>
                  </Radio.Group>
                )}
              />
            </Flex>

            <Grid p={0} m={0} gutter={5} mt={10}>
              <Grid.Col span={6}>
                <Button fullWidth type='submit' loading={loading} c='primary' variant='outline' radius={4}>
                  Search
                </Button>
              </Grid.Col>
              <Grid.Col span={6}>
                <Button fullWidth type='button' onClick={resetAllData} c='primary' variant='outline' radius={4}>
                  Reset
                </Button>
              </Grid.Col>
            </Grid>
          </Box>
        </form>
      </Card>
    </>
  );
}
