export default function makeTitleCase(text: string): string {
  const lowercasedText = text.trim().toLowerCase();
  const parts = lowercasedText.split(' ');
  const titleCasedParts = [];

  for (let i = 0; i < parts.length; ++i) {
    titleCasedParts.push(parts[i].charAt(0).toUpperCase() + parts[i].slice(1));
  }

  return titleCasedParts.join(' ');
}
