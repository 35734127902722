import { yupResolver } from '@hookform/resolvers/yup';
import {
  Box,
  Button,
  Card,
  Checkbox,
  Divider,
  Flex,
  Grid,
  Group,
  LoadingOverlay,
  Paper,
  ScrollArea,
  Text,
  TextInput,
  Textarea,
  Title,
} from '@mantine/core';
import { DatePicker, DateValue } from '@mantine/dates';
import { useDisclosure, useMediaQuery } from '@mantine/hooks';
import dayjs from 'dayjs';
import { useContext, useEffect, useState } from 'react';
import { useCookies } from 'react-cookie';
import { useForm } from 'react-hook-form';
import { useParams, useSearchParams } from 'react-router-dom';
import RelatedProfiles from '../../../../components/RelatedProfiles';
import { ThemeContext } from '../../../../context/themeContext';
import { useCreateBooking, useSlotBookedList, useSlotList } from '../../../../services/booking';
import { useSubscriptionLimitCheck } from '../../../../services/subscription.api';
import { useUserInfo } from '../../../../services/user.api';
import { useUserOverview } from '../../../../services/userProfiles';
import { useVerification } from '../../../../services/verification';
import { RoleType } from '../../../../utils';
import { convertTimeFormat } from '../../../../utils/helpers';
import { toastMessageUpdate } from '../../../../utils/helpers/tosterMessage';
import { GetWeekDates } from '../../../../utils/helpers/weeklyDate';
import { serviceDetailsSchema } from '../../../../utils/schema/formSchema';
import SubscritionError from './components/SubscritionError';
import { useServiceStyles } from './components/style';
import { useBookServiceStyles } from './style';
import onlyAllowNumbersInput from '../../../../utils/helpers/onlyAllowNumbersInput';
import decodeUserIdFromRandomizedString from '../../../../utils/helpers/decodeUserIdFromRandomizedString';

interface ISlot {
  slot_id: string;
  time_from: string;
  time_to: string;
  disabled: boolean;
}
export default function BookService() {
  const [cookie] = useCookies(['user_Info', 'subscription_status']);
  const { encodedIdWithRandomString } = useParams();
  const partnerId = decodeUserIdFromRandomizedString(encodedIdWithRandomString);
  const [searchParams] = useSearchParams();
  const { classes } = useServiceStyles();
  const [selectedDate, setSelectedDate] = useState<Date | null>(new Date());
  const [dateList, setDateList] = useState<any>(); // GetWeekDates(new Date(), 6)
  const [activeSlot, setActiveSlot] = useState<string>('');
  const [showErrorMessage, setShowErrorMessage] = useState('');
  const [opened, { open, close }] = useDisclosure(JSON.parse(cookie.subscription_status));
  const { data: verificationData } = useVerification(cookie?.user_Info?.id);
  const [userVerified, setUserVerified] = useState(false);
  const [openVerificationModal, setOpenVerificationModal] = useState(false);
  const { setSubscriptionExhausted, selectedServiceName, setSelectionVerification } = useContext(ThemeContext);
  const [updatedBookingSlot, setUpdatedBookingSlot] = useState<any>([]);

  const {
    register,
    handleSubmit,
    reset,
    control,
    formState: { errors },
    setValue,
    watch,
  } = useForm({
    resolver: yupResolver(serviceDetailsSchema),
    mode: 'all',
    defaultValues: {
      // booking_type: '2',
      providers_quantity: '1',
    },
  });

  const { data: freelancerData } = useUserOverview(partnerId || '');

  useEffect(() => {
    setDateList(GetWeekDates(new Date(), 6));
  }, []);

  // Handle week dates and listing next 6 days
  const dateHandler = (e: DateValue) => {
    setSelectedDate(e);
    setDateList(GetWeekDates(e, 6));
  };
  const addBooking = useCreateBooking();
  const { data, isLoading, isError, isSuccess, refetch: userRefetch } = useUserInfo();
  const { data: slotList, isLoading: slotListLoading } = useSlotList();

  const isMobile = useMediaQuery('(max-width: 576px)');

  const { data: bookedSlot, isLoading: bookedSlotLoading } = useSlotBookedList(
    partnerId || '',
    {
      booking_date: dayjs(selectedDate).format('YYYY-MM-DD'),
    },
    slotList?.data,
  );

  useEffect(() => {
    // if (slotList?.data) {
    //   const filterIn = slotList.data.findIndex(
    //     (item: any) => item?.time_from.split(':')[0] > dayjs(new Date()).format('HH'),
    //   );
    // }

    console.log(slotList?.data);

    getPresentToFutureSlot();
  }, [slotList, bookedSlot]);

  const getPresentToFutureSlot = () => {
    const today = dayjs(new Date()).format('YYYY-MM-DD');
    const selectDate = dayjs(selectedDate).format('YYYY-MM-DD');
    const currentTime = dayjs(new Date(Date.now() + 3 * 60 * 60 * 1000)).format('HH:mm');
    setActiveSlot('');
    if (today === selectDate) {
      const result = bookedSlot?.data?.filter((item: any) => item?.time_from >= currentTime);
      setUpdatedBookingSlot(result);
    } else {
      setUpdatedBookingSlot(bookedSlot?.data);
    }
  };

  const { data: subscriptionLimitData } = useSubscriptionLimitCheck(true);

  // set user details in address form
  useEffect(() => {
    if (isSuccess) {
      const info = data;
      setValue('name', info?.data?.user?.name || '');
      setValue('email', info?.data?.user?.email || '');
      setValue('phoneNumber', info?.data?.user?.mobile || '');
      setValue('address', info?.data?.has_address?.address || '');
    }
  }, [data]);

  // verification functionality
  useEffect(() => {
    if (verificationData?.data?.user_verification) {
      setUserVerified(true);
    }
    if (!verificationData?.data?.user_verification) {
      if (!JSON.parse(cookie?.subscription_status)) {
        setOpenVerificationModal(true);
      } else {
        setOpenVerificationModal(false);
      }
    }
  }, [verificationData]);

  // add service details and call the booking api.
  const addServiceDetails = async (data: any) => {
    // if (subscriptionLimitData?.data?.service_exausted) {
    //   setSubscriptionExhausted(subscriptionLimitData);
    //   toastMessageUpdate(
    //     'info',
    //     'Maximum users are allowed in your plan to book service on a given day, please upgrade to continue. ',
    //   );
    //   return;
    // } else {

    if (activeSlot) {
      // data.serviceSlot = activeSlot;
      // data.serviceDate = dayjs(selectedDate).format('DD/MM/YYYY');
      setShowErrorMessage('');
      const formData = {
        // booking_type: data?.booking_type,
        booking_type: '2',
        service_id: searchParams.get('service_id'),
        service_type_id: searchParams.get('service_type'),
        service_from: partnerId,
        booking_date: dayjs(selectedDate).format('YYYY-MM-DD'),
        slot_id: activeSlot,
        providers_quantity: data?.providers_quantity,
      };
      if (!data.verification) {
        addBooking.mutate(formData);
      }
    } else {
      setShowErrorMessage('Please select a time slot.');
      return;
    }
    // }
  };

  // checking the available slot.
  const isSlotAvailable = () => {
    if (!bookedSlotLoading) {
      const data = bookedSlot?.data?.every((item: ISlot) => item.disabled);
      if (data) {
        return (
          <Text fz={15} pt={6} pl={25} fw={500} color='red'>
            No slot available. Please select another available date.
          </Text>
        );
      }
    }
  };

  // address update functionality.
  const addressUpdate = (params: any) => {
    if (params) {
      userRefetch();
    }
  };

  const { classes: bookServiceClasses, cx } = useBookServiceStyles();

  return (
    <Grid m={0}>
      {/* <SubscritionError closeHandler={close} opened={opened && !(isLoading && slotListLoading)} /> */}
      {/* <VerificationError
        close={() => setOpenVerificationModal(false)}
        opened={openVerificationModal && !(isLoading && slotListLoading)}
        verification={verificationData}
      /> */}
      <Grid.Col md={7} lg={8}>
        <Paper p='xs'>
          <Flex direction='column' gap={4} className={cx(bookServiceClasses.serviceDetailsFlexContainer)}>
            <Group spacing={6}>
              <Text fw={600}>Service:</Text>
              <Text>{atob(searchParams.get('name') as string)}</Text>
            </Group>
            <Group spacing={6}>
              <Text fw={600}>Service Provider:</Text>
              <Text>{freelancerData?.data.name}</Text>
            </Group>
          </Flex>
          <Title my='xs' order={4}>
            Select date
          </Title>

          <ScrollArea maw={'100%'} w={'620px'} type='never'>
            <ul className={classes.list_container}>
              {dateList?.map((date: any, index: number) => {
                return (
                  <li
                    onClick={() => {
                      setSelectedDate(date);
                    }}
                    key={index}
                    className={date.getDate() === selectedDate?.getDate() ? classes.activeDate : ''}>
                    <Flex direction='column' ml='xl'>
                      <Text size='lg' className='heading-font'>
                        {dayjs(date).format('ddd')}
                      </Text>
                      <Text>{dayjs(date).format('DD')}</Text>
                    </Flex>
                  </li>
                );
              })}
            </ul>
          </ScrollArea>

          <Divider my='xs' />
          <ul className={classes.slot_container}>
            {!bookedSlotLoading && updatedBookingSlot?.length > 0 ? (
              <>
                {updatedBookingSlot?.map((slot: ISlot) => {
                  return (
                    <Button
                      disabled={slot?.disabled}
                      variant='outline'
                      component='li'
                      key={slot.slot_id}
                      onClick={() => {
                        setActiveSlot(slot.slot_id);
                        setShowErrorMessage('');
                      }}
                      className={activeSlot === slot.slot_id ? 'active' : ''}>
                      <Text fw={500} size='sm'>
                        {convertTimeFormat(slot?.time_from)} - {convertTimeFormat(slot?.time_to)}
                      </Text>
                    </Button>
                  );
                })}
              </>
            ) : (
              <>
                {!bookedSlotLoading && (
                  <Text fz={15} pt={6} pl={25} fw={500} color='red'>
                    No slot available for today. Please select another available date.
                  </Text>
                )}
              </>
            )}
          </ul>
          <Text fz={15} pt={6} pl={42} fw={500} color='red'>
            {showErrorMessage}
          </Text>
          {isSlotAvailable()}
        </Paper>

        {!data?.data?.has_address?.address && (
          <>
            <Paper shadow='xs' withBorder p='xs' mt={50} mb={20}>
              {/* <Address updateAddressDetails={addressUpdate} /> */}
            </Paper>
            <Text fz={13}>
              <span style={{ color: 'red' }}>*</span> Please add your full address in the address information to
              continue booking.
            </Text>
          </>
        )}

        <form onSubmit={handleSubmit(addServiceDetails)} autoComplete='off'>
          <Paper>
            <LoadingOverlay visible={isLoading || bookedSlotLoading} />
            <Title my='xs' order={3}>
              Add Details
            </Title>
            <Box mt={30}>
              <Grid gutter='md'>
                {/* <Grid.Col md={6}>
                  <Controller
                    name='booking_type'
                    control={control}
                    render={({ field }) => (
                      <Select
                        label='Book Service For'
                        size='md'
                        {...field}
                        placeholder='Book Service'
                        rightSection={<IconChevronDown size='1rem' />}
                        rightSectionWidth={30}
                        styles={{ rightSection: { pointerEvents: 'none' } }}
                        data={[
                          {
                            value: '1',
                            label: 'For now',
                          },
                          {
                            value: '2',
                            label: 'For later',
                          },
                        ]}
                      />
                    )}
                  />
                  {errors?.name && (
                    <Text fz={14} pt={8} pl={5} fw={500} color='red'>
                      {errors?.name?.message}
                    </Text>
                  )}
                </Grid.Col> */}
                {RoleType.organization_service_consumer === cookie?.user_Info?.type &&
                  freelancerData?.data?.user?.type === RoleType.organization_service_provider && (
                    <>
                      <Grid.Col md={6}>
                        <TextInput
                          size='md'
                          onKeyDown={onlyAllowNumbersInput}
                          {...register('providers_quantity')}
                          placeholder='Number of service'
                          label='Number of service'
                          radius='md'
                        />
                        {errors?.providers_quantity && (
                          <Text fz={14} pt={8} pl={5} fw={500} color='red'>
                            {errors?.providers_quantity?.message}
                          </Text>
                        )}
                      </Grid.Col>
                    </>
                  )}
                <Grid.Col md={6}>
                  <TextInput
                    readOnly
                    size='md'
                    {...register('name')}
                    placeholder='Your Name'
                    label='Name'
                    radius='md'
                  />
                  {errors?.name && (
                    <Text fz={14} pt={8} pl={5} fw={500} color='red'>
                      {errors?.name?.message}
                    </Text>
                  )}
                </Grid.Col>
                <Grid.Col md={6}>
                  <TextInput
                    readOnly
                    size='md'
                    {...register('phoneNumber')}
                    placeholder='Your Phone number'
                    label='Phone number'
                    radius='md'
                  />
                  {errors?.phoneNumber && (
                    <Text fz={14} pt={8} pl={5} fw={500} color='red'>
                      {errors?.phoneNumber?.message}
                    </Text>
                  )}
                </Grid.Col>

                <Grid.Col md={6}>
                  <TextInput
                    readOnly
                    size='md'
                    {...register('email')}
                    placeholder='Your email'
                    label='Email'
                    radius='md'
                  />
                  {errors?.email && (
                    <Text fz={14} pt={8} pl={5} fw={500} color='red'>
                      {errors?.email?.message}
                    </Text>
                  )}
                </Grid.Col>
                <Grid.Col md={6}>
                  <Textarea
                    readOnly
                    autosize
                    {...register('address')}
                    rows={1}
                    placeholder='Your Address'
                    label='Address'
                  />
                  {errors?.address && (
                    <Text fz={14} pt={8} pl={5} fw={500} color='red'>
                      {errors?.address?.message}
                    </Text>
                  )}
                </Grid.Col>
                {!freelancerData?.data?.verification ? (
                  <Grid.Col md={12}>
                    <Card
                      shadow='sm'
                      py={0}
                      mb={35}
                      withBorder
                      className={cx(bookServiceClasses.serviceDetailsFlexContainer)}>
                      <Box mt={20}>
                        <Group position='apart'>
                          <Text>Provider was not verified</Text>
                        </Group>
                      </Box>
                      <Divider mt={20}></Divider>{' '}
                      <Box my={20}>
                        <Checkbox
                          onClick={(e: any) => setSelectionVerification(e.target.checked)}
                          label='I Would like to pay for this provider background checked for an ONE TIME FEE of 32.99 as I Understand that 99% of
                      People hiring someone would like their background verified.'
                        />
                      </Box>
                    </Card>
                  </Grid.Col>
                ) : (
                  <></>
                )}
              </Grid>
            </Box>
          </Paper>
          <Group mt='sm' position='left' spacing={15}>
            {/* {JSON.parse(cookie.subscription_status) === true ? (
              <Button
                type='button'
                size='md'
                w={isMobile ? `100%` : 150}
                h={isMobile ? 45 : 55}
                radius={4}
                variant='gradient'
                gradient={{ to: '#fda223', from: '#fe7720', deg: 270 }}
                onClick={open}>
                Book Now
              </Button>
            ) : (
              <> */}
            <Button
              type='submit'
              size='md'
              w={isMobile ? `100%` : 150}
              radius={4}
              loading={addBooking.isLoading}
              h={isMobile ? 35 : 55}
              variant='gradient'
              gradient={{ to: '#fda223', from: '#fe7720', deg: 270 }}>
              {!addBooking.isLoading && 'Book Now'}
            </Button>
            {/* </>
            )} */}
          </Group>
        </form>
      </Grid.Col>
      <Grid.Col md={5} lg={4} p={0} m={0}>
        <Box p='md' h={'calc(100svh - 150px)'} style={{ border: '1px solid #d9d9d9', borderRadius: 10 }}>
          <Flex direction='column' justify='center' align='center'>
            <DatePicker value={selectedDate} onChange={dateHandler} minDate={new Date()} />
            <Divider my='lg' />
            <RelatedProfiles id={searchParams.get('service_type')} withBorder={false} title='Available users' />
          </Flex>
        </Box>
      </Grid.Col>
    </Grid>
  );
}
