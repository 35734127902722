import { useQuery } from '@tanstack/react-query';
import { catchHandler } from './../utils/helpers/index';
import { axiosTokenInst } from './axios.config';
import { SelectItem } from '@mantine/core';

export const orgStatus = catchHandler(async (user_id: any) => {
  return await axiosTokenInst.get(`organization/status`, {
    params: {
      user_id,
    },
  });
});

export const createOrg = async (formData: object) => {
  try {
    return await axiosTokenInst.post(`organization/create`, formData);
  } catch (error) {
    return Promise.reject(error);
  }
};

export const createSubscription = async (formData: object) => {
  try {
    return await axiosTokenInst.post(`organization/subscription`, formData);
  } catch (error) {
    return Promise.reject(error);
  }
};

export const updateOrg = async (formData: object) => {
  try {
    return await axiosTokenInst.post(`organization/update`, formData);
  } catch (error) {
    return Promise.reject(error);
  }
};

export const createOrgUser = async (formData: object) => {
  try {
    return await axiosTokenInst.post(`organization/addUserToOrganization`, formData);
  } catch (error) {
    return Promise.reject(error);
  }
};

export const getOrguser = catchHandler(async (organization_id: any) => {
  return await axiosTokenInst.get(`organization/getOrganizationUser`, {
    params: {
      organization_id,
    },
  });
});

export const deleteOrgUser = async (formData: Record<string, any>) => {
  try {
    const data = {
      user_id: formData.user_id,
      organization_id: formData.organization_id,
    };
    return await axiosTokenInst.delete(`organization/deleteOrganizationUser`, { data });
  } catch (error) {
    return Promise.reject(error);
  }
};

export const orgSpecificServices = catchHandler(async (user_id: any) => {
  return await axiosTokenInst.get(`service/user-service-list/${user_id}`);
});

export const addServisesforOrgUser = async (formData: object) => {
  try {
    return await axiosTokenInst.post(`organization/addServiceToOrganizationUser`, formData);
  } catch (error) {
    return Promise.reject(error);
  }
};

export const deleteServisesforOrgUser = async (formData: object) => {
  try {
    return await axiosTokenInst.post(`organization/deleteServiceForOrganizationUser`, formData);
  } catch (error) {
    return Promise.reject(error);
  }
};

export const useOrgUesrList = (data: any) => {
  return useQuery({
    queryKey: ['org-user-list'],
    queryFn: () => getOrguser(data),
    enabled: data !== '',
  });
};

type UseSpecificServicesArgType =
  | {
      type: 'organization-solo';
      orgId: any;
    }
  | {
      type: 'organization-provider';
      providerId: any;
    };

export const useSpecificServices = (arg: UseSpecificServicesArgType) => {
  return useQuery({
    queryKey: ['specific-service', arg.type === 'organization-solo' ? arg.orgId : arg.providerId],
    queryFn: async (): Promise<SelectItem[]> => {
      const data = await orgSpecificServices(arg.type === 'organization-solo' ? arg.orgId : arg.providerId);
      return data.data.map((service: any) => {
        return {
          value: service.service_type_id,
          label: service.has_service_type.service,
        };
      });
    },
    enabled: Boolean(arg.type === 'organization-solo' ? arg.orgId : arg.providerId),
  });
};
