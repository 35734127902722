import { Badge, Box, Button, Card, Flex, Grid, Group, Text, Title, useMantineTheme } from '@mantine/core';
import { useNavigate } from 'react-router-dom';
import { applyJobService, getJobByUserId } from '../../../../services/job.api';
import { useCookies } from 'react-cookie';
import { RoleType } from '../../../../utils';
import { toastMessageUpdate } from '../../../../utils/helpers/tosterMessage';
import { useState } from 'react';

const MyJobs = () => {
  const theme = useMantineTheme();
  const navigate = useNavigate();
  const [cookies] = useCookies(['role']);
  const { data, isLoading } = getJobByUserId(
    [RoleType.customer, RoleType.organization_service_consumer].includes(+cookies.role) ? 'user' : 'provider',
  );
  console.log(data);

  const [loading, setLoading] = useState<boolean>(false);

  const applyJob = async () => {
    setLoading(true);
    const formData = {
      job_id: 1,
      cover_letter: 'I am very interested in this job.',
      status: 'pending',
    };
    return;
    try {
      const res: any = await applyJobService();
      if (res?.status) {
        toastMessageUpdate('success', res?.message);
        setLoading(false);
        navigate('my-jobs');
      }
    } catch (error) {
      console.log(error);
      setLoading(false);
    }
    console.log('fsfsffsfsf');
  };

  return (
    <>
      <Grid gutter={30} m={0}>
        {!isLoading && data.success ? (
          <>
            <Grid.Col lg={6} md={6} sm={6} xs={12}>
              {data?.data?.map((item: any, index: number) => {
                return (
                  <Grid.Col key={index}>
                    <Card bg={theme.colorScheme === 'light' ? '#ECECEC' : theme.colors.dark[6]} radius='md' withBorder>
                      <Flex direction='column' gap={10}>
                        <Group position='apart' mb='xs'>
                          <Title order={4} weight={500}>
                            {item.title}
                          </Title>
                          <Badge color='green' variant='light'>
                            {item.status}
                          </Badge>
                        </Group>
                        <Text size='md' color='dimmed'>
                          {item.location}
                        </Text>
                        <Text size='md' color='dimmed'>
                          {item.description} With Fjord Tours you can explore more of the magical fjord landscapes with
                          tours and activities on and around the fjords of Norway
                        </Text>
                        <Flex gap={10}>
                          <Button
                            variant='light'
                            fullWidth
                            radius={4}
                            onClick={(e) => {
                              console.log(e);
                              // navigate(`/my-jobs${}`)
                            }}>
                            <Text c='orange.8'>View Job</Text>
                          </Button>
                          {[RoleType.service_provider, RoleType.organization_service_provider].includes(
                            +cookies.role,
                          ) ? (
                            <Button
                              variant='light'
                              radius={4}
                              fullWidth
                              color='orange'
                              loading={loading}
                              onClick={(item) => applyJob()}>
                              <Text c='orange.8'>{!loading && 'Apply'}</Text>
                            </Button>
                          ) : (
                            <></>
                          )}
                        </Flex>
                      </Flex>
                    </Card>
                  </Grid.Col>
                );
              })}
            </Grid.Col>
            <Grid.Col lg={6} md={6} sm={6} xs={12}>
              <Card
                bg={theme.colorScheme === 'light' ? '#ECECEC' : theme.colors.dark[6]}
                radius='md'
                withBorder
                mt={15}>
                <Text>View Job</Text>
              </Card>
            </Grid.Col>
          </>
        ) : (
          <Grid.Col>
            <Flex justify='center'>
              <Text>No job found</Text>
            </Flex>
          </Grid.Col>
        )}
      </Grid>
    </>
  );
};

export default MyJobs;
