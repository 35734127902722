import { Group, Modal, Stack, Text, Button, Title } from '@mantine/core';
import React from 'react';
import { useModalStyles } from '../pages/Dashboard/MyBooking/components/style';

// If any video call is running, user want to move other section, then show this modal
// to close the video call.
const EndVideoCall = ({ opened, close }: any) => {
  const { classes } = useModalStyles();

  return (
    <>
      <Modal centered closeOnClickOutside={false} closeOnEscape={false} size='md' opened={opened} onClose={close}>
        <Stack>
          <Title ta='center' mb={20}>
            End Video Call
          </Title>
          <Text size={18} align='center' color='black' pt='10px'>
            Please end your ongoing video call?
          </Text>

          <Group spacing={20} position='center' my='xl' mt='xl'>
            <Button
              size='md'
              w='40%'
              radius={4}
              variant='gradient'
              gradient={{ to: '#fda223', from: '#fe7720', deg: 270 }}
              onClick={() => {
                close();
              }}>
              OK
            </Button>
            {/* <Button size='md' radius='md' onClick={close} color='red'>
              No
            </Button> */}
          </Group>
        </Stack>
      </Modal>
    </>
  );
};

export default EndVideoCall;
