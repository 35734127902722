import { Avatar, Box, Button, Flex, Group, Text, Tooltip } from '@mantine/core';
import { useMediaQuery } from '@mantine/hooks';
import { IconArrowUpRight, IconCalendarCheck, IconClockHour5, IconFileInvoice } from '@tabler/icons-react';
import dayjs from 'dayjs';
import { Link, useLocation, useNavigate } from 'react-router-dom';
import { useBookingContext } from '../../../../../context/booking-context';
import { convertTimeFormat, serviceIconHandler } from '../../../../../utils/helpers';
import { useBookingCardStyles } from '../style';
import getStatusOfTheBooking, { BookingStatus } from '../../../../../utils/helpers/getStatusOfTheBooking';
import { useCookies } from 'react-cookie';
import BookingStatusOld from '../../components/BookingStatusOld';
import { providerRole } from '../../../../../utils';

interface Props {
  data: any;
  type: 'current' | 'history';
}

export default function BookingCard({ data, type }: Props) {
  const location = useLocation();
  const navigate = useNavigate();
  const isMobile = useMediaQuery(`(max-width: 768px)`);
  const [cookie, _] = useCookies(['role']);

  const {
    allGridColStyles,
    dispatchGridStyle,
    setShowMap,
    setShowAddOrApproveItems,
    setShowBookingDetails,
    setSelectedBooking,
    selectedBooking,
  } = useBookingContext();

  const { classes, cx } = useBookingCardStyles();

  const handleViewBooking = () => {
    setShowAddOrApproveItems(false);
    setShowMap(false);
    setShowBookingDetails(true);
    setSelectedBooking(data);

    const statusOfThisBooking = getStatusOfTheBooking(data, +cookie.role).status;
    const existingQueryParams = new URLSearchParams(location.search);
    if (existingQueryParams.has('opened')) {
      existingQueryParams.set('opened', data?.booking_id);
    } else {
      existingQueryParams.append('opened', data?.booking_id);
    }
    navigate({ search: existingQueryParams.toString() });

    if ([BookingStatus.JOURNEY_STARTED].includes(statusOfThisBooking)) {
      if (!isMobile) {
        dispatchGridStyle({
          type: 'SET_GRID_STYLES_FOR_OPENING_BOOKING_WITH_MAP',
        });
      }

      if (statusOfThisBooking === BookingStatus.JOURNEY_STARTED) {
        setShowMap(true);
      }
      // else if (statusOfThisBooking === BookingStatus.APPROVED) {
      //   setShowAddOrApproveItems(true);
      // }
    } else {
      dispatchGridStyle({
        type: 'SET_GRID_STYLES_FOR_OPENING_REGULAR_BOOKING',
      });
      setShowMap(false);
      setShowAddOrApproveItems(false);
    }
  };

  return (
    <>
      <Box
        className={cx(classes.card, {
          [classes.activeCard]: data?.booking_id === selectedBooking?.booking_id,
          [classes.inactiveCard]: data?.booking_id !== selectedBooking?.booking_id,
        })}>
        <Flex direction='column'>
          <Flex justify='space-between'>
            <Flex gap={8} className={classes.serviceNameAndIconContainerFlex}>
              <Avatar src={serviceIconHandler(data?.service_type?.service)} />
              <Text className={classes.serviceNameText}>{data?.service_type?.service}</Text>
            </Flex>

            {/* <Box>
              {type === 'history' ? (
                <>
                  <Tooltip label='Get invoice'>
                    <Link to='/invoice' target='_blank' style={{ textDecoration: 'none', color: '#616161' }}>
                      <IconFileInvoice />
                    </Link>
                  </Tooltip>
                </>
              ) : null}
            </Box> */}
          </Flex>

          <Flex justify='space-between'>
            <BookingStatusOld data={data} />
            <Text fw='bold'>${data.amount}/hr</Text>
          </Flex>

          <Flex mt={16} justify='space-between'>
            <Group spacing={2}>
              <IconClockHour5 color='#373A40' className={classes.icon} />
              <Text c='dimmed' fz={13}>
                {convertTimeFormat(data?.slot?.time_from)} - {convertTimeFormat(data?.slot?.time_to)}
              </Text>
            </Group>

            <Group spacing={2}>
              <IconCalendarCheck color='#373A40' className={classes.icon} />
              <Text c='dimmed' fz={13}>
                {dayjs(data?.booking_date).format(`MMM DD, YYYY`)}
              </Text>
            </Group>
          </Flex>

          <Flex className={classes.viewBookingButtonFlexContainer}>
            <Button
              variant='subtle'
              rightIcon={<IconArrowUpRight size={20} />}
              onClick={handleViewBooking}
              disabled={providerRole[1] === +cookie.role}
              compact
              size='sm'>
              View Booking
            </Button>
          </Flex>
        </Flex>
      </Box>
    </>
  );
}
