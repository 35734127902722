import { Drawer, Grid } from '@mantine/core';
import { useMediaQuery } from '@mantine/hooks';
import { useCookies } from 'react-cookie';
import { useNavigate } from 'react-router-dom';
import { useBookingContext } from '../../../../../context/booking-context';
import { useFetchUserOverview } from '../../../../../services/userProfiles';
import { providerRole } from '../../../../../utils';
import { useEncodedUserId } from '../../../../../utils/hooks/useEncodedId';
import { useBookingDetailsContainerStyles } from '../style';
import BookingDetails from './BookingDetails';

interface Props {
  refetch: any;
}

export default function BookingDetailsContainer({ refetch }: Props) {
  const {
    showBookingDetails,
    setSelectedBooking,
    setShowBookingDetails,
    selectedBooking,
    allGridColStyles,
    setShowMap,
    setShowAddOrApproveItems,
    dispatchGridStyle,
  } = useBookingContext();
  const isMobile = useMediaQuery(`(max-width: 768px)`);
  const [cookie, _] = useCookies(['role']);

  const navigate = useNavigate();

  const { data: userOverviewData, isLoading: isUserOverviewLoading } = useFetchUserOverview({
    userId: providerRole.includes(+cookie.role) ? selectedBooking?.service_to : selectedBooking?.service_from,
    enableCondition: selectedBooking !== null && selectedBooking !== undefined,
  });

  const encodedUserId = useEncodedUserId({
    data: selectedBooking,
    userId: providerRole.includes(+cookie.role) ? selectedBooking?.service_to : selectedBooking?.service_from,
  });

  const handleClose = () => {
    setSelectedBooking(null);
    setShowBookingDetails(false);
    setShowMap(false);
    setShowAddOrApproveItems(false);

    if (isMobile) {
      console.log('i');
    } else {
      dispatchGridStyle({
        type: 'SET_STYLES_TO_DEFAULT',
      });
    }

    const queryParams = new URLSearchParams(location.search);
    queryParams.delete('opened');
    navigate({ search: queryParams.toString() });
  };

  const { classes } = useBookingDetailsContainerStyles();

  return (
    <>
      {/* This will appear on desktop devices - from 769px upto anything */}
      {!isMobile && showBookingDetails ? (
        <>
          <Grid.Col {...allGridColStyles.parentRightGridColumnStyles} className={classes.childGridColTwo}>
            <BookingDetails
              refetch={refetch}
              onClose={handleClose}
              encodedUserId={encodedUserId}
              isUserOverviewLoading={isUserOverviewLoading}
              userOverviewData={userOverviewData}
            />
          </Grid.Col>
        </>
      ) : null}

      {/* This will appear on mobile devices - from 0px upto 768px */}
      <Drawer
        opened={isMobile && showBookingDetails}
        onClose={handleClose}
        position='bottom'
        size='xl'
        withCloseButton={false}
        transitionProps={{ transition: 'slide-up', duration: 250, timingFunction: 'ease' }}
        styles={(theme) => ({
          body: {
            padding: 16,
          },
        })}>
        <BookingDetails
          refetch={refetch}
          onClose={handleClose}
          encodedUserId={encodedUserId}
          isUserOverviewLoading={isUserOverviewLoading}
          userOverviewData={userOverviewData}
        />
      </Drawer>
    </>
  );
}
