import { Box, Grid, ScrollArea, Tabs, Text, Title, type TabsValue } from '@mantine/core';
import { useSearchParams } from 'react-router-dom';
import { useBookingContext } from '../../../../../context/booking-context';
import { useAllBookingsScrollAreaStyles, useAllBookingsStyles } from '../style';
import ShowBookings from './ShowBookings';

interface Props {
  data:
    | {
        currentBookings: any;
        pastBookings: any;
      }
    | undefined;

  isLoading: boolean;
}

export default function AllBookings({ data, isLoading }: Props) {
  const { setActiveTab, activeTab, setShowBookingDetails, setSelectedBooking, allGridColStyles, dispatchGridStyle } =
    useBookingContext();
  const { classes, cx } = useAllBookingsStyles();
  const { classes: allBookingsScrollAreaStyles } = useAllBookingsScrollAreaStyles();

  const [searchParams, setSearchParams] = useSearchParams();

  const handleTabChange = (value: TabsValue) => {
    if (value) {
      setSearchParams({ tab: value });
      setActiveTab(value);
      setShowBookingDetails(false);
      setSelectedBooking(null);
      dispatchGridStyle({ type: 'SET_STYLES_TO_DEFAULT' });
    }
  };

  return (
    <>
      <Grid.Col {...allGridColStyles.parentLeftGridColumnStyles} className={cx(classes.childGridColOne)}>
        <Title order={2} mt={4}>
          My Bookings
        </Title>

        <Tabs defaultValue='current' value={activeTab} onTabChange={handleTabChange}>
          <Tabs.List className={classes.tabsList}>
            <Tabs.Tab fw={600} value='current'>
              <Text>Current</Text>
            </Tabs.Tab>
            <Tabs.Tab fw={600} value='history'>
              <Text>History</Text>
            </Tabs.Tab>
          </Tabs.List>

          <Tabs.Panel value='current'>
            <Box mt={4}>
              <ScrollArea.Autosize classNames={allBookingsScrollAreaStyles} scrollbarSize={0} type='never'>
                <Grid p={0} m={0}>
                  {!isLoading && !data?.currentBookings.length ? (
                    <Text mt={15} ml={15}>
                      Booking not found!
                    </Text>
                  ) : (
                    <ShowBookings data={data?.currentBookings} isLoading={isLoading} type='current' />
                  )}
                </Grid>
              </ScrollArea.Autosize>
            </Box>
          </Tabs.Panel>

          <Tabs.Panel value='history'>
            <Box mt={4}>
              <ScrollArea.Autosize classNames={allBookingsScrollAreaStyles} scrollbarSize={2}>
                <Grid p={0} m={0}>
                  {!isLoading && !data?.pastBookings.length ? (
                    <Text mt={15} ml={15}>
                      Booking not found!
                    </Text>
                  ) : (
                    <ShowBookings data={data?.pastBookings} isLoading={isLoading} type='history' />
                  )}
                </Grid>
              </ScrollArea.Autosize>
            </Box>
          </Tabs.Panel>
        </Tabs>
      </Grid.Col>
    </>
  );
}
