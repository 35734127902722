import { createStyles } from '@mantine/core';

export const useTabElementStyle = createStyles((theme) => ({
  listContainer: {
    listStyle: 'none',
    padding: 0,
    fontSize: theme.fontSizes.sm,
    color: theme.colors.gray[6],
    li: {
      '&:not(:last-child)': {
        marginBottom: 30,
      },
    },
  },
  thumb: {
    visibility: 'hidden',
  },

  tabsList: {
    display: 'flex',
    justifyContent: 'space-between',
  },

  bookNowBtn: {
    paddingLeft: '0.45em',
  },
}));

export const useCommentCardStyles = createStyles((theme) => ({
  container: {
    padding: 10,
    marginInline: 8,
    backgroundColor: theme.colorScheme === 'light' ? '#f0f0f0' : theme.colors.dark[7],
  },
}));
