import {
  Box,
  Button,
  Divider,
  Drawer,
  Flex,
  Grid,
  Group,
  MediaQuery,
  NavLink,
  Paper,
  Rating,
  ScrollArea,
  Skeleton,
  Stack,
  Tabs,
  Text,
  TextInput,
  Textarea,
  Title,
  createStyles,
  keyframes,
  useMantineTheme,
} from '@mantine/core';
import { useDisclosure, useMediaQuery } from '@mantine/hooks';
import {
  IconArrowLeft,
  IconBrandWhatsapp,
  IconClearAll,
  IconMail,
  IconMessageDots,
  IconMessages,
  IconPhone,
  IconX,
} from '@tabler/icons-react';
import dayjs from 'dayjs';
import { useContext, useEffect, useState } from 'react';
import { useBookingUserSpec } from '../../../services/booking';
import { reviewList } from '../../../services/review';
import { ReviewType, customerRole } from '../../../utils';
import { convertTimeFormat } from '../../../utils/helpers';
import BookingCard from './components/BookingCard';
import { useUserOverview } from '../../../services/userProfiles';
import { useCookies } from 'react-cookie';
import DetailsModal from './components/DetailsModal';
import BookingDetails from './components/BookingDetails';
import { ThemeContext } from '../../../context/themeContext';
import React from 'react';

enum StatusEnum {
  completed = 'completed',
  pending = 'pending',
  cancelled = 'cancelled',
  inProgress = 'in progress',
}

// function CardView() {
//   const [opened, { open, close }] = useDisclosure(false);
//   const [cookie, setCookie] = useCookies(['role', 'peerId', 'bookingDetails']);
//   const [bookingId, setBookingId] = useState<string>('');
//   const [bookingData, setBookingData] = useState<Record<string, any> | null>(null);
//   const { data, isLoading, isError, refetch } = useBookingUserSpec();
//   const [pageLoading, setPageLoading] = useState(true);
//   const [reviewData, setReviewData] = useState<any>([]);

//   const { data: userProfile, isLoading: userIsLoading } = useUserOverview(
//     (opened && customerRole.includes(+cookie.role) ? data?.data?.service_from : data?.data?.service_to) || '',
//   );

//   // handle user specific booking data and fetch the review list
//   const modalHandler = (id: string) => {
//     const currentIn = data?.data.findIndex((item: Record<string, any>) => item?.booking_id === id);
//     console.log(currentIn);
//     console.log(opened);

//     setBookingId(id);
//     open();
//     if (currentIn > -1) {
//       setBookingData(data?.data[currentIn]);
//       fetchReview(data?.data[currentIn].booking_id);
//     }
//   };

//   useEffect(() => {
//     setPageLoading(false);
//     // console.log(data);
//   }, [data]);

//   // refetch user specific booking data.
//   const getBookingUser = () => {
//     refetch();
//   };

//   // fetch booking specific review list.
//   const fetchReview = async (bookingId: string) => {
//     try {
//       const getReviewdata = await reviewList(ReviewType.booking, bookingId);
//       setReviewData(getReviewdata.data);
//     } catch (error) {
//       console.log(error);
//     }
//   };

//   return (
//     <Tabs defaultValue='current'>
//       <Tabs.List>
//         <Tabs.Tab fw={600} value='current'>
//           Current
//         </Tabs.Tab>
//         <Tabs.Tab fw={600} value='history'>
//           History
//         </Tabs.Tab>
//       </Tabs.List>
//       <DetailsModal
//         booking_id={bookingId}
//         fetchData={getBookingUser}
//         opened={opened}
//         close={close}
//         bookingData={bookingData}
//         reviewData={reviewData}
//       />
//       <Tabs.Panel value='current' pt='xs'>
//         <Grid>
//           <Grid.Col span={!opened ? 12 : 8}>
//             <Grid gutter='xl' my='xl'>
//               {isLoading ? (
//                 <Grid.Col sm={6} md={4}>
//                   <Paper withBorder shadow='xs'>
//                     <Skeleton visible={true} h={135} />
//                   </Paper>
//                 </Grid.Col>
//               ) : (
//                 <>
//                   {data?.data
//                     ?.filter((item: any) => !item?.completed)
//                     .map((item: any) => {
//                       return (
//                         <>
//                           <Grid.Col sm={6} md={4} key={item?.booking_id}>
//                             <BookingCard
//                               opened={opened}
//                               openHandler={modalHandler}
//                               title={item?.['service_type']?.['service']}
//                               rate={item?.['service']?.['rate']}
//                               date={item?.['booking_date']}
//                               time_from={item?.['slot']?.['time_from']}
//                               time_to={item?.['slot']?.['time_to']}
//                               id={item?.booking_id}
//                               avatar={item?.['service']?.['image']}
//                               allData={item}
//                             />
//                           </Grid.Col>
//                         </>
//                       );
//                     })}
//                 </>
//               )}
//               {!isLoading && (!data?.data?.filter((item: any) => !item?.completed).length || !data?.data?.length) ? (
//                 <Grid.Col>
//                   <Text>Booking not found!</Text>
//                 </Grid.Col>
//               ) : null}

//               {/* {opened && (
//             <Grid.Col>
//               <Text>Hi</Text>
//             </Grid.Col>
//           )} */}
//             </Grid>
//           </Grid.Col>
//           {opened && (
//             <Grid.Col span={4}>
//               {/* <Paper withBorder my={35} p={5}>
//                 <Flex direction='column'>
//                   <Text>Service Type:</Text>
//                   <Text>{bookingData?.service_type?.service}</Text>
//                   <Text>Time Slot</Text>
//                   <Text>
//                     {covertTimeFormat(bookingData?.slot?.time_from)} - {covertTimeFormat(bookingData?.slot?.time_to)},{' '}
//                     {dayjs(bookingData?.booking_date).format('MMM DD, YYYY')}
//                   </Text>
//                   <Text>Service Provider</Text>
//                   {userIsLoading ? (
//                     <Skeleton visible={true} h={15} />
//                   ) : (
//                     <>
//                       {customerRole.includes(+cookie.role) ? (
//                         <Text c='dimmed'>{userProfile?.data?.name}</Text>
//                       ) : (
//                         <Text c='dimmed'>{userProfile?.data?.name || 'No user found'}</Text>
//                       )}
//                     </>
//                   )}
//                   <Text>Contact Info</Text>
//                   <Group>
//                     <IconClearAll />
//                     <Text>602-751-5869</Text>
//                   </Group>
//                   <Group>
//                     <IconClearAll />
//                     <Text>602-751-5869</Text>
//                   </Group>
//                   <Group>
//                     <IconClearAll />
//                     <Text>602-751-5869</Text>
//                   </Group>
//                   <Group>
//                     <IconClearAll />
//                     <Text>602-751-5869</Text>
//                   </Group>
//                   <Group>
//                     <IconClearAll />
//                     <Text>602-751-5869</Text>
//                   </Group>

//                   <Text>Booking Location :</Text>
//                   <Text>1241, Dogowood Road Phoenix</Text>

//                   <Text>Status :</Text>
//                   <Text>Completed</Text>

//                   <Text>Rate and Review our services</Text>
//                   <Rating />
//                   <Text>Review</Text>
//                   <Textarea placeholder='Your comment' label='Your comment' withAsterisk />
//                 </Flex>
//               </Paper> */}
//             </Grid.Col>
//           )}
//         </Grid>
//       </Tabs.Panel>

//       <Tabs.Panel value='history' pt='xs'>
//         <Grid gutter='xl' my='xl'>
//           {isLoading ? (
//             <Grid.Col sm={6} md={4}>
//               <Paper withBorder shadow='xs'>
//                 <Skeleton visible={true} h={135} />
//               </Paper>
//             </Grid.Col>
//           ) : (
//             <>
//               {data?.data
//                 ?.filter((item: any) => item?.completed)
//                 .map((item: any) => {
//                   return (
//                     <Grid.Col sm={6} md={3} key={item?.booking_id}>
//                       <BookingCard
//                         opened={opened}
//                         openHandler={modalHandler}
//                         title={item?.['service_type']?.['service']}
//                         rate={item?.['service']?.['rate']}
//                         date={item?.['booking_date']}
//                         time_from={item?.['slot']?.['time_from']}
//                         time_to={item?.['slot']?.['time_to']}
//                         id={item?.booking_id}
//                         avatar={item?.['service']?.['image']}
//                         allData={item}
//                       />
//                     </Grid.Col>
//                   );
//                 })}
//             </>
//           )}
//           {!isLoading && (!data?.data?.filter((item: any) => item?.completed).length || !data?.data?.length) ? (
//             <Grid.Col>
//               <Text>Booking not found!</Text>
//             </Grid.Col>
//           ) : null}
//         </Grid>
//       </Tabs.Panel>
//     </Tabs>
//   );
// }

export const rightToLeftAnimation = keyframes(
  {
    'from, 20%, 40%, 60%, 80% to': { transform: 'translate3d(0, 0, 0)' },
    '0%': { transform: 'translate3d(36rem, 0, 0)' },
    '10%': { transform: 'translate3d(32rem, 0, 0)' },
    '20%': { transform: 'translate3d(28rem, 0, 0)' },
    '30%': { transform: 'translate3d(24rem, 0, 0)' },
    '40%': { transform: 'translate3d(20rem, 0, 0)' },
    '50%': { transform: 'translate3d(16rem, 0, 0)' },
    '60%': { transform: 'translate3d(12rem, 0, 0)' },
    '70%': { transform: 'translate3d(8rem, 0, 0)' },
    '80%': { transform: 'translate3d(4rem, 0, 0)' },
    '90%': { transform: 'translate3d(0, 0, 0)' },
  },
  {
    'from, 80%, 60%, 40%, 20% to': { transform: 'translate3d(0, 0, 0)' },
    '90%': { transform: 'translate3d(0, 0, 0)' },
    '80%': { transform: 'translate3d(4rem, 0, 0)' },
    '70%': { transform: 'translate3d(8rem, 0, 0)' },
    '60%': { transform: 'translate3d(12rem, 0, 0)' },
    '50%': { transform: 'translate3d(16rem, 0, 0)' },
    '40%': { transform: 'translate3d(20rem, 0, 0)' },
    '30%': { transform: 'translate3d(24rem, 0, 0)' },
    '20%': { transform: 'translate3d(28rem, 0, 0)' },
    '10%': { transform: 'translate3d(32rem, 0, 0)' },
    '0%': { transform: 'translate3d(36rem, 0, 0)' },
  },
);

const useStyles = createStyles((theme) => ({
  tabsList: {
    width: 'fit-content',
    display: 'flex',
  },

  drawer: {
    display: 'inherit',
    [theme.fn.largerThan('sm')]: {
      display: 'none',
    },
  },

  gridCol: {
    // animation: `${rightToLeftAnimation} 0.25s ease-in-out`,
    [theme.fn.smallerThan(576)]: {
      flexBasis: '50%',
      flexShrink: 0,
      maxWidth: '50%',
      flexGrow: 0,
    },

    [theme.fn.smallerThan(520)]: {
      flexBasis: '100%',
      flexShrink: 0,
      maxWidth: '100%',
      flexGrow: 0,
    },
  },
  card: {
    backgroundColor: theme.colorScheme === 'light' ? '#ececec' : theme.colors.dark[6],
    borderRadius: 10,
  },
}));

const useDrawerStyles = createStyles((theme) => ({
  header: {
    padding: 0,
  },
}));

export default function MyBooking() {
  const isTablet = useMediaQuery('(max-width: 767px)');
  const [opened, { open, close }] = useDisclosure(false);
  const { data, isLoading, isError, refetch } = useBookingUserSpec();
  const [cookie, setCookie] = useCookies(['role', 'peerId', 'bookingDetails']);
  const [bookingId, setBookingId] = useState<string>('');
  const [bookingData, setBookingData] = useState<Record<string, any> | null>(null);
  const [reviewData, setReviewData] = useState<any>([]);
  const [providerReviewData, setProviderReviewData] = useState<any>([]);
  const [customerReviewData, setCustomerReviewData] = useState<any>([]);
  const { data: userProfile, isLoading: userIsLoading } = useUserOverview(
    (opened && customerRole.includes(+cookie.role) ? data?.data?.service_from : data?.data?.service_to) || '',
  );
  const { notificationRecieved } = useContext(ThemeContext);

  const theme = useMantineTheme();
  const isMobile = useMediaQuery(`(max-width: ${theme.breakpoints.sm})`);

  const [isThereMap, setIsThereMap] = useState<boolean>(false);
  const [isBookingApproved, setIsBookingApproved] = useState(false);

  const fetchReview = async (entityId: string) => {
    try {
      const getReviewdata = await reviewList(ReviewType.booking, entityId);
      setReviewData(getReviewdata.data);
    } catch (error) {
      console.log(error);
    }
  };

  const fetchReviewForProvider = async (entityId: string) => {
    try {
      const getReviewdata = await reviewList(ReviewType.user, entityId);
      setProviderReviewData(getReviewdata.data);
    } catch (error) {
      console.log(error);
    }
  };

  const fetchReviewForCustomer = async (entityId: string) => {
    try {
      const getReviewdata = await reviewList(ReviewType.user, entityId);
      setCustomerReviewData(getReviewdata.data);
    } catch (error) {
      console.log(error);
    }
  };

  const sidebarHandler = (id: string, allData: any) => {
    console.log(allData);

    if (allData?.booking_approved === 1) setIsBookingApproved(true);
    else setIsBookingApproved(false);

    if (allData.journey_started && !allData.journey_ended) setIsThereMap(true);
    else setIsThereMap(false);
    const currentIn = data?.data.findIndex((item: Record<string, any>) => item?.booking_id === id);
    setBookingId(id);
    open();
    console.log('sel index', data?.data[currentIn]);
    if (currentIn > -1) {
      setBookingData(data?.data[currentIn]);
      if (customerRole.includes(+cookie.role)) {
        fetchReview(data?.data[currentIn].booking_id);
        fetchReviewForProvider(data?.data[currentIn].service_from);
        fetchReviewForCustomer(data?.data[currentIn].service_to);
      } else {
        fetchReview(data?.data[currentIn].booking_id);
        fetchReviewForProvider(data?.data[currentIn].service_to);
        fetchReviewForCustomer(data?.data[currentIn].service_from);
      }
    }
  };

  const { classes } = useStyles();
  const { classes: drawerStyles } = useDrawerStyles();

  useEffect(() => {
    if (notificationRecieved?.type === 'service') {
      refetch();
    }
  }, [notificationRecieved]);

  const getBookingUser = () => {
    refetch();
  };

  const [activeCardIndex, setActiveCardIndex] = useState(-1);

  return (
    <>
      <Grid gutter={0} m={5}>
        <Grid.Col
          lg={opened ? (isBookingApproved ? 4 : 9) : 12}
          md={opened ? (isBookingApproved ? 4 : 9) : 12}
          sm={opened ? (isBookingApproved ? 4 : 8) : 12}>
          <Title order={3} pl={12} mt={isMobile ? 35 : 0}>
            My Booking
          </Title>
          <Tabs
            defaultValue='current'
            onTabChange={(value) => {
              close();
              setActiveCardIndex(-1);
            }}>
            <Tabs.List className={classes.tabsList}>
              <Tabs.Tab fw={600} value='current'>
                <Text>Current</Text>
              </Tabs.Tab>
              <Tabs.Tab fw={600} value='history'>
                <Text>History</Text>
              </Tabs.Tab>
            </Tabs.List>

            <Tabs.Panel value='current' pt='xs'>
              <ScrollArea.Autosize mt='xl' mah={isTablet ? '100%' : `calc(100vh - 250px)`}>
                <Grid gutter='xl' m={0}>
                  {isLoading ? (
                    <>
                      {Array(9)
                        .fill(0)
                        .map((e, index) => {
                          return (
                            <Grid.Col key={index} lg={4} md={4} sm={6} xs={6}>
                              <Skeleton visible={true} width={'100%'} height={'20vh'} />
                            </Grid.Col>
                          );
                        })}
                    </>
                  ) : (
                    <>
                      {data?.data
                        ?.filter((item: any) => !item?.completed)
                        .map((item: any, index: number) => {
                          return (
                            <React.Fragment key={index}>
                              <Grid.Col
                                lg={opened ? (isBookingApproved ? 12 : 4) : 4}
                                md={opened ? (isBookingApproved ? 12 : 4) : 4}
                                sm={opened ? (isBookingApproved ? 12 : 6) : 6}
                                xs={6}
                                className={classes.gridCol}
                                key={item?.booking_id}>
                                <BookingCard
                                  type='current'
                                  opened={opened}
                                  isThereMap={isThereMap}
                                  openHandler={sidebarHandler}
                                  title={item?.['service_type']?.['service']}
                                  rate={item?.['service']?.['rate']}
                                  date={item?.['booking_date']}
                                  time_from={item?.['slot']?.['time_from']}
                                  time_to={item?.['slot']?.['time_to']}
                                  id={item?.booking_id}
                                  avatar={item?.['service']?.['image']}
                                  allData={item}
                                  setActiveCardIndex={setActiveCardIndex}
                                  selectedIndex={index}
                                  isThisCurrentlyActive={index === activeCardIndex ? true : false}
                                />
                              </Grid.Col>
                            </React.Fragment>
                          );
                        })}
                    </>
                  )}
                  {!isLoading &&
                  (!data?.data?.filter((item: any) => !item?.completed).length || !data?.data?.length) ? (
                    <Grid.Col>
                      <Text>Booking not found!</Text>
                    </Grid.Col>
                  ) : null}
                </Grid>
              </ScrollArea.Autosize>
            </Tabs.Panel>

            <Tabs.Panel value='history' pt='xs'>
              <ScrollArea.Autosize mt='xl' mah={isTablet ? '100%' : `calc(100vh - 250px)`}>
                <Grid gutter='xl' m={0}>
                  {isLoading ? (
                    <>
                      {Array(9)
                        .fill(0)
                        .map((e, index) => {
                          return (
                            <Grid.Col key={index} lg={4} md={4} sm={6} xs={6}>
                              <Skeleton visible={true} width={'100%'} height={'30vh'} />
                            </Grid.Col>
                          );
                        })}
                    </>
                  ) : (
                    <>
                      {data?.data
                        ?.filter((item: any) => item?.completed)
                        .map((item: any, index: number) => {
                          return (
                            <React.Fragment key={index}>
                              <Grid.Col
                                lg={opened ? (isThereMap ? 12 : 4) : 4}
                                md={opened ? (isThereMap ? 12 : 4) : 4}
                                sm={opened ? (isThereMap ? 12 : 6) : 6}
                                xs={6}
                                className={classes.gridCol}
                                key={item?.booking_id}>
                                <BookingCard
                                  type='history'
                                  opened={opened}
                                  isThereMap={isThereMap}
                                  openHandler={sidebarHandler}
                                  title={item?.['service_type']?.['service']}
                                  rate={item?.['service']?.['rate']}
                                  date={item?.['booking_date']}
                                  time_from={item?.['slot']?.['time_from']}
                                  time_to={item?.['slot']?.['time_to']}
                                  id={item?.booking_id}
                                  avatar={item?.['service']?.['image']}
                                  allData={item}
                                  setActiveCardIndex={setActiveCardIndex}
                                  selectedIndex={index}
                                  isThisCurrentlyActive={index === activeCardIndex ? true : false}
                                />
                              </Grid.Col>
                            </React.Fragment>
                          );
                        })}
                    </>
                  )}
                  {!isLoading && (!data?.data?.filter((item: any) => item?.completed).length || !data?.data?.length) ? (
                    <Grid.Col>
                      <Text>Booking not found!</Text>
                    </Grid.Col>
                  ) : null}
                </Grid>
              </ScrollArea.Autosize>
            </Tabs.Panel>
          </Tabs>
        </Grid.Col>

        {opened && (
          <Grid.Col
            lg={isBookingApproved ? 8 : 3}
            md={isBookingApproved ? 8 : 3}
            sm={isBookingApproved ? 8 : 4}
            className={classes.gridCol}
            display={opened ? 'inherit' : 'none'}>
            <Box className={classes.card} h='100%' w={'100%'}>
              <BookingDetails
                setIsThereMap={setIsThereMap}
                opened={opened}
                close={close}
                bookingId={bookingId}
                fetchData={getBookingUser}
                bookingData={bookingData}
                reviewData={reviewData}
                providerReviewData={providerReviewData}
                customerReviewData={customerReviewData}
              />
              {/* <DetailsModal
              opened={opened}
              close={close}
              booking_id={bookingId}
              fetchData={getBookingUser}
              bookingData={bookingData}
              reviewData={reviewData}
            /> */}
            </Box>
          </Grid.Col>
        )}
      </Grid>

      {/* This drawer will open up below the sm breakpoint i.e. 768px */}
      {isTablet && (
        <Drawer
          classNames={drawerStyles}
          // className={classes.drawer}
          opened={opened}
          onClose={close}
          size='lg'
          position='bottom'
          padding={0}
          overlayProps={{ opacity: 0.5, blur: 4 }}>
          <Drawer.Header style={{ height: 40 }}>
            <IconArrowLeft
              className='mobileBackButton'
              onClick={() => {
                close();
              }}
            />
          </Drawer.Header>
          <BookingDetails
            setIsThereMap={setIsThereMap}
            opened={opened}
            close={close}
            bookingId={bookingId}
            fetchData={getBookingUser}
            bookingData={bookingData}
            reviewData={reviewData}
            providerReviewData={providerReviewData}
            customerReviewData={customerReviewData}
          />
        </Drawer>
      )}
    </>
  );
}
