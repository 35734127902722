import { customerRole } from '../interface/RoleEnum';
import checkIfBookingDateTimeHasPassedFromCurrentTime from './checkIfBookingDateTimeHasPassedFromCurrentTime';

export enum BookingStatus {
  PAYMENT_PENDING = 'Payment pending',
  PAYMENT_COMPLETED = 'Payment Completed',
  BOOKING_EXPIRED = 'Booking expired',
  REJECTED = 'Rejected',
  APPROVED = 'Approved',
  CANCELLED = 'Cancelled',
  JOURNEY_STARTED = 'Journey started',
  JOURNEY_ENDED = 'Journey Ended',
  WAITING_FOR_WORK_START = 'Waiting for work start',
  WAITING_FOR_WORK_END = 'Waiting for work end',
  IN_PROGRESS = 'In-progress',
  SERVICE_STARTED = 'Service started',
  COMPLETED = 'Completed',
  LOADING = 'Loading ...',
}

type getStatusOfTheBookingReturn = {
  status: BookingStatus;
};

export default function getStatusOfTheBooking(data: any, role: number): getStatusOfTheBookingReturn {
  let bookingStatus: BookingStatus = BookingStatus.LOADING;

  if (data === undefined) {
    return {
      status: bookingStatus,
    };
  }

  if (data?.invoice_status === true) {
    if (data?.canceled === null && data?.service_started === null && data?.journey_started === null) {
      if (data?.booking_approved === 1) {
        bookingStatus = BookingStatus.APPROVED;
      } else if (data?.booking_approved === 0) {
        bookingStatus = BookingStatus.REJECTED;
      } else if (data?.booking_approved === null) {
        bookingStatus = BookingStatus.PAYMENT_COMPLETED;
      }
    }

    if (data?.canceled === 1) {
      bookingStatus = BookingStatus.CANCELLED;
    }

    if (data?.service_started === null && data?.completed === null && data?.canceled === null) {
      if (data?.journey_started !== null && typeof data?.journey_started === 'string') {
        if (data?.journey_ended === null) {
          bookingStatus = BookingStatus.JOURNEY_STARTED;
        }

        if (data?.journey_ended !== null && typeof data?.journey_ended === 'string') {
          if (data?.booking_start_request === null) {
            bookingStatus = BookingStatus.JOURNEY_ENDED;
          } else if (typeof data?.booking_start_request === 'string' && data?.booking_end_request === null) {
            bookingStatus = BookingStatus.WAITING_FOR_WORK_START;
          }
        }
      }
    }

    if (typeof data?.service_started === 'string' && data?.completed === null && data?.canceled === null) {
      if (data?.booking_end_request === null) {
        bookingStatus = customerRole.includes(role) ? BookingStatus.IN_PROGRESS : BookingStatus.SERVICE_STARTED;
      }

      if (
        typeof data?.journey_started === 'string' &&
        typeof data?.journey_ended === 'string' &&
        typeof data?.booking_start_request === 'string' &&
        typeof data?.booking_end_request === 'string'
      ) {
        bookingStatus = BookingStatus.WAITING_FOR_WORK_END;
      }
    }

    if (typeof data?.service_started === 'string' && typeof data?.service_ended === 'string' && data?.completed === 1) {
      bookingStatus = BookingStatus.COMPLETED;
    }
  } else {
    if (checkIfBookingDateTimeHasPassedFromCurrentTime(data).hasPassed) {
      bookingStatus = BookingStatus.BOOKING_EXPIRED;
    } else {
      bookingStatus = BookingStatus.PAYMENT_PENDING;
    }
  }

  return {
    status: bookingStatus,
  };
}
