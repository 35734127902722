import { createStyles, rem } from '@mantine/core';

export const useParentGridContainerStyles = createStyles((theme) => ({
  parentGridContainer: {
    padding: 0,
    margin: 0,
    // border: '1px solid #ececec',
    minHeight: `calc(100svh - ${rem(130)})`,
  },

  childGridContainerLeft: {
    padding: 0,
    margin: 0,
    // border: '1px solid black',
    WebkitFlexShrink: 0,
    flexShrink: 0,
    WebkitBoxFlex: 0,
    WebkitFlexGrow: 0,
    flexGrow: 0,
    '-ms-flex-negative': 0,
    '-ms-flex-positive': 0,

    // will start from 992px upto 1199px
    [theme.fn.largerThan('md')]: {
      WebkitFlexBasis: `${(7 / 12) * 100}%`,
      flexBasis: `${(7 / 12) * 100}%`,
      maxWidth: `${(7 / 12) * 100}%`,
      '-ms-flex-preferred-size': `${(7 / 12) * 100}%`,
    },

    // will start from 1200px upto 1407px
    [theme.fn.largerThan('lg')]: {
      WebkitFlexBasis: `${(9 / 12) * 100}%`,
      flexBasis: `${(9 / 12) * 100}%`,
      maxWidth: `${(9 / 12) * 100}%`,
      '-ms-flex-preferred-size': `${(9 / 12) * 100}%`,
    },

    // will start from 1408px
    [theme.fn.largerThan('xl')]: {
      WebkitFlexBasis: `${(9 / 12) * 100}%`,
      flexBasis: `${(9 / 12) * 100}%`,
      maxWidth: `${(9 / 12) * 100}%`,
      '-ms-flex-preferred-size': `${(9 / 12) * 100}%`,
    },
  },

  childGridContainerRight: {
    padding: 0,
    margin: 0,
    // border: '1px solid black',
    WebkitFlexShrink: 0,
    flexShrink: 0,
    WebkitBoxFlex: 0,
    WebkitFlexGrow: 0,
    flexGrow: 0,
    '-ms-flex-negative': 0,
    '-ms-flex-positive': 0,

    // will start from 992px upto 1199px
    [theme.fn.largerThan('md')]: {
      WebkitFlexBasis: `${(5 / 12) * 100}%`,
      flexBasis: `${(5 / 12) * 100}%`,
      maxWidth: `${(5 / 12) * 100}%`,
      '-ms-flex-preferred-size': `${(5 / 12) * 100}%`,
    },

    // will start from 1200px upto 1407px
    [theme.fn.largerThan('lg')]: {
      WebkitFlexBasis: `${(3 / 12) * 100}%`,
      flexBasis: `${(3 / 12) * 100}%`,
      maxWidth: `${(3 / 12) * 100}%`,
      '-ms-flex-preferred-size': `${(3 / 12) * 100}%`,
    },

    // will start from 1408px
    [theme.fn.largerThan('xl')]: {
      WebkitFlexBasis: `${(3 / 12) * 100}%`,
      flexBasis: `${(3 / 12) * 100}%`,
      maxWidth: `${(3 / 12) * 100}%`,
      '-ms-flex-preferred-size': `${(3 / 12) * 100}%`,
    },
  },
}));

export const useProviderListHeaderStyles = createStyles((theme) => ({
  container: {
    // border: '1px solid black',
    padding: `${rem(16)} ${rem(8)}`,
    backgroundColor: '#f1f3f5',
    borderRadius: 8,
  },
}));

export const useFilterHeaderStyles = createStyles((theme) => ({
  container: {
    // border: '1px solid black',
    padding: `${rem(16)} ${rem(8)}`,
    backgroundColor: '#f1f3f5',
    borderRadius: 8,
  },
}));

export const useProviderListContainerScrollAreaStyles = createStyles((theme) => ({
  root: {
    maxHeight: `calc(100svh - 270px)`,
    // border: '1px solid black',
  },
  viewport: {
    padding: 0,
  },
}));

export const useGridProfileCardStyles = createStyles((theme) => ({
  container: {
    padding: 8,
    // border: '1px solid black',
    // height: 350,
  },

  card: {
    borderRadius: 8,
    border: '1px solid #ececec',
  },
}));
