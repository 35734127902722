import { Box, Button, Card, Checkbox, Divider, Flex, Grid, Group, Image, Modal, Text, Title } from '@mantine/core';
import { FileWithPath } from '@mantine/dropzone';
import { useDisclosure, useMediaQuery } from '@mantine/hooks';
import { useState } from 'react';
import { useCookies } from 'react-cookie';
import { checker } from '../../../../../assets';
import FileDropzone from '../../../../../components/FileDropzone';
import PaymentModal from '../../../../../components/PaymentModal';
import { generateVerificationInvoices } from '../../../../../services/payment.api';
import { addVerifications, useVerification, verificationChecker } from '../../../../../services/verification';
import { RoleType } from '../../../../../utils';
import renderVerificationStatusText from '../../../../../utils/helpers/renderVerificationStatusText';
import { toastMessageUpdate } from '../../../../../utils/helpers/tosterMessage';
import BackgroundCheckType from './BackgroundCheckType';
import SubmitBusinessLicense from './SubmitBusinessLicense';
import SubmitEmail from './SubmitEmail';
import VerificationInfo from './VerificationInfo';
import { useVerificationStyle } from './style';

export default function VerificationRefactored() {
  const [cookies] = useCookies(['user_Info', 'role', 'user_Address']);

  const [paymentIsLoading, setPaymentIsloading] = useState(false);

  const [businessLicenseFile, setBusinessLicenseFile] = useState<FileWithPath | null>(null);
  const [backgroundCheckType, setBackgroundCheckType] = useState('');

  const [paymentModalOpened, { open: openPaymentModal, close: closePaymentModal }] = useDisclosure(false);
  const { data: userVerificationData, refetch } = useVerification(cookies.user_Info?.id);

  const paymentHandler = async () => {
    setPaymentIsloading(true);
    const formData = {
      currency: '$',
      verification_type: backgroundCheckType,
    };
    try {
      const res: any = await generateVerificationInvoices(formData);
      toastMessageUpdate('success', res?.message);
      verificationHandler();
      closePaymentModal();
    } catch (error: any) {
      toastMessageUpdate('error', error?.data?.message);
      setPaymentIsloading(false);
    }
  };

  const verificationHandler = async () => {
    setPaymentIsloading(true);
    if (cookies.user_Address.state_short_code) {
      const formData = new FormData();

      const docFile = {
        business_license: businessLicenseFile,
        users_id: cookies.user_Info?.id,
        verification_type: +backgroundCheckType || 2,
      };
      console.log(docFile);

      for (const [key, value] of Object.entries(docFile)) {
        if (value) {
          formData.append(key, value);
        }
      }

      try {
        if (!userVerificationData?.data && backgroundCheckType === '1') {
          const checkerAdd = await verificationChecker({ state_code: cookies.user_Address.state_short_code }); // checkr API call on 1st time.
        }
        const addVerificationsData: any = await addVerifications(formData);
        setBusinessLicenseFile(null);

        toastMessageUpdate('success', addVerificationsData?.message);
        setPaymentIsloading(false);

        refetch();
      } catch (error: any) {
        toastMessageUpdate('error', error?.data?.message);
        setPaymentIsloading(false);
      }
    } else {
      toastMessageUpdate('error', 'Please checking the check box and add your full address from overview.');
    }
  };

  const rejectDocuments = (files: any) => {
    if (files[0].errors[0].code === 'file-too-large') {
      toastMessageUpdate('error', 'File size must be lower than 15MB');
    } else {
      toastMessageUpdate('error', files[0].errors[0].message);
    }
  };

  const isMobile = useMediaQuery('(max-width: 576px)');

  const { classes: verificationClasses } = useVerificationStyle();

  const handleSaveDisable = ({ roleType }: { roleType: number }): boolean | undefined => {
    switch (roleType) {
      case 2: // RSP: regular service provider
        if (userVerificationData?.data) return true;
        else {
          if (backgroundCheckType === '') return true;
          else return false;
        }

      case 3: // OSP: organisational service provider
        if (userVerificationData?.data) return true;
        else {
          if (!businessLicenseFile) return true;
          else return false;
        }
    }
  };

  const handleSaveClick = async ({ roleType }: { roleType: number }) => {
    switch (roleType) {
      case 2: // RSP: regular service provider
        if (backgroundCheckType === '1') {
          if (cookies.user_Info?.chargebee_card) {
            paymentHandler();
          } else {
            openPaymentModal();
          }
        } else if (backgroundCheckType === '2') {
          verificationHandler();
        }
        break;
      case 3: // OSP: organisational service provider
        paymentHandler();
        break;
    }
  };

  // Only applicable for organisational service provider
  const handleBusinessLicenseFile = (e: FileWithPath[]) => {
    console.log('business license file: ', e);
    setBusinessLicenseFile(e[0]);
  };

  const handleBackgroundCheckType = ({ backgroundCheckType }: { backgroundCheckType: string }) => {
    console.log(backgroundCheckType);
    setBackgroundCheckType(backgroundCheckType);
  };

  return (
    <>
      <Title order={2} c='orange.8' fw={600} mb={20} className={verificationClasses.header}>
        Verifications
      </Title>

      <VerificationInfo />

      <Grid gutter='xl' m={0}>
        <>
          <Grid.Col md={4} sm={6} xs={6}>
            {RoleType.service_provider === +cookies.role && (
              <Box
                p={10}
                display='flex'
                h='100%'
                w='auto'
                style={{ justifyContent: 'center', border: '1px solid #dee2e6', borderRadius: '5px' }}>
                <SubmitEmail backgroundCheckType={backgroundCheckType} data={userVerificationData?.data} />
              </Box>
            )}

            {RoleType.organization_service_provider === +cookies.role && !userVerificationData?.data && (
              <Flex direction='column' align='center' justify='center' h={'100%'}>
                <FileDropzone
                  w='100%'
                  title='Business License'
                  accept={['application/pdf']}
                  filename={businessLicenseFile?.name}
                  disabled={userVerificationData?.data}
                  onDrop={handleBusinessLicenseFile}
                  onReject={(files) => {
                    rejectDocuments(files);
                  }}
                />
              </Flex>
            )}

            {RoleType.organization_service_provider === +cookies.role && userVerificationData?.data && (
              <Box
                p={10}
                display='flex'
                h='100%'
                w='auto'
                style={{ justifyContent: 'center', border: '1px solid #dee2e6', borderRadius: '5px' }}>
                <SubmitBusinessLicense data={userVerificationData.data} />
              </Box>
            )}
          </Grid.Col>

          <Grid.Col md={4} sm={6} xs={6}>
            <Box
              target='_blank'
              component='a'
              href={`https://shareasale.com/r.cfm?b=1141021&u=3770646&m=29568&urllink=&afftrack=`}
              className={verificationClasses.optionalDrugTestingBox}>
              <Text>Optional drug testing</Text>
            </Box>
          </Grid.Col>
        </>

        <Grid.Col>
          <Card shadow='sm' py={0} px={10} radius='md' mb={35} withBorder>
            {RoleType.service_provider === +cookies.role && (
              <>
                <Box mt={20}>
                  <Group position='apart'>
                    <Text>Submitted email for background check.</Text>
                    <Text ta='center' size='sm' weight={600} fz={15}>
                      Status :{' '}
                      <Text
                        weight={600}
                        fz={15}
                        span
                        c={userVerificationData?.data.background_verification ? 'green' : 'red'}>
                        {
                          renderVerificationStatusText({
                            data: userVerificationData?.data,
                            role: cookies.role,
                            userIsViewingOwnProfile: true,
                          }).backgroundVerification
                        }
                      </Text>
                    </Text>
                  </Group>
                </Box>
                <Divider mt={20}></Divider>
              </>
            )}

            <Box my={20}>
              {RoleType.service_provider === +cookies?.role && (
                <BackgroundCheckType
                  backgroundCheckType={userVerificationData?.data.type}
                  sendDataToParent={handleBackgroundCheckType}
                />
              )}

              {RoleType.organization_service_provider === +cookies?.role && (
                <Checkbox
                  label='Aidby are not responsible for the conduct of their employees and we will not be responsible for their actions.'
                  mt='25px'
                  checked
                  radius='sm'
                  size='sm'
                />
              )}

              <Button
                mt={20}
                size='sm'
                w={150}
                disabled={handleSaveDisable({ roleType: +cookies.role })}
                onClick={() => handleSaveClick({ roleType: +cookies.role })}
                radius={4}
                loading={paymentIsLoading}
                loaderPosition='center'
                variant='gradient'
                gradient={{ to: '#fda223', from: '#fe7720', deg: 270 }}>
                <Text>{paymentIsLoading ? null : 'Save'}</Text>
              </Button>
            </Box>
          </Card>

          <Card shadow='sm' radius='md' withBorder>
            <Flex align='center' justify='center'>
              <Flex gap={10} align='center' direction={isMobile ? 'column' : 'row'}>
                <Title order={1} fz={isMobile ? 16 : 20}>
                  Our partner for background check verifications is one of the industry leading verification company to
                  visit them click here
                </Title>
                <Box target='_blank' component='a' href={`https://checkr.com`}>
                  <Image src={checker} maw={isMobile ? 200 : 300} fit='cover' />
                </Box>
              </Flex>
            </Flex>
          </Card>
        </Grid.Col>
      </Grid>

      <Modal opened={paymentModalOpened} onClose={closePaymentModal} size='calc(100vw - 50%)'>
        <Flex justify='center' align='center'>
          <PaymentModal
            type='withoutShadow'
            forbookingServices={true}
            onPayment={paymentHandler}
            state={paymentModalOpened}
            close={closePaymentModal}
          />
        </Flex>
      </Modal>
    </>
  );
}
