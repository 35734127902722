import { Flex, Grid, Title, createStyles } from '@mantine/core';
import BackButton from './BackButton';
import CrossButton from './CrossButton';

const titleGridColSizeForDesktop = 11;
const titleGridColSizeForMobile = 10;
const backButtonGridColSize = 1;
const crossButtonGridColSize = 1;

const useStyles = createStyles((theme) => ({
  title: {
    lineHeight: 1,
    fontSize: 26,
    [theme.fn.smallerThan(769)]: {
      textAlign: 'center',
      fontSize: 20,
    },

    [theme.fn.largerThan(769)]: {
      textAlign: 'start',
    },
  },

  genericGridColumn: {
    padding: 0,
    margin: 0,
  },

  backButtonGridColumn: {
    [theme.fn.smallerThan(769)]: {
      WebkitFlexBasis: `${(backButtonGridColSize / 12) * 100}%`,
      flexBasis: `${(backButtonGridColSize / 12) * 100}%`,
      WebkitFlexShrink: 0,
      flexShrink: 0,
      maxWidth: `${(backButtonGridColSize / 12) * 100}%`,
      WebkitBoxFlex: 0,
      WebkitFlexGrow: 0,
      flexGrow: 0,
      '-ms-flex-preferred-size': `${(backButtonGridColSize / 12) * 100}%`,
      '-ms-flex-negative': 0,
      '-ms-flex-positive': 0,
    },

    [theme.fn.largerThan(769)]: {
      display: 'none',
    },
  },

  titleGridColumn: {
    [theme.fn.smallerThan(769)]: {
      WebkitFlexBasis: `${(titleGridColSizeForMobile / 12) * 100}%`,
      flexBasis: `${(titleGridColSizeForMobile / 12) * 100}%`,
      WebkitFlexShrink: 0,
      flexShrink: 0,
      maxWidth: `${(titleGridColSizeForMobile / 12) * 100}%`,
      WebkitBoxFlex: 0,
      WebkitFlexGrow: 0,
      flexGrow: 0,
      '-ms-flex-preferred-size': `${(titleGridColSizeForMobile / 12) * 100}%`,
      '-ms-flex-negative': 0,
      '-ms-flex-positive': 0,
    },

    [theme.fn.largerThan(769)]: {
      WebkitFlexBasis: `${(titleGridColSizeForDesktop / 12) * 100}%`,
      flexBasis: `${(titleGridColSizeForDesktop / 12) * 100}%`,
      WebkitFlexShrink: 0,
      flexShrink: 0,
      maxWidth: `${(titleGridColSizeForDesktop / 12) * 100}%`,
      WebkitBoxFlex: 0,
      WebkitFlexGrow: 0,
      flexGrow: 0,
      '-ms-flex-preferred-size': `${(titleGridColSizeForDesktop / 12) * 100}%`,
      '-ms-flex-negative': 0,
      '-ms-flex-positive': 0,
    },
  },
  crossButtonGridColumn: {
    [theme.fn.smallerThan(769)]: {
      display: 'none',
    },

    [theme.fn.largerThan(769)]: {
      WebkitFlexBasis: `${(crossButtonGridColSize / 12) * 100}%`,
      flexBasis: `${(crossButtonGridColSize / 12) * 100}%`,
      WebkitFlexShrink: 0,
      flexShrink: 0,
      maxWidth: `${(crossButtonGridColSize / 12) * 100}%`,
      WebkitBoxFlex: 0,
      WebkitFlexGrow: 0,
      flexGrow: 0,
      '-ms-flex-preferred-size': `${(crossButtonGridColSize / 12) * 100}%`,
      '-ms-flex-negative': 0,
      '-ms-flex-positive': 0,
    },
  },

  invisibleGridColumn: {
    display: 'none',
  },

  extraGridColumn: {
    [theme.fn.smallerThan(769)]: {
      WebkitFlexBasis: `${(1 / 12) * 100}%`,
      flexBasis: `${(1 / 12) * 100}%`,
      WebkitFlexShrink: 0,
      flexShrink: 0,
      maxWidth: `${(1 / 12) * 100}%`,
      WebkitBoxFlex: 0,
      WebkitFlexGrow: 0,
      flexGrow: 0,
      '-ms-flex-preferred-size': `${(1 / 12) * 100}%`,
      '-ms-flex-negative': 0,
      '-ms-flex-positive': 0,
    },

    [theme.fn.largerThan(769)]: {
      display: 'none',
    },
  },
}));

interface Props {
  onClose: () => void;
  showMap: boolean;
  showAddOrApproveItems: boolean;
}

export default function Header({ onClose, showMap, showAddOrApproveItems }: Props) {
  const { classes, cx } = useStyles();
  return (
    <>
      <Grid p={0} m={0}>
        <Grid.Col className={cx(classes.genericGridColumn, classes.backButtonGridColumn)}>
          <Flex justify='flex-start' align='center'>
            <BackButton onClose={onClose} />
          </Flex>
        </Grid.Col>
        <Grid.Col className={cx(classes.genericGridColumn, classes.titleGridColumn)}>
          <Title order={2} lh={1} className={classes.title}>
            Booking Details
          </Title>
        </Grid.Col>
        <Grid.Col
          className={cx(classes.genericGridColumn, classes.crossButtonGridColumn, {
            [classes.invisibleGridColumn]: showMap || showAddOrApproveItems,
          })}>
          <Flex justify='center' align='center' direction='column'>
            <CrossButton onClose={onClose} />
          </Flex>
        </Grid.Col>
        <Grid.Col className={classes.extraGridColumn}></Grid.Col>
      </Grid>
    </>
  );
}
