import {
  Box,
  Button,
  Flex,
  FocusTrap,
  Grid,
  Loader,
  Stack,
  Text,
  TextInput,
  Textarea,
  Title,
  useMantineTheme,
} from '@mantine/core';
import { Reducer, useEffect, useReducer, useState } from 'react';
import { useCookies } from 'react-cookie';
import GooglePlacesAutocomplete, { geocodeByPlaceId } from 'react-google-places-autocomplete';
import GlobalLoader from '../../../../../components/GlobalLoader';
import { addAddress, updateAddress } from '../../../../../services/Addresses';
import { useUserProfile } from '../../../../../services/user.api';
import onlyAllowNumbersInput from '../../../../../utils/helpers/onlyAllowNumbersInput';
import CustomButton from './CustomButton';
import { useGridColStyle } from './style';
import getAddressComponentsFromGooglePlaceAutocomplete from '../../../../../utils/helpers/getAddressComponentsFromGooglePlaceAutocomplete';
import onlyAllowTextInput from '../../../../../utils/helpers/onlyAllowTextInput';

type AddressState = {
  fullAddress: string;
  country: string;
  state: string;
  stateShortCode: string;
  city: string;
  postalCode: string;
  latitude: string;
  longitude: string;
  addressId: string | null;
};

const addressInitialState: AddressState = {
  addressId: null,
  city: '',
  country: '',
  fullAddress: '',
  latitude: '',
  longitude: '',
  postalCode: '',
  state: '',
  stateShortCode: '',
};

type GenerateSetters<T> = `SET_${Uppercase<keyof T & string>}`;

type ActionType = GenerateSetters<AddressState>;

type Action =
  | {
      type: Exclude<ActionType, 'SET_ADDRESSID'>;
      payload: string;
    }
  | {
      type: 'SET_ADDRESSID';
      payload: string | null;
    }
  | {
      type: 'CLEAR';
    }
  | {
      type: 'SET_ADDRESS_INITIALLY_IF_IT_EXISTS';
      payload: any;
    };

const reducer = (state: AddressState, action: Action): AddressState => {
  switch (action.type) {
    case 'SET_ADDRESSID':
      return { ...state, addressId: action.payload };
    case 'SET_FULLADDRESS':
      return { ...state, fullAddress: action.payload };
    case 'SET_COUNTRY':
      return { ...state, country: action.payload };
    case 'SET_STATE':
      return { ...state, state: action.payload };
    case 'SET_STATESHORTCODE':
      return { ...state, stateShortCode: action.payload };
    case 'SET_CITY':
      return { ...state, city: action.payload };
    case 'SET_POSTALCODE':
      return { ...state, postalCode: action.payload };
    case 'SET_LATITUDE':
      return { ...state, latitude: action.payload };
    case 'SET_LONGITUDE':
      return { ...state, longitude: action.payload };
    case 'SET_ADDRESS_INITIALLY_IF_IT_EXISTS':
      return {
        ...state,
        addressId: action.payload.address_id ?? '',
        fullAddress: action.payload.address ?? '',
        country: action.payload.country ?? '',
        state: action.payload.state ?? '',
        city: action.payload.city ?? '',
        postalCode: action.payload.zip_code ?? '',
      };
    case 'CLEAR':
      return {
        ...state,
        fullAddress: '',
        country: '',
        state: '',
        city: '',
        postalCode: '',
      };
  }
};

interface Props {
  opened?: boolean;
  enable: boolean;
}

export default function Address({ opened, enable }: Props) {
  const [cookies, setCookie] = useCookies(['user_Info', 'user_Address', 'user_Overview']);
  const [loading, setLoading] = useState<boolean>(false);
  const [addressState, dispatch] = useReducer<Reducer<AddressState, Action>>(reducer, addressInitialState);
  const { data, refetch, isLoading } = useUserProfile();
  const { classes: GridColStyle } = useGridColStyle();

  const [userAlreadyHasAddress, setUserAlreadyHasAddress] = useState(false);
  const [addressHasBeenSearched, setAddressHasBeenSearched] = useState(false);
  const [showEditButton, setShowEditButton] = useState(false);
  const [postalCodeLengthError, setPostalCodeLengthError] = useState(false);
  const [postalCodeInputIsTouched, setPostalCodeInputIsTouched] = useState(false);
  const [cityInputIsTouched, setCityInputIsTouched] = useState(false);

  useEffect(() => {
    if (data?.data.has_address) {
      setShowEditButton(true);
      setUserAlreadyHasAddress(true);
      dispatch({ type: 'SET_ADDRESS_INITIALLY_IF_IT_EXISTS', payload: data.data.has_address });
    } else {
      setUserAlreadyHasAddress(false);
      dispatch({ type: 'SET_ADDRESSID', payload: null });
    }
  }, [data]);

  const handleSave = async (e: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
    e.preventDefault();
    if (!addressState.state && !addressState.postalCode) return;
    setLoading(true);
    const addressData = {
      address: addressState.fullAddress,
      country: addressState.country,
      city: addressState.city,
      state: addressState.state,
      state_short_code: addressState.stateShortCode,
      zip_code: addressState.postalCode,
      user_profile_id: cookies.user_Overview?.user_profile_id,
      latitude: addressState.latitude,
      longitude: addressState.longitude,
      address_id: addressState.addressId,
    };

    if (userAlreadyHasAddress) {
      try {
        const updateAddressResponse = await updateAddress(addressState.addressId, addressData);
        setLoading(false);
        setAddressHasBeenSearched(false);
        setPostalCodeInputIsTouched(false);
        setCityInputIsTouched(false);
        setCookie('user_Address', updateAddressResponse.data, { path: '/' });
      } catch (error) {
        console.log(error);
      }
    } else {
      try {
        const addAddressResponse = await addAddress(addressData);
        setLoading(false);
        setAddressHasBeenSearched(false);
        setPostalCodeInputIsTouched(false);
        setCityInputIsTouched(false);
        setCookie('user_Address', addAddressResponse.data, { path: '/' });
      } catch (error) {
        console.log(error);
      }
    }
    refetch();
    setShowEditButton(true);
  };

  const handleCancel = () => {
    setShowEditButton(true);
    setPostalCodeInputIsTouched(false);
    setAddressHasBeenSearched(false);
    setPostalCodeLengthError(false);
    if (data) {
      dispatch({
        type: 'SET_FULLADDRESS',
        payload: userAlreadyHasAddress ? data.data.has_address.address : '',
      });
      dispatch({ type: 'SET_COUNTRY', payload: userAlreadyHasAddress ? data.data.has_address.country : '' });
      dispatch({ type: 'SET_STATE', payload: userAlreadyHasAddress ? data.data.has_address.state : '' });
      dispatch({ type: 'SET_CITY', payload: userAlreadyHasAddress ? data.data.has_address.city : '' });
      dispatch({
        type: 'SET_POSTALCODE',
        payload: userAlreadyHasAddress ? data.data.has_address.zip_code : '',
      });
    }
  };

  const disablePostalCodeInput = () => {
    if (showEditButton) return true;
    else {
      // when it comes here, the `save` & `cancel` button are shown
      if (addressHasBeenSearched && addressState.postalCode === '') return false;
      else if (postalCodeInputIsTouched) {
        return false;
      } else return true;
    }
  };

  const disableCityInput = () => {
    if (showEditButton) return true;
    else {
      if (addressHasBeenSearched && addressState.city === '') {
        return false;
      } else if (cityInputIsTouched) {
        return false;
      } else {
        return true;
      }
    }
  };

  const disableSaveButton = () => {
    if (!enable) return true;
    if (!addressHasBeenSearched) return true;

    if (addressHasBeenSearched) {
      if (
        addressState.state === '' ||
        addressState.postalCode === '' ||
        addressState.city === '' ||
        postalCodeLengthError
      )
        return true;
      else false;
    }
  };

  const decideIfTheAddressSearchShouldBeRendered = () => {
    if (enable) {
      if (!userAlreadyHasAddress) return true;
      else {
        if (!showEditButton) return true;
        else return false;
      }
    } else return false;
  };

  const theme = useMantineTheme();

  return (
    <>
      {isLoading && <GlobalLoader />}
      <Stack pt={20} pos='relative'>
        <Title order={3} fw={600} mt={30} c={enable ? (theme.colorScheme === 'light' ? 'black' : '#ced4da') : 'dimmed'}>
          Address Information
        </Title>
        <Box>
          {decideIfTheAddressSearchShouldBeRendered() && (
            <>
              <Text
                fz={20}
                fw={600}
                mb={8}
                c={enable ? (theme.colorScheme === 'light' ? 'black' : '#ced4da') : 'dimmed'}>
                Search for your address
              </Text>
              <GooglePlacesAutocomplete
                autocompletionRequest={{
                  componentRestrictions: {
                    country: ['us'],
                  },
                }}
                apiKey={'AIzaSyAk6vsC0ab0_dpKTm_vp78FrnftU4npnLM'}
                selectProps={{
                  autoFocus: true,
                  blurInputOnSelect: true,
                  isClearable: true,
                  isDisabled: !enable,
                  styles: {
                    control: (styles, { isFocused }) => ({
                      ...styles,
                      backgroundColor: theme.colorScheme === 'light' ? 'rgba(241, 243, 245, 0.6)' : '#2b2c3d',
                      border: `0.0625rem solid ${theme.colorScheme === 'light' ? '#e1e1e1' : '#414141'}`,
                      opacity: '0.6',
                      borderRadius: '0.5rem',
                      boxShadow: isFocused ? '0 0 0 2px #fe7720' : 'none',

                      ':hover': {
                        borderColor: '#fe7720',
                      },
                    }),

                    input: (styles) => ({
                      ...styles,
                      color: theme.colorScheme === 'light' ? 'black' : 'white',
                    }),

                    singleValue: (styles) => ({
                      ...styles,
                      color: '#8c8fa3',
                    }),

                    menuList: (styles) => ({
                      ...styles,
                      backgroundColor: theme.colorScheme === 'light' ? 'white' : '#46475d',
                    }),

                    option: (styles, { isFocused }) => ({
                      ...styles,
                      backgroundColor:
                        theme.colorScheme === 'light'
                          ? isFocused
                            ? '#fe7720'
                            : 'white'
                          : isFocused
                          ? '#1F2544'
                          : '#46475d',

                      ':active': {
                        backgroundColor: theme.colorScheme === 'light' ? '#fda223' : '#1F2544',
                      },
                    }),
                  },

                  loadingMessage: ({ inputValue }) => {
                    return (
                      <>
                        <Flex justify='center' gap={4} align='center'>
                          <Loader size={20} />
                          <Text>{`Searching for '${inputValue}'`}</Text>
                        </Flex>
                      </>
                    );
                  },
                  noOptionsMessage: ({ inputValue }) => {
                    return (
                      <>
                        {inputValue.length ? (
                          <Flex justify='center' gap={4} align='center'>
                            <Text>{`Sorry, it seems there are no places that match '${inputValue}'`}</Text>
                          </Flex>
                        ) : (
                          <Flex justify='center' gap={4} align='center'>
                            <Text>Search an address</Text>
                          </Flex>
                        )}
                      </>
                    );
                  },

                  onChange: (e) => {
                    setAddressHasBeenSearched(true);
                    setPostalCodeInputIsTouched(false);
                    setCityInputIsTouched(false);
                    setPostalCodeLengthError(false);

                    if (e === null) {
                      return;
                    }

                    geocodeByPlaceId(e.value.place_id).then((res) => {
                      dispatch({ type: 'CLEAR' });
                      dispatch({ type: 'SET_FULLADDRESS', payload: res[0].formatted_address });
                      getAddressComponentsFromGooglePlaceAutocomplete(res[0]);
                      console.log(res[0]);

                      dispatch({ type: 'SET_LATITUDE', payload: String(res[0].geometry.location.lat()) });
                      dispatch({ type: 'SET_LONGITUDE', payload: String(res[0].geometry.location.lng()) });

                      res[0].address_components.forEach((component) => {
                        if (component.types.includes('country')) {
                          dispatch({ type: 'SET_COUNTRY', payload: component.long_name });
                        } else if (component.types.includes('administrative_area_level_1')) {
                          dispatch({ type: 'SET_STATE', payload: component.long_name });
                          dispatch({ type: 'SET_STATESHORTCODE', payload: component.short_name });
                        } else if (
                          // component.types.includes('administrative_area_level_2') ||
                          // component.types.includes('administrative_area_level_3') ||
                          component.types.includes('locality')
                        ) {
                          dispatch({ type: 'SET_CITY', payload: component.long_name });
                        } else if (component.types.includes('postal_code')) {
                          dispatch({ type: 'SET_POSTALCODE', payload: component.long_name });
                        }
                      });
                    });
                  },
                }}
              />
            </>
          )}

          <Grid gutter={8} mt={12}>
            <Grid.Col xs={opened ? 12 : 6}>
              <Textarea
                size='md'
                disabled
                autosize
                minRows={2}
                maxRows={4}
                withAsterisk
                spellCheck={false}
                value={addressState.fullAddress}
                placeholder='Enter address'
                label='Full Address'
                labelProps={{ c: enable ? (theme.colorScheme === 'light' ? 'black' : '#ced4da') : 'dimmed' }}
              />
            </Grid.Col>
            <Grid.Col xs={opened ? 12 : 6}>
              <TextInput
                label='Country'
                size='md'
                withAsterisk
                placeholder='Select Country'
                disabled
                value={addressState.country}
                labelProps={{ c: enable ? (theme.colorScheme === 'light' ? 'black' : '#ced4da') : 'dimmed' }}
              />
            </Grid.Col>
            <Grid.Col xs={opened ? 12 : 6}>
              <TextInput
                value={addressState.state}
                label='State'
                size='md'
                withAsterisk
                placeholder='Select State'
                disabled
                labelProps={{ c: enable ? (theme.colorScheme === 'light' ? 'black' : '#ced4da') : 'dimmed' }}
              />
              {!addressState.state && addressHasBeenSearched && (
                <Text fz={14} c='red'>
                  State cannot be empty, please select a more specific address
                </Text>
              )}
            </Grid.Col>
            <Grid.Col xs={opened ? 12 : 6}>
              <TextInput
                value={addressState.city}
                label='City'
                size='md'
                withAsterisk
                placeholder='Select City'
                disabled={disableCityInput()}
                labelProps={{ c: enable ? (theme.colorScheme === 'light' ? 'black' : '#ced4da') : 'dimmed' }}
                onChange={(e) => {
                  setCityInputIsTouched(true);
                  dispatch({ type: 'SET_CITY', payload: e.target.value });
                }}
                onKeyDown={onlyAllowTextInput}
              />

              {addressHasBeenSearched && !addressState.city && (
                <Text fz={14} c='red'>
                  Please enter city
                </Text>
              )}
            </Grid.Col>
            <Grid.Col xs={opened ? 12 : 6}>
              <FocusTrap active={addressHasBeenSearched && !addressState.postalCode}>
                <TextInput
                  disabled={disablePostalCodeInput()}
                  value={addressState.postalCode}
                  type='number'
                  size='md'
                  withAsterisk
                  data-autofocus
                  labelProps={{ c: enable ? (theme.colorScheme === 'light' ? 'black' : '#ced4da') : 'dimmed' }}
                  placeholder='Enter ZIP code'
                  label='ZIP Code'
                  onChange={(e) => {
                    setPostalCodeInputIsTouched(true);
                    dispatch({ type: 'SET_POSTALCODE', payload: e.target.value });

                    if (e.target.value.length === 0) {
                      setPostalCodeLengthError(false);
                    } else if (e.target.value.length < 5 || e.target.value.length > 7) {
                      setPostalCodeLengthError(true);
                    } else setPostalCodeLengthError(false);
                  }}
                  onKeyDown={onlyAllowNumbersInput}
                />
              </FocusTrap>

              {addressHasBeenSearched && !addressState.postalCode && (
                <Text fz={14} c='red'>
                  Please enter ZIP code
                </Text>
              )}

              {postalCodeLengthError && (
                <Text fz={14} c='red'>
                  Enter valid ZIP code
                </Text>
              )}
            </Grid.Col>
            <Grid.Col>
              <Grid gutter={8}>
                {showEditButton ? (
                  <>
                    <Grid.Col
                      lg={opened ? 3 : 1.5}
                      md={opened ? 3 : 1.5}
                      sm={1.5}
                      xs={3}
                      className={GridColStyle.gridCol}>
                      <Button
                        size='sm'
                        radius={4}
                        fullWidth
                        disabled={!enable}
                        onClick={(e) => {
                          e.preventDefault();
                          setShowEditButton(false);
                        }}
                        variant='gradient'
                        gradient={{ to: '#fda223', from: '#fe7720', deg: 270 }}>
                        Edit
                      </Button>
                    </Grid.Col>
                  </>
                ) : (
                  <>
                    <Grid.Col
                      lg={opened ? 3 : 1.5}
                      md={opened ? 3 : 1.5}
                      sm={1.5}
                      xs={3}
                      className={GridColStyle.gridCol}>
                      <CustomButton
                        variant='gradient'
                        disabled={disableSaveButton()}
                        type='submit'
                        loading={loading}
                        onClick={handleSave}>
                        Save
                      </CustomButton>
                    </Grid.Col>
                    {cookies.user_Address === 'null'}
                    <Grid.Col
                      lg={opened ? 3 : 1.5}
                      md={opened ? 3 : 1.5}
                      sm={1.5}
                      xs={3}
                      className={GridColStyle.gridCol}>
                      {data?.data.has_address && (
                        <CustomButton variant='outline' onClick={handleCancel} loading={false}>
                          Cancel
                        </CustomButton>
                      )}
                    </Grid.Col>
                  </>
                )}
              </Grid>
            </Grid.Col>
          </Grid>
        </Box>
      </Stack>
    </>
  );
}
