import { Flex, Title, Text, Textarea, Grid, Button } from '@mantine/core';
import { useState } from 'react';
import { bookingCancel } from '../../../../../services/booking';
import { useCookies } from 'react-cookie';
import { sendNotification } from '../../../../../services/websocket.api';
import { toastMessageUpdate } from '../../../../../utils/helpers/tosterMessage';

interface Props {
  data: any;
  closeCancelBooking: () => void;
  refetch: any;
}

export default function CancelBooking({ data, closeCancelBooking, refetch }: Props) {
  console.log(data);
  const [cancellationReason, setCancellationReason] = useState('');
  const [loading, setLoading] = useState(false);
  const [cookie, setCookie] = useCookies(['user_Info']);

  const submitCancel = async () => {
    const params = {
      booking_id: data?.booking_id,
      cancel_remarks: cancellationReason?.trim(),
    };

    console.log(params);

    try {
      setLoading(true);
      const cancelData = await bookingCancel(params);
      const notificationParam = {
        to_user: data?.service_from,
        message: `Your service has been cancelled by ${cookie?.user_Info?.name}.`,
        type: 'service',
      };
      await sendNotification(notificationParam);
      toastMessageUpdate('success', 'Booking cancelled successfully.');
      refetch();
      setLoading(false);
    } catch (error) {
      setLoading(false);
      console.log(error);
    }
    closeCancelBooking();
  };

  return (
    <>
      <Flex direction='column'>
        <Title order={2} ta='center'>
          Cancel booking
        </Title>
        <Text size={16} align='left' fw={600} pt='10px' ta='center'>
          Are you sure you want to cancel this booking?
        </Text>

        <Textarea
          size='md'
          value={cancellationReason}
          onChange={(event: any) => {
            setCancellationReason(event.target.value);
          }}
          autosize
          minRows={1}
          maxRows={3}
          mt={16}
          placeholder='Enter reason'
          label='Reason for cancellation'
        />
        <Grid gutter={8} mt={16}>
          <Grid.Col sm={6}>
            <Button
              loading={loading}
              loaderPosition='center'
              radius={4}
              size='md'
              fullWidth
              variant='gradient'
              gradient={{ from: '#fda223', to: '#fe7720' }}
              onClick={submitCancel}>
              <Text>{loading ? null : 'Yes'}</Text>
            </Button>
          </Grid.Col>
          <Grid.Col sm={6}>
            <Button
              c='orange'
              size='md'
              fullWidth
              color='gray'
              variant='light'
              radius={4}
              onClick={closeCancelBooking}
              sx={(theme) => ({ borderColor: '#e1e1e1' })}>
              No
            </Button>
          </Grid.Col>
        </Grid>
      </Flex>
    </>
  );
}
