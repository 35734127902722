import { Box, Button, Flex, Text } from '@mantine/core';
import { useCookies } from 'react-cookie';
import { useNavigate } from 'react-router-dom';
import { providerRole } from '../../../../../utils';
import Loading from './Loading';
import { IconArrowRight } from '@tabler/icons-react';

interface Props {
  data: any;
  isLoading: boolean;
  encodedUserId: string;
}

export default function ServiceProviderOrConsumer({ data, isLoading, encodedUserId }: Props) {
  const [cookie, _] = useCookies(['role']);
  const navigate = useNavigate();
  const redirect = () => {
    navigate(`/${providerRole.includes(+cookie.role) ? 'cust' : 'prov'}/${encodedUserId}`);
  };
  return (
    <>
      <Flex direction='column' gap={4}>
        <Text fw={600}>Service {providerRole.includes(+cookie.role) ? 'Consumer' : 'Provider'}</Text>
        {isLoading ? (
          <>
            <Loading />
          </>
        ) : (
          <>
            {data === undefined ? (
              <Text fz={14} c='dimmed'>
                This user does not exist
              </Text>
            ) : (
              <Flex gap={10}>
                <Box>
                  <Text c='dimmed'>{data?.data?.name}</Text>
                </Box>
                <Button compact variant='subtle' onClick={redirect}>
                  <IconArrowRight stroke={2} />
                </Button>
              </Flex>
            )}
          </>
        )}
      </Flex>
    </>
  );
}
