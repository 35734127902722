import { ProviderListContextProvider } from '../../../../context/provider-list-context';
import ParentGridContainer from './components/ParentGridContainer';

export default function ProviderList() {
  return (
    <>
      <ProviderListContextProvider>
        <ParentGridContainer />
      </ProviderListContextProvider>
    </>
  );
}
