import { Route, Router, Routes, useLocation } from 'react-router-dom';
import { AuthLayout } from '../layouts';
import Dashboard from '../pages/Dashboard';
import {
  BookService,
  ConfirmBooking,
  CustomerProfile,
  FreelancerList,
  Login,
  MyBooking,
  Partner,
  Register,
  MyEarning,
  UserCommonProfile,
  Chat,
  UserProfile,
  ForgotPassword,
  VerifyOTP,
  Subscription,
  BookingRequest,
  WebVideo,
  Address,
} from '../pages';
import NonAuthLayout from '../layouts/nonAuthLayout';
import { AuthGuard, NonAuthGuard } from '../guard';
import PageNotFound from '../pages/404';
import RoleGuard from '../guard/Role.guard';
import { RoleType } from '../utils';
import { useLayoutEffect } from 'react';
import ResetPassword from '../pages/NonAuth/resetPassword';
import Favourites from '../pages/Dashboard/MyBooking/components/Favourites';
import ProviderViewProfile from '../pages/Dashboard/Partner/Profile/components/ProviderViewProfile';
import CustomerViewProfile from '../pages/Dashboard/Partner/Profile/components/CustomerViewProfile';
import Invoice from '../pages/Dashboard/Partner/MyEarning/components/Invoice';
import ContentNotFound from '../pages/ContentNotFound';
import MobileVerification from '../pages/NonAuth/MobileVerification';
import CommonUserViewProfile from '../pages/Dashboard/Partner/Profile/components/CommonUserViewProfile';
import ProviderList from '../pages/Dashboard/Customer/ProviderList';
import MyBookingRefactored from '../pages/Dashboard/MyBooking/v2';
import MyJobs from '../pages/Dashboard/Customer/MyJobs';
import CreateJobs from '../pages/Dashboard/Customer/CreateJobs';

export default function Routing({ removeValue }: any) {
  const customerPanel = [RoleType.customer, RoleType.organization_service_consumer];
  const freelancerPanel = [RoleType.service_provider, RoleType.organization_service_provider];
  const location = useLocation();
  // Scroll to top if path changes
  useLayoutEffect(() => {
    window.scroll({
      top: 0,
      behavior: 'smooth',
    });
  }, [location.pathname]);

  return (
    <Routes>
      <Route
        element={
          <AuthGuard>
            <AuthLayout removeValue={removeValue} />
          </AuthGuard>
        }>
        <Route element={<RoleGuard role={customerPanel} />}>
          <Route index path='/' element={<Dashboard />}></Route>
          <Route path='providers/:serviceId' element={<ProviderList />} />
          <Route path='freelancer-list/:serviceId' element={<FreelancerList />}></Route>
          <Route path='book-services/:encodedIdWithRandomString' element={<BookService />}></Route>
          <Route path='/confirm-booking' element={<ConfirmBooking />}></Route>
          <Route path='user/:encodedIdWithRandomString' element={<UserCommonProfile />}></Route>
          <Route path='my-profile' element={<CustomerProfile />}></Route>
          <Route path='/' element={<Dashboard />}>
            <Route path='/create-jobs' element={<CreateJobs />}></Route>
          </Route>
          <Route path='/content-not-found' element={<ContentNotFound />}></Route>
        </Route>
        <Route element={<RoleGuard role={freelancerPanel} />}>
          <Route path='/partner' element={<Partner />}>
            <Route path='profile' element={<UserProfile />}></Route>
            <Route index path='earning' element={<MyEarning />}></Route>
          </Route>
          <Route path='booking-request/:id' element={<BookingRequest />}></Route>
          <Route path='/content-not-found' element={<ContentNotFound />}></Route>
        </Route>
        <Route element={<RoleGuard role={[...freelancerPanel, ...customerPanel]} />}>
          <Route path='/my-bookings' element={<MyBooking />}></Route>
          <Route path='/my-bookings-ref' element={<MyBookingRefactored />}></Route>
          <Route path='my-jobs' element={<MyJobs />}></Route>
          <Route path='my-jobs/:jobId' element={<MyJobs />}></Route>
          <Route path='/chat' element={<Chat />}></Route>
          <Route path='/favourites' element={<Favourites />}></Route>
          {/* <Route path='/provider/:encodedIdWithRandomString' element={<ProviderViewProfile />}></Route> */}
          <Route path='/customer/:encodedIdWithRandomString' element={<CustomerViewProfile />}></Route>
          <Route path='/cust/:encodedIdWithRandomString' element={<CommonUserViewProfile />}></Route>
          <Route path='/prov/:encodedIdWithRandomString' element={<CommonUserViewProfile />}></Route>

          <Route path='/web-video' element={<WebVideo />}></Route>
          <Route path='/invoice' element={<Invoice />}></Route>
        </Route>
        <Route path='/subscription' element={<Subscription />}></Route>
      </Route>
      <Route
        element={
          <NonAuthGuard>
            <NonAuthLayout />
          </NonAuthGuard>
        }>
        <Route path='/login' element={<Login />}></Route>
        <Route path='/register' element={<Register />}></Route>
        <Route path='/forgot-password' element={<ForgotPassword />}></Route>
        <Route path='/verify-otp' element={<VerifyOTP />}></Route>
        <Route path='/reset-password' element={<ResetPassword />}></Route>
        <Route path='/mobile-verification' element={<MobileVerification />}></Route>
      </Route>
      <Route path='/content-not-found' element={<ContentNotFound />}></Route>
      <Route path='*' element={<PageNotFound />}></Route>
    </Routes>
  );
}
