import { Flex, Text } from '@mantine/core';

export default function NoProfilesFound() {
  return (
    <>
      <Flex direction='column'>
        <Text fz={20} fw={600} c='dimmed'>
          No profiles found
        </Text>
        <Text fz={16} c='dimmed'>
          It appears that there are no profiles to display at the moment. We understand your interest and are actively
          working to bring you a diverse and engaging selection of profiles. Please check back soon as we continue to
          update and expand our user listings. If you have any questions or specific preferences, feel free to contact
          our support team – we are here to help! Thank you for your understanding.
        </Text>
      </Flex>
    </>
  );
}
