import {
  ActionIcon,
  Badge,
  Box,
  Flex,
  Grid,
  Loader,
  LoadingOverlay,
  MultiSelect,
  ScrollArea,
  SelectItem,
  Text,
  rem,
} from '@mantine/core';
import { IconChevronDown, IconX } from '@tabler/icons-react';
import React, { useEffect, useMemo, useRef, useState } from 'react';
import { useCookies } from 'react-cookie';
import {
  addServisesforOrgUser,
  deleteServisesforOrgUser,
  orgSpecificServices,
} from '../../../../../services/organization.api';
import getProcessedServiceData from '../../../../../utils/helpers/getProcessedServiceData';
import { toastMessageUpdate } from '../../../../../utils/helpers/tosterMessage';
import CustomButton from './CustomButton';

export default function ShareService(props: any) {
  const { employeeName } = props;
  const [cookies] = useCookies(['user_Info']);
  const ref = useRef<HTMLInputElement>(null);
  const [loading, setLoading] = useState<boolean>(false);
  const [servicesOfferedByOrganization, setServicesOfferedByOrganization] = useState<Array<Record<string, any>> | null>(
    null,
  );
  const [servicesThatAreAlreadyAssignedToThisUser, setServicesThatAreAlreadyAssignedToThisUser] = useState<Array<
    Record<string, any>
  > | null>(null);

  const addServicesToThisParticularUser = async () => {
    setLoading(true);
    const formData = {
      service_provider: employeeName?.pivot?.user_id,
      organization_id: employeeName?.pivot?.organization_id,
      service_type_ids: servicesSelectedFromMultiselect?.toString(),
    };
    try {
      const saveData: any = await addServisesforOrgUser(formData);
      toastMessageUpdate('success', saveData?.message);
      setLoading(false);
      await refetchBothTypesOfServices();
      setServicesSelectedFromMultiselect([]);
      if (ref.current) {
        ref.current.value = '';
      }
    } catch (error) {
      console.log(error);
      setLoading(false);
      setServicesSelectedFromMultiselect([]);
    }
  };
  const [servicesDeleteLoader, setServicesDeleteLoader] = useState('');
  const deleteThisParticularServiceForThisUser = async (serviceId: string) => {
    setServicesDeleteLoader(serviceId);
    const formData = {
      service_provider: employeeName?.pivot?.user_id,
      organization_id: employeeName?.pivot?.organization_id,
      service_type_id: serviceId,
    };
    try {
      const saveData: any = await deleteServisesforOrgUser(formData);
      toastMessageUpdate('success', saveData?.message);
      if (saveData.success) {
        await refetchBothTypesOfServices();
        setServicesSelectedFromMultiselect([]);
        if (ref.current) {
          ref.current.value = '';
        }
        setServicesDeleteLoader('');
      }
    } catch (error) {
      console.log(error);
      setServicesSelectedFromMultiselect([]);
      setServicesDeleteLoader('');
    }
  };

  const refetchBothTypesOfServices = async () => {
    await fetchServicesOfferedByThisOrganization();
    await fetchServicesThatAreAlreadyAssignedToThisUser();
  };

  const fetchServicesOfferedByThisOrganization = async () => {
    const organizationId = +cookies?.user_Info?.id;
    try {
      const data: any = await orgSpecificServices(organizationId);
      if (data?.success && Array.isArray(data?.data)) {
        const processedData = getProcessedServiceData(data?.data);
        // console.group('ORG SPECIFIC SERVICES');
        // console.log(processedData);
        // console.groupEnd();
        if (processedData) setServicesOfferedByOrganization(processedData);
      }
    } catch (error: any) {
      console.error(error);
      toastMessageUpdate('error', error?.data?.message);
    }
  };

  const fetchServicesThatAreAlreadyAssignedToThisUser = async () => {
    const userId = employeeName?.pivot?.user_id;
    try {
      const data: any = await orgSpecificServices(userId);
      if (data?.success && Array.isArray(data?.data)) {
        const processedData = getProcessedServiceData(data?.data);
        // console.group('USER SPECIFIC SERVICES');
        // console.log(processedData);
        // console.groupEnd();
        if (processedData) setServicesThatAreAlreadyAssignedToThisUser(processedData);
      }
    } catch (error: any) {
      console.error(error);
      toastMessageUpdate('error', error?.data?.message);
    }
  };

  useEffect(() => {
    (async function () {
      setLoading(true);
      await fetchServicesOfferedByThisOrganization();
      await fetchServicesThatAreAlreadyAssignedToThisUser();
      setLoading(false);
    })();
  }, []);

  /**
   * These are services that are available for selection in the MultiSelect
   * For example:
   *    the services provided by organization are   [A, B, C, D, E, F]
   *    the services assigned to this user are      [B, C, E]
   *
   * Then the services which will be available for selection (will be shown in dropdown) would be [A, D, F]
   */
  const servicesAvailableForSelection = useMemo(() => {
    if (Array.isArray(servicesOfferedByOrganization) && Array.isArray(servicesThatAreAlreadyAssignedToThisUser)) {
      if (servicesThatAreAlreadyAssignedToThisUser.length === 0) return servicesOfferedByOrganization;
      else {
        return servicesOfferedByOrganization.filter(
          (service) =>
            !servicesThatAreAlreadyAssignedToThisUser.some(
              (assignedServices) => service.value === assignedServices.value,
            ),
        );
      }
    }
  }, [servicesOfferedByOrganization, servicesThatAreAlreadyAssignedToThisUser]);

  const [servicesSelectedFromMultiselect, setServicesSelectedFromMultiselect] = useState<Array<string>>([]);

  // console.group('DATA AVAILABLE IN THE MULTISELECT');
  // console.log(servicesAvailableForSelection);
  // console.groupEnd();

  // console.group('DATA SELECTED IN THE MULTISELECT');
  // console.log(servicesSelectedFromMultiselect);
  // console.groupEnd();

  return (
    <>
      <Box
        p={4}
        style={{
          display: 'flex',
          justifyContent: 'space-between',
          flexDirection: 'column',
          height: 500,
        }}>
        <LoadingOverlay visible={loading} />
        <Box>
          <h1>{employeeName?.name}</h1>
          <Text fz={16} c='dimmed' mb={10}>
            {`Your employee's services`}
          </Text>

          <Box
            h={70}
            pos='relative'
            style={{
              border: '1px solid #ececec',
              borderRadius: 4,
            }}>
            {Array.isArray(servicesThatAreAlreadyAssignedToThisUser) &&
            servicesThatAreAlreadyAssignedToThisUser.length === 0 ? (
              <Flex justify='center' align='center' h='100%' style={{ border: '2px dashed #ececec', borderRadius: 8 }}>
                <Text fz={16} c='#636363'>
                  No services assigned
                </Text>
              </Flex>
            ) : (
              <ScrollArea.Autosize mah={60} type='always' m={8}>
                {servicesThatAreAlreadyAssignedToThisUser?.map((element: any) => (
                  <React.Fragment key={element.value}>
                    <Badge
                      my={2}
                      mr={2}
                      color='orange'
                      size='lg'
                      variant='outline'
                      rightSection={
                        <Flex justify='center' align='center'>
                          <ActionIcon
                            onClick={() => deleteThisParticularServiceForThisUser(element.value)}
                            c='#fe7720'
                            sx={(theme) => ({
                              minWidth: 16,
                              width: 16,
                              minHeight: 16,
                              height: 16,
                              transition: '150ms',

                              ':hover': {
                                transition: '150ms',
                                backgroundColor: '#fda22347',
                                borderRadius: '50%',
                              },
                            })}>
                            {servicesDeleteLoader === element.value ? (
                              <Loader />
                            ) : (
                              <IconX size={rem(14)} cursor='pointer' />
                            )}
                          </ActionIcon>
                        </Flex>
                      }>
                      {element?.label}
                    </Badge>
                  </React.Fragment>
                ))}
              </ScrollArea.Autosize>
            )}
          </Box>
          <Flex justify='space-between' align='center' my={12}>
            <Text fz={14} c='dimmed'>
              Choose service for your employee
            </Text>
          </Flex>
          <MultiSelect
            ref={ref}
            zIndex={0}
            value={servicesSelectedFromMultiselect}
            onChange={(e) => {
              setServicesSelectedFromMultiselect(e);
            }}
            placeholder='Pick a service'
            dropdownPosition='bottom'
            disableSelectedItemFiltering
            maxDropdownHeight={280}
            disabled={servicesAvailableForSelection?.length === 0}
            // transitionProps={{ duration: 0, transition: 'pop', timingFunction: 'linear' }}
            data-autofocus
            rightSection={
              <IconChevronDown
                size='1rem'
                onClick={() => {
                  ref.current?.focus();
                  ref.current?.click();
                }}
              />
            }
            data={(servicesAvailableForSelection as SelectItem[]) ?? []}
            styles={(theme) => ({
              rightSection: {
                cursor: 'pointer',
                svg: {
                  stroke: '#fe7720',
                },
              },
              input: {
                paddingTop: 8,
                paddingBottom: 8,
              },

              dropdown: {
                padding: 24,
              },

              values: {
                maxHeight: 60,
                overflow: 'auto',

                '::-webkit-scrollbar': {
                  width: 2,
                },

                '::-webkit-scrollbar-track': {
                  backgroundColor: '#EEEEEE',
                },

                '::-webkit-scrollbar-thumb': {
                  backgroundColor: '#fda223',
                },
              },

              item: {
                marginBottom: 2,
                transition: '250ms',
                position: 'relative',

                '&[data-selected]': {
                  backgroundColor: '#FFECD8',
                  color: 'black',
                  borderRadius: 4,
                  transition: '250ms',

                  '&::after': {
                    position: 'absolute',
                    content: '""',
                    width: '100%',
                    inset: 0,
                    border: '1px solid #FFCF9C',
                    borderRadius: 4,
                    transition: '250ms',
                  },

                  '&:hover': {
                    backgroundColor: '#FEE4C8',
                    transition: '250ms',
                  },
                },

                '&:hover': {
                  backgroundColor: '#FFFAF5',
                  borderRadius: 4,
                  transition: '250ms',
                },
              },
            })}
          />
        </Box>

        <Grid mt={16}>
          <Grid.Col>
            <CustomButton
              variant='gradient'
              disabled={servicesSelectedFromMultiselect.length === 0 ?? true}
              onClick={addServicesToThisParticularUser}
              loading={loading}>
              Done
            </CustomButton>
          </Grid.Col>
        </Grid>
      </Box>
    </>
  );
}
