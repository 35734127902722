import { Box, Grid } from '@mantine/core';
import { useEffect, useState } from 'react';
import { useParams, useSearchParams } from 'react-router-dom';
import { useProviderListContext } from '../../../../../context/provider-list-context';
import { DefaultSearch, FilteredSearch, useProviderList } from '../../../../../services/profiles';
import makeTitleCase from '../../../../../utils/helpers/makeTitleCase';

import ProviderFilter from '../../../Home/components/ProviderFilter';
import { useParentGridContainerStyles } from '../style';
import FilterHeader from './FilterHeader';
import ProviderListContainer from './ProviderListContainer';
import ProviderListHeader from './ProviderListHeader';

export default function ParentGridContainer() {
  const { classes, cx } = useParentGridContainerStyles();

  const { serviceId } = useParams<{ serviceId: string }>();
  const [searchParams, setSearchParams] = useSearchParams();
  const previousViewType = searchParams.get('view');
  const previousSearchType = searchParams.get('search');

  const { setServiceName, currentServiceId, setCurrentServiceId, setProfileViewType } = useProviderListContext();

  const [searchType, setSearchType] = useState<DefaultSearch | FilteredSearch>({
    type: 'default',
    service_type_id: serviceId!,
  });

  useEffect(() => {
    const previousViewType = searchParams.get('view');
    if (previousViewType === 'list' || previousViewType === 'grid') {
      setProfileViewType(previousViewType);
    }
  }, [searchParams.entries()]);

  useEffect(() => {
    if (serviceId) {
      setCurrentServiceId(serviceId);
    }

    if (previousViewType === null) {
      // setting the query param named 'view' for the first time
      setSearchParams((prev) => [...prev.entries(), ['view', 'list']]);
      setProfileViewType('list');
    } else {
      if (previousViewType === 'list' || previousViewType === 'grid') {
        setProfileViewType(previousViewType);
      } else {
        /**
         * If the view type is anything other than 'view' and 'grid'
         * then set the view as 'list'
         */
        setSearchParams((prev) => {
          const newParams = new URLSearchParams(prev);
          newParams.set('view', 'list');
          return newParams;
        });
        setProfileViewType('list');
      }
    }

    if (previousSearchType === null) {
      setSearchParams((prev) => [...prev.entries(), ['search', 'default']]);
    }

    const serviceNameFromURL = searchParams.get('service');
    if (serviceNameFromURL) {
      const normalizedServiceName = makeTitleCase(serviceNameFromURL);
      setServiceName(normalizedServiceName);
    }
  }, []);

  const { data, isLoading } = useProviderList(searchType, Boolean(currentServiceId));

  const handleProviderFilterFormData = (type: any, data: any) => {
    console.log({ type, data });
    if (type === 'submit') {
      setSearchType({ type: 'filtered', service_type_id: serviceId!, data: data });
    } else if (type === 'reset') {
      setSearchType({ type: 'default', service_type_id: serviceId! });
    }
  };

  return (
    <>
      <Grid className={classes.parentGridContainer}>
        <Grid.Col className={classes.childGridContainerLeft}>
          <Box style={{ padding: 8, height: '100%' }}>
            <ProviderListHeader />
            <ProviderListContainer data={data?.data} isLoading={isLoading} />
          </Box>
        </Grid.Col>
        <Grid.Col className={classes.childGridContainerRight}>
          <Box style={{ padding: 8, height: '100%' }}>
            <FilterHeader />
            <ProviderFilter sendDataToParent={handleProviderFilterFormData} />
          </Box>
        </Grid.Col>
      </Grid>
    </>
  );
}
