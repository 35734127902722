import { Box, Flex, Grid, Text } from '@mantine/core';
import { useBookingContext } from '../../../../../context/booking-context';
import { useGoogleMapComponentStyles } from '../style';
import CrossButton from './CrossButton';

interface Props {
  renderIf: boolean;
  onClose: () => void;
}

export default function GoogleMapComponent({ renderIf, onClose }: Props) {
  const { allGridColStyles, showMap } = useBookingContext();
  const { classes } = useGoogleMapComponentStyles();

  return (
    <>
      {renderIf ? (
        <Grid.Col {...allGridColStyles.childBookingDetailsMapOrAddItemGridColumnStyles} h={'100%'}>
          <Flex justify='space-between' align='flex-start'>
            <Box>
              <Text pb={4} fz={14} fw={600}>
                Service provider will arrive within 30 mins*
              </Text>
              <Text fz={13} color='dimmed' mb={10}>
                Service provider arrival depends upon multiple factors hence we can&apos;t guarantee but we will try our
                best. AidBy is not responsible for any late arrival.
              </Text>
            </Box>

            <CrossButton onClose={onClose} />
          </Flex>
          <Box className={classes.container}>map</Box>
        </Grid.Col>
      ) : null}
    </>
  );
}
