import { useEffect, useState } from 'react';
import generateRandomString from '../helpers/generateRandomAlphabetStrings';

type useEncodedIdArguments = {
  data: any;
  userId: number;
};

/**
 * This custom hook returns an encoded user id for a particular user
 * for example, lets say the user id is 42
 * 1. it will prepend and append random 16 character to this user id (e.g. Dpu.....42.....JnH)
 * 2. then it will encode that string using the btoa function and return that
 */

export const useEncodedUserId = ({ data, userId }: useEncodedIdArguments) => {
  const [encodedIdWithString, setEncodedIdWithString] = useState('');

  useEffect(() => {
    setEncodedIdWithString(btoa(generateRandomString(16) + userId + generateRandomString(16)));
  }, [data]);

  return encodedIdWithString;
};
