import { Box, Flex, Text } from '@mantine/core';
import { RoleType } from '../../../../../utils';
import renderVerificationStatusText from '../../../../../utils/helpers/renderVerificationStatusText';
import getVerificationStatusIfUserIsViewingOwnProfile from '../../../../../utils/helpers/getVerificationStatusIfUserIsViewingOwnProfile';
import { useCookies } from 'react-cookie';
import getVerificationStatusIfUserIsNotViewingOwnProfile from '../../../../../utils/helpers/getVerificationStatusIfUserIsNotViewingOwnProfile';

interface Props {
  role: any;
  data: any;
  userIsViewingOwnProfile: boolean;
}

export default function VerificationStatusContainer({ role, data, userIsViewingOwnProfile }: Props) {
  console.log('589JK');

  console.log(role);
  console.log(data);
  console.log(userIsViewingOwnProfile);

  const [cookie, setCookie] = useCookies(['user_Info']);

  // {props?.user?.type === RoleType.service_provider && (
  //   <Text ta='center' size='sm' weight={600} fz={14} color='dimmed'>
  //     Background Verification :{' '}
  //     <Text weight={500} fz={14} span c={props?.verification?.background_verification ? 'green' : 'dimmed'}>
  //       {
  //         renderVerificationStatusText({
  //           data: props?.verification,
  //           // data: {
  //           //   background_verification: false,
  //           //   business_license_verification: null,
  //           //   drug_test_verification: true,
  //           //   onfido_result: 'clear',
  //           //   type: '1',
  //           //   user_verification: true,
  //           // },
  //           role: props?.user?.type,
  //           userIsViewingOwnProfile,
  //         }).backgroundVerification
  //       }
  //     </Text>
  //   </Text>
  // )}

  return (
    <>
      {/* <Box ta={'center'}> */}
      {RoleType.service_provider === role ? (
        <>
          {/* <Flex gap={4}> */}
          <Text ta='center' size='sm' weight={600} fz={14} color='dimmed'>
            Background Verification:
          </Text>
          <Text weight={500} fz={14} span color={data?.background_verification ? 'green' : 'dimmed'}>
            {userIsViewingOwnProfile
              ? getVerificationStatusIfUserIsViewingOwnProfile({
                  data: { ...data, onfido_applicant_id: cookie.user_Info.onfido_applicant_id },
                  role,
                }).backgroundVerification
              : getVerificationStatusIfUserIsNotViewingOwnProfile({
                  data: data,
                  role,
                }).backgroundVerification}
          </Text>
          {/* </Flex> */}
        </>
      ) : null}

      {RoleType.organization_service_provider !== role ? (
        <Flex gap={4}>
          <Text ta='center' size='sm' weight={600} fz={14} color='dimmed'>
            Identity Verification:
          </Text>
          <Text
            ta='center'
            size='sm'
            weight={500}
            fz={14}
            c={data?.onfido_result === 'clear' || data?.signzy_journey__result ? 'green' : 'dimmed'}>
            {userIsViewingOwnProfile
              ? getVerificationStatusIfUserIsViewingOwnProfile({
                  data: {
                    ...data,
                    onfido_applicant_id: cookie.user_Info.onfido_applicant_id,
                  },
                  role,
                }).identityVerification
              : getVerificationStatusIfUserIsNotViewingOwnProfile({
                  data: data,
                  role,
                }).identityVerification}
          </Text>
        </Flex>
      ) : null}

      {RoleType.organization_service_provider === role ? (
        <Flex>
          <Text ta='center' size='sm' weight={600} fz={14} color='dimmed'>
            License verification:
          </Text>
          <Text ta='center' size='sm' weight={500} fz={14} c={data?.business_license_verification ? 'green' : 'dimmed'}>
            {userIsViewingOwnProfile
              ? getVerificationStatusIfUserIsViewingOwnProfile({
                  data: { ...data, onfido_applicant_id: cookie.user_Info.onfido_applicant_id },
                  role,
                }).licenseVerification
              : getVerificationStatusIfUserIsNotViewingOwnProfile({
                  data: data,
                  role,
                }).licenseVerification}
          </Text>
        </Flex>
      ) : null}
      {/* </Box> */}
    </>
  );
}
