import { createStyles, getStylesRef, keyframes, rem } from '@mantine/core';

export const useServiceCardStyles = createStyles((theme) => ({
  card: {
    backgroundColor: theme.colorScheme === 'dark' ? theme.colors.dark[7] : theme.white,
  },
  body: {
    padding: theme.spacing.md,
  },
  like: {
    color: theme.colors.red[6],
  },
  edit: {
    color: theme.colors.red[6],
  },
  container: {
    background: theme.fn.lighten(theme.fn.primaryColor(), 0.75),
    transition: 'all .5s linear',
    display: 'flex',
    flexDirection: 'column',
    '&:hover': {
      background: theme.fn.linearGradient(136, '#FE5D1B', '#FEB923'),
      '.icon_container': {
        boxShadow: theme.shadows.xs,
      },
      '.title': {
        color: '#fff',
      },
      '.description': {
        color: '#fff',
      },
      '.action-btn': {
        background: 'white',
        color: theme.fn.primaryColor(),
      },
    },
    '.icon_container': {
      boxShadow: theme.shadows.xs,
    },
    '.title': {
      color: theme.fn.primaryColor(),
      fontSize: theme.fontSizes.xl,
    },
    '.description': {
      color: theme.colors.gray[7],
      fontSize: theme.fontSizes.md,
    },
  },
}));

export const useDashboardStyles = createStyles((theme) => ({
  container: {
    minHeight: 250,
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    background: theme.fn.lighten(theme.fn.primaryColor(), 0.75),
    '&.active': {
      position: 'relative',
      '&:before': {
        content: `''`,
        position: 'absolute',
        bottom: -30,
        left: '50%',
        transform: 'translateX(-50%)',
        height: 10,
        width: 10,
        border: '15px solid ',
        borderColor: '#FE5D1B transparent transparent transparent',
        zIndex: -1,
      },
    },
    '&.active, &:hover': {
      background: theme.fn.linearGradient(136, '#FE5D1B', '#FEB923'),
      '.icon_container': {
        boxShadow: theme.shadows.xs,
      },
      '.title': {
        color: '#fff',
      },
      '.description': {
        color: '#fff',
      },
      '.action-btn': {
        background: 'white',
        color: theme.fn.primaryColor(),
      },
    },
    '.icon_container': {
      boxShadow: theme.shadows.xs,
    },
    '.title': {
      color: theme.fn.primaryColor(),
      // fontSize: theme.fontSizes.lg,
    },
    '.description': {
      color: theme.colors.gray[7],
      fontSize: theme.fontSizes.sm,
    },
  },
  childcontainer: {
    minHeight: 250,
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    background: theme.fn.lighten(theme.fn.primaryColor(), 0.75),
    '&.active': {
      position: 'relative',
      '&:before': {
        content: `''`,
        position: 'absolute',
        bottom: -30,
        left: '50%',
        transform: 'translateX(-50%)',
        height: 10,
        width: 10,
        border: '15px solid ',
        borderColor: '#FE5D1B transparent transparent transparent',
        zIndex: -1,
      },
    },
    '&.active, &:hover': {
      background: theme.fn.linearGradient(136, '#f7d8cb', '#ffebbf'),
      '.icon_container': {
        boxShadow: theme.shadows.xs,
      },
      '.title': {
        color: '#FE7D49',
      },
      '.description': {
        color: '#fff',
      },
      '.action-btn': {
        background: 'white',
        color: theme.fn.primaryColor(),
      },
    },
    '.icon_container': {
      boxShadow: theme.shadows.xs,
    },
    '.title': {
      color: theme.fn.primaryColor(),
      // fontSize: theme.fontSizes.lg,
    },
    '.description': {
      color: theme.colors.gray[7],
      fontSize: theme.fontSizes.sm,
    },
  },
}));

export const useMonthHeaderStyles = createStyles(() => ({
  calendarHeader: {
    display: 'none',
  },

  input: {
    '&[data-with-icon]': {
      paddingLeft: '1em',
    },
  },

  icon: {
    position: 'absolute',
    left: 'auto',
    right: 0,
  },
}));

export const usePromotionModalStyles = createStyles((theme) => ({
  title: {
    fontSize: '20px',
    color: theme.colors.orange[6],
    fontWeight: 600,
  },
  close: {
    border: 'none',
  },
}));

export const useRelatedProfilesStyles = createStyles((theme) => ({
  relatedProfilesCardContainer: {
    backgroundColor: theme.colorScheme === 'light' ? '#f4f4f4' : theme.colors.dark[6],
    userSelect: 'none',
    // height: 'calc(100% - 300px)',

    [theme.fn.smallerThan(992)]: {
      backgroundColor: 'white',
      padding: 0,
    },
  },
}));

export const useRelatedProfilesScrollAreaStylesVariationOne = createStyles((theme) => ({
  root: {
    maxHeight: 'calc(100svh - 225px)',

    [theme.fn.smallerThan(992)]: {
      maxHeight: '100%',
    },
  },
}));

export const useRelatedProfilesScrollAreaStylesVariationTwo = createStyles((theme) => ({
  root: {
    maxHeight: 'calc(100svh - 590px)',

    [theme.fn.smallerThan(992)]: {
      maxHeight: '100%',
    },
  },
}));

export const useRelatedProfileCard = createStyles((theme) => ({
  relatedProfilesCard: {
    width: '100%',
    padding: theme.spacing.xs,
    border: `1px solid ${theme.colorScheme === 'light' ? '#c1c1c1' : '#3a3a3a'}`,
    borderRadius: 10,
    backgroundColor: theme.colorScheme === 'light' ? '#dfdfdf' : theme.colors.dark[7],
    transition: '200ms',

    '&:hover': {
      backgroundColor: theme.colorScheme === 'light' ? '#d8d8d8' : theme.colors.dark[6],
      border: `1px solid ${theme.colorScheme === 'light' ? '#868686' : '#484848'}`,
      transition: '200ms',
    },

    '.providerName': {
      fontSize: 18,
      fontWeight: 600,
    },

    '.priceRateText': {
      fontSize: 16,
      color: '#767676',
    },

    '.iconRoute': {
      width: 16,
      height: 16,
      stroke: '#767676',
    },

    '.currencyIcon': {
      width: 19,
      height: 19,
      stroke: '#767676',
    },
  },
}));

export const usePaymentModalStyles = createStyles((theme) => ({
  container: {
    width: '50%',
    padding: 12,
    display: 'flex',
    position: 'fixed',
    border: '1px solid #d6d6d6',
    borderRadius: `${rem(8)} 0 0 ${rem(8)}`,
    height: 'calc(100lvh - 160px)',
    boxShadow: `-4px 0px 12px 0px rgba(0,0,0,0.1)`,
    right: 0,
    top: 118,
    animation: `${comeFromRight} 400ms ease-out`,

    [theme.fn.smallerThan(993)]: {
      animation: 'none',
      width: '100%',
      border: 'none',
      padding: 0,
      boxShadow: 'none',
      position: 'relative',
      height: '100%',
      top: 0,
    },
  },
}));

const comeFromRight = keyframes({
  '0%': {
    transform: 'translateX(+100%)',
  },

  '100%': {
    transform: 'translateX(0%)',
  },
});

export const useImageCropperModalStyles = createStyles((theme) => ({
  body: {
    borderRadius: 8,
  },
  content: {
    position: 'relative',
    padding: '0px !important',
    margin: '0px !important',
    flex: '0 0 calc(100vw - 800px) !important',

    [theme.fn.smallerThan(1350)]: {
      flex: '0 0 calc(100vw - 30%) !important',
    },

    [theme.fn.smallerThan(700)]: {
      flex: '0 0 calc(100vw - 20%) !important',
    },
  },
  inner: {
    padding: '0px !important',
    margin: '0px !important',
  },
  overlay: {
    padding: '0px !important',
    margin: '0px !important',
  },
  root: {
    padding: '0px !important',
    margin: '0px !important',
  },
}));

export const useImageCropperStyles = createStyles((theme) => ({
  crossIconButtonContainer: {
    position: 'absolute',
    top: 20,
    right: 20,
    color: '#888888',
    transition: '120ms',
    ':hover': {
      backgroundColor: 'none',
      color: '#fe7720',
      transition: '120ms',
    },

    [`&:hover .${getStylesRef('cross-button')}`]: {
      transition: '120ms',
    },

    [theme.fn.smallerThan(451)]: {
      display: 'none',
    },
  },

  crossIconButton: {
    ref: getStylesRef('cross-button'),
    width: 20,
    height: 20,
    transition: '120ms',
  },
}));
