import { yupResolver } from '@hookform/resolvers/yup';
import { Button, Checkbox, Flex, Paper, PasswordInput, Stack, Text, TextInput, Title } from '@mantine/core';
import { upperFirst, useDisclosure, useMediaQuery } from '@mantine/hooks';
import { IconEye, IconEyeOff } from '@tabler/icons-react';
import axios from 'axios';
import DeviceDetector from 'device-detector-js';
import { useState } from 'react';
import { useCookies } from 'react-cookie';
import { SubmitHandler, useForm } from 'react-hook-form';
import { Link, useNavigate } from 'react-router-dom';
import { v4 as uuidv4 } from 'uuid';
import { InferType } from 'yup';
import { getIP, loginAPI } from '../../services/authentication.api';
import { userProfile } from '../../services/user.api';
import { providerRole } from '../../utils';
import { toastMessageUpdate } from '../../utils/helpers/tosterMessage';
import { signInSchema } from '../../utils/schema/formSchema';
import ModalOrDrawer from '../Dashboard/Customer/Profile/components/ModalOrDrawer';
import ForgotPassword from './ForgotPassword';
import ValidateMobileNumber from './ValidateMobileNumber';
import Wrapper from './component/Wrapper';
import { useLoginStyles } from './component/style';

export default function Login() {
  const { classes } = useLoginStyles();
  const isMobile = useMediaQuery('(min-width: 420px)');
  const [cookies, setCookie] = useCookies([
    'access_token',
    'role',
    'user_Info',
    'user_Address',
    'user_Overview',
    'org_info',
    'subscription_status',
  ]);
  const navigate = useNavigate();
  const [loading, setLoading] = useState<boolean>(false);

  const {
    register,
    handleSubmit,
    formState: { errors },
    setValue,
  } = useForm({
    resolver: yupResolver(signInSchema),
    mode: 'all',
  });

  const onSubmit: SubmitHandler<InferType<typeof signInSchema>> = async (data) => {
    setLoading(true);
    let device_id: any = JSON.parse(localStorage.getItem('d_id') as any);

    if (!device_id) {
      device_id = uuidv4();
      localStorage.setItem('d_id', JSON.stringify(device_id));
    }
    const deviceDetector = new DeviceDetector();
    const userAgent = window.navigator.userAgent;
    const device_type = deviceDetector.parse(userAgent).device?.type;

    const ip_address = await getIP();
    const loginData = {
      email: data.email.trim().toLowerCase(),
      password: data.password,
      device_type,
      device_id,
      ip_address,
    };
    // return;

    try {
      const data = await loginAPI(loginData);
      let userType = null;
      if (data.data.success) {
        userType = data.data.success?.['user']?.['type'];
        setCookie('access_token', data.data.success?.['token'], { path: '/' });
        setCookie('user_Info', data.data.success?.['user'], { path: '/' });
        setCookie('role', userType, { path: '/' });
      }

      const resUserData = await userProfile();
      setCookie('user_Overview', resUserData.data?.['user_profile'], { path: '/' });
      setCookie('user_Address', resUserData.data?.['has_address'], { path: '/' });
      setCookie('subscription_status', resUserData.data?.is_expired, {
        path: '/',
      });

      if (providerRole.includes(userType)) {
        navigate('/partner/profile', { replace: true });
      } else {
        if (resUserData.data?.has_address) {
          navigate('/', {
            replace: true,
          });
        } else {
          navigate('/my-profile', {
            replace: true,
          });
        }
      }
      setLoading(false);
    } catch (error: any) {
      if (axios.isAxiosError(error)) {
        if (error.response?.data.mobile_verification === false) {
          openValidateMobileNumber();
        } else if (Object.hasOwn(error.response?.data, 'error')) {
          toastMessageUpdate('error', error.response?.data.error);
          setLoading(false);
        } else {
          toastMessageUpdate('error', error.response?.data.message);
          setLoading(false);
        }
      } else {
        toastMessageUpdate('error', error?.response?.data?.message || '');
        setLoading(false);
      }
    }
  };

  const [validateMobileNumberIsOpened, { open: openValidateMobileNumber, close: closeValidateMobileNumber }] =
    useDisclosure(false);

  const [forgotPasswordIsOpened, { open: openForgotPassword, close: closeForgotPassword }] = useDisclosure(false);

  const stopTheLoaderAndCloseValidateMobileNumber = () => {
    closeValidateMobileNumber();
    setLoading(false);
  };

  return (
    <>
      <ModalOrDrawer
        opened={validateMobileNumberIsOpened}
        onClose={stopTheLoaderAndCloseValidateMobileNumber}
        drawerProps={{ dz: '150', drawerBackButton: true }}
        modalProps={{ modalCloseButton: true }}>
        <ValidateMobileNumber closeMobileNumberInput={closeValidateMobileNumber} />
      </ModalOrDrawer>

      <ModalOrDrawer
        opened={forgotPasswordIsOpened}
        onClose={closeForgotPassword}
        drawerProps={{ dz: '200', drawerBackButton: true }}
        modalProps={{ modalCloseButton: true }}>
        <ForgotPassword close={closeForgotPassword} />
      </ModalOrDrawer>

      <Wrapper>
        <Paper className={classes.paper} radius='md' py={!isMobile ? 0 : 'xl'} px='xs' withBorder={false}>
          <Title ta='center' order={1} fw={600}>
            Welcome back
          </Title>
          <Text ta='center' color='dimmed' mb={20}>
            Please enter your details
          </Text>

          <form onSubmit={handleSubmit(onSubmit)} autoComplete='off' spellCheck='false'>
            <Stack>
              <TextInput
                {...register('email', { required: true })}
                size='md'
                label='Email'
                fw='lg'
                placeholder='Enter your email'
                onPaste={(e) => {
                  e.preventDefault();
                  setValue('email', e.clipboardData.getData('Text').trim(), { shouldValidate: true });
                }}
              />
              {errors?.email && (
                <Text fz={14} mt={-16} pl={5} fw={500} color='red'>
                  <span></span>
                  {errors?.email?.message}
                </Text>
              )}
              <PasswordInput
                {...register('password', { required: true })}
                label='Password'
                size='md'
                placeholder='Enter your Password'
                radius='md'
                visibilityToggleIcon={({ reveal }) =>
                  reveal ? <IconEye size={20} stroke={1} /> : <IconEyeOff size={20} stroke={1} />
                }
              />
              {errors?.password && (
                <Text fz={14} mt={-16} pl={5} fw={500} color='red'>
                  {errors?.password?.message}
                </Text>
              )}
              <Flex mb='xl' align='center' justify='space-between'>
                <Checkbox transitionDuration={250} label='Remember me' />
                <Button compact size='md' c='orange' variant='transparent' onClick={openForgotPassword}>
                  <Text fz={'sm'}>Forgot Password?</Text>
                </Button>
              </Flex>
            </Stack>

            <Button
              variant='gradient'
              gradient={{ from: '#fda223', to: '#fe7720' }}
              fullWidth
              size='md'
              loading={loading}
              type='submit'
              radius='sm'>
              {!loading && upperFirst('Sign In')}
            </Button>

            <Flex justify='center' gap={5} mt={25} direction={isMobile ? 'row' : 'column'}>
              <Text ta='center'>Don&apos;t have an account yet?</Text>
              <Link style={{ textDecoration: 'none' }} to='/register'>
                <Text ta='center' c='orange.9'>
                  Sign up
                </Text>
              </Link>
            </Flex>
          </form>
        </Paper>
      </Wrapper>
    </>
  );
}
