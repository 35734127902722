import { Box, Grid, LoadingOverlay, ScrollArea } from '@mantine/core';
import { useProviderListContext } from '../../../../../context/provider-list-context';
import { GridCard } from '../../FreelancerList/components/GridCard';
import ListCardRefactored from '../../FreelancerList/components/ListCardRefactored';
import { useProviderListContainerScrollAreaStyles } from '../style';
import NoProfilesFound from './NoProfilesFound';
import UrgentBookingButton from './UrgentBookingButton';

interface Props {
  data: any;
  isLoading: boolean;
}

export default function ProviderListContainer({ data, isLoading }: Props) {
  console.log(data);

  const { classes } = useProviderListContainerScrollAreaStyles();
  const { profileViewType } = useProviderListContext();

  console.log(Array.isArray(data) && data.length === 0);

  return (
    <>
      <Box style={{ position: 'relative', height: 'calc(100svh - 210px)' }}>
        <LoadingOverlay visible={isLoading} />

        <UrgentBookingButton
          mt={8}
          onClick={() => console.log('clicked')}
          renderIf={Array.isArray(data) && data.length > 0}
        />

        <Box mt={16}>
          <ScrollArea.Autosize classNames={classes} type='never'>
            {data === undefined ? null : (
              <>
                <Grid p={0} m={0}>
                  {Array.isArray(data) && data.length === 0 && (
                    <Grid.Col p={0} m={0}>
                      <NoProfilesFound />
                    </Grid.Col>
                  )}

                  {data.map((provider: any) => {
                    switch (profileViewType) {
                      case 'list':
                        return (
                          <Grid.Col p={8} m={0} key={provider.id} lg={12}>
                            <ListCardRefactored {...provider} />
                          </Grid.Col>
                        );

                      case 'grid':
                        return (
                          <Grid.Col p={8} m={0} key={provider.id} lg={4}>
                            <GridCard {...provider} />
                          </Grid.Col>
                        );
                    }
                  })}
                </Grid>
              </>
            )}
          </ScrollArea.Autosize>
        </Box>
      </Box>
    </>
  );
}
