import { Avatar, Button, Flex, Grid, Group, Paper, Skeleton, Tabs, Text, createStyles } from '@mantine/core';
import { useDisclosure } from '@mantine/hooks';
import { IconCalendarCheck, IconCreditCard } from '@tabler/icons-react';
import dayjs from 'dayjs';
import { useState } from 'react';
import { requestWithdraw, useEarningList, useGetTotalEarning } from '../../../../services/earning.api';
import { serviceIconHandler } from '../../../../utils/helpers';
import { makeUpperCase } from '../../../../utils/helpers/makeFirstLetterUpper';
import { toastMessageUpdate } from '../../../../utils/helpers/tosterMessage';
import { CusBadge } from '../../MyBooking/components/BookingStatusOld';
import WithdrawModal from './components/WithdrawModal';

const useStyles = createStyles((theme) => ({
  card: {
    backgroundColor: theme.colorScheme === 'light' ? '#ececec' : theme.colors.dark[6],

    '&:hover': {
      backgroundColor: theme.colorScheme === 'light' ? 'white' : theme.colors.dark[5],
      boxShadow: '0px 0px 20px #FECDA6',
      borderColor: '#FECDA6',
    },
  },
}));
function TransactionTable({ data }: any) {
  // const ths = (
  //   <tr>
  //     <th>Booking Id</th>
  //     <th>Booking Date</th>
  //     <th>Payment Methods</th>
  //     <th>Actions</th>
  //     <th>Amount</th>
  //   </tr>
  // );
  const { classes } = useStyles();

  // const rows = data.map((element: any) => {
  //   console.log(element);
  //   return (
  //     // <tr key={element?.booking_id}>
  //     //   <td>{element?.booking_id}</td>
  //     //   <td>{dayjs(element?.booking_date).format('DD MMMM, YYYY')}</td>
  //     //   <td>N/A</td>
  //     //   <td>{element?.withdrawal_status_details}</td>
  //     //   <td>${element?.service?.rate}</td>
  //     // </tr>
  //   )
  // });

  return (
    // <Table verticalSpacing={13} captionSide='bottom' miw={500}>
    //   <thead>{ths}</thead>
    //   <tbody>{rows}</tbody>
    // </Table>
    <>
      <Grid gutter={30} m={0}>
        {data.map((element: any) => {
          return (
            <Grid.Col key={data.booking_id} lg={3} md={4} sm={6} xs={12}>
              <Paper withBorder p='sm' color='red' className={classes.card}>
                <Flex gap={15} mb={8} h={48}>
                  <Avatar
                    src={serviceIconHandler(element?.service_type?.service)}
                    styles={(theme) => ({ image: { objectFit: 'contain' } })}></Avatar>
                  <Text fz='md' fw={600}>
                    {makeUpperCase(element?.service_type?.service)}
                  </Text>
                </Flex>
                {/* <Flex gap={15} mb={8} justify='space-between'>
                  <Text fz='md' fw={600}>
                    {element?.service_type?.service}
                  </Text>
                </Flex> */}
                <Flex justify='space-between' mb={8}>
                  <Text size={18} fw={'bold'}>
                    Amount:
                  </Text>
                  <Text fw='bold'>${element.amount ? element.amount : element?.service?.rate}</Text>
                </Flex>
                <Flex justify='space-between' mb={8}>
                  <Group spacing={2}>
                    <IconCalendarCheck size='1.1em' />
                    <Text size='sm'> Date:</Text>
                  </Group>
                  <Text c='dimmed' size='sm'>
                    {dayjs(element?.booking_date).format('DD MMMM, YYYY')}
                  </Text>
                </Flex>
                <Flex justify='space-between' mb={10}>
                  <Group spacing={2}>
                    <IconCreditCard size='1.1em' />
                    <Text size='sm'>Withdrawal Status:</Text>
                  </Group>
                  <Text c='dimmed' size='sm'>
                    <CusBadge color='green'>{element?.withdrawal_status_details}</CusBadge>
                  </Text>
                </Flex>
                {/* <Flex>
                  <BookingStatus data={element} fromModal={false} />
                </Flex> */}
              </Paper>
            </Grid.Col>
          );
        })}
      </Grid>
    </>
  );
}
export default function MyEarning() {
  const { data, isLoading, refetch } = useEarningList();
  const { data: totalEarningData } = useGetTotalEarning();
  const [opened, { open, close }] = useDisclosure(false);

  const [withdrawLoading, setWithdrawLoading] = useState(false);
  const withdrawHandler = async () => {
    try {
      setWithdrawLoading(true);
      const res: any = await requestWithdraw();
      refetch();
      setWithdrawLoading(false);
      toastMessageUpdate('success', res?.message);
    } catch (error: any) {
      setWithdrawLoading(false);
      toastMessageUpdate('info', error?.data?.message);
    }
  };
  return (
    <>
      <WithdrawModal opened={opened} close={close} />
      <Text size='xl' ml={10} mt={40}>
        My Earnings
      </Text>
      <Flex align='center' justify='space-between' mb='xl'>
        <Text size='lg' weight={600} ml={10}>
          ${totalEarningData?.data?.earnings || 0}
        </Text>
        {totalEarningData?.data?.earnings && (
          <Button
            loading={withdrawLoading}
            size='md'
            mr={10}
            onClick={withdrawHandler}
            variant='gradient'
            gradient={{ to: 'orange', from: 'yellow', deg: 160 }}>
            Withdraw
          </Button>
        )}
      </Flex>
      <Tabs defaultValue='transactions' m={5}>
        <Tabs.List style={{ width: 'fit-content', display: 'flex' }}>
          <Tabs.Tab value='transactions'>Transactions</Tabs.Tab>
          {/* <Tabs.Tab value='withdraw_history'>Withdraw history</Tabs.Tab> */}
        </Tabs.List>

        <Tabs.Panel value='transactions' pt='xs'>
          {isLoading ? (
            <>
              <Grid gutter={30}>
                {Array(9)
                  .fill(0)
                  .map((_, index) => {
                    return (
                      <Grid.Col key={index} lg={3} md={4} sm={6}>
                        <Skeleton visible={true} h={160} />
                      </Grid.Col>
                    );
                  })}
              </Grid>
            </>
          ) : data?.data?.length ? (
            <TransactionTable data={data?.data} />
          ) : (
            'No data'
          )}
        </Tabs.Panel>
      </Tabs>
    </>
  );
}
