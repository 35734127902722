import { AxiosResponse } from 'axios';
import {
  LogoLight,
  babySittingIcon,
  bedPositioningAndTransferringIcon,
  careGivingIcon,
  carpenterIcon,
  carpetCleaningAndWashingIcon,
  cleaningIcon,
  companionshipAssistanceIcon,
  cookingIcon,
  deepCleaningIcon,
  deliveryIcon,
  dogWalkingIcon,
  electricianIcon,
  eventCookingIcon,
  feedingAssistanceIcon,
  foodDeliveryIcon,
  generalCaregivingIcon,
  groceryDeliveryIcon,
  handymanIcon,
  kidsPickupAndDropoffIcon,
  laundryIcon,
  lawnCareIcon,
  moveoutCleaningIcon,
  movingIcon,
  organizingIcon,
  painterIcon,
  petBoardingIcon,
  petCareIcon,
  petGroomingIcon,
  petSittingIcon,
  plumberIcon,
  postConstructionCleaningIcon,
  pressureWashingIcon,
  regularCleaningIcon,
  regularCookingIcon,
  showerAssistanceIcon,
  toiletingAssistanceIcon,
  walkingAssistanceIcon,
  washAndFoldIcon,
  washAndPressIcon,
  windowCleaningIcon,
} from '../../assets';

export function convertTimeFormat(time: string) {
  // console.log(time);

  if (!time) {
    return '';
  }
  const [hr, min] = time.split(':');
  let hours = Number(hr);

  let period = 'AM';

  if (+hours >= 12) {
    period = 'PM';
    if (+hours > 12) {
      hours -= 12;
    }
  }

  return `${hours}:${min} ${period}`;
}

export function convertUtcTimeToLocalFormat(providedUtcTime: string) {
  // console.log(providedUtcTime);

  if (!providedUtcTime) return;
  const convertedTimeObject = new Date(
    new Date(providedUtcTime).getTime() - new Date().getTimezoneOffset() * 60 * 1000,
  ).toLocaleTimeString(undefined, { hour12: true });
  const [time, ampm] = convertedTimeObject.split(' ');
  const hour = parseInt(time.split(':')[0]) < 10 ? `0${time.split(':')[0]}` : time.split(':')[0];
  return `${hour}:${time.split(':')[1]} ${ampm.toUpperCase()}`;
}

export const maskEmail = (email: string) => {
  const [username, domain] = email.split('@');
  let maskedUsername = '';
  if (username.length > 5) {
    maskedUsername = username.slice(0, 2) + '*'.repeat(username.length - 3) + username.slice(-1);
  } else {
    maskedUsername = username.slice(0, 1) + '*'.repeat(username.length - 1) + username.slice(-1);
  }
  const maskedEmail = maskedUsername + '@' + domain;
  return maskedEmail;
};

export const maskMobile = (mobile: string) => {
  const maskedPortion = mobile.slice(2, -3).replace(/\d/g, '*');
  return mobile.slice(0, 2) + maskedPortion + mobile.slice(-3);
};

export const catchHandler = <T>(fn: (arg?: T) => Promise<AxiosResponse>): ((arg?: T) => Promise<AxiosResponse>) => {
  return async (arg?: T) => {
    try {
      return await fn(arg);
    } catch (error) {
      console.log(error);
      // throw error;
      return Promise.reject(error);
    }
  };
};

export const serviceIconHandler = (title: string) => {
  const new_title = title.toLowerCase().replaceAll(' ', '_');

  switch (new_title) {
    case 'walking_assistance':
      return walkingAssistanceIcon;
    case 'baby_sitting':
      return babySittingIcon;
    case 'cleaning':
      return cleaningIcon;
    case 'pet_care':
      return petCareIcon;
    case 'cooking':
      return cookingIcon;
    case 'caregiving':
      return careGivingIcon;
    case 'laundry':
      return laundryIcon;
    case 'delivery':
      return deliveryIcon;
    case 'food_delivery':
      return foodDeliveryIcon;
    case 'grocery_delivery':
      return groceryDeliveryIcon;
    case 'moving':
      return movingIcon;
    case 'companionship_assistance':
      return companionshipAssistanceIcon;
    case 'bed_positioning_and_transferring':
      return bedPositioningAndTransferringIcon;
    case 'transportation_services':
      return companionshipAssistanceIcon;
    case 'feeding_assistance':
      return feedingAssistanceIcon;
    case 'general_caregiving':
      return generalCaregivingIcon;
    case 'shower_assistance':
      return showerAssistanceIcon;
    case 'toiletting_assistance':
      return toiletingAssistanceIcon;
    case 'regular_cooking':
      return regularCookingIcon;
    case 'event_cooking':
      return eventCookingIcon;
    case 'regular_cleaning':
      return regularCleaningIcon;
    case 'wash_and_fold':
      return washAndFoldIcon;
    case 'wash_and_press':
      return washAndPressIcon;
    case 'kids_pick_up_and_drop_off':
      return kidsPickupAndDropoffIcon;
    case 'dog_walking':
      return dogWalkingIcon;
    case 'pet_sitting':
      return petSittingIcon;
    case 'pet_grooming':
      return petGroomingIcon;
    case 'pet_boarding':
      return petBoardingIcon;
    case 'deep_cleaning':
      return deepCleaningIcon;
    case 'carpet_cleaning_and_washing':
      return carpetCleaningAndWashingIcon;
    case 'moveout_cleaning':
      return moveoutCleaningIcon;
    case 'window_cleaning':
      return windowCleaningIcon;
    case 'post_construction_cleaning':
      return postConstructionCleaningIcon;
    case 'pressure_washing':
      return pressureWashingIcon;
    case 'organizing':
      return organizingIcon;
    case 'lawn_care':
      return lawnCareIcon;
    case 'handyman':
      return handymanIcon;
    case 'painter':
      return painterIcon;
    case 'carpenter':
      return carpenterIcon;
    case 'electrician':
      return electricianIcon;
    case 'plumber':
      return plumberIcon;
    default:
      return LogoLight;
  }
};

export const filterServicesList = (data: any[]) => {
  const sequence = [
    'CAREGIVING',
    'Cooking',
    'Cleaning',
    'LAUNDRY',
    'Baby sitting',
    'PET CARE',
    'DELIVERY',
    'Hygiene',
    'WALKING ASSISTANCE',
    'Washing',
  ].map((item) => item.replaceAll(' ', '_').toLowerCase());
  let itemList: any[] = data || [];
  itemList = itemList.map((item: any) => {
    return {
      ...item,
      service: item?.service.replaceAll(' ', '_').toLowerCase(),
    };
  });

  let newArr: any[] = [];
  sequence.forEach((item, index) => {
    const fIndex = itemList.findIndex((el) => el?.service === item);
    if (fIndex > -1) {
      newArr = [...newArr, itemList[fIndex]];
    }
    if (index === sequence.length - 1) {
      newArr = [...newArr, ...itemList];
      newArr = newArr
        .filter((item, index, self) => self.findIndex((obj: any) => obj['service'] === item['service']) === index)
        .map((item) => ({
          ...item,
          service: item['service']?.replaceAll('_', ' '),
        }));
    }
  });
  return newArr;
};
