import { useQuery } from '@tanstack/react-query';
import { axiosTokenInst } from './axios.config';

type BookingReview = {
  reviewType: 'booking';
  bookingId: string;
};

type UserReview = {
  reviewType: 'user';
  bookingId: string;
  userId: string;
};

type useFetchBookingReviewArg = BookingReview | UserReview;

type Params = {
  review_type: 'booking' | 'user';
  entity_id: string;
};

export function useFetchBookingReview(arg: useFetchBookingReviewArg) {
  // console.group('PARAMETER RECEIVED');
  // console.log({ arg });
  // console.groupEnd();

  let params: Partial<Params> = {};

  if (arg.reviewType === 'booking') {
    params = {
      review_type: arg.reviewType,
      entity_id: arg.bookingId,
    };

    return useQuery({
      queryKey: ['booking-review-details', arg.bookingId],
      queryFn: async () => {
        const result = await axiosTokenInst.get('reviews/list', { params });
        let possibleReview: Record<string, any> | null = null;
        if (Array.isArray(result.data) && result.data.length !== 0) {
          possibleReview = result.data[0];
        }
        return possibleReview;
      },
      cacheTime: 5 * 60 * 1000,
      staleTime: 5 * 60 * 1000,
    });
  } else {
    params = {
      review_type: arg.reviewType,
      entity_id: arg.userId,
    };

    return useQuery({
      queryKey: ['user-review-details-for-specific-booking', arg.bookingId, arg.userId],
      queryFn: async () => {
        const result = await axiosTokenInst.get('reviews/list', { params });
        let possibleReview: Record<string, any> | null = null;

        if (Array.isArray(result.data)) {
          const indexOfTheRequiredReview = result.data.findIndex((review: any) => review?.booking_id === arg.bookingId);
          if (indexOfTheRequiredReview !== -1) {
            possibleReview = result.data[indexOfTheRequiredReview];
          }
        } else {
          throw new Error('received `data` in invalid format: not an array');
        }

        return possibleReview;
      },
      cacheTime: 5 * 60 * 1000,
      staleTime: 5 * 60 * 1000,
    });
  }
}
