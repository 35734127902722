import { yupResolver } from '@hookform/resolvers/yup';
import { Box, Skeleton } from '@mantine/core';
import { useCookies } from 'react-cookie';
import { SubmitHandler, useForm } from 'react-hook-form';
import { InferType } from 'yup';
import { createReview } from '../../../../../services/review';
import { useFetchBookingReview } from '../../../../../services/review-refactored';
import { customerRole } from '../../../../../utils';
import { toastMessageUpdate } from '../../../../../utils/helpers/tosterMessage';
import { rateAndReviewSchema } from '../../../../../utils/schema/formSchema';
import RateAndReviewForm from './RateAndReviewForm';
import RatingAndReviewByOther from './RatingAndReviewByOther';
import RatingAndReviewByYou from './RatingAndReviewByYou';

/**
 * The requirement:
 * customer (service consumer) can give two types of rating and review :-
 *  1. for the service
 *  2. for the service provider
 *
 * provider (service provider) can give one types of rating and review :-
 *  1. for the customer
 *
 * So, at any given moment we need to check for 3 things
 *  1. If the rating and review is given by the customer to the service
 *  2. If the rating and review is given by the customer to the service provider
 *  3. If the rating and review is given by the provider to the customer
 */

interface Props {
  data: any;
}

export default function RatingAndReview({ data }: Props) {
  const bookingIdOfThisBooking: string = data?.booking_id;
  const userIdOfTheServiceProvider = data?.service_from;
  const userIdOfTheServiceConsumer = data?.service_to;

  // console.log({ userIdOfTheServiceConsumer, userIdOfTheServiceProvider });

  const [cookies] = useCookies(['role']);

  // getting the rating and review data given by the customer to the service
  const {
    data: dataForCustomerToService,
    isLoading: dataForCustomerToServiceIsLoading,
    isSuccess: dataForCustomerToServiceIsSuccess,
    refetch: dataForCustomerToServiceRefetch,
  } = useFetchBookingReview({
    reviewType: 'booking',
    bookingId: bookingIdOfThisBooking,
  });

  // getting the rating and review data given by the customer to the provider
  const {
    data: dataForCustomerToProvider,
    isLoading: dataForCustomerToProviderIsLoading,
    isSuccess: dataForCustomerToProviderIsSuccess,
    refetch: dataForCustomerToProviderRefetch,
  } = useFetchBookingReview({
    reviewType: 'user',
    bookingId: bookingIdOfThisBooking,
    userId: userIdOfTheServiceProvider,
  });

  // getting the rating and review data given by the provider to the customer
  const {
    data: dataForProviderToCustomer,
    isLoading: dataForProviderToCustomerIsLoading,
    isSuccess: dataForProviderToCustomerIsSuccess,
    refetch: dataForProviderToCustomerRefetch,
  } = useFetchBookingReview({
    reviewType: 'user',
    bookingId: bookingIdOfThisBooking,
    userId: userIdOfTheServiceConsumer,
  });

  // console.log({ dataForCustomerToService, dataForCustomerToProvider, dataForProviderToCustomer });

  let whoIsViewing: 'customer' | 'provider' | null = null;

  if (customerRole.includes(+cookies.role)) {
    whoIsViewing = 'customer';
  } else {
    whoIsViewing = 'provider';
  }

  const {
    control,
    handleSubmit,
    formState: { errors },
  } = useForm({
    resolver: yupResolver(rateAndReviewSchema),
    mode: 'all',
  });

  type SchemaType = InferType<typeof rateAndReviewSchema>;
  type FormSubmitType = SchemaType & {
    review_type: 'user' | 'booking';
    entity_id: string;
    booking_id: string;
  };

  // const [loading, setLoading] = useState(false);

  const onSubmit: SubmitHandler<FormSubmitType> = async (data) => {
    const requestBody = {
      review_type: data.review_type,
      entity_id: data.entity_id,
      booking_id: data.booking_id,
      review: data.review === undefined ? null : data.review,
      rating: data.rating,
    };

    console.log(requestBody);
    await createReview(requestBody);
    toastMessageUpdate('success', 'Review submitted successfully.');

    /*
      After everything is done, we need to do a refetch
      There are total 3 APIs, which one to refetch?
      If the `review_type` is `booking`, then there is only one API, we need to refetch it
      If the `review_type` is `user`, then we need refetch depending upon which kind of user is logged in
    */
    if (data.review_type === 'booking') {
      dataForCustomerToServiceRefetch();
    } else if (data.review_type === 'user') {
      if (whoIsViewing === 'customer') {
        dataForCustomerToProviderRefetch();
      } else if (whoIsViewing === 'provider') {
        dataForProviderToCustomerRefetch();
      }
    }
  };

  if (dataForCustomerToServiceIsLoading || dataForCustomerToProviderIsLoading || dataForProviderToCustomerIsLoading) {
    return <Skeleton height={120} />;
  }

  if (dataForCustomerToServiceIsSuccess && dataForCustomerToProviderIsSuccess && dataForProviderToCustomerIsSuccess)
    return (
      <>
        <Box pos='relative'>
          {/* rate and review our service: from customer to service */}

          {whoIsViewing === 'customer' ? (
            <RateAndReviewForm
              data={dataForCustomerToService}
              type='booking'
              title='Rate and Review our service'
              bookingIdOfThisBooking={bookingIdOfThisBooking}
              onSubmit={onSubmit}
            />
          ) : null}

          {/* rate and review our service provider: from customer to service provider */}

          {whoIsViewing === 'customer' ? (
            <RateAndReviewForm
              data={dataForCustomerToProvider}
              type='user'
              title='Rate and Review our service provider'
              bookingIdOfThisBooking={bookingIdOfThisBooking}
              userId={userIdOfTheServiceProvider}
              onSubmit={onSubmit}
            />
          ) : null}

          {/* rate and review our customer: from provider to customer */}

          {whoIsViewing === 'provider' ? (
            <RateAndReviewForm
              data={dataForProviderToCustomer}
              type='user'
              title='Rate and Review our customer'
              bookingIdOfThisBooking={bookingIdOfThisBooking}
              userId={userIdOfTheServiceConsumer}
              onSubmit={onSubmit}
            />
          ) : null}

          <RatingAndReviewByYou
            whoIsViewing={whoIsViewing}
            dataForCustomerToProvider={dataForCustomerToProvider}
            dataForCustomerToService={dataForCustomerToService}
            dataForProviderToCustomer={dataForProviderToCustomer}
          />

          <RatingAndReviewByOther
            whoIsViewing={whoIsViewing}
            dataForCustomerToProvider={dataForCustomerToProvider}
            dataForCustomerToService={dataForCustomerToService}
            dataForProviderToCustomer={dataForProviderToCustomer}
          />
        </Box>
      </>
    );

  return null;
}
