import React, { useEffect, useState } from 'react';
import { Button, Grid, Group, Paper, Stack, Textarea, TextInput, Text, Title } from '@mantine/core';
import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import { organizationInfoSchema } from '../../../../../utils/schema/formSchema';
import { createOrg, orgStatus, updateOrg } from '../../../../../services/organization.api';
import { useCookies } from 'react-cookie';
import { toastMessageUpdate } from '../../../../../utils/helpers/tosterMessage';
import { useMediaQuery } from '@mantine/hooks';
import { useCancelButtonStyles } from '../../../Customer/Profile/components/style';

const OrganizationInfo = ({ goNextTab }: any) => {
  const [loading, setLoading] = useState<boolean>(false);
  const [cookies] = useCookies(['user_Info']);
  const [showInfo, setShowInfo] = useState(false);
  const [showOrgInfo, setShowOrgInfo] = useState(false);
  const [orgInfoData, setOrgInfoData] = useState<any>();

  const isMobile = useMediaQuery('(max-width: 576px)');
  const isTablet = useMediaQuery('(max-width: 768px)');
  const isLaptop = useMediaQuery('(max-width: 1440px)');
  const { classes: CancelButtonStyle } = useCancelButtonStyles();

  const {
    register,
    handleSubmit,
    reset,
    formState: { errors },
  } = useForm({
    resolver: yupResolver(organizationInfoSchema),
    mode: 'all',
  });

  useEffect(() => {
    fetchOrganizationStatus();
  }, []);

  const fetchOrganizationStatus = async () => {
    try {
      const statusData = await orgStatus(cookies?.user_Info?.id);
      if (statusData?.data) {
        setOrgInfoData(statusData?.data);
        reset({
          name: statusData?.data?.name || '',
          description: statusData?.data?.description || '',
        });
        setShowInfo(true);
        setShowOrgInfo(true);
      } else {
        setShowInfo(false);
        setShowOrgInfo(false);
      }
    } catch (error: any) {
      console.log(error.status);
      if (error.status) {
        setShowInfo(false);
        setShowOrgInfo(false);
      }
    }
  };

  const organizationInfoSubmit = async (data: any) => {
    setLoading(true);
    const infoParams = {
      organization_id: orgInfoData?.organization_id || '',
      name: data.name.trim(),
      description: data.description.trim(),
    };
    if (showOrgInfo) {
      try {
        const updateData = await updateOrg(infoParams);
        // setOrgInfoData(updateData?.data); // no data return;
        fetchOrganizationStatus();
        setLoading(false);
        setShowInfo(true);
        setShowOrgInfo(true);
        toastMessageUpdate('success', 'Organization updated successfully.');
      } catch (error: any) {
        setLoading(false);
        console.log(error);
      }
    } else {
      delete infoParams.organization_id;
      try {
        const createData = await createOrg(infoParams);
        setOrgInfoData(createData?.data);
        setLoading(false);
        setShowInfo(true);
        setShowOrgInfo(true);
        goNextTab('verifications');
        toastMessageUpdate('success', 'Organization created successfully.');
      } catch (error: any) {
        setLoading(false);
        console.log(error);
      }
    }
  };
  const resetData = () => {
    reset({
      name: orgInfoData?.name || '',
      description: orgInfoData?.description || '',
    });
    setShowInfo(true);
  };

  return (
    <>
      {!isMobile && (
        <Title order={2} c='orange.8' fw={600} mb={20}>
          Organization Info
        </Title>
      )}
      <Stack pb={20}>
        <form onSubmit={handleSubmit(organizationInfoSubmit)} autoComplete='off'>
          <Grid gutter='xl'>
            <Grid.Col span={isMobile ? 12 : 6}>
              <TextInput
                size='md'
                {...register('name')}
                placeholder='Enter name'
                disabled={showInfo}
                label='Name'
                withAsterisk
              />
              {errors?.name && (
                <Text fz={14} pt={8} pl={5} fw={500} color='red'>
                  {errors?.name?.message}
                </Text>
              )}
            </Grid.Col>
            <Grid.Col span={isMobile ? 12 : 6}>
              <Textarea
                autosize
                size='md'
                minRows={1}
                maxRows={4}
                {...register('description')}
                placeholder='Enter description'
                label='Description'
                disabled={showInfo}
              />
            </Grid.Col>
          </Grid>
          <Group pt={20} spacing={3} position={showInfo ? 'left' : isMobile ? 'center' : 'left'}>
            {showInfo ? (
              <Button
                size='md'
                w={isMobile ? `49%` : 150}
                radius={4}
                type='button'
                onClick={() => setShowInfo(false)}
                variant='gradient'
                gradient={{ to: '#fda223', from: '#fe7720', deg: 270 }}>
                Edit
              </Button>
            ) : (
              <>
                <Button
                  size='md'
                  w={isMobile ? `49%` : 150}
                  radius={4}
                  type='submit'
                  loading={loading}
                  variant='gradient'
                  gradient={{ to: '#fda223', from: '#fe7720', deg: 270 }}>
                  Save
                </Button>
                <Button
                  size='md'
                  c='orange'
                  w={isMobile ? `49%` : 150}
                  className={CancelButtonStyle.root}
                  onClick={() => resetData()}
                  variant='light'
                  color='gray'>
                  Cancel
                </Button>
              </>
            )}
          </Group>
        </form>
      </Stack>
    </>
  );
};

export default OrganizationInfo;
