import { Box, Flex, Text } from '@mantine/core';
import { IconBriefcase, IconGenderBigender, IconMapPin, IconRocket, IconUsers } from '@tabler/icons-react';
import { useUserBasicInfoStyles } from './style';
import { RoleType } from '../../../../../utils';

interface Props {
  data: any;
}

export default function UserBasicInfo({ data }: Props) {
  const { classes } = useUserBasicInfoStyles();

  return (
    <>
      <Box>
        <Text ta='left' c='orange' fw={600} size='lg'>
          Basic info
        </Text>
        <Flex direction='column' mt={16} gap={14} className={classes.flexContainer}>
          {data?.user?.type !== RoleType.organization_service_provider ? (
            <Flex gap={8} align='center'>
              <IconGenderBigender />
              <Text fw={600}>Gender:</Text>
              <Text color='dimmed'>{data?.user_profile?.gender}</Text>
            </Flex>
          ) : null}

          {RoleType.customer !== data?.user?.type ? (
            <Flex gap={8} align='center'>
              <IconBriefcase />
              <Text fw={600}>Experience:</Text>
              <Text color='dimmed'>{data?.user_profile?.experience ?? 'N/A'}</Text>
            </Flex>
          ) : null}

          <Flex gap={8} align='center'>
            <IconMapPin />
            <Text fw={600}>Location:</Text>
            <Text color='dimmed'>{data?.has_address?.city ?? 'N/A'}</Text>
          </Flex>

          {RoleType.customer !== data?.user?.type ? (
            <Flex gap={8} align='center'>
              <IconUsers />
              <Text fw={600}>Client:</Text>
              <Text color='dimmed'>{data?.service?.no_of_user ? data?.service?.no_of_user : 'N/A'}</Text>
            </Flex>
          ) : null}

          {RoleType.customer !== data?.user?.type ? (
            <Flex gap={8} align='center'>
              <IconRocket />
              <Text fw={600}>Completed Project:</Text>
              <Text color='dimmed'>{data?.service?.service_completed ? data?.service?.service_completed : 'N/A'}</Text>
            </Flex>
          ) : null}
        </Flex>
      </Box>
    </>
  );
}
