import { Badge, Divider, Flex } from '@mantine/core';
import { useState } from 'react';
import { customerRole, providerRole } from '../../../../../utils';
import { BookingStatus } from '../../../../../utils/helpers/getStatusOfTheBooking';

import { useDisclosure } from '@mantine/hooks';
import { useCookies } from 'react-cookie';
import { useLocation, useNavigate } from 'react-router-dom';
import { useBookingContext } from '../../../../../context/booking-context';
import { BookingAction, handleBookingAction } from '../../../../../services/booking-refactored';
import { convertUtcTimeToLocalFormat } from '../../../../../utils/helpers';
import getDifferenceBetweenTwoDateTime from '../../../../../utils/helpers/getDifferenceBetweenTwoDateTime';
import ModalOrDrawer from '../../../Customer/Profile/components/ModalOrDrawer';
import BookingActionButton from './BookingActionButton';
import CancelBooking from './CancelBooking';
import RatingAndReview from './RatingAndReview';
import StartedAt from './StartedAt';
import TimeElapsed from './TimeElapsed';

interface Props {
  data: any;
  status: BookingStatus;
  role: number;
  refetch: any;
}

export default function ShowBookingAction({ status, role, data, refetch }: Props) {
  const navigate = useNavigate();
  const location = useLocation();

  const [cookie, _] = useCookies(['user_Info']);
  const [cancelBookingOpened, { open: openCancelBooking, close: closeCancelBooking }] = useDisclosure(false);
  const { dispatchGridStyle, setShowMap } = useBookingContext();
  const [loadingType, setLoadingType] = useState<BookingAction | null>(null);

  type handleButtonClickArg = {
    type: BookingAction;
    payload?: any;
  };
  const handleButtonClick = async ({ type, payload }: handleButtonClickArg) => {
    if (type === BookingAction.PAY) {
      navigate(`/confirm-booking?id=${data?.booking_id}`);
    } else if (
      type === BookingAction.CANCEL_AFTER_PAYMENT_COMPLETE ||
      type === BookingAction.CANCEL_AFTER_REJECTED ||
      type === BookingAction.CANCEL_AFTER_APPROVED
    ) {
      openCancelBooking();
    } else if (type === BookingAction.START_JOURNEY) {
      setLoadingType(type);
      dispatchGridStyle({
        type: 'SET_GRID_STYLES_FOR_OPENING_BOOKING_WITH_MAP',
      });
      await handleBookingAction({ type, payload });
      setLoadingType(null);
      refetch();
    } else if (type === BookingAction.END_JOURNEY) {
      setLoadingType(type);
      await handleBookingAction({ type, payload });
      dispatchGridStyle({
        type: 'SET_GRID_STYLES_FOR_OPENING_REGULAR_BOOKING',
      });
      setShowMap(false);
      refetch();
      setLoadingType(null);
    } else if (type === BookingAction.CONFIRM_START_SERVICE) {
      setLoadingType(type);
      await handleBookingAction({ type, payload });
      refetch();
      setLoadingType(null);
    } else if (type === BookingAction.END) {
      const existingQueryParams = new URLSearchParams(location.search);
      existingQueryParams.delete('opened');
      navigate({ search: existingQueryParams.toString() });
      setLoadingType(type);
      await handleBookingAction({ type, payload });
      setLoadingType(null);
      refetch();
    } else {
      setLoadingType(type);
      await handleBookingAction({ type, payload });
      setLoadingType(null);
      refetch();
    }
  };

  return (
    <>
      {status === BookingStatus.PAYMENT_PENDING && customerRole.includes(role) ? (
        <BookingActionButton variant='light' onClick={() => handleButtonClick({ type: BookingAction.PAY })}>
          Pay
        </BookingActionButton>
      ) : null}

      {status === BookingStatus.PAYMENT_COMPLETED && (
        <>
          {customerRole.includes(role) && (
            <BookingActionButton
              variant='light'
              onClick={() => handleButtonClick({ type: BookingAction.CANCEL_AFTER_PAYMENT_COMPLETE })}>
              Cancel
            </BookingActionButton>
          )}

          {providerRole.includes(role) && (
            <Flex direction='column' gap={8}>
              <BookingActionButton
                loading={loadingType === BookingAction.ACCEPT}
                variant='filled'
                onClick={() =>
                  handleButtonClick({
                    type: BookingAction.ACCEPT,
                    payload: {
                      bookingId: data?.booking_id,
                      serviceTo: data?.service_to,
                      message: `Your booking service is accepted by ${cookie?.user_Info?.name}`,
                    },
                  })
                }>
                Accept
              </BookingActionButton>
              <BookingActionButton
                loading={loadingType === BookingAction.REJECT}
                variant='light'
                onClick={() =>
                  handleButtonClick({
                    type: BookingAction.REJECT,
                    payload: {
                      bookingId: data?.booking_id,
                      serviceTo: data?.service_to,
                      message: `Your booking service is rejected by ${cookie?.user_Info?.name}`,
                    },
                  })
                }>
                Reject
              </BookingActionButton>
            </Flex>
          )}
        </>
      )}

      {status === BookingStatus.REJECTED && customerRole.includes(role) ? (
        <BookingActionButton
          variant='light'
          onClick={() => handleButtonClick({ type: BookingAction.CANCEL_AFTER_REJECTED })}>
          Cancel
        </BookingActionButton>
      ) : null}

      {status === BookingStatus.APPROVED && (
        <>
          {customerRole.includes(role) && (
            <BookingActionButton
              variant='light'
              onClick={() => handleButtonClick({ type: BookingAction.CANCEL_AFTER_APPROVED })}>
              Cancel
            </BookingActionButton>
          )}

          {providerRole.includes(role) && (
            <BookingActionButton
              loading={loadingType === BookingAction.START_JOURNEY}
              variant='filled'
              onClick={() =>
                handleButtonClick({
                  type: BookingAction.START_JOURNEY,
                  payload: {
                    bookingId: data?.booking_id,
                    serviceTo: data?.service_to,
                    message: `Your booked service journey started by ${cookie?.user_Info?.name}.`,
                  },
                })
              }>
              Start Journey
            </BookingActionButton>
          )}
        </>
      )}

      {status === BookingStatus.JOURNEY_STARTED && (
        <>
          {providerRole.includes(role) && (
            <BookingActionButton
              loading={loadingType === BookingAction.END_JOURNEY}
              variant='filled'
              onClick={() =>
                handleButtonClick({
                  type: BookingAction.END_JOURNEY,
                  payload: {
                    bookingId: data?.booking_id,
                    serviceTo: data?.service_to,
                    message: `Your booked service journey ended by ${cookie?.user_Info?.name}.`,
                  },
                })
              }>
              End Journey
            </BookingActionButton>
          )}
        </>
      )}

      {status === BookingStatus.JOURNEY_ENDED && (
        <>
          {customerRole.includes(role) && (
            <BookingActionButton
              loading={loadingType === BookingAction.START_SERVICE}
              variant='filled'
              onClick={() =>
                handleButtonClick({
                  type: BookingAction.START_SERVICE,
                  payload: {
                    bookingId: data?.booking_id,
                    serviceFrom: data?.service_from,
                    message: `Booked service start request by ${cookie?.user_Info?.name}.`,
                  },
                })
              }>
              Start Service
            </BookingActionButton>
          )}
        </>
      )}

      {status === BookingStatus.WAITING_FOR_WORK_START && (
        <>
          {providerRole.includes(role) && (
            <BookingActionButton
              loading={loadingType === BookingAction.CONFIRM_START_SERVICE}
              variant='filled'
              onClick={() =>
                handleButtonClick({
                  type: BookingAction.CONFIRM_START_SERVICE,
                  payload: {
                    bookingId: data?.booking_id,
                    serviceTo: data?.service_to,
                    message: `Your booked service started by ${cookie?.user_Info?.name}.`,
                  },
                })
              }>
              Confirm Start Service
            </BookingActionButton>
          )}
        </>
      )}

      {(status === BookingStatus.IN_PROGRESS || status === BookingStatus.SERVICE_STARTED) && (
        <>
          <Flex direction='column' gap={4}>
            <StartedAt data={data} />
            <TimeElapsed data={data?.service_started} />
          </Flex>

          {customerRole.includes(role) && (
            <BookingActionButton
              variant='filled'
              loading={loadingType === BookingAction.END_SERVICE}
              onClick={() =>
                handleButtonClick({
                  type: BookingAction.END_SERVICE,
                  payload: {
                    bookingId: data?.booking_id,
                    serviceFrom: data?.service_from,
                    message: `Booked service end request by ${cookie?.user_Info?.name}.`,
                  },
                })
              }>
              End Service
            </BookingActionButton>
          )}
        </>
      )}

      {status === BookingStatus.WAITING_FOR_WORK_END && (
        <>
          {providerRole.includes(role) && (
            <BookingActionButton
              variant='filled'
              loading={loadingType === BookingAction.END}
              onClick={() =>
                handleButtonClick({
                  type: BookingAction.END,
                  payload: {
                    bookingId: data?.booking_id,
                    serviceTo: data?.service_to,
                    message: `Your booked service completed by ${cookie?.user_Info?.name}.`,
                  },
                })
              }>
              End
            </BookingActionButton>
          )}
        </>
      )}

      {status === BookingStatus.COMPLETED && (
        <>
          <Flex direction='column' gap={4}>
            <Badge w='fit-content'>Ended At: {convertUtcTimeToLocalFormat(data?.service_ended)}</Badge>
            <Badge w='fit-content'>
              Worked For: {getDifferenceBetweenTwoDateTime(data?.service_ended, data?.service_started)}
            </Badge>
          </Flex>
          <Divider />
          <RatingAndReview data={data} />
        </>
      )}

      <ModalOrDrawer
        opened={cancelBookingOpened}
        onClose={closeCancelBooking}
        drawerProps={{
          dz: 'sm',
          drawerBackButton: true,
          bs: '0px -4px 10px 0px rgba(0,0,0,0.1)',
          drawerOverlayProps: { opacity: 0, blur: 0 },
        }}
        modalProps={{ modalCloseButton: true }}>
        <CancelBooking data={data} closeCancelBooking={closeCancelBooking} refetch={refetch} />
      </ModalOrDrawer>
    </>
  );
}
