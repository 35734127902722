import { catchHandler } from './../utils/helpers/index';
import { AxiosResponse } from 'axios';
import { axiosTokenInst } from './axios.config';
import { useQuery } from '@tanstack/react-query';

export const addVerifications = catchHandler(async (data: any) => {
  return await axiosTokenInst.post('verification', data, {
    headers: {
      'Content-Type': 'multipart/form-data',
    },
  });
});

export const getVerifications = catchHandler(async (userId: any): Promise<AxiosResponse> => {
  const data = await axiosTokenInst.get(`verification/status/${userId}`);
  console.log(data);
  return data;
});

export const useVerification = (id: string) => {
  return useQuery({
    queryKey: ['verification-status', id],
    queryFn: async () => await getVerifications(id),
    // enabled: id ? true : false,
  });
};

export const verificationChecker = catchHandler(async (params: any) => {
  return await axiosTokenInst.post('verification/checkr', params);
});

//Customer Verification

export const getOnfidoToken = catchHandler(async () => {
  return await axiosTokenInst.get('/onfido/generate-token');
});

export const customerVerification = catchHandler(async (formData: any) => {
  return await axiosTokenInst.post(`/onfido/create-user`, formData);
});

export const customerVerificationWithSingzy = catchHandler(async () => {
  return await axiosTokenInst.post(`/singzy/create-journey-url`);
});
