import { useMutation, useQuery } from '@tanstack/react-query';
import { axiosTokenInst } from './axios.config';
import { toastMessageUpdate } from '../utils/helpers/tosterMessage';
import { useNavigate } from 'react-router-dom';

export const createBooking = async (arg: any) => {
  return await axiosTokenInst.post('/booking/create', arg);
};

export const useCreateBooking = () => {
  const navigate = useNavigate();
  return useMutation({
    mutationFn: createBooking,
    onSuccess: (data) => {
      navigate(`/confirm-booking?id=${data.data?.booking_id}`);
    },
    onError: (error: any) => {
      toastMessageUpdate('error', error?.response?.data?.error || error?.data?.error || '');
    },
  });
};

// Slot
export const slotList = async () => {
  try {
    return await axiosTokenInst.get('/slots/list');
  } catch (error) {
    console.log(error);
    throw error;
  }
};

export const useSlotList = (enable = true) => {
  return useQuery({
    queryKey: ['slot-list'],
    queryFn: slotList,
    enabled: enable,
    staleTime: 10 * 60 * 1000,
    onError: (error: any) => {
      toastMessageUpdate('error', error?.response?.data?.error || '');
    },
  });
};

// Slot
export const slotBookedList = async (userId: string, params: Record<string, any>) => {
  try {
    return await axiosTokenInst.get(`/slots/booked/${userId}`, {
      params: params,
    });
  } catch (error) {
    console.log(error);
    throw error;
  }
};

export const useSlotBookedList = (userId: string, params: Record<string, any>, slotList: any[]) => {
  return useQuery({
    queryKey: ['slot-booked', params],
    queryFn: async () => {
      const res = await slotBookedList(userId, params);
      res.data = slotList.map((item) => {
        const isBookedIn = res.data?.findIndex((resItem: any) => resItem?.slot_id === item.slot_id);
        return {
          ...item,
          disabled: isBookedIn > -1 ? true : false,
        };
      });
      return res;
    },
    enabled: slotList?.length ? true : false,
  });
};

export const bookingDetails = (booking_id: string) => {
  return axiosTokenInst.get(`/booking/details`, {
    params: {
      booking_id,
    },
  });
};

export const useBookingDetails = (id: string, enabled: boolean) => {
  return useQuery({
    queryKey: ['booking-details', id],
    queryFn: async () => bookingDetails(id),
    enabled: enabled,
  });
};

export const bookingApprove = (formData: Record<string, any>) => {
  return axiosTokenInst.post(`/booking/approve`, formData);
};

export const useBookingAppprove = () => {
  useMutation({
    mutationFn: bookingApprove,
  });
};

export const bookingUserSpec = async () => {
  return await axiosTokenInst.get(`booking/user-specific`);
};

export const useBookingUserSpec = () => {
  return useQuery({
    queryKey: ['user-booking-specific'],
    queryFn: bookingUserSpec,
  });
};

export const bookingDelete = (bookingId: any) => {
  const params = {
    booking_id: bookingId,
  };
  return axiosTokenInst.delete(`/booking/delete`, { params });
};

export const bookingCancel = (params: any) => {
  return axiosTokenInst.post('/booking/cancel', params);
};

export const bookingStart = (bookingId: any) => {
  return axiosTokenInst.post('/booking/start', bookingId);
};

export const bookingComplete = (bookingId: any) => {
  return axiosTokenInst.post('/booking/complete', bookingId);
};

export const bookingSOS = (params: any) => {
  return axiosTokenInst.post('/booking/sos', params);
};

export const immediateBooking = (params: any) => {
  return axiosTokenInst.post('/booking/request', params, {
    headers: {
      'Content-Type': 'multipart/form-data',
    },
  });
};

export const acceptBooking = (params: any) => {
  return axiosTokenInst.post('/booking/express-request', params, {
    headers: {
      'Content-Type': 'multipart/form-data',
    },
  });
};

export const bookingRequestDetails = (params: any) => {
  return axiosTokenInst.post('/booking/request-details', params);
};

export const bookingStartJourney = (params: any) => {
  return axiosTokenInst.post('/booking/start-journey', params);
};

export const bookingEndJourney = (params: any) => {
  return axiosTokenInst.post('/booking/end-journey', params);
};

export const bookingStartRequest = (params: any) => {
  return axiosTokenInst.post('/booking/start-request', params);
};

export const bookingEndRequest = (params: any) => {
  return axiosTokenInst.post('/booking/end-request', params);
};

export const bookingPaymentStatus = (params: any) => {
  return axiosTokenInst.post('/booking/payment-status', params);
};

export const useBookingPaymentStatus = (params: any, enable: boolean) => {
  return useQuery({
    queryKey: ['user-additional-payment'],
    queryFn: async () => {
      if (params?.booking_id) {
        await bookingPaymentStatus(params);
      }
    },
    enabled: enable,
  });
};

export const additionalBookingItem = (service_id: string) => {
  return axiosTokenInst.get(`/additionalItems/getItemsByServiceId/${service_id}`);
};

export const additionalItem = (service_id: any) => {
  return useQuery({
    queryKey: ['additional-item'],
    queryFn: async () => await additionalBookingItem(service_id),
  });
};

export const addAdditionalItem = (formData: Record<string, any>) => {
  return axiosTokenInst.post(`/booking/booking-item/add`, formData);
};

export const getAdditionalBookingItem = (booking_id: string) => {
  return axiosTokenInst.get(`/booking/booking-item/details/${booking_id}`);
};

export const approvaladdAdditionalItem = (formData: Record<string, any>) => {
  return axiosTokenInst.post(`/booking/booking-item/approve`, formData);
};

export const getInvoiceData = (booking_id: any) => {
  return axiosTokenInst.get(`/invoices/showInvoiceByBookingId/${booking_id}`);
};
