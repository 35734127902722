import { Flex, Text, Box, Rating, Space } from '@mantine/core';

interface Props {
  whoIsViewing: 'customer' | 'provider';
  dataForCustomerToService: Record<string, any> | null;
  dataForCustomerToProvider: Record<string, any> | null;
  dataForProviderToCustomer: Record<string, any> | null;
}

/**
 * For this component, `you` will vary
 * if the customer is viewing then `you` is customer
 * if the provider is viewing then `you` is provider
 */

export default function RatingAndReviewByYou({
  whoIsViewing,
  dataForCustomerToProvider,
  dataForCustomerToService,
  dataForProviderToCustomer,
}: Props) {
  switch (whoIsViewing) {
    case 'customer':
      return (
        <>
          {dataForCustomerToService || dataForCustomerToProvider ? (
            <>
              <Text fw={600} fz={16} mt={16}>
                Rating & Review by you
              </Text>
              <Flex direction='column' gap={8} mt={8}>
                {dataForCustomerToService && (
                  <Box style={{ padding: 8, border: '1px solid #e4e4e4', borderRadius: 4 }}>
                    <Text fw={600} fz={14} c='#434343'>
                      Service Rating
                    </Text>
                    <Rating value={dataForCustomerToService?.rating} color='#fda223' fractions={4} readOnly />

                    <Space h={8} />
                    <Text fw={600} fz={14} c='#434343'>
                      Service Review
                    </Text>
                    {dataForCustomerToService?.review === null || dataForCustomerToService?.review === '' ? (
                      <Box bg={'#f9f9f9'} p={8} style={{ borderRadius: 4, border: '2px dashed #ececec' }}>
                        <Text fz={14} c='dimmed' fw={600}>
                          No review provided
                        </Text>
                      </Box>
                    ) : (
                      <Text fz={14} c='dimmed'>
                        {dataForCustomerToService?.review}
                      </Text>
                    )}
                  </Box>
                )}

                {dataForCustomerToProvider && (
                  <Box style={{ padding: 8, border: '1px solid #e4e4e4', borderRadius: 4 }}>
                    <Text fw={600} fz={14} c='#434343'>
                      Provider Rating
                    </Text>
                    <Rating value={dataForCustomerToProvider?.rating} color='#fda223' fractions={4} readOnly />
                    <Space h={8} />
                    <Text fw={600} fz={14} c='#434343'>
                      Provider Review
                    </Text>
                    {dataForCustomerToProvider?.review === null || dataForCustomerToProvider?.review === '' ? (
                      <Box bg={'#f9f9f9'} p={8} style={{ borderRadius: 4, border: '2px dashed #ececec' }}>
                        <Text fz={14} c='dimmed' fw={600}>
                          No review provided
                        </Text>
                      </Box>
                    ) : (
                      <Text fz={14} c='dimmed'>
                        {dataForCustomerToProvider?.review}
                      </Text>
                    )}
                  </Box>
                )}
              </Flex>
            </>
          ) : null}
        </>
      );

    case 'provider':
      return (
        <>
          {dataForProviderToCustomer ? (
            <>
              <Text fw={600} fz={16} mt={16}>
                Rating & Review by you
              </Text>
              <Flex direction='column' mt={8}>
                <Box style={{ padding: 8, border: '1px solid #e4e4e4', borderRadius: 4 }}>
                  <Text fw={600} fz={14} c='#434343'>
                    Customer Rating
                  </Text>
                  <Rating value={dataForProviderToCustomer?.rating} color='#fda223' fractions={4} readOnly />
                  <Space h={8} />
                  <Text fw={600} fz={14} c='#434343'>
                    Customer Review
                  </Text>
                  {dataForProviderToCustomer?.review === null || dataForProviderToCustomer?.review === '' ? (
                    <Box bg={'#f9f9f9'} p={8} style={{ borderRadius: 4, border: '2px dashed #ececec' }}>
                      <Text fz={14} c='dimmed' fw={600}>
                        No review provided
                      </Text>
                    </Box>
                  ) : (
                    <Text fz={14} c='dimmed'>
                      {dataForProviderToCustomer?.review}
                    </Text>
                  )}
                </Box>
              </Flex>
            </>
          ) : null}
        </>
      );
  }
}
