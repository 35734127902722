import { UnstyledButton } from '@mantine/core';
import { IconX } from '@tabler/icons-react';
import { useCrossButtonStyles } from '../style';

interface Props {
  onClose: () => void;
}

export default function CrossButton({ onClose }: Props) {
  const { classes } = useCrossButtonStyles();
  return (
    <>
      <UnstyledButton onClick={onClose} c='#909090' className={classes.button}>
        <IconX />
      </UnstyledButton>
    </>
  );
}
