/**
 * Role types reference ---
 * 1: 'User'
 * 2: 'Service Provider'
 * 3: 'Organizational Service Provider'
 * 4: 'Organizational Service User'
 */

type VerificationStatus = {
  backgroundVerification: string | null;
  identityVerification: string | null;
  licenseVerification: string | null;
};

type ValidRoles = 1 | 2 | 3 | 4;

type Parameter = {
  data: {
    user_verification: boolean;
    background_verification: boolean;
    drug_test_verification: boolean;
    business_license_verification: boolean | null;
    signzy_journey__result: boolean | null;
    onfido_result?: string;
    onfido_applicant_id?: string;
    type: '1' | '2' | null;
  };
  role: any;
};

export default function getVerificationStatusIfUserIsViewingOwnProfile({ data, role }: Parameter) {
  console.log(data, role);

  const verification = {} as Partial<VerificationStatus>;

  if (data === undefined || data === null) {
    verification.backgroundVerification = 'Not Verified';
    verification.identityVerification = 'Not Verified';
    verification.licenseVerification = 'Not Verified';
    return verification;
  }

  let validatedRole: ValidRoles;
  if (typeof role === 'string' && ['1', '2', '3', '4'].includes(role)) {
    validatedRole = +role as ValidRoles;
  } else if (typeof role === 'number' && [1, 2, 3, 4].includes(role)) {
    validatedRole = role as ValidRoles;
  } else {
    throw new Error('Invalid role provided');
  }

  switch (validatedRole) {
    /**
     * [Regular User]
     * Applicable checks (1):
     *  - Identity verification (Done by Onfido)
     */
    case 1:
      /**
       * Not Applicable as of 2024-29-04
       * Reason: Regular User cannot view his own profile
       */
      break;

    /* ------------------------------------------------------------------------- */

    /**
     * [Regular Service Provider]
     * Applicable checks (2):
     *  - Identity Verification (Done by Onfido)
     *  - Background Verification (Done by Checkr)
     */
    case 2:
      // Identity Verification
      if (
        Object.hasOwn(data, 'signzy_journey__result') &&
        data.signzy_journey__result === null &&
        Object.hasOwn(data, 'onfido_applicant_id') &&
        data.onfido_applicant_id === null
      ) {
        verification.identityVerification = 'N/A';
      } else if (data.signzy_journey__result || data.onfido_result) {
        verification.identityVerification = 'Verified';
      } else {
        verification.identityVerification = 'Not Verified';
      }

      // if (Object.hasOwn(data, 'onfido_applicant_id') && data.onfido_applicant_id === null) {
      //   verification.identityVerification = 'N/A';
      // } else if (Object.hasOwn(data, 'onfido_applicant_id') && data.onfido_applicant_id !== 'null') {
      //   if (data.onfido_result === null) {
      //     verification.identityVerification = 'Not Verified';
      //   } else if (data.onfido_result === 'clear') {
      //     verification.identityVerification = 'Verified';
      //   } else if (data.onfido_result === 'consider') {
      //     verification.identityVerification = 'Not Verified';
      //   } else throw new Error('Received invalid value for `onfido_result`');
      // }

      // Background Verification
      if (Object.hasOwn(data, 'type')) {
        if (data.type === null) verification.backgroundVerification = 'Not Submitted';
        else if (data.type === '2') verification.backgroundVerification = 'Not Submitted';
        else if (data.type === '1') {
          if (data.background_verification) {
            verification.backgroundVerification = 'Verified';
          } else {
            verification.backgroundVerification = 'Verification Pending';
          }
        } else {
          throw new Error('Received invalid value for `type`');
        }
      } else {
        verification.backgroundVerification = 'N/A';
      }

      break;

    /* ------------------------------------------------------------------------- */

    /**
     * [Organizational Service Provider]
     * Applicable checks (1):
     *  - Business License Verification (Done by admin panel)
     */
    case 3:
      if (data.business_license_verification) {
        verification.licenseVerification = 'Verified';
      } else {
        verification.licenseVerification = 'Not Verified';
      }

      break;

    /* ------------------------------------------------------------------------- */

    /**
     * [Organizational User]
     * Applicable checks (1):
     *  - Identity verification (Done by Onfido)
     */
    case 4:
      /**
       * Not Applicable as of 2024-29-04
       * Reason: Organizational User cannot view his own profile
       */
      break;
  }

  return verification;
}
