import { yupResolver } from '@hookform/resolvers/yup';
import { Box, Button, Flex, Radio, ScrollArea, Stack, Text, Textarea, TextInput } from '@mantine/core';
import React, { useContext, useState } from 'react';
import { Controller, useForm } from 'react-hook-form';
import { HomeContextRefactored } from '../../Home/home.context';
import { questionSchema } from '../../../../utils/schema/formSchema';
import { createJob, getQuestionByServicetype } from '../../../../services/job.api';
import { toastMessageUpdate } from '../../../../utils/helpers/tosterMessage';
import { useNavigate } from 'react-router-dom';

const CreateJobs = () => {
  const { selectedChildServiceId } = useContext(HomeContextRefactored);
  const { data, isLoading, refetch } = getQuestionByServicetype(selectedChildServiceId);
  const validationSchema = questionSchema(data?.questions);
  const {
    register,
    handleSubmit,
    control,
    formState: { errors },
  } = useForm({
    resolver: yupResolver(validationSchema),
    mode: 'all',
  });

  const navigate = useNavigate();
  const [loading, setLoading] = useState<boolean>(false);

  const onSubmit = async (data: any) => {
    setLoading(true);
    const dynamicQuestionData = [];
    for (const [key, value] of Object.entries(data)) {
      if (key.startsWith('questions')) {
        const question_id = key.replace(/^questionsText|^questionsOption/, '');
        dynamicQuestionData.push({
          question_id: question_id,
          answer: value,
        });
      }
    }

    const formData = {
      service_type_id: selectedChildServiceId,
      title: data.jobTitle,
      description: data.jobDescription,
      location: 'New York',
      budget: data.jobBudget,
      status: 'open',
      answers: dynamicQuestionData,
    };
    try {
      const res: any = await createJob(formData);
      console.log(res);
      if (res?.status) {
        toastMessageUpdate('success', res?.message);
        setLoading(false);
        navigate('my-jobs');
      }
    } catch (error) {
      console.log(error);
      setLoading(false);
    }
  };

  return (
    <Box>
      <ScrollArea h='calc(100vh - 320px)'>
        <form onSubmit={handleSubmit(onSubmit)} autoComplete='off'>
          <Flex direction='column' gap={10} mt={10}>
            <Stack>
              <TextInput
                withAsterisk
                size='md'
                fw='lg'
                label='What is the job title?'
                placeholder='Please give your job title'
                {...register('jobTitle')}
              />
              {errors['jobTitle'] && (
                <Text fz={14} mt={-16} pl={5} fw={500} color='red'>
                  {`${errors.jobTitle.message}`}
                </Text>
              )}
            </Stack>

            <Stack>
              <Textarea
                withAsterisk
                size='md'
                fw='lg'
                label='Description'
                placeholder='Please describe the job'
                {...register('jobDescription')}
              />
              {errors.jobDescription && (
                <Text fz={14} mt={-16} pl={5} fw={500} color='red'>
                  {`${errors.jobDescription.message}`}
                </Text>
              )}
            </Stack>

            <Stack>
              <TextInput
                withAsterisk
                size='md'
                fw='lg'
                label='What is your budget for this job?'
                placeholder='Please give the budget in $'
                type='number'
                {...register('jobBudget')}
              />
              {errors.jobBudget && (
                <Text fz={14} mt={-16} pl={5} fw={500} color='red'>
                  {`${errors.jobBudget.message}`}
                </Text>
              )}
            </Stack>

            {data?.questions?.map((question: any) => (
              <React.Fragment key={question.id}>
                {question.question_type === 'text' && (
                  <Stack>
                    <TextInput
                      withAsterisk
                      size='md'
                      fw='lg'
                      label={question.question_text}
                      placeholder={question.question_text}
                      {...register(`questionsText${question.id}`)}
                    />
                    {errors[`questionsText${question.id}`] && (
                      <Text fz={14} mt={-16} pl={5} fw={500} color='red'>
                        {`${errors[`questionsText${question.id}`]?.message}`}
                      </Text>
                    )}
                  </Stack>
                )}
                {question.question_type === 'mcq' && (
                  <Stack>
                    <Controller
                      name={`questionsOption${question.id}`}
                      control={control}
                      render={({ field }) => (
                        <Radio.Group
                          withAsterisk
                          size='md'
                          styles={{ label: { fontWeight: 'bold' } }}
                          label={question.question_text}
                          value={field.value}
                          onChange={field.onChange}>
                          <Flex gap={15} my={15} direction='column'>
                            {question.options.map((option: any, index: any) => (
                              <Radio key={index} size='sm' value={option} label={option} />
                            ))}
                          </Flex>
                        </Radio.Group>
                      )}
                    />
                    {errors[`questionsOption${question.id}`] && (
                      <Text fz={14} mt={-16} pl={5} fw={500} color='red'>
                        {`${errors[`questionsOption${question.id}`]?.message}`}
                      </Text>
                    )}
                  </Stack>
                )}
              </React.Fragment>
            ))}
          </Flex>

          <Flex justify='center' direction='column' mt='xl'>
            <Button
              variant='gradient'
              gradient={{ from: '#fda223', to: '#fe7720' }}
              radius='sm'
              fullWidth
              size='md'
              loading={loading}
              type='submit'>
              {!loading && 'Submit'}
            </Button>
          </Flex>
        </form>
      </ScrollArea>
    </Box>
  );
};

export default CreateJobs;
