import { MantineStyleSystemProps, Text } from '@mantine/core';
import type { FieldError } from 'react-hook-form';

type CustomProps = {
  errorProperty: FieldError | undefined;
};

type Props = CustomProps & MantineStyleSystemProps;

export default function ErrorText({ errorProperty, ...rest }: Props) {
  if (!errorProperty) return null;

  return (
    <>
      <Text
        fz={14}
        pl={5}
        fw={500}
        {...rest}
        sx={(theme) => ({
          color: theme.colorScheme === 'light' ? '#f04545' : '#c54e4e',
        })}>
        {errorProperty.message}
      </Text>
    </>
  );
}
