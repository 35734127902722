import dayjs, { type Dayjs } from 'dayjs';

type checkIfBookingDateTimeHasPassedFromCurrentTimeReturnType = {
  hasPassed: boolean;
};

export default function checkIfBookingDateTimeHasPassedFromCurrentTime(
  data: any,
): checkIfBookingDateTimeHasPassedFromCurrentTimeReturnType {
  // We receive the data in an object, so need to properly structure in order to pass it to dayjs
  const bookingDateTime = `${data?.booking_date},${data?.slot?.time_from}`;

  // We convert the received string into a dayjs object in order calculate the difference
  const formattedBookingDateTime: Dayjs = dayjs(bookingDateTime, { format: 'YYYY-MM-DD,HH:mm' });

  // we get the current datetime in Dayjs format
  const formattedTodaysDateAndTime: Dayjs = dayjs();

  /**
   * We only need to calculate the hour difference
   * if we calculate only the hour difference, then dayjs will have already checked the year, month, date before that
   * for example, if the two dates are
   *    2023-05-24,15:00
   *    2024-05-24,17:00
   *
   * then dayjs will not show the hour difference as 2 hours, rather it will calculate all the hours passed between these two dates
   * this is why it was useful to convert it to a Dayjs object before calculating the hour difference
   *
   * [caveat]
   * if two datetimes are like this
   *    2024-05-24,10:50
   *    2024-05-24,11:00
   * then it will not show the hour difference as 1 but as 0.
   * this is why we will calculate the minute difference instead of the hour.
   *
   * if the current time is 10:56 and the booking time is 11:00 then it will show the difference as 3 and not 4
   *
   * [the logic]
   * `formattedBookingDateTime` should be "bigger" than the `formattedTodaysDateAndTime`
   * which essentially means that the `formattedBookingDateTime` should be in the future.
   * if it is not, then that means that the booking has expired.
   * so, as long as the difference is 0 or greater than 0, the booking is not expired.
   */

  const result = formattedBookingDateTime.diff(formattedTodaysDateAndTime);

  if (result >= 0) {
    return { hasPassed: false };
  } else return { hasPassed: true };
}
