import { yupResolver } from '@hookform/resolvers/yup';
import { Box, Button, Divider, Flex, Grid, Group, Paper, PinInput, Text, Title } from '@mantine/core';
import { useEffect, useState } from 'react';
import { useCookies } from 'react-cookie';
import { Controller, SubmitHandler, useForm } from 'react-hook-form';
import { useLocation, useNavigate } from 'react-router-dom';
import { maskEmail, maskMobile } from '../../utils/helpers';
import { verifyEmailOTPSchema, verifyMobileOTPSchema, verifyOTPSchema } from '../../utils/schema/formSchema';
import { useLoginStyles } from './component/style';
import { mobileVerifyOTP, resendMobileVerifyOTP } from '../../services/authentication.api';
import { toastMessageUpdate } from '../../utils/helpers/tosterMessage';
import { InferType } from 'yup';
import { useDisclosure } from '@mantine/hooks';
import ModalOrDrawer from '../Dashboard/Customer/Profile/components/ModalOrDrawer';
import OtpVerificationSuccess from './OtpVerificationSuccess';

export default function MobileVerification() {
  const { classes } = useLoginStyles();
  const time = 120;
  const [remaingTime, setRemainingTime] = useState(time);
  const [mobileRemaingTime, setMobileRemaingTime] = useState(time);
  const location = useLocation();

  const {
    register,
    handleSubmit,
    formState: { errors },
    control,
    getValues,
    watch,
    reset,
  } = useForm({
    resolver: yupResolver(verifyMobileOTPSchema),
    mode: 'all',
  });

  const {
    register: emailRegister,
    handleSubmit: handleEmailSubmit,
    formState: { errors: emailerrors },
    control: emailControl,
    getValues: getEmailvalues,
    watch: emailWatch,
    reset: emailReset,
  } = useForm({
    resolver: yupResolver(verifyEmailOTPSchema),
    mode: 'all',
  });

  const [isEmailLoading, setEmailIsLoading] = useState<boolean>(false);
  const [isMobileLoading, setMobileIsLoading] = useState<boolean>(false);
  const [isCompleted, setIsCompleted] = useState<boolean>(false);
  const [isMobileCompleted, setIsMobileCompleted] = useState<boolean>(false);
  const [reSendEmailLoading, setReSendEmailLoading] = useState<boolean>(false);
  const [reSendMobileLoading, setReSendMobileLoading] = useState<boolean>(false);
  const [loginMobileTrue, setLoginTrueFromMobile] = useState<boolean>(false);
  const [loginEmailTrue, setLoginTrueFromEmail] = useState<boolean>(false);
  const [cookie, setCookie, removeCookie] = useCookies(['mobilenumber', 'emailAddress']);
  const navigate = useNavigate();

  useEffect(() => {
    //Implementing the setInterval method
    let interval: any = null;
    if (!isCompleted) {
      interval = setInterval(() => {
        setRemainingTime(remaingTime - 1);
        if (remaingTime < 1) {
          setIsCompleted(true);
          clearInterval(interval);
        }
      }, 1000);
    }

    //Clearing the interval
    return () => clearInterval(interval);
  }, [remaingTime]);

  useEffect(() => {
    //Implementing the setInterval method
    let interval: any = null;
    if (!isMobileCompleted) {
      interval = setInterval(() => {
        setMobileRemaingTime(mobileRemaingTime - 1);
        if (mobileRemaingTime < 1) {
          setIsMobileCompleted(true);
          clearInterval(interval);
        }
      }, 1000);
    }

    //Clearing the interval
    return () => clearInterval(interval);
  }, [mobileRemaingTime]);

  useEffect(() => {
    reset({
      mobile: cookie['mobilenumber'] || '',
    });
    emailReset({
      email: cookie['emailAddress'] || '',
    });
  }, []);

  function formatTime(seconds: number): string {
    const minutes = Math.floor(seconds / 60);
    const remainingSeconds = seconds % 60;
    return `${minutes}:${remainingSeconds.toString().padStart(2, '0')}`;
  }

  const onSubmit: SubmitHandler<InferType<typeof verifyMobileOTPSchema>> = async (data) => {
    try {
      setMobileIsLoading(true);
      const response = await mobileVerifyOTP(data);
      if (response.data.success) {
        setMobileIsLoading(false);
        setLoginTrueFromMobile(true);
        toastMessageUpdate('success', response?.data?.message);
        if (loginEmailTrue) {
          openOtpVerificationSuccess();
          setTimeout(() => {
            navigate('/login');
          }, 3000);
          removeCookie('emailAddress');
        }
        removeCookie('mobilenumber');
      }
    } catch (error: any) {
      setMobileIsLoading(false);
      toastMessageUpdate('error', error?.response?.data?.message);
    }
  };

  const onEmailSubmit: SubmitHandler<InferType<typeof verifyEmailOTPSchema>> = async (data) => {
    try {
      setEmailIsLoading(true);
      const response = await mobileVerifyOTP(data);
      if (response.data.success) {
        setEmailIsLoading(false);
        setLoginTrueFromEmail(true);
        toastMessageUpdate('success', response?.data?.message);
        if (loginMobileTrue) {
          openOtpVerificationSuccess();
          setTimeout(() => {
            navigate('/login');
          }, 3000);
          removeCookie('mobilenumber');
        }
        removeCookie('emailAddress');
      }
    } catch (error: any) {
      setEmailIsLoading(false);
      toastMessageUpdate('error', error?.response?.data?.message);
    }
  };

  const resendMobileOTPHandler = async () => {
    try {
      setReSendMobileLoading(true);
      const data = await resendMobileVerifyOTP({ mobile: getValues('mobile') });
      setIsMobileCompleted(false);
      setMobileRemaingTime(time);
      setReSendMobileLoading(false);
    } catch (error: any) {
      setReSendMobileLoading(true);
      toastMessageUpdate('error', error?.response?.data?.message || 'Something went wrong.');
    }
  };

  const resendEmailOTPHandler = async () => {
    try {
      setReSendEmailLoading(true);
      const data = await resendMobileVerifyOTP({ email: getEmailvalues('email') });
      setIsCompleted(false);
      setRemainingTime(time);
      setReSendEmailLoading(false);
    } catch (error: any) {
      setReSendEmailLoading(true);
      toastMessageUpdate('error', error?.response?.data?.message || 'Something went wrong.');
    }
  };

  const [otpVerificationSuccessIsOpened, { open: openOtpVerificationSuccess, close: closeOtpVerificationSuccess }] =
    useDisclosure(false);

  return (
    <>
      <ModalOrDrawer
        opened={otpVerificationSuccessIsOpened}
        onClose={closeOtpVerificationSuccess}
        drawerProps={{ dz: '250', drawerBackButton: true }}
        modalProps={{ modalCloseButton: true }}>
        <OtpVerificationSuccess />
      </ModalOrDrawer>
      <Grid w='100%'>
        <Grid.Col md={5} offsetMd={3.5}>
          <Paper withBorder shadow='md' p={30} radius='xl' mt='xl'>
            <Title order={2} align='center'>
              {cookie['emailAddress'] && cookie['mobilenumber']
                ? 'Validate Email or Mobile Number'
                : cookie['mobilenumber'] || loginEmailTrue
                ? 'Validate Mobile Number'
                : 'Validate Email'}
            </Title>
            {!loginEmailTrue && (
              <>
                <form onSubmit={handleEmailSubmit(onEmailSubmit)} autoComplete='off'>
                  {cookie['emailAddress'] && (
                    <Flex justify='center' gap={5} direction='column'>
                      <Text fz='md' fw='bold' ta='center'>
                        OTP sent on your:
                      </Text>
                      {cookie['emailAddress'] && (
                        <Group position='center' spacing={4}>
                          <Text fz='sm' c='orange' fw={600}>
                            Email Address:
                          </Text>
                          <Text fz='sm'>{maskEmail(cookie['emailAddress'] || '')}</Text>
                        </Group>
                      )}
                    </Flex>
                  )}

                  <Box mb='lg' mt={16}>
                    <Group position='center'>
                      <Controller
                        name='OTP_email'
                        control={emailControl}
                        render={({ field }) => (
                          <PinInput
                            error={emailerrors?.OTP_email ? true : false}
                            {...field}
                            name='otp'
                            length={6}
                            size='lg'
                          />
                        )}
                      />
                      {emailerrors?.OTP_email && (
                        <Text fz={14} pt={5} pl={5} fw={500} color='red'>
                          {emailerrors?.OTP_email?.message}
                        </Text>
                      )}
                    </Group>
                  </Box>
                  <Button
                    variant='gradient'
                    gradient={{ from: '#fda223', to: '#fe7720' }}
                    fullWidth
                    size='md'
                    mt={20}
                    loading={isEmailLoading}
                    type='submit'
                    radius='sm'>
                    {!isEmailLoading && 'Submit'}
                  </Button>
                  <Flex justify='center' gap={5} mt={15}>
                    <Text c='dimmed' fz='sm' ta='center'>
                      Didn&apos;t get code yet?
                    </Text>
                    {!isCompleted && (
                      <Text c='orange' fz='sm' ta='center'>
                        {formatTime(remaingTime)}
                      </Text>
                    )}
                  </Flex>
                  {isCompleted && (
                    <Flex justify='center' gap={5} mt={15}>
                      <Button
                        c='orange'
                        variant='transparent'
                        loading={reSendEmailLoading}
                        onClick={resendEmailOTPHandler}
                        type='button'
                        className={classes.control}>
                        Re-send OTP
                      </Button>
                    </Flex>
                  )}
                </form>
                <Divider my={15} />
              </>
            )}
            <>
              {!loginMobileTrue && (
                <form onSubmit={handleSubmit(onSubmit)} autoComplete='off'>
                  {cookie['mobilenumber'] && (
                    <Flex justify='center' gap={5} direction='column'>
                      <Text fz='md' fw='bold' ta='center'>
                        OTP sent on your:
                      </Text>
                      {cookie['mobilenumber'] && (
                        <Group position='center' spacing={4}>
                          <Text fz='sm' c='orange' fw={600}>
                            Mobile Number:
                          </Text>
                          <Text fz='sm'>{maskMobile(getValues('mobile') || '')}</Text>
                        </Group>
                      )}
                    </Flex>
                  )}

                  <Box mb='lg' mt={16}>
                    <Group position='center'>
                      <Controller
                        name='OTP_mobile'
                        control={control}
                        render={({ field }) => (
                          <PinInput
                            error={errors?.OTP_mobile ? true : false}
                            {...field}
                            name='otp'
                            length={6}
                            size='lg'
                          />
                        )}
                      />
                      {errors?.OTP_mobile && (
                        <Text fz={14} pt={5} pl={5} fw={500} color='red'>
                          {errors?.OTP_mobile?.message}
                        </Text>
                      )}
                    </Group>
                  </Box>
                  <Button
                    variant='gradient'
                    gradient={{ from: '#fda223', to: '#fe7720' }}
                    fullWidth
                    size='md'
                    mt={20}
                    loading={isMobileLoading}
                    type='submit'
                    radius='sm'>
                    {!isMobileLoading && 'Submit'}
                  </Button>
                  <Flex justify='center' gap={5} mt={15}>
                    <Text c='dimmed' fz='sm' ta='center'>
                      Didn&apos;t get code yet?
                    </Text>
                    {!isMobileCompleted && (
                      <Text c='orange' fz='sm' ta='center'>
                        {formatTime(mobileRemaingTime)}
                      </Text>
                    )}
                  </Flex>
                  {isMobileCompleted && (
                    <Flex justify='center' gap={5} mt={15}>
                      <Button
                        c='orange'
                        variant='transparent'
                        loading={reSendMobileLoading}
                        onClick={resendMobileOTPHandler}
                        type='button'
                        className={classes.control}>
                        Re-send OTP
                      </Button>
                    </Flex>
                  )}
                </form>
              )}
            </>
            <Flex justify='center' gap={5}>
              <Button
                c='orange'
                variant='transparent'
                type='button'
                className={classes.control}
                onClick={() => navigate('/login')}>
                Skip & login
              </Button>
            </Flex>
          </Paper>
        </Grid.Col>
      </Grid>
    </>
  );
}
