import { type ReactNode, createContext, useContext, useState } from 'react';

const useProviderListContextStore = () => {
  const [serviceName, setServiceName] = useState<string | null>(null);
  const [currentServiceId, setCurrentServiceId] = useState<string>('');
  const [profileViewType, setProfileViewType] = useState<'list' | 'grid'>('list');

  return {
    serviceName,
    setServiceName,
    currentServiceId,
    setCurrentServiceId,
    profileViewType,
    setProfileViewType,
  };
};

type ProviderListContextType = ReturnType<typeof useProviderListContextStore>;

const ProviderListContext = createContext<ProviderListContextType | null>(null);

type ProviderListContextProviderProps = {
  children: ReactNode;
};

export function ProviderListContextProvider({ children }: ProviderListContextProviderProps) {
  return <ProviderListContext.Provider value={useProviderListContextStore()}>{children}</ProviderListContext.Provider>;
}

export function useProviderListContext() {
  const providerListCtx = useContext(ProviderListContext);

  if (providerListCtx === null) {
    throw new Error('ProviderListContext is null');
  }

  return providerListCtx;
}
