import { BackgroundImage, Box, Flex, Grid, Image, Text } from '@mantine/core';
import { useContext } from 'react';

import { HomeContextRefactored } from '../home.context';
import { usePromotionalBannerStyles } from '../style';
import { box, character } from '../../../../assets';

export default function PromotionalBanner() {
  const { selectedServiceName } = useContext(HomeContextRefactored);
  const { classes, cx } = usePromotionalBannerStyles();
  return (
    <>
      <BackgroundImage src={box}>
        <Grid gutter={3} align='center' px={15} py={20}>
          <Grid.Col lg={8} md={7} sx={() => ({ transition: '500ms ease-in-out' })} h={'100%'}>
            <Text className={cx(classes.serviceName)}>Book your first {selectedServiceName} service</Text>
          </Grid.Col>
          <Grid.Col lg={4} md={5} sx={() => ({ transition: '500ms ease-in-out' })}>
            <Box>
              <Grid align='center'>
                <Grid.Col md={8}>
                  <Flex direction='column' c='black'>
                    <Text ta='center'>and get upto</Text>
                    <Text ta='center' fz={20} fw={500}>
                      20% off
                    </Text>
                  </Flex>
                </Grid.Col>
                <Grid.Col md={4}>
                  <Image src={character} maw={50}></Image>
                </Grid.Col>
              </Grid>
            </Box>
          </Grid.Col>
        </Grid>
      </BackgroundImage>
    </>
  );
}
