import { Flex, Loader } from '@mantine/core';
import { IconHeart, IconHeartFilled } from '@tabler/icons-react';

interface Props {
  renderIf: boolean | undefined;
  isThisUserMyFavourite: boolean | undefined;
  favouritesIsLoading: boolean;
  handleFavouritesAddOrRemove: (type: 'add' | 'remove') => void;
}

export default function AddToFavourites({
  renderIf,
  favouritesIsLoading,
  isThisUserMyFavourite,
  handleFavouritesAddOrRemove,
}: Props) {
  return (
    <>
      <Flex justify='flex-end' mt={16} h={24}>
        {renderIf ? (
          isThisUserMyFavourite ? (
            favouritesIsLoading ? (
              <Loader color='orange' size='sm' />
            ) : (
              <IconHeartFilled
                onClick={() => handleFavouritesAddOrRemove('remove')}
                style={{ cursor: 'pointer', color: '#fe7720' }}
              />
            )
          ) : favouritesIsLoading ? (
            <Loader color='orange' size='sm' />
          ) : (
            <IconHeart
              onClick={() => handleFavouritesAddOrRemove('add')}
              style={{ cursor: 'pointer', color: '#fe7720' }}
            />
          )
        ) : null}
      </Flex>
    </>
  );
}
