import { yupResolver } from '@hookform/resolvers/yup';
import { Box, Button, Flex, Grid, Group, Paper, PinInput, Text, Title } from '@mantine/core';
import { useEffect, useState } from 'react';
import { useCookies } from 'react-cookie';
import { Controller, useForm } from 'react-hook-form';
import { useNavigate } from 'react-router-dom';
import { OTPVerify, forgetAPI } from '../../services/authentication.api';
import { maskEmail } from '../../utils/helpers';
import { toastMessageUpdate } from '../../utils/helpers/tosterMessage';
import { verifyOTPSchema } from '../../utils/schema/formSchema';
import { useLoginStyles } from './component/style';

export default function VerifyOTP() {
  const { classes } = useLoginStyles();
  const [cookie, setCookie, removeCookie] = useCookies(['forget-email']);
  const time = 30;
  const [remaingTime, setRemainingTime] = useState(time);
  const navigate = useNavigate();

  const {
    register,
    handleSubmit,
    formState: { errors },
    control,
    getValues,
    reset,
  } = useForm({
    resolver: yupResolver(verifyOTPSchema),
    mode: 'all',
  });

  const [isLoading, setIsLoading] = useState(false);
  const resetPassword = async (data: any) => {
    try {
      setIsLoading(true);
      const resData = await OTPVerify(data);
      navigate('/reset-password');
      setIsLoading(false);
    } catch (error: any) {
      setIsLoading(false);
      toastMessageUpdate('error', error?.response?.data?.message);
    }
  };

  const [isCompleted, setIsCompleted] = useState<boolean>(false);
  useEffect(() => {
    //Implementing the setInterval method
    let interval: any = null;
    if (!isCompleted) {
      interval = setInterval(() => {
        setRemainingTime(remaingTime - 1);
        if (remaingTime < 1) {
          setIsCompleted(true);
          clearInterval(interval);
        }
      }, 1000);
    }

    //Clearing the interval
    return () => clearInterval(interval);
  }, [remaingTime]);

  useEffect(() => {
    reset({
      email: cookie['forget-email'] || '',
    });
  }, []);

  const [reSendLoading, setReSendLoading] = useState(false);
  const otpHandler = async () => {
    try {
      setReSendLoading(true);
      const data = await forgetAPI({ email: getValues('email') });
      setIsCompleted(false);
      setRemainingTime(time);
      setReSendLoading(false);
    } catch (error: any) {
      setReSendLoading(true);
      toastMessageUpdate('error', error?.response?.data?.message || 'Something went wrong.');
    }
  };

  return (
    <>
      <Grid w='100%'>
        <Grid.Col md={5} offsetMd={3.5}>
          <Paper withBorder shadow='md' p={30} radius='xl' mt='xl'>
            <Title align='center'>Enter your OTP</Title>
            <form onSubmit={handleSubmit(resetPassword)} autoComplete='off'>
              {getValues('email') && (
                <Flex justify='center' gap={5}>
                  <Text c='orange' fz='sm' fw='bold' ta='center'>
                    OTP sent to:
                  </Text>
                  <Text fz='sm' ta='center'>
                    {maskEmail(getValues('email') || '')}
                  </Text>
                </Flex>
              )}

              <Box mb='lg' mt={16}>
                <Group position='center'>
                  <Controller
                    name='otp'
                    control={control}
                    render={({ field }) => (
                      <PinInput error={errors?.otp ? true : false} {...field} name='otp' length={6} size='lg' />
                    )}
                  />
                  {errors?.otp && (
                    <Text fz={14} pt={5} pl={5} fw={500} color='red'>
                      {errors?.otp?.message}
                    </Text>
                  )}
                </Group>
              </Box>
              <Button
                variant='gradient'
                gradient={{ from: '#fda223', to: '#fe7720' }}
                fullWidth
                size='md'
                mt={20}
                loading={isLoading}
                type='submit'
                radius='sm'>
                {!isLoading && 'Submit'}
              </Button>
              <Flex justify='center' gap={5} mt={15}>
                <Text c='dimmed' fz='sm' ta='center'>
                  Didn&apos;t get code yet?
                </Text>
                {!isCompleted && (
                  <Text c='orange' fz='sm' ta='center'>
                    {remaingTime}
                  </Text>
                )}
              </Flex>
              {isCompleted && (
                <Flex justify='center' gap={5} mt={15}>
                  <Button
                    c='orange'
                    variant='transparent'
                    loading={reSendLoading}
                    onClick={otpHandler}
                    type='button'
                    className={classes.control}>
                    Re-send OTP
                  </Button>
                </Flex>
              )}
            </form>
          </Paper>
        </Grid.Col>
      </Grid>
    </>
  );
}
