import { Box, Button, Flex, Text } from '@mantine/core';
import { useNavigate } from 'react-router-dom';
import Loading from './Loading';
import { IconArrowRight } from '@tabler/icons-react';
import { customerRole } from '../../../../../utils';
import { useCookies } from 'react-cookie';

interface Props {
  data: any;
}

export default function ServiceType({ data }: Props) {
  const navigate = useNavigate();
  const [cookies] = useCookies(['role']);
  const redirect = () => {
    navigate(
      `/freelancer-list/${data?.service_type?.service_type_id}?service=${data?.service_type?.service?.toLowerCase()}`,
    );
  };
  return (
    <>
      <Flex direction='column' gap={4}>
        <Text fw={600} fz={16}>
          Service Type
        </Text>
        {data === undefined ? (
          <Loading />
        ) : (
          <>
            <Flex gap={10}>
              <Box>
                <Text c='dimmed'>{data?.service_type?.service}</Text>
              </Box>
              {/* {customerRole.includes(+cookies.role) && (
                <Button compact variant='subtle' onClick={redirect}>
                  <IconArrowRight stroke={2} />
                </Button>
              )} */}
            </Flex>
          </>
        )}
      </Flex>
    </>
  );
}
