import { Drawer, Grid, LoadingOverlay, ScrollArea, Text, UnstyledButton, useMantineTheme } from '@mantine/core';
import { useMediaQuery } from '@mantine/hooks';
import { IconX } from '@tabler/icons-react';
import { useContext, useEffect, useState } from 'react';

import { HomeContextRefactored } from '../home.context';
import { useProviderFilterScrollAreaStyles, useProviderProfilesContainerStyles } from '../style';
import PromotionalBanner from './PromotionalBanner';
import ProviderFilter from './ProviderFilter';
import ProviderProfiles from './ProviderProfiles';
import { DefaultSearch, FilteredSearch, WithoutRangeSearch, useProviderList } from '../../../../services/profiles';

interface Props {
  renderIf: boolean;
}

/*
  this is a `smart component`, it will do all the data related things and pass them onto the children
  the children will be `dumb components`, i.e. they will not handle anything related to data.
  the children will simply take the data from parent and send their responses (if any) to the parent.
*/
export default function ProviderProfilesContainer() {
  const { classes, cx } = useProviderProfilesContainerStyles();
  const { classes: useProviderFilterScrollAreaClasses } = useProviderFilterScrollAreaStyles();
  const { showFilter, selectedChildServiceId, setShowFilter, withoutRangeSearch, setWithoutRangeSearch } =
    useContext(HomeContextRefactored);
  const [searchType, setSearchType] = useState<DefaultSearch | FilteredSearch | WithoutRangeSearch | undefined>(
    undefined,
  );

  useEffect(() => {
    if (withoutRangeSearch) {
      setSearchType((previousValue) => ({
        ...previousValue,
        type: 'withoutRange',
        service_type_id: selectedChildServiceId,
      }));
    } else {
      setSearchType((previousState) => ({
        ...previousState,
        type: 'default',
        service_type_id: selectedChildServiceId,
      }));
    }
  }, [selectedChildServiceId, withoutRangeSearch]);

  const {
    data,
    refetch,
    isLoading,
    isSuccess: isProviderListFetchSuccess,
  } = useProviderList(searchType, searchType ? true : false);

  const receivedDataFromProviderFilter = (type: 'submit' | 'reset', data: any) => {
    switch (type) {
      case 'submit':
        setSearchType({ type: 'filtered', service_type_id: selectedChildServiceId, data: data });
        setShowFilter(false);
        break;
      case 'reset':
        setSearchType({ type: 'default', service_type_id: selectedChildServiceId });
        setShowFilter(false);
        refetch();
        break;
    }
  };
  const theme = useMantineTheme();
  const drawerScreenSize = useMediaQuery(`(max-width: ${theme.breakpoints.md})`);

  return (
    <>
      <Grid p={0} m={0}>
        <Grid.Col lg={12} p={0} m={0} className={cx(classes.promotionalBannerGridContainer)}>
          <PromotionalBanner />
        </Grid.Col>
        <Grid.Col lg={showFilter ? 8 : 12} md={showFilter ? 7 : 12} p={0} m={0} pos='relative'>
          <LoadingOverlay visible={isLoading} overlayBlur={2} overlayOpacity={0.8} />
          <ProviderProfiles profiles={isProviderListFetchSuccess ? data?.data : []} />
        </Grid.Col>

        <Grid.Col
          lg={4}
          md={5}
          p={0}
          m={0}
          className={cx(classes.filterProfileGridContainer, {
            [classes.filterProfileGridContainerHide]: !showFilter,
          })}>
          <Text fw={600} fz={18} className={cx(classes.filterHeading)}>
            Filter
          </Text>
          <ScrollArea.Autosize mt={8} classNames={useProviderFilterScrollAreaClasses} type='scroll' scrollbarSize={2}>
            <ProviderFilter sendDataToParent={receivedDataFromProviderFilter} />
          </ScrollArea.Autosize>
        </Grid.Col>
      </Grid>

      {/* Filter drawer for mobile devices */}
      <Drawer
        keepMounted
        opened={drawerScreenSize && showFilter}
        onClose={() => setShowFilter(false)}
        size='90%'
        closeOnClickOutside={false}
        withCloseButton={false}
        overlayProps={{ opacity: 0, blur: 0 }}
        position='bottom'>
        <UnstyledButton onClick={() => setShowFilter(false)} className={cx(classes.backIconButtonContainer)}>
          <IconX className={cx(classes.backIconButton)} />
        </UnstyledButton>
        <ProviderFilter sendDataToParent={receivedDataFromProviderFilter} />
      </Drawer>
    </>
  );
}
