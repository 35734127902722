import { Grid, Skeleton } from '@mantine/core';
import React from 'react';
import BookingCard from './BookingCard';
import { useBookingContext } from '../../../../../context/booking-context';

interface Props {
  data: any;
  isLoading: boolean;
  type: 'current' | 'history';
}

export default function ShowBookings({ data, isLoading, type }: Props) {
  const { allGridColStyles } = useBookingContext();
  return (
    <>
      {isLoading ? (
        <>
          {Array(9)
            .fill(0)
            .map((_, index) => {
              return (
                <Grid.Col key={index} lg={4} md={4} sm={6} xs={6}>
                  <Skeleton visible={true} width={'100%'} height={164} />
                </Grid.Col>
              );
            })}
        </>
      ) : (
        <>
          {data?.map((bookingData: any, index: number) => {
            return (
              <Grid.Col {...allGridColStyles.childBookingCardGridColumnStyles} key={index}>
                <BookingCard data={bookingData} type={type} />
              </Grid.Col>
            );
          })}
        </>
      )}
    </>
  );
}
