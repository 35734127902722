import { Badge, Box, Flex, ScrollArea, Text, UnstyledButton } from '@mantine/core';
import ProfilePhoto from '../../../../../components/ProfilePhoto';
import { IconBox, IconDiscountCheckFilled } from '@tabler/icons-react';
import VerificationStatusContainer from './VerificationTextContainer';
import { providerRole } from '../../../../../utils';
import { useProfileCardStyles } from './style';

interface Props {
  profileData: any;
  verificationData: any;
  userIsViewingOwnProfile: boolean;
}

export default function ProfileCard({ profileData, verificationData, userIsViewingOwnProfile }: Props) {
  const { classes } = useProfileCardStyles();
  const renderBlueTick = () => {
    switch (+profileData?.user?.type) {
      case 1:
        return verificationData?.onfido_result === 'clear' || verificationData?.signzy_journey__result ? true : false;
      case 2:
        return verificationData?.background_verification ? true : false;
      case 3:
        return verificationData?.business_license_verification ? true : false;
    }
  };

  const renderVerificationBorder = (): boolean | undefined => {
    switch (+profileData?.user?.type) {
      case 1:
        return verificationData?.onfido_result === 'clear' || verificationData?.signzy_journey__result ? true : false;
      case 2:
        return verificationData?.background_verification ? true : false;
      case 3:
        return verificationData?.business_license_verification ? true : false;
    }
  };

  return (
    <>
      <Box mt={20}>
        <Flex justify='center' direction='column' align='center'>
          <ProfilePhoto
            type='renderWithoutEdit'
            src={profileData?.user_profile?.image}
            showGradientBackground
            showVerificationBorder={renderVerificationBorder()}
            sizes={{ xl: 160, lg: 150, md: 100, sm: 120, xs: 120 }}
          />
          <Flex mt={16} align='center' gap={8}>
            <Text fz={24} fw={600}>
              {profileData?.name}
            </Text>
            {renderBlueTick() ? <IconDiscountCheckFilled size={20} style={{ color: '#39A7FF' }} /> : null}
          </Flex>
          <VerificationStatusContainer
            role={+profileData?.user?.type}
            data={verificationData}
            userIsViewingOwnProfile={userIsViewingOwnProfile}
          />

          {providerRole.includes(profileData?.user?.type) && (
            <Box className={classes.providerIntroContainer}>
              <ScrollArea.Autosize mah={60} offsetScrollbars scrollbarSize={4}>
                <Box pos='relative'>
                  <Text fz={14} c='dimmed' ta='center'>
                    {profileData?.user_profile?.intro}
                  </Text>
                </Box>
              </ScrollArea.Autosize>
            </Box>
          )}

          <Box mt={8} w={'100%'}>
            <ScrollArea.Autosize mah={60} offsetScrollbars scrollbarSize={4}>
              <Flex w={'100%'} wrap='wrap' justify='center' gap={2}>
                {profileData?.user_profile?.skills?.split(',')?.map((skill: any, index: number) => {
                  return <Badge key={index}>{skill}</Badge>;
                })}
              </Flex>
            </ScrollArea.Autosize>
          </Box>
        </Flex>
      </Box>
    </>
  );
}
