import { Badge, BadgeProps, Box, Flex, Text } from '@mantine/core';
import { BookingStatus } from '../../../../../utils/helpers/getStatusOfTheBooking';
import { useShowStatusBadgeStyles } from '../style';

interface Props {
  data: any;
  status: BookingStatus;
}

export default function ShowStatusBadge({ data, status }: Props) {
  const errorState: Array<BookingStatus> = [
    BookingStatus.BOOKING_EXPIRED,
    BookingStatus.CANCELLED,
    BookingStatus.REJECTED,
  ];

  let color: BadgeProps['color'] = 'gray';

  if (status === BookingStatus.LOADING) {
    color = 'gray';
  } else if (errorState.includes(status)) {
    color = 'red';
  } else {
    color = 'green';
  }

  const { classes } = useShowStatusBadgeStyles();

  return (
    <>
      <Flex direction='column' gap={4}>
        <Text fw={600}>Status</Text>
        <Badge w='fit-content' size='sm' color={color}>
          {status}
        </Badge>

        {status === BookingStatus.BOOKING_EXPIRED && (
          <Text fz={12} c='dimmed'>
            * Booking date and time has expired from the current date and time.
          </Text>
        )}

        {status === BookingStatus.CANCELLED && (
          <>
            <Text fw={600}>Reason:</Text>
            {data?.cancle_remarks === null ? (
              <Box className={classes.noCancellationReasonContainer}>
                <Text>No reason provided</Text>
              </Box>
            ) : (
              <Text c='dimmed' fz={15}>
                {data?.cancle_remarks}
              </Text>
            )}
          </>
        )}
      </Flex>
    </>
  );
}
