import { Box, Text } from '@mantine/core';
import { useFilterHeaderStyles } from '../style';

export default function FilterHeader() {
  const { classes } = useFilterHeaderStyles();

  return (
    <>
      <Box className={classes.container}>
        <Text fz={18} fw={600}>
          Filter
        </Text>
      </Box>
    </>
  );
}
