import { Flex, Text } from '@mantine/core';
import { convertTimeFormat } from '../../../../../utils/helpers';
import dayjs from 'dayjs';
import Loading from './Loading';

interface Props {
  data: any;
}

export default function TimeSlot({ data }: Props) {
  return (
    <>
      <Flex direction='column' gap={4}>
        <Text fw={600} fz={16}>
          Time Slot
        </Text>

        {data === undefined ? (
          <Loading />
        ) : (
          <>
            <Text c='dimmed' fz={14}>
              {convertTimeFormat(data?.slot?.time_from)} - {convertTimeFormat(data?.slot?.time_to)},{' '}
              {dayjs(data?.booking_date).format('MMM DD, YYYY')}
            </Text>
          </>
        )}
      </Flex>
    </>
  );
}
