import { UnstyledButton } from '@mantine/core';
import { IconArrowLeft } from '@tabler/icons-react';
import { useBackButtonStyles } from '../style';

interface Props {
  onClose: () => void;
}

export default function BackButton({ onClose }: Props) {
  const { classes } = useBackButtonStyles();
  return (
    <>
      <UnstyledButton onClick={onClose} c='#909090' className={classes.button} w={20} h={20}>
        <IconArrowLeft size={20} />
      </UnstyledButton>
    </>
  );
}
