import { Box, Grid, ScrollArea, Skeleton, Title } from '@mantine/core';
import React, { useContext } from 'react';

import { HomeContextRefactored } from '../home.context';
import ServiceCardRefactored from './ServiceCardRefactored';

import { useServicesList } from '../../../../services/service_types.api';
import {
  useChildServicesScrollAreaStyles,
  useParentServicesScrollAreaStyles,
  useServiceCardGridStyles,
} from '../style';
import BackButton from './BackButton';

export default function ServiceCardGrid() {
  const {
    setShowProviderProfilesContainer,
    selectedParentServiceId,
    setSelectedParentServiceId,
    setSelectedChildServiceId,
    showFilter,
    setShowFilter,
  } = useContext(HomeContextRefactored);

  // this will fetch all the parent services
  const { data: parentServices, isSuccess, isLoading: isParentServicesLoading } = useServicesList();
  // this will fetch all the child services, but it will not fetch until a parent service has been selected
  const { data: childServices, isInitialLoading: isChildServicesLoading } = useServicesList(
    selectedParentServiceId,
    selectedParentServiceId === '' ? false : true,
  );
  const { classes, cx } = useServiceCardGridStyles();
  const { classes: childServicesScrollAreaClasses } = useChildServicesScrollAreaStyles();
  const { classes: parentServicesScrollAreaClasses } = useParentServicesScrollAreaStyles();

  const handleBack = () => {
    setSelectedParentServiceId('');
    setSelectedChildServiceId('');
    setShowFilter(false);
    setShowProviderProfilesContainer(false);
  };

  return (
    <>
      <Box pos='relative'>
        <Title order={2}>Services offered</Title>
        <BackButton renderIf={!!selectedParentServiceId} onClick={handleBack} />

        {/* This is rendering the parent services */}
        <Box mt={56}>
          <ScrollArea.Autosize scrollbarSize={2} classNames={parentServicesScrollAreaClasses}>
            <Grid p={0} m={0}>
              {isParentServicesLoading &&
                Array(12)
                  .fill(0)
                  .map((_, index) => {
                    return (
                      <React.Fragment key={index}>
                        <Grid.Col lg={4} md={6} sm={4} xs={4} className={cx(classes.gridContainer)}>
                          <Skeleton visible h={120}></Skeleton>
                        </Grid.Col>
                      </React.Fragment>
                    );
                  })}

              {isSuccess &&
                parentServices?.data.map((e: any, index: number) => {
                  return (
                    <React.Fragment key={index}>
                      {/* rendering all the parent service */}
                      {selectedParentServiceId === '' && (
                        <Grid.Col
                          p={4}
                          m={0}
                          lg={showFilter ? 6 : 4}
                          md={showFilter ? 6 : 6}
                          sm={4}
                          xs={4}
                          className={cx(classes.gridContainer)}>
                          <ServiceCardRefactored service={e} />
                        </Grid.Col>
                      )}

                      {/* rendering a single parent service (the selected one) */}
                      {selectedParentServiceId === e.service_type_id && (
                        <Grid.Col
                          p={4}
                          m={0}
                          lg={showFilter ? 6 : 4}
                          md={showFilter ? 6 : 6}
                          sm={4}
                          xs={4}
                          className={cx(classes.gridContainer)}>
                          <ServiceCardRefactored service={e} />
                        </Grid.Col>
                      )}
                    </React.Fragment>
                  );
                })}
            </Grid>
          </ScrollArea.Autosize>
        </Box>

        {/* This is rendering the child services */}
        <ScrollArea.Autosize scrollbarSize={2} classNames={childServicesScrollAreaClasses} type='never'>
          <Grid p={0} m={0} pos='relative'>
            {isChildServicesLoading &&
              Array(12)
                .fill(0)
                .map((_, index) => {
                  return (
                    <React.Fragment key={index}>
                      <Grid.Col lg={4} md={6} sm={4} xs={4} className={cx(classes.gridContainer)}>
                        <Skeleton visible h={120}></Skeleton>
                      </Grid.Col>
                    </React.Fragment>
                  );
                })}

            {selectedParentServiceId &&
              childServices?.data.map((e: any, index: number) => {
                return (
                  <React.Fragment key={index}>
                    <Grid.Col
                      p={4}
                      m={0}
                      lg={showFilter ? 6 : 4}
                      md={showFilter ? 6 : 6}
                      sm={4}
                      xs={4}
                      className={cx(classes.gridContainer)}>
                      <ServiceCardRefactored service={e} />
                    </Grid.Col>
                  </React.Fragment>
                );
              })}
          </Grid>
        </ScrollArea.Autosize>
      </Box>
    </>
  );
}
