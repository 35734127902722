import { Avatar, Box, Card, Flex, Text } from '@mantine/core';

import { useContext } from 'react';
import { serviceIconHandler } from '../../../../utils/helpers';
import { makeUpperCase } from '../../../../utils/helpers/makeFirstLetterUpper';
import { HomeContextRefactored } from '../home.context';
import { useServiceCardStyles } from '../style';

interface Props {
  service: {
    parent_id: string | null;
    service: string;
    service_type_id: string;
  };
}

export default function ServiceCardRefactored({ service }: Props) {
  // console.log(service);
  const { classes, cx } = useServiceCardStyles();
  const {
    setSelectedParentServiceId,
    setSelectedChildServiceId,
    selectedParentServiceId,
    selectedChildServiceId,
    setShowProviderProfilesContainer,
    setSelectedServiceName,
    setWithoutRangeSearch,
    setShowJobSection,
  } = useContext(HomeContextRefactored);

  const handleCardClick = () => {
    // if the service to be selected is a parent service
    if (!service.parent_id) {
      setSelectedParentServiceId(service.service_type_id);
    }
    // else if it is a child service
    else {
      setSelectedChildServiceId(service.service_type_id);
      setSelectedServiceName(service.service);
      setShowProviderProfilesContainer(true);
      setShowJobSection(false);
      if (service.service_type_id !== selectedChildServiceId) {
        setWithoutRangeSearch(false);
      }
    }
  };

  return (
    <>
      <Card
        className={cx(classes.card, {
          [classes.selectedParentServiceCard]: selectedParentServiceId === service.service_type_id,
          [classes.selectedChildServiceCard]: selectedChildServiceId === service.service_type_id && service.parent_id,
        })}
        onClick={handleCardClick}>
        <Flex direction='column' h='100%' justify='space-between' align='flex-start' gap={10}>
          <Box>
            <Avatar
              size='md'
              src={serviceIconHandler(service.service)}
              styles={{ image: { objectFit: 'contain' } }}></Avatar>
          </Box>
          <Text>{makeUpperCase(service.service)}</Text>
        </Flex>
      </Card>
    </>
  );
}
