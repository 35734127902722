import { Box, Flex, Grid, LoadingOverlay, ScrollArea } from '@mantine/core';
import { useCookies } from 'react-cookie';
import { useBookingContext } from '../../../../../context/booking-context';
import getStatusOfTheBooking from '../../../../../utils/helpers/getStatusOfTheBooking';
import { useBookingDetailsScrollAreaStyles, useBookingDetailsStyles } from '../style';
import AddAndApproveItems from './AddAndApproveItems';
import BookingLocation from './BookingLocation';
import GoogleMapComponent from './GoogleMapComponent';
import Header from './Header';
import ServiceProviderOrConsumer from './ServiceProviderOrConsumer';
import ServiceType from './ServiceType';
import ShowBookingAction from './ShowBookingAction';
import ShowContactInfo from './ShowContactInfo';
import ShowStatusBadge from './ShowStatusBadge';
import TimeSlot from './TimeSlot';

interface Props {
  onClose: () => void;
  isUserOverviewLoading: boolean;
  userOverviewData: any;
  encodedUserId: string;
  refetch: any;
}

export default function BookingDetails({
  onClose,
  isUserOverviewLoading,
  userOverviewData,
  encodedUserId,
  refetch,
}: Props) {
  const [cookie, _] = useCookies(['role']);
  const { classes, cx } = useBookingDetailsStyles();
  const { classes: bookingDetailsScrollAreaClasses } = useBookingDetailsScrollAreaStyles();
  const { selectedBooking, allGridColStyles, showMap, showAddOrApproveItems } = useBookingContext();

  const status = getStatusOfTheBooking(selectedBooking, +cookie.role).status;
  console.log(selectedBooking);

  return (
    <>
      <Box className={classes.container}>
        <Grid p={0} m={0}>
          <Grid.Col {...allGridColStyles.childBookingDetailsInfoGridColumnStyles} p={0} m={0}>
            <Header onClose={onClose} showMap={showMap} showAddOrApproveItems={showAddOrApproveItems} />
            <Box className={classes.innerContainer}>
              <LoadingOverlay
                visible={isUserOverviewLoading}
                overlayBlur={2}
                overlayOpacity={0.1}
                overlayColor='#fff'
              />

              <ScrollArea.Autosize classNames={bookingDetailsScrollAreaClasses} scrollbarSize={4} type='never'>
                <Flex direction='column' gap={16}>
                  <ServiceType data={selectedBooking} />

                  <TimeSlot data={selectedBooking} />

                  <ServiceProviderOrConsumer
                    data={userOverviewData}
                    isLoading={isUserOverviewLoading}
                    encodedUserId={encodedUserId}
                  />

                  <ShowContactInfo
                    data={userOverviewData}
                    bookingData={selectedBooking}
                    renderIf={selectedBooking?.invoice_status === true}
                  />

                  <BookingLocation data={userOverviewData} isLoading={isUserOverviewLoading} />

                  <ShowStatusBadge status={status} data={selectedBooking} />

                  <ShowBookingAction status={status} role={+cookie.role} data={selectedBooking} refetch={refetch} />
                </Flex>
              </ScrollArea.Autosize>
            </Box>
          </Grid.Col>

          <GoogleMapComponent renderIf={showMap} onClose={onClose} />

          {/* <AddAndApproveItems renderIf={showAddOrApproveItems} onClose={onClose} /> */}
        </Grid>
      </Box>
    </>
  );
}
