import { Badge, Flex, Text } from '@mantine/core';
import { convertUtcTimeToLocalFormat } from '../../../../../utils/helpers';

interface Props {
  data: any;
}

export default function StartedAt({ data }: Props) {
  return (
    <>
      <Badge w='fit-content'>
        <Flex gap={4}>
          <Text fw={700}>Started At:</Text>
          <Text fw={500}>{convertUtcTimeToLocalFormat(data?.service_started)}</Text>
        </Flex>
      </Badge>
    </>
  );
}
