import { Carousel } from '@mantine/carousel';
import { Image, createStyles } from '@mantine/core';
import Autoplay from 'embla-carousel-autoplay';
import { useRef } from 'react';
import {
  babySittingServiceImage,
  cleaningImage,
  cookingServiceImage,
  deliveryServiceImage,
  laundryServiceImage,
  petCareServiceImage,
  walkingAssistanceServiceImage,
} from '../../../../assets';

export default function ServiceImageCarousel() {
  const carouselImages = [
    babySittingServiceImage,
    cleaningImage,
    cookingServiceImage,
    deliveryServiceImage,
    laundryServiceImage,
    petCareServiceImage,
    walkingAssistanceServiceImage,
  ];
  const autoplay = useRef(Autoplay({ delay: 5000 }));

  return (
    <>
      <Carousel
        // maw='100%'
        // h='100%'
        // h={770}
        withControls={false}
        withIndicators
        loop
        plugins={[autoplay.current]}
        onMouseEnter={autoplay.current.stop}
        onMouseLeave={autoplay.current.reset}
        styles={{
          indicator: {
            width: '20px',
            height: '5px',
            transition: 'width 250ms ease',

            '&[data-active]': {
              width: '40px',
              backgroundColor: '#fe7720',
            },
          },
        }}>
        <>
          {carouselImages.map((image, index) => {
            return (
              <Carousel.Slide key={index}>
                <Image src={image} height={'calc(100lvh - 162px)'} fit='cover' />
              </Carousel.Slide>
            );
          })}
        </>
      </Carousel>
    </>
  );
}
