import {
  ActionIcon,
  Box,
  Card,
  Drawer,
  Flex,
  Indicator,
  Tooltip,
  rem,
  Text,
  useMantineTheme,
  Anchor,
  Menu,
  Button,
  createStyles,
} from '@mantine/core';
import { useDisclosure, useMediaQuery } from '@mantine/hooks';
import { IconArrowLeft, IconMessageDots, IconMessages, IconSend } from '@tabler/icons-react';
import React, { useContext, useEffect, useState } from 'react';
import { ThemeContext } from '../../../context';
import { customerRole } from '../../../utils';
import { useCookies } from 'react-cookie';
import { readNotification, useNotificationtId } from '../../../services/websocket.api';
import { useLocation, useNavigate } from 'react-router-dom';
import { HeaderMenuStyle, IndicatorStyle, ScrollContianer } from './styles';

const useNotifcationStyles = createStyles((theme) => ({
  notificationCardUnread: {
    background: theme.colorScheme === 'light' ? '#fff4e1' : '#1f1f1f',
  },

  notificationCardRead: {
    background: theme.colorScheme === 'light' ? '#f7f7f7' : '#0b0b0b',
  },
}));

const MessageNotification = () => {
  const theme = useMantineTheme();
  const mobile = useMediaQuery(`(max-width: ${theme.breakpoints.sm})`);
  const [cookies, setCookie] = useCookies(['access_token', 'role', 'user_Info']);
  const [opened, { open, close }] = useDisclosure(false);
  const navigate = useNavigate();
  const {
    data: notificationData,
    isLoading,
    refetch,
    isRefetchError,
    hasNextPage,
    isFetching,
    fetchNextPage,
  } = useNotificationtId('chat');
  const [notifications, setNotifications] = useState(null);
  const { setChannelId, setChatType, setChatRecieverId, notificationRecieved } = useContext(ThemeContext);
  const { classes: menuClass } = HeaderMenuStyle();
  const { classes: indicatorClass } = IndicatorStyle();
  const { classes, cx } = useNotifcationStyles();
  const location = useLocation();

  useEffect(() => {
    if (notificationRecieved) {
      refetch();
    }
  }, [notificationRecieved]);

  const replyToUser = async (userType: any) => {
    const formData = { notification_id: userType.notification_Id };
    await readNotification(formData);
    if (customerRole.includes(+cookies.role)) {
      setChannelId('' + userType?.from_user + userType?.to_user);
      setChatType('userChat');
      setChatRecieverId(userType?.from_user);
    } else {
      setChannelId('' + userType?.to_user + userType?.from_user); //130 108
      setChatType('userChat');
      setChatRecieverId(userType?.from_user);
    }
    refetch();
    close();
    navigate('/chat');
  };

  const goToChat = () => {
    if (location.pathname !== '/chat') {
      setChatType('userChat');
      setChannelId(null);
      navigate('/chat');
      close();
    }
  };

  const getAllNotification = async () => {
    notificationData?.pages.map((page) => {
      setNotifications(page?.notifications.unread_count);
    });
  };

  useEffect(() => {
    getAllNotification();
  }, [notificationData]);

  const scrollHandler = async (e: any) => {
    const totalHeight = e.target.clientHeight + e.target.scrollTop;
    if (totalHeight >= e.target.scrollHeight - 50 && !isFetching && !isLoading && hasNextPage && !isRefetchError) {
      fetchNextPage();
    }
  };

  return (
    <>
      {mobile ? (
        <>
          <Indicator
            disabled={!notifications}
            label={notifications && notifications > 99 ? '99+' : notifications}
            position={'top-start'}
            classNames={indicatorClass}
            size={'md'}>
            <ActionIcon onClick={open}>
              <IconMessages size={rem(20)} />
            </ActionIcon>
          </Indicator>

          <Drawer opened={opened} onClose={close} position='bottom' withCloseButton={false} padding={0}>
            <Drawer.Header>
              <IconArrowLeft onClick={close} className='mobileBackButton' />
            </Drawer.Header>
            <ScrollContianer onScroll={scrollHandler}>
              {notificationData?.pages.map((page, index) => (
                <React.Fragment key={index}>
                  {!page.notifications.notifications.data.length ? (
                    <>
                      <Text c='dimmed' ta='center' fz='md'>
                        You have no new messages.
                      </Text>
                      <Flex justify='center'>
                        <Button
                          mt={5}
                          size='sm'
                          variant='gradient'
                          gradient={{ to: 'orange', from: 'yellow', deg: 160 }}
                          onClick={() => {
                            goToChat();
                          }}>
                          Go To Chat
                        </Button>
                      </Flex>
                    </>
                  ) : (
                    <>
                      {page.notifications.notifications.data.map((e: any) => {
                        return (
                          <Box
                            key={e.notification_Id}
                            sx={(theme) => ({
                              margin: 10,
                            })}>
                            <Card
                              withBorder
                              className={cx({
                                [classes.notificationCardRead]: e.read,
                                [classes.notificationCardUnread]: !e.read,
                              })}
                              onClick={() => replyToUser(e)}>
                              <Flex justify='space-between' direction='row' align='center'>
                                <Text c='dimmed'>{e.message}</Text>
                                <Flex direction='column'>
                                  <Tooltip label='Reply' position='left'>
                                    <ActionIcon size={22}>
                                      <IconSend color='#495057' onClick={() => replyToUser(e)} />
                                    </ActionIcon>
                                  </Tooltip>
                                </Flex>
                              </Flex>
                            </Card>
                          </Box>
                        );
                      })}
                    </>
                  )}
                </React.Fragment>
              ))}
            </ScrollContianer>
          </Drawer>
        </>
      ) : (
        <Menu
          width={300}
          shadow='md'
          position='bottom-end'
          withArrow
          arrowOffset={150}
          arrowSize={15}
          closeOnItemClick={true}
          transitionProps={{ transition: 'pop-top-right', duration: 200 }}>
          <Menu.Target>
            <Anchor className={menuClass.headerCommon} underline={false}>
              <Indicator
                disabled={!notifications}
                label={notifications && notifications > 99 ? '99+' : notifications}
                classNames={indicatorClass}
                position={'top-start'}
                size={'md'}>
                <ActionIcon className={location.pathname === '/chat' ? menuClass.active : menuClass.icon}>
                  <IconMessageDots size={rem(18)} />
                </ActionIcon>
              </Indicator>
              <Text className={location.pathname === '/chat' ? menuClass.active : menuClass.text}>Message</Text>
            </Anchor>
          </Menu.Target>

          <Menu.Dropdown>
            <Menu.Label fz='sm' fw='bold'>
              Your messages
            </Menu.Label>

            <ScrollContianer onScroll={scrollHandler}>
              {notificationData?.pages.map((page, index) => (
                <React.Fragment key={index}>
                  {!page.notifications.notifications.data.length ? (
                    <>
                      <Text c='dimmed' ta='center' fz='sm'>
                        You have no new messages.
                      </Text>
                      <Menu.Item pt={3} closeMenuOnClick={true}>
                        <Flex justify='center'>
                          <Button
                            mt={3}
                            size='sm'
                            variant='gradient'
                            gradient={{ to: 'orange', from: 'yellow', deg: 160 }}
                            onClick={() => {
                              goToChat();
                            }}>
                            Go To Chat
                          </Button>
                        </Flex>
                      </Menu.Item>
                    </>
                  ) : (
                    <>
                      {page.notifications.notifications.data.map((e: any) => {
                        return (
                          <Menu.Item
                            key={e.notification_Id}
                            closeMenuOnClick={true}
                            sx={(theme) => ({
                              transition: '500ms',
                            })}>
                            <Card
                              withBorder
                              className={cx({
                                [classes.notificationCardRead]: e.read,
                                [classes.notificationCardUnread]: !e.read,
                              })}
                              onClick={() => replyToUser(e)}>
                              <Flex justify='space-between' direction='row' align='center'>
                                <Text c='dimmed'>{e.message}</Text>
                                <Flex direction='column'>
                                  <Tooltip label='Reply' position='left'>
                                    <ActionIcon size={22}>
                                      <IconSend color='#495057' onClick={() => replyToUser(e)} />
                                    </ActionIcon>
                                  </Tooltip>
                                </Flex>
                              </Flex>
                            </Card>
                          </Menu.Item>
                        );
                      })}
                    </>
                  )}
                </React.Fragment>
              ))}
            </ScrollContianer>
          </Menu.Dropdown>
        </Menu>
      )}
    </>
  );
};

export default MessageNotification;
