import { Avatar, Center, Flex, Grid, Group, Paper, Rating, Stack, Text, rem, useMantineTheme } from '@mantine/core';
import dayjs from 'dayjs';
import { profileImageFile } from '../../../../assets';
import { useMediaQuery } from '@mantine/hooks';
import { IconShieldCheckFilled } from '@tabler/icons-react';
import { useCommentCardStyles } from './style';

interface CommentSimpleProps {
  postedAt: string;
  body: string;
  rating: any;
  author: {
    name: string;
    image: string;
  };
  verifiedUser: any;
}

export function CommentCard({ postedAt, body, author, rating, verifiedUser }: CommentSimpleProps) {
  const isMobile = useMediaQuery('(max-width: 576px)');
  const isTablet = useMediaQuery('(max-width: 768px)');
  const theme = useMantineTheme();

  const { classes, cx } = useCommentCardStyles();

  return (
    <>
      <Paper className={cx(classes.container)}>
        <Flex mb='xs' align='center'>
          <Avatar src={author.image} size={isMobile ? 34 : isTablet ? 55 : 70} alt={author.name} radius='xl' />
          <Group position='apart' w='100%'>
            <Flex direction='column' ml='sm' gap={1}>
              <Text size='sm' pl={3}>
                {author.name}
              </Text>
              <Rating
                size={isMobile ? `0.75em` : isTablet ? `0.9em` : `1.1em`}
                fractions={2}
                color={theme.colors.orange[7]}
                value={rating}
                readOnly
              />
              {verifiedUser && (
                <Group spacing={2}>
                  <IconShieldCheckFilled size={rem(18)} />
                  <Text color='dimmed'>Verified user</Text>
                </Group>
              )}
            </Flex>

            <Text fz='sm' color='dimmed'>
              {dayjs(postedAt).format('MMM DD, YYYY')}
            </Text>
          </Group>
        </Flex>
        <Text size='sm'>{body}</Text>
      </Paper>
    </>
  );
}
