import { Badge, BadgeProps, Text } from '@mantine/core';
import dayjs from 'dayjs';
import { useEffect, useState } from 'react';
import { useCookies } from 'react-cookie';
import { customerRole, providerRole } from '../../../../utils';

export const CusBadge = (props: BadgeProps) => {
  return (
    <Badge variant='light' size='sm' w={'fit-content'} {...props}>
      {props.children}
    </Badge>
  );
};

const BookingStatusOld = ({ data, fromModal, paymentData }: any) => {
  const [cookie] = useCookies(['role']);
  const [bookingDateOver, setBookingDateOver] = useState(false);

  // calculating date difference.
  useEffect(() => {
    const date1 = dayjs(dayjs(new Date()).format('MMM DD, YYYY'));
    const date2 = dayjs(dayjs(data?.booking_date).format('MMM DD, YYYY')); //'2023-09-04'
    const duration = date2.diff(date1, 'day');
    if (duration < 0) {
      setBookingDateOver(true);
    } else {
      setBookingDateOver(false);
    }
  }, []);

  // handling service booking status.
  const statusHandler = (status: number | null) => {
    switch (status) {
      case 1:
        return { label: 'Approved', color: 'green' };
      case 0:
        return { label: 'Rejected', color: 'red' };
      default:
        return { label: 'Payment Completed', color: 'green' };
    }
  };

  if (!data) {
    return <Text>Loading...</Text>;
  }

  return (
    <>
      {data?.invoice_status ? (
        <>
          {!data?.canceled && !data?.service_started && !data?.journey_started && (
            <CusBadge color={statusHandler(data?.booking_approved).color}>
              {statusHandler(data?.booking_approved).label}
            </CusBadge>
          )}
          {data?.canceled && (
            <>
              <CusBadge color='red'>Cancelled</CusBadge>
              {fromModal && (
                <>
                  <Text mt={15} mb={5} fw={600} size={16}>
                    Reason:
                  </Text>
                  <Text c='dimmed'>{data?.cancle_remarks || 'N/A'}</Text>
                </>
              )}
            </>
          )}
          {/* // journey start status */}
          {!data?.service_started && !data.completed && !data?.canceled && (
            <>
              {data?.journey_started && !data?.journey_ended && <CusBadge color='green'>Journey Started</CusBadge>}
              {data?.journey_started && data?.journey_ended && !data?.booking_start_request && (
                <CusBadge color='green'>Journey Ended</CusBadge>
              )}
              {data?.journey_started &&
                data?.journey_ended &&
                data?.booking_start_request &&
                !data?.booking_end_request && <CusBadge color='green'>Waiting for Work Start</CusBadge>}
            </>
          )}

          {data?.service_started && !data.completed && !data?.canceled && (
            <>
              {customerRole.includes(+cookie.role) && !data?.booking_end_request && (
                <CusBadge color='green'>In-progress</CusBadge>
              )}
              {providerRole.includes(+cookie.role) && !data?.booking_end_request && (
                <CusBadge color='green'>Service Started</CusBadge>
              )}
              {data?.journey_started &&
                data?.journey_ended &&
                data?.booking_start_request &&
                data?.booking_end_request && <CusBadge color='green'>Waiting for Work End</CusBadge>}
            </>
          )}
          {data?.service_started && data?.service_ended && data?.completed && (
            <>
              {paymentData > 0 ? (
                <>
                  <CusBadge color='green'>Completed</CusBadge>
                  <CusBadge color='green'>Waiting for additional payment</CusBadge>
                </>
              ) : (
                <CusBadge color='green'>Completed</CusBadge>
              )}
            </>
          )}
        </>
      ) : (
        <>
          {bookingDateOver ? (
            <CusBadge color='red'>Booking Expired</CusBadge>
          ) : (
            <CusBadge color='red'>Payment Pending</CusBadge>
          )}
        </>
      )}
    </>
  );
};

export default BookingStatusOld;
