import { Button, Flex, MantineStyleSystemProps, Text } from '@mantine/core';
import { IconArrowRight } from '@tabler/icons-react';
import type { ComponentPropsWithoutRef } from 'react';

interface Props extends MantineStyleSystemProps {
  onClick: ComponentPropsWithoutRef<'button'>['onClick'];
  renderIf: boolean;
}

export default function UrgentBookingButton({ onClick, renderIf, ...rest }: Props) {
  return (
    <>
      {renderIf ? (
        <Button variant='gradient' gradient={{ from: '#fda223', to: '#fe7720' }} radius={4} {...rest} onClick={onClick}>
          <Flex justify='center' align='center' gap={8}>
            <Text>Urgent Booking</Text>
            <IconArrowRight size={18} />
          </Flex>
        </Button>
      ) : null}
    </>
  );
}
