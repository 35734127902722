import { BookingContextProvider } from '../../../../context/booking-context';
import ParentGridContainer from './components/ParentGridContainer';

export default function MyBookingRefactored() {
  return (
    <>
      <BookingContextProvider>
        <ParentGridContainer />
      </BookingContextProvider>
    </>
  );
}
