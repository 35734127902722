import { yupResolver } from '@hookform/resolvers/yup';
import { Anchor, Box, Button, Center, Container, Flex, Paper, Text, TextInput, Title } from '@mantine/core';
import { useMediaQuery } from '@mantine/hooks';
import { useState } from 'react';
import { useCookies } from 'react-cookie';
import { SubmitHandler, useForm } from 'react-hook-form';
import { useNavigate } from 'react-router-dom';
import { forgetAPI } from '../../services/authentication.api';
import { toastMessageUpdate } from '../../utils/helpers/tosterMessage';
import { forgotPasswordSchema } from '../../utils/schema/formSchema';
import { useLoginStyles } from './component/style';
import { InferType } from 'yup';

interface Props {
  close: () => void;
}

export default function ForgotPassword({ close }: any) {
  const isMobile = useMediaQuery('(min-width: 768px)');
  const [cookie, setCookie] = useCookies(['forget-email']);
  const navigate = useNavigate();
  const { classes } = useLoginStyles();

  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm({
    resolver: yupResolver(forgotPasswordSchema),
    mode: 'onChange',
  });

  const [isLoading, setIsLoading] = useState(false);

  const onSubmit: SubmitHandler<InferType<typeof forgotPasswordSchema>> = async (data) => {
    const email = { email: data.email.toLowerCase() };
    // navigate('/verify-otp');
    // return;
    try {
      setIsLoading(true);
      const res = await forgetAPI(email);
      setCookie('forget-email', email.email, {
        path: '/',
      });
      setIsLoading(false);
      navigate('/verify-otp');
      close();
    } catch (error: any) {
      setIsLoading(false);
      toastMessageUpdate('error', error?.response?.data?.message || 'Something went wrong.');
      console.log(error);
    }
  };

  return (
    <>
      <Box>
        <Title order={2} align='center'>
          Forgot password
        </Title>
        <Text c='dimmed' fz='sm' ta='center'>
          Enter your email to get a verification code
        </Text>
        <Paper radius='md' mt='xl'>
          <form onSubmit={handleSubmit(onSubmit)} autoComplete='off' spellCheck='false'>
            <TextInput
              {...register('email', {
                required: true,
              })}
              size='md'
              label='Email'
              placeholder='Enter your email'
              data-autofocus
            />
            {errors?.email && (
              <Text fz={14} pt={5} pl={5} fw={500} color='red'>
                {errors?.email?.message}
              </Text>
            )}
            <Button
              variant='gradient'
              gradient={{ from: '#fda223', to: '#fe7720' }}
              fullWidth
              mt={20}
              size='md'
              loading={isLoading}
              type='submit'
              radius='sm'>
              {!isLoading && 'Submit'}
            </Button>

            <Flex justify='center' mt={10}>
              <Anchor onClick={close} color='dimmed' size='sm' className={classes.control}>
                <Center inline>
                  <Box ml={5}>Back to the login</Box>
                </Center>
              </Anchor>
            </Flex>
          </form>
        </Paper>
      </Box>
    </>
  );
}
