import { Avatar, Flex, Group, Text, UnstyledButton, UnstyledButtonProps, useMantineTheme } from '@mantine/core';
import { upperFirst, useMediaQuery } from '@mantine/hooks';
import { IconCurrencyDollar, IconRoute } from '@tabler/icons-react';
import { HeaderAvatarIcon } from '../layouts/authLayout/component/styles';
import { useRelatedProfileCard } from './style';
import ProfilePhoto from './ProfilePhoto';

interface UserButtonProps extends UnstyledButtonProps {
  image: string;
  role: string;
  location: string;
  experience: string;
  click: () => void;
  rate?: number;
  isVerified: boolean;
}

export function RelatedProfileCard({
  image,
  role,
  location,
  experience,
  click,
  rate,
  isVerified,
  ...others
}: UserButtonProps) {
  const { classes: relatedProfileCardClasses } = useRelatedProfileCard();
  const { classes: AvatarStyle } = HeaderAvatarIcon();
  const theme = useMantineTheme();
  const isMobile = useMediaQuery(`(max-width: ${theme.breakpoints.xs})`);

  return (
    <UnstyledButton className={relatedProfileCardClasses.relatedProfilesCard} {...others} onClick={click} mb={10}>
      <Flex align='center'>
        {/* <Avatar
          classNames={AvatarStyle}
          src={image ? process.env.REACT_APP_IMAGE_URL + 'service_images/' + image : ''}
          radius='xl'
          ml={15}
          size={60}
        /> */}
        <ProfilePhoto
          src={image ? process.env.REACT_APP_IMAGE_URL + 'service_images/' + image : ''}
          type='renderWithoutEdit'
          sizes={{
            xl: 80,
            lg: 80,
            md: 80,
            sm: 100,
            xs: 70,
          }}
          showVerificationBorder={isVerified}
        />
        <Flex direction='column' ml={30} w='100%' gap={5}>
          <Group spacing='lg' pb={isMobile ? 0 : 5} style={{ justifyContent: 'normal' }}>
            <Text className='providerName'>{upperFirst(role)}</Text>
          </Group>
          <Group spacing={2}>
            <IconRoute className='iconRoute' />
            <Text size={isMobile ? 14 : 16} color='dimmed'>
              {parseFloat(location).toFixed(2)} Miles
            </Text>
            <Text size={isMobile ? 12 : 16} color='dimmed'>
              Distance
            </Text>
          </Group>

          <Group spacing={2}>
            <IconCurrencyDollar className='currencyIcon' />
            {rate && <Text className='priceRateText'>{rate}/hr</Text>}
          </Group>
        </Flex>
      </Flex>
    </UnstyledButton>
  );
}
