import { yupResolver } from '@hookform/resolvers/yup';
import { Button, Text, TextInput } from '@mantine/core';
import axios from 'axios';
import { useState } from 'react';
import { useCookies } from 'react-cookie';
import { SubmitHandler, useForm } from 'react-hook-form';
import { useNavigate } from 'react-router-dom';
import { InferType } from 'yup';
import { resendMobileVerifyOTP } from '../../services/authentication.api';
import { toastMessageUpdate } from '../../utils/helpers/tosterMessage';
import { otpResendViaEmailSchema } from '../../utils/schema/formSchema';

interface Props {
  closeMobileNumberInput: () => void;
  valuetype: string;
}

/*
This component will be used when user has signed up but did not verify their mobile number on the next page for whatever reason
*/
export default function ValidateEmail({ closeMobileNumberInput, valuetype }: Props) {
  const [cookie, setCookie] = useCookies(['emailAddress']);
  const [loading, setLoading] = useState(false);
  const navigate = useNavigate();

  const {
    register,
    handleSubmit,
    formState: { errors },
    watch,
  } = useForm({
    resolver: yupResolver(otpResendViaEmailSchema),
    mode: 'all',
    defaultValues: {
      email: '',
    },
  });

  const onSubmit: SubmitHandler<InferType<typeof otpResendViaEmailSchema>> = async (data) => {
    setLoading(true);
    setCookie('emailAddress', data.email, {
      path: '/',
    });

    try {
      const response = await resendMobileVerifyOTP({ email: data.email });
      toastMessageUpdate('success', response.data.message);
      setLoading(false);
      closeMobileNumberInput();
      navigate('/mobile-verification', { state: { type: valuetype } });
    } catch (error: unknown) {
      setLoading(false);
      if (axios.isAxiosError(error)) {
        toastMessageUpdate('error', error.response?.data.message || 'Something went wrong.');
      } else console.log(error);
    }
  };

  return (
    <>
      <form onSubmit={handleSubmit(onSubmit)} autoComplete='off' spellCheck='false'>
        <TextInput
          {...register('email', { required: true })}
          type='email'
          mt={12}
          data-autofocus
          description='Please enter your email address'></TextInput>
        {errors.email && (
          <Text fz={13} pt={5} pl={5} fw={500} color='red'>
            {errors?.email?.message}
          </Text>
        )}
        <Button
          fullWidth
          variant='gradient'
          gradient={{ from: '#fda223', to: '#fe7720' }}
          radius={4}
          mt={16}
          type='submit'
          loading={loading}
          loaderPosition='center'>
          {!loading && 'Submit'}
        </Button>
      </form>
    </>
  );
}
