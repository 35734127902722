import { Button, Card, Flex, Popover, Tabs, Title } from '@mantine/core';
import { useMediaQuery } from '@mantine/hooks';
import { IconArrowNarrowLeft, IconArrowNarrowRight, IconDotsVertical } from '@tabler/icons-react';
import { useState } from 'react';
import { useCookies } from 'react-cookie';
import { StyledTabs } from '../../../../../components';
import { RoleType, customerRole, providerRole } from '../../../../../utils';
import UserOverviewRefactored from '../../../Customer/Profile/components/UserOverviewRefactored';
import Services from '../../services';

import IdentityVerify from './IdentityVerify';
import OrganizationInfo from './OrganizationInfo';
import ProviderOverview from './ProviderOverview';
import TabsList from './TabsList';
import VerificationRefactored from './VerificationRefactored';
import { useUserInfoStyles } from './style';
import AddUser from './AddUser';
import Verifications from './Verifications';

export default function UserInfo() {
  const [activeTab, setActiveTab] = useState<string | null>('overview');
  const [cookies, setCookie] = useCookies(['user_Info', 'role', 'user_Address', 'user_Overview']);
  const isMobile = useMediaQuery('(max-width: 576px)');

  const { classes: userInfoClasses } = useUserInfoStyles();

  const goNextTab = (value: string) => {
    setActiveTab(value);
  };

  const [popoverOpened, setPopoverOpened] = useState(false);

  const selectedTabRenderer = (value: string | null): string | undefined => {
    switch (value?.trim().toLowerCase()) {
      case 'overview':
        return 'Overview';
      case 'identity-verify':
        return 'Identity Verify';
      case 'info':
        return 'Organization Info';
      case 'services':
        return 'Services';
      case 'verifications':
        return 'Verifications';
      case 'user':
        return 'User';
      default:
        undefined;
    }
  };

  const handlePreviousTab = () => {
    switch (activeTab) {
      case 'overview': {
        break;
      }
      case 'identity-verify': {
        setActiveTab('overview');
        break;
      }
      case 'info': {
        setActiveTab('overview');
        break;
      }
      case 'services': {
        if (cookies?.role === '3') setActiveTab('info');
        else setActiveTab('identity-verify');
        break;
      }
      case 'verifications': {
        setActiveTab('services');
        break;
      }
      case 'user': {
        setActiveTab('verifications');
        break;
      }
      default:
        return;
    }
  };

  const handleNextTab = () => {
    switch (activeTab) {
      case 'overview': {
        if (cookies?.role === '3') setActiveTab('info');
        else setActiveTab('identity-verify');
        break;
      }
      case 'identity-verify': {
        setActiveTab('services');
        break;
      }
      case 'info': {
        setActiveTab('services');
        break;
      }
      case 'services': {
        setActiveTab('verifications');
        break;
      }
      case 'verifications': {
        if (cookies?.role === '3') setActiveTab('user');
        break;
      }
      default:
        return;
    }
  };

  return (
    <>
      <StyledTabs
        value={activeTab}
        onTabChange={(currentTab) => {
          setActiveTab(currentTab);
          setPopoverOpened((openState) => !openState);
        }}
        mt='xs'>
        {isMobile ? (
          <>
            <Flex justify='space-between'>
              <Title order={3} ml={8}>
                {selectedTabRenderer(activeTab)}
              </Title>
              <Popover opened={popoverOpened} onChange={setPopoverOpened} withArrow>
                <Popover.Target>
                  <IconDotsVertical onClick={() => setPopoverOpened((openState) => !openState)} />
                </Popover.Target>

                <Popover.Dropdown>
                  <TabsList />
                </Popover.Dropdown>
              </Popover>
            </Flex>
          </>
        ) : (
          <>
            <TabsList />
          </>
        )}

        <Card className={userInfoClasses.containerForUserOrProviderProfileOverview}>
          <Tabs.Panel value='overview' pt='xs'>
            {customerRole.includes(+cookies.role) ? (
              <>
                {/* <UserOverview /> */}
                <UserOverviewRefactored />
              </>
            ) : (
              <ProviderOverview />
            )}
          </Tabs.Panel>

          {RoleType.organization_service_provider !== +cookies?.role && (
            <Tabs.Panel value='identity-verify' pt='xs'>
              <IdentityVerify />
            </Tabs.Panel>
          )}

          {RoleType.organization_service_provider === +cookies?.role && (
            <Tabs.Panel value='info' pt='xs'>
              <OrganizationInfo goNextTab={goNextTab} />
            </Tabs.Panel>
          )}

          {providerRole.includes(+cookies?.role) && (
            <>
              <Tabs.Panel value='services' pt='xs'>
                <Services tabChangeService={activeTab} />
              </Tabs.Panel>

              <Tabs.Panel value='verifications' pt='xs'>
                {/* <Verifications tabChange={activeTab} /> */}
                <VerificationRefactored />
              </Tabs.Panel>
            </>
          )}
          {[RoleType.organization_service_provider].includes(+cookies?.role) && (
            <Tabs.Panel value='user' pt='xs'>
              {activeTab === 'user' && <AddUser />}
            </Tabs.Panel>
          )}
        </Card>

        <Flex justify='center' my={36}>
          <Flex gap={36}>
            <Button
              disabled={cookies.user_Address === 'null' ? true : activeTab === 'overview'}
              variant='gradient'
              gradient={{ from: '#fda223', to: '#fe7720', deg: 270 }}
              sx={{ '&[data-disabled]': { pointerEvents: 'all' } }}
              leftIcon={<IconArrowNarrowLeft />}
              onClick={handlePreviousTab}>
              Prev
            </Button>

            <Button
              disabled={
                cookies.user_Address === 'null'
                  ? true
                  : cookies?.role === '1' || cookies?.role === '4'
                  ? activeTab === 'identity-verify'
                  : cookies?.role === '2'
                  ? activeTab === 'verifications'
                  : activeTab === 'user'
              }
              variant='gradient'
              gradient={{ from: '#fda223', to: '#fe7720', deg: 270 }}
              sx={{ '&[data-disabled]': { pointerEvents: 'all' } }}
              rightIcon={<IconArrowNarrowRight />}
              onClick={handleNextTab}>
              Next
            </Button>
          </Flex>
        </Flex>
      </StyledTabs>
    </>
  );
}
