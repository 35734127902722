export default function getProcessedServiceData(data: any) {
  if (!data) return;

  if (Array.isArray(data)) {
    return data.map((service: any) => {
      return {
        value: service.service_type_id,
        label: service.has_service_type.service,
      };
    });
  }
}
