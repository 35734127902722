import {
  Badge,
  Box,
  Button,
  Divider,
  Flex,
  Grid,
  Group,
  LoadingOverlay,
  Rating,
  ScrollArea,
  Skeleton,
  Stack,
  Text,
  Title,
  useMantineTheme,
} from '@mantine/core';
import { useMediaQuery } from '@mantine/hooks';
import {
  IconBrandWhatsapp,
  IconCircleMinus,
  IconCirclePlus,
  IconMail,
  IconMessage,
  IconMessageChatbot,
  IconPhone,
} from '@tabler/icons-react';
import dayjs from 'dayjs';
import { useContext, useEffect, useState } from 'react';
import { useCookies } from 'react-cookie';
import { NavLink, useNavigate } from 'react-router-dom';
import GoogleMapLive from '../../../../components/GoogleMapLive';
import { ThemeContext } from '../../../../context/themeContext';
import {
  addAdditionalItem,
  additionalBookingItem,
  additionalItem,
  approvaladdAdditionalItem,
  bookingApprove,
  bookingComplete,
  bookingEndJourney,
  bookingEndRequest,
  bookingPaymentStatus,
  bookingStart,
  bookingStartJourney,
  bookingStartRequest,
  getAdditionalBookingItem,
  useBookingDetails,
} from '../../../../services/booking';
import { generateInvoices } from '../../../../services/payment.api';
import { liveLocation, useLiveLocation } from '../../../../services/service';
import { useUserOverview } from '../../../../services/userProfiles';
import { sendNotification } from '../../../../services/websocket.api';
import { customerRole, providerRole } from '../../../../utils';
import { convertTimeFormat, convertUtcTimeToLocalFormat } from '../../../../utils/helpers';
import generateRandomString from '../../../../utils/helpers/generateRandomAlphabetStrings';
import { decodeString } from '../../../../utils/helpers/sendFIle';
import { toastMessageUpdate } from '../../../../utils/helpers/tosterMessage';
import ModalOrDrawer from '../../Customer/Profile/components/ModalOrDrawer';
import { useCancelButtonStyles } from '../../Customer/Profile/components/style';
import BookingStatusOld from './BookingStatusOld';
import CancelModal from './CancelModal';
import RatingReview from './RatingReview';

interface IBookingDetails {
  opened: boolean;
  close: () => void;
  bookingId: string;
  bookingData: Record<string, any> | null;
  fetchData: () => void;
  reviewData: any;
  setIsThereMap: React.Dispatch<React.SetStateAction<boolean>>;
  providerReviewData: any;
  customerReviewData: any;
}

export default function BookingDetails({
  opened,
  close,
  bookingId,
  bookingData,
  fetchData,
  reviewData,
  setIsThereMap,
  providerReviewData,
  customerReviewData,
}: IBookingDetails) {
  const [cookie, setCookie] = useCookies(['role', 'peerId', 'bookingDetails', 'user_Info']);
  const { data, isLoading, isError, isFetching, refetch } = useBookingDetails(bookingId, opened);
  const [cancelModal, setCancelModal] = useState(false);
  const [detailsData, setDetailsData] = useState(null);
  const [currentLatitude, setCurrentLatitude] = useState('');
  const [currentLongitude, setCurrentLongitude] = useState('');

  const { data: userProfile, isLoading: userIsLoading } = useUserOverview(
    (opened && customerRole.includes(+cookie.role) ? data?.data?.service_from : data?.data?.service_to) || '',
  );
  const { setChatType, setChannelId, setChatRecieverId, notificationRecieved } = useContext(ThemeContext);

  const [pathList, setPathList] = useState<[] | any>([]);
  const [successBooking, setSuccessBooking] = useState(false);
  const [loading, setLoading] = useState(false);
  const [bookingDateOver, setBookingDateOver] = useState(false);
  const [refetchMap, setRefetchMap] = useState(false);
  const [paymentAmount, setPaymentAmount] = useState<any>(null);
  const [paymentStatus, setPaymentStatus] = useState(false);
  const {
    data: locationData,
    isLoading: locationLoading,
    isError: locationError,
  }: any = useLiveLocation(bookingId, currentLatitude, currentLongitude, refetchMap && opened);
  const navigate = useNavigate();
  // const { data: additionalItemData, isLoading: additionalItemLoading } = additionalItem('6579b5b47b1a1');

  useEffect(() => {
    if (notificationRecieved?.type === 'service' && opened) {
      refetch();
    }
  }, [notificationRecieved]);

  useEffect(() => {
    const date1 = dayjs(dayjs(new Date()).format('MMM DD, YYYY'));
    const date2 = dayjs(dayjs(bookingData?.booking_date).format('MMM DD, YYYY'));
    const duration = date2.diff(date1, 'day');
    if (duration < 0) {
      setBookingDateOver(true);
    } else {
      setBookingDateOver(false);
    }
    if (bookingData?.journey_started && !bookingData?.journey_ended) {
      setRefetchMap(true);
    }
  }, [opened, bookingData, reviewData, customerReviewData, providerReviewData]);

  useEffect(() => {
    if (data?.data?.service_ended || opened) {
      checkPaymentStatus();
    } else {
      setPaymentStatus(false);
    }

    if (data?.data?.journey_started && !data?.data?.journey_ended) setIsThereMap(true);
    else setIsThereMap(false);
  }, [data]);

  useEffect(() => {
    navigator.geolocation.getCurrentPosition(function (position) {
      setCurrentLatitude(JSON.stringify(position.coords.latitude));
      setCurrentLongitude(JSON.stringify(position.coords.longitude));
    });
  }, []);

  useEffect(() => {
    const timer = setInterval(() => {
      navigator.geolocation.getCurrentPosition(function (position) {
        setCurrentLatitude(JSON.stringify(position.coords.latitude)); // 22.5412
        setCurrentLongitude(JSON.stringify(position.coords.longitude)); // 88.3472
      });
    }, 4000);
    // clearing interval
    return () => clearInterval(timer);
  });

  useEffect(() => {
    if (currentLatitude && currentLongitude) {
      fetchLiveLocation();
    }
  }, [successBooking]);

  const bookingApproval = async (status: any) => {
    setLoading(true);
    const bookingApproveParams = {
      booking_id: bookingId,
      approval_status: status,
    };
    if (status) {
      try {
        await bookingApprove(bookingApproveParams);
        serviceNotification(bookingData?.service_to, `Your service accepted by ${cookie?.user_Info?.name}`);
        fetchData();
        refetch();
        toastMessageUpdate('success', 'Booking approved successfully.');
        setLoading(false);
        // close();
      } catch (error) {
        setLoading(false);
        console.log(error);
      }
    } else {
      try {
        await bookingApprove(bookingApproveParams);
        serviceNotification(bookingData?.service_to, `Your booking service rejected by ${cookie?.user_Info?.name}`);
        fetchData();
        refetch();
        toastMessageUpdate('success', 'Booking rejected successfully.');
        setLoading(false);
        // close();
      } catch (error) {
        setLoading(false);
        console.log(error);
      }
    }
  };

  const startProviderJourney = async () => {
    setLoading(true);
    const params = {
      booking_id: bookingId,
    };
    try {
      const startJourney = await bookingStartJourney(params);
      console.log('start j', startJourney);
      setRefetchMap(true);
      toastMessageUpdate('success', 'Journey started successfully.');
      serviceNotification(
        bookingData?.service_to,
        `Your booked service journey started by ${cookie?.user_Info?.name}.`,
      );
      fetchData();
      setSuccessBooking(true);
      refetch();
      setLoading(false);
    } catch (error) {
      console.log(error);
      setLoading(false);
    }
  };

  const endProviderJourney = async () => {
    setLoading(true);
    const params = {
      booking_id: bookingId,
    };
    try {
      const endJourney = await bookingEndJourney(params);
      setRefetchMap(false);
      toastMessageUpdate('success', 'Journey ended successfully.');
      serviceNotification(bookingData?.service_to, `Your booked service journey ended by ${cookie?.user_Info?.name}.`);
      console.log('end j', endJourney);
      fetchData();
      setSuccessBooking(true);
      refetch();
      setLoading(false);
    } catch (error) {
      console.log(error);
      setLoading(false);
    }
  };

  const startBookingRequest = async () => {
    setLoading(true);
    const params = {
      booking_id: bookingId,
    };
    console.log(params);

    // return;
    try {
      await bookingStartRequest(params);
      toastMessageUpdate('success', 'Service start request sent successfully.');
      serviceNotification(bookingData?.service_from, `Booked service start request by ${cookie?.user_Info?.name}.`);
      fetchData();
      refetch();
      setSuccessBooking(true);
      setLoading(false);
    } catch (error) {
      console.log(error);
      setLoading(false);
    }
  };

  const endBookingRequest = async () => {
    console.log('end Working');
    setLoading(true);
    const params = {
      booking_id: bookingId,
    };
    try {
      await bookingEndRequest(params);
      toastMessageUpdate('success', 'Service end request sent successfully.');
      serviceNotification(bookingData?.service_from, `Booked service end request by ${cookie?.user_Info?.name}.`);
      fetchData();
      refetch();
      setSuccessBooking(true);
      setLoading(false);
    } catch (error) {
      console.log(error);
      setLoading(false);
    }
  };

  const startBooking = async () => {
    setLoading(true);
    const params = {
      booking_id: bookingId,
      approval_status: true,
    };
    try {
      await bookingStart(params);
      toastMessageUpdate('success', 'Service started successfully.');
      serviceNotification(bookingData?.service_to, `Your booked service started by ${cookie?.user_Info?.name}.`);
      fetchData();
      setSuccessBooking(true);
      refetch();
      setLoading(false);
    } catch (error) {
      console.log(error);
      setLoading(false);
    }
  };

  const fetchLiveLocation = async () => {
    const params = {
      booking_id: bookingId,
      latitude: currentLatitude,
      longitude: currentLongitude,
    };

    // console.log('params', params);

    try {
      const locationData = await liveLocation(params);
      const decodeArray = decodeString(locationData?.data?.route, '1e5');
      setPathList(decodeArray);
    } catch (error: any) {
      console.log('location err=>', error);
    }
  };

  const completeBooking = async () => {
    setLoading(true);
    const params = {
      booking_id: bookingId,
      approval_status: true,
    };
    try {
      await bookingComplete(params);
      fetchData();
      toastMessageUpdate('success', 'Service completed successfully.');
      serviceNotification(bookingData?.service_to, `Your booked service completed by ${cookie?.user_Info?.name}.`);
      checkPaymentStatus();
      refetch();
      close();
      setLoading(false);
    } catch (error) {
      console.log(error);
      setLoading(false);
    }
  };

  const checkPaymentStatus = async () => {
    const params = {
      booking_id: bookingId,
    };
    try {
      const paymentData = await bookingPaymentStatus(params);
      console.log(paymentData);
      setPaymentAmount(paymentData?.data?.total_amount);
    } catch (error) {
      console.log(error);
      setLoading(false);
    }
  };

  const additionalPaymentHandler = async () => {
    setLoading(true);
    const paymentParams = {
      currency: '$',
      services: '',
      booking_id: bookingData?.booking_id,
      service_id: bookingData?.service_id,
      service_type_id: bookingData?.service_type_id,
      slot_id: bookingData?.slot_id,
      remarks: 'Additional Payment',
      additional_payment: true,
      additional_payment_amount: paymentAmount,
    };
    try {
      const paymentRes: any = await generateInvoices(paymentParams);
      refetch();
      close();
      setPaymentAmount(0);
      toastMessageUpdate('success', 'Additional payment submitted successfully.');
      setLoading(false);
    } catch (error) {
      toastMessageUpdate('error');
      setLoading(false);
    }
  };

  const sendData = () => {
    if (customerRole.includes(cookie?.user_Info?.type)) {
      // setChatRecieverId(+bookingData?.service_to);
      setChatRecieverId(+bookingData?.service_from);
    } else {
      // setChatRecieverId(+bookingData?.service_from);
      setChatRecieverId(+bookingData?.service_to);
    }
    setCookie('peerId', bookingData?.service_from + bookingData?.service_to, { path: '/' });
    setCookie('bookingDetails', bookingData, { path: '/' });
    // setChannelId(bookingData?.booking_id);
    setChannelId(bookingData?.service_from + bookingData?.service_to);
    setChatType('bookingChat');
    navigate('/chat');
  };

  const { classes: CancelButtonStyle } = useCancelButtonStyles();

  const [timeDifference, setTimeDifference] = useState<number>(0);

  useEffect(() => {
    const interval = setInterval(() => {
      if (data?.data?.service_started) {
        setTimeDifference(
          new Date(new Date().toLocaleString('en-US', { timeZone: 'UTC' })).getTime() -
            new Date(data?.data?.service_started).getTime(),
        );
      } else setTimeDifference(0);
    }, 1000);

    return () => clearInterval(interval);
  }, [new Date().getSeconds()]);

  const formatElapsedTime = (seconds: number): string => {
    const date = new Date('1970-01-01T00:00:00.000Z');
    date.setSeconds(seconds / 1000);
    return date.toISOString().slice(11, 19);
  };

  const serviceNotification = async (recieverId: any, notificationMsg: string) => {
    const notificationParam = {
      to_user: recieverId,
      message: notificationMsg,
      type: 'service',
    };
    await sendNotification(notificationParam);
  };

  const theme = useMantineTheme();
  const isTablet = useMediaQuery(`(max-width: ${theme.breakpoints.sm})`);

  const [encodedIdWithString, setEncodedIdWithString] = useState('');
  useEffect(() => {
    if (customerRole.includes(+cookie.role))
      setEncodedIdWithString(btoa(generateRandomString(16) + data?.data?.service_from + generateRandomString(16)));
    else setEncodedIdWithString(btoa(generateRandomString(16) + data?.data?.service_to + generateRandomString(16)));
  }, [data]);

  const [addItem, setAddItem] = useState([]);
  const getAdditionalData = async () => {
    try {
      const addData: any = await additionalBookingItem(bookingData?.service_id);
      console.log(addData);
      if (addData?.success) {
        setAddItem(addData.data);
      }
    } catch (error) {
      console.log(error);
    }
  };
  useEffect(() => {
    if (customerRole.includes(+cookie.role)) {
      additonalItemforUser();
    } else {
      getAdditionalData();
    }
  }, [bookingData?.service_id]);

  const addItemForUser = async (itemId: string) => {
    const formData = {
      booking_id: bookingId,
      item_id: itemId,
    };
    try {
      const addData: any = await addAdditionalItem(formData);
      console.log(addData);
    } catch (error) {
      console.log(error);
    }
  };

  const additonalItemforUser = async () => {
    try {
      const addData: any = await getAdditionalBookingItem(bookingId);
      console.log(addData);
      if (addData?.success) {
        setAddItem(addData.data);
      }
    } catch (error) {
      console.log(error);
    }
  };

  const additionalItemBookingApproval = async (itemId: string, approvalData: number) => {
    const formData = {
      booking_id: bookingId,
      item_id: itemId,
      approval_status: approvalData,
    };
    try {
      const addData: any = await approvaladdAdditionalItem(formData);
      console.log(addData);
    } catch (error) {
      console.log(error);
    }
  };

  return (
    <>
      <LoadingOverlay visible={loading} />
      <Grid m={5}>
        <Grid.Col
          pt={0}
          lg={
            (data?.data?.journey_started && !data.data.journey_ended) ||
            (data?.data?.booking_approved === 1 && !data.data.journey_started) ||
            (data?.data?.booking_approved === 1 && data.data?.journey_ended && !data.data?.service_ended)
              ? 4
              : 12
          }
          md={
            (data?.data?.journey_started && !data.data.journey_ended) ||
            (data?.data?.booking_approved === 1 && !data.data.journey_started) ||
            (data?.data?.booking_approved === 1 && data.data?.journey_ended && !data.data?.service_ended)
              ? 5
              : 12
          }
          sm={
            (data?.data?.journey_started && !data.data.journey_ended) ||
            (data?.data?.booking_approved === 1 && !data.data.journey_started) ||
            (data?.data?.booking_approved === 1 && data.data?.journey_ended && !data.data?.service_ended)
              ? 6
              : 12
          }>
          <Title order={isTablet ? 4 : 3} mb={isTablet ? 0 : 70} ta={isTablet ? 'center' : 'initial'}>
            Booking details
          </Title>
          <Divider my={10} display={isTablet ? 'block' : 'none'} />
          <ScrollArea.Autosize
            type='never'
            mah={isTablet ? '100%' : `calc(100svh - 270px)`}
            offsetScrollbars
            scrollbarSize={2}
            w={'100%'}>
            <Stack spacing={20}>
              <Box>
                <Text mb={5} fw={600} size={16}>
                  Service Type:
                </Text>
                <NavLink
                  to={`/freelancer-list/${
                    bookingData?.service_type?.service_type_id
                  }?service=${bookingData?.service_type?.service?.toLowerCase()}`}>
                  <Text c='dimmed'>{bookingData?.service_type?.service}</Text>
                </NavLink>
              </Box>
              <Box>
                <Text mb={5} fw={600} size={16}>
                  Time Slot:
                </Text>
                <Text c='dimmed'>
                  {convertTimeFormat(bookingData?.slot?.time_from)} - {convertTimeFormat(bookingData?.slot?.time_to)},{' '}
                  {dayjs(bookingData?.booking_date).format('MMM DD, YYYY')}
                </Text>
              </Box>
              <Box>
                <Text mb={5} fw={600} size={16}>
                  Service {+cookie.role === 1 ? 'Provider' : 'Consumer'}:
                </Text>
                {userIsLoading ? (
                  <Skeleton visible={true} h={15} />
                ) : (
                  <>
                    {/* {customerRole.includes(+cookie.role) ? (
                      <NavLink to={`/user/${encodedIdWithString}`}>
                        <Text c='dimmed'>{userProfile?.data?.name}</Text>{' '}
                      </NavLink>
                    ) : (
                      <NavLink to={`/customer/${encodedIdWithString}`}>
                        <Text c='dimmed'>{userProfile?.data?.name || 'No user found'}</Text>
                      </NavLink>
                    )} */}

                    <NavLink to={`/${customerRole.includes(+cookie.role) ? 'prov' : 'cust'}/${encodedIdWithString}`}>
                      <Text c='dimmed'>{userProfile?.data?.name}</Text>
                    </NavLink>
                  </>
                )}
                {/* <NavLink to={`/user/${+cookie.role === 1 ? data?.data?.service_from : data?.data?.service_to}`}>
              <Text c='dimmed'>{userProfile?.data?.name}</Text>{' '}
            </NavLink> */}
              </Box>
              {data?.data?.invoice_status && (
                <Box component={Flex} direction='column' align='start'>
                  <Text mb={5} fw={600} size={16}>
                    Contact info:
                  </Text>
                  {userIsLoading ? (
                    <Skeleton visible={true} h={15} />
                  ) : (
                    <>
                      {userProfile?.data ? (
                        <>
                          <Button
                            pl={0}
                            component='a'
                            href={`tel:${userProfile?.data?.user?.mobile}`}
                            compact
                            variant='subtle'
                            leftIcon={<IconPhone />}>
                            {userProfile?.data?.user?.mobile}
                          </Button>
                          <Button
                            target='_blank'
                            pl={0}
                            mt={7}
                            component='a'
                            href={`mailto:${userProfile?.data?.user?.email}`}
                            compact
                            variant='subtle'
                            leftIcon={<IconMail />}>
                            {userProfile?.data?.user?.email}
                          </Button>
                          <Button
                            pl={0}
                            mt={7}
                            component='a'
                            href={`sms:${userProfile?.data?.user?.mobile}`}
                            compact
                            variant='subtle'
                            leftIcon={<IconMessage />}>
                            Send SMS
                          </Button>
                          <Button
                            pl={0}
                            mt={7}
                            compact
                            variant='subtle'
                            onClick={sendData}
                            leftIcon={<IconMessageChatbot />}>
                            Chat online
                          </Button>
                          <Button
                            target='_blank'
                            pl={0}
                            mt={7}
                            component='a'
                            href={`https://wa.me/${userProfile?.data?.user?.mobile}`}
                            compact
                            variant='subtle'
                            leftIcon={<IconBrandWhatsapp />}>
                            Contact on WhatsApp
                          </Button>
                        </>
                      ) : (
                        <Text c='dimmed'>{'No contact details found'}</Text>
                      )}
                    </>
                  )}
                </Box>
              )}
              <Box>
                <Text mb={5} fw={600} size={16}>
                  Booking Location:
                </Text>
                {userIsLoading ? (
                  <Skeleton visible={true} h={15} />
                ) : (
                  <Text c='dimmed'>
                    {userProfile?.data ? userProfile?.data?.['has_address']?.['address'] : 'No location found'}
                  </Text>
                )}
              </Box>
              <Box>
                <Text mb={10} fw={600} size={16}>
                  Status:
                </Text>
                {!isLoading && <BookingStatusOld paymentData={paymentAmount} data={data?.data} fromModal={true} />}

                {/* in-progress  */}
                {data?.data?.service_started && !data?.data?.completed && !data?.data?.canceled && (
                  <>
                    {!data?.data?.booking_end_request && (
                      <>
                        <Badge>Started at: {convertUtcTimeToLocalFormat(data?.data?.service_started)}</Badge>
                        <Badge>Time elapsed: {formatElapsedTime(timeDifference)}</Badge>
                      </>
                    )}
                  </>
                )}

                {data?.data?.service_started && data?.data?.completed && !data?.data?.canceled && (
                  <>
                    <Badge>Ended at: {convertUtcTimeToLocalFormat(data?.data?.service_ended)}</Badge>
                    {/* {console.log(data?.data?.service_ended)}
                    {console.log(data?.data?.service_started)} */}
                    <Badge>
                      Worked for:{' '}
                      {formatElapsedTime(
                        new Date(data?.data?.service_ended).getTime() - new Date(data?.data?.service_started).getTime(),
                      )}
                    </Badge>
                  </>
                )}
              </Box>
            </Stack>
            {customerRole.includes(+cookie.role) && !bookingDateOver && (
              <>
                {data?.data?.invoice_status ? (
                  <>
                    <Box my='xl' mt='xl'>
                      {/* !data.data.completed && !data?.data?.canceled && !data?.data?.service_started */}
                      {!data?.data?.journey_started && !data?.data.canceled && (
                        <Button
                          radius={4}
                          fullWidth
                          variant='light'
                          className={CancelButtonStyle.root}
                          mt='xl'
                          onClick={() => {
                            setCancelModal(true);
                          }}>
                          Cancel
                        </Button>
                      )}

                      {data?.data?.booking_approved && !data?.data?.canceled && !data?.data?.service_started ? (
                        <>
                          {!data?.data?.booking_start_request &&
                            data?.data?.journey_started &&
                            data?.data?.journey_ended && (
                              <>
                                <Button
                                  radius={4}
                                  variant='gradient'
                                  fullWidth
                                  gradient={{ to: '#fda223', from: '#fe7720', deg: 270 }}
                                  onClick={startBookingRequest}>
                                  Start Service
                                </Button>
                              </>
                            )}
                        </>
                      ) : (
                        <>{''}</>
                      )}

                      {/* {data?.data?.service_started && !data.data.completed && data?.data?.invoice_status && ( */}
                      {data?.data?.booking_start_request &&
                        data?.data?.start_request_by &&
                        data?.data?.service_started &&
                        !data?.data?.booking_end_request && (
                          <>
                            <Button
                              radius={4}
                              variant='gradient'
                              fullWidth
                              gradient={{ to: '#fda223', from: '#fe7720', deg: 270 }}
                              onClick={endBookingRequest}>
                              End Service
                            </Button>
                          </>
                        )}

                      {data?.data?.service_ended && paymentAmount > 0 && (
                        <>
                          <Button
                            radius={4}
                            variant='gradient'
                            fullWidth
                            gradient={{ to: '#fda223', from: '#fe7720', deg: 270 }}
                            onClick={additionalPaymentHandler}>
                            Additional Payment ${Number(paymentAmount).toFixed(2)}
                          </Button>
                        </>
                      )}
                    </Box>
                  </>
                ) : (
                  !isFetching && (
                    <Button
                      radius={4}
                      variant='gradient'
                      fullWidth
                      gradient={{ to: '#fda223', from: '#fe7720', deg: 270 }}
                      mt='xl'
                      component={NavLink}
                      to={`/confirm-booking?id=${data?.data?.booking_id}`}>
                      Pay
                    </Button>
                  )
                )}
              </>
            )}
            {providerRole.includes(+cookie.role) && data?.data?.invoice_status && !bookingDateOver && (
              <Group spacing={10} my='xl' mt='xl'>
                {data?.data?.booking_approved === null && !data?.data?.canceled && (
                  <>
                    <Button
                      radius={4}
                      variant='gradient'
                      fullWidth
                      gradient={{ to: '#fda223', from: '#fe7720', deg: 270 }}
                      onClick={() => {
                        bookingApproval(1);
                      }}>
                      Accept
                    </Button>
                    <Button
                      radius={4}
                      fullWidth
                      variant='light'
                      className={CancelButtonStyle.root}
                      onClick={() => {
                        bookingApproval(0);
                      }}>
                      Reject
                    </Button>
                  </>
                )}
                {data.data.booking_approved && !data?.data?.canceled && !data?.data?.service_started && (
                  <>
                    {/* <Button
                      radius='xl'
                      color='red'
                      onClick={() => {
                        setCancelModal(true);
                      }}>
                      Cancel
                    </Button> */}
                    {!data?.data?.journey_started && !data.data.journey_ended && (
                      <Button
                        radius={4}
                        variant='gradient'
                        fullWidth
                        gradient={{ to: '#fda223', from: '#fe7720', deg: 270 }}
                        onClick={() => {
                          startProviderJourney();
                          setTimeout(() => {
                            setIsThereMap(true);
                          }, 500);
                        }}>
                        Start Journey
                      </Button>
                    )}
                    {data?.data?.journey_started && !data.data.journey_ended && (
                      <Button
                        radius={4}
                        variant='gradient'
                        fullWidth
                        gradient={{ to: '#fda223', from: '#fe7720', deg: 270 }}
                        onClick={() => {
                          endProviderJourney();
                          setTimeout(() => {
                            setIsThereMap(false);
                          }, 500);
                        }}>
                        End Journey
                      </Button>
                    )}
                  </>
                )}

                {data?.data?.booking_start_request && data?.data?.start_request_by && !data?.data?.service_started && (
                  <Button
                    radius={4}
                    variant='gradient'
                    fullWidth
                    gradient={{ to: '#fda223', from: '#fe7720', deg: 270 }}
                    onClick={() => {
                      startBooking();
                    }}>
                    Confirm Start Service
                  </Button>
                )}

                {data?.data?.booking_start_request &&
                  data?.data?.start_request_by &&
                  data?.data?.service_started &&
                  data?.data?.booking_end_request &&
                  !data?.data?.completed && (
                    <>
                      <Button
                        radius={4}
                        variant='gradient'
                        fullWidth
                        gradient={{ to: '#fda223', from: '#fe7720', deg: 270 }}
                        onClick={completeBooking}>
                        End
                      </Button>
                    </>
                  )}
              </Group>
            )}
            {(customerRole.includes(+cookie.role) || providerRole.includes(+cookie.role)) &&
              !data?.data?.invoice_status &&
              bookingDateOver && (
                <>
                  <Text c='dimmed' pt='sm'>
                    * Booking date has expired from the current date.
                  </Text>
                </>
              )}
            {/* {customerRole.includes(+cookie.role) ? (    */}

            {customerRole.includes(+cookie.role) ? (
              <>
                {data?.data?.completed &&
                  data?.data?.invoice_status &&
                  (reviewData?.filter((item: any) => item?.booking_id === bookingId)?.length === 0 ||
                    providerReviewData?.filter((item: any) => item?.booking_id === bookingId)?.length === 0) && (
                    <RatingReview
                      serviceProviderId={data?.data?.service_from}
                      userId={data?.data?.service_to}
                      reviewId={data?.data?.review_id}
                      close={close}
                      bookingId={bookingId}
                      reviewData={reviewData}
                      providerReviewData={providerReviewData}
                    />
                  )}

                {data?.data?.completed &&
                data?.data?.invoice_status &&
                reviewData?.filter((item: any) => item?.booking_id === bookingId)?.length ? (
                  <Box pt={10}>
                    <Text mb={10} fw={600} size={16}>
                      Rating & Review by you:
                    </Text>
                    <Text mb={10} fw={600} size={14}>
                      Service Rating:
                    </Text>
                    <Rating
                      size='md'
                      readOnly
                      value={+reviewData?.filter((item: any) => item?.booking_id === bookingId)[0]?.rating}
                    />
                    <Text my={10} fw={600} size={14}>
                      Service Review:
                    </Text>
                    <Text mb={10} fw={500} size={14} color='dimmed'>
                      {reviewData?.filter((item: any) => item?.booking_id === bookingId)[0]?.review}
                    </Text>
                  </Box>
                ) : null}

                {data?.data?.completed &&
                data?.data?.invoice_status &&
                providerReviewData?.filter((item: any) => item?.booking_id === bookingId)?.length ? (
                  <Box>
                    <Text mb={10} fw={600} size={14}>
                      Provider Rating:
                    </Text>
                    <Rating
                      size='md'
                      readOnly
                      value={+providerReviewData?.filter((item: any) => item?.booking_id === bookingId)[0]?.rating}
                    />
                    <Text mb={10} fw={600} size={14}>
                      Provider Review:
                    </Text>
                    <Text mb={10} fw={500} size={14} color='dimmed'>
                      {providerReviewData?.filter((item: any) => item?.booking_id === bookingId)[0]?.review}
                    </Text>
                  </Box>
                ) : null}

                {data?.data?.completed &&
                data?.data?.invoice_status &&
                customerReviewData?.filter((item: any) => item?.booking_id === bookingId)?.length ? (
                  <Box pt={10}>
                    <Text mb={10} fw={600} size={16}>
                      Rating & Review by Provider:
                    </Text>
                    <Text mb={10} fw={600} size={14}>
                      Customer Rating:
                    </Text>
                    <Rating
                      size='md'
                      readOnly
                      value={+customerReviewData?.filter((item: any) => item?.booking_id === bookingId)[0]?.rating}
                    />
                    <Text mb={10} fw={600} size={14}>
                      Customer Review:
                    </Text>
                    <Text mb={10} fw={500} size={14} color='dimmed'>
                      {customerReviewData?.filter((item: any) => item?.booking_id === bookingId)[0]?.review}
                    </Text>
                  </Box>
                ) : null}
              </>
            ) : (
              <>
                {data?.data?.completed &&
                  data?.data?.invoice_status &&
                  providerReviewData?.filter((item: any) => item?.booking_id === bookingId)?.length === 0 && (
                    <RatingReview
                      serviceProviderId={data?.data?.service_from}
                      userId={data?.data?.service_to}
                      reviewId={data?.data?.review_id}
                      close={close}
                      bookingId={bookingId}
                      // reviewData={reviewData}
                      providerReviewData={providerReviewData}
                    />
                  )}

                {data?.data?.completed &&
                data?.data?.invoice_status &&
                providerReviewData?.filter((item: any) => item?.booking_id === bookingId)?.length ? (
                  <Box>
                    <Divider my={10}></Divider>
                    <Text my={10} fw={600} fz={20}>
                      Rating & Review by You:
                    </Text>
                    <Text mb={10} fw={600} size={14}>
                      Customer Rating:
                    </Text>
                    <Rating
                      size='md'
                      readOnly
                      value={+providerReviewData?.filter((item: any) => item?.booking_id === bookingId)[0]?.rating}
                    />
                    <Text my={10} fw={600} size={14}>
                      Customer Review:
                    </Text>
                    <Text mb={10} fw={500} size={14} color='dimmed'>
                      {providerReviewData?.filter((item: any) => item?.booking_id === bookingId)[0]?.review}
                    </Text>
                  </Box>
                ) : null}

                {data?.data?.completed &&
                data?.data?.invoice_status &&
                reviewData?.filter((item: any) => item?.booking_id === bookingId)?.length ? (
                  <Box>
                    <Divider my={10}></Divider>
                    <Text my={10} fw={600} fz={20}>
                      Rating & Review by Customer:
                    </Text>
                    <Text mb={10} fw={600} size={14}>
                      Service Rating:
                    </Text>
                    <Rating
                      size='md'
                      readOnly
                      value={+reviewData?.filter((item: any) => item?.booking_id === bookingId)[0]?.rating}
                    />
                    <Text my={10} fw={600} size={14}>
                      Service Review:
                    </Text>
                    <Text mb={10} fw={500} size={14} color='dimmed'>
                      {reviewData?.filter((item: any) => item?.booking_id === bookingId)[0]?.review}
                    </Text>
                  </Box>
                ) : null}

                {data?.data?.completed &&
                data?.data?.invoice_status &&
                customerReviewData?.filter((item: any) => item?.booking_id === bookingId)?.length ? (
                  <Box>
                    <Text mb={10} fw={600} size={14}>
                      Provider Rating:
                    </Text>
                    <Rating
                      size='md'
                      readOnly
                      value={+customerReviewData?.filter((item: any) => item?.booking_id === bookingId)[0]?.rating}
                    />
                    <Text my={10} fw={600} size={14}>
                      Provider Review:
                    </Text>
                    <Text mb={10} fw={500} size={14} color='dimmed'>
                      {customerReviewData?.filter((item: any) => item?.booking_id === bookingId)[0]?.review}
                    </Text>
                  </Box>
                ) : null}
              </>
            )}
          </ScrollArea.Autosize>
        </Grid.Col>

        {/* {data?.data?.booking_approved && !data.data.journey_ended && (
          <Grid.Col md={7} pt={0}>
            <Title order={3}>Add Items</Title>
            <Box>
              <Grid gutter={0} p={0} m={0} mt={32}>
                {addItem.length > 0 ? (
                  addItem.map((item: any, index) => {
                    console.log(item);
                    return (
                      <Grid.Col p={0} m={0} md={4} style={{ width: '100%' }} key={item?.item_id}>
                        <Box
                          style={{
                            backgroundColor: '#ececec',
                            padding: 4,
                            margin: 8,
                            border: '1px solid #c1c1c1',
                            borderRadius: 4,
                            boxShadow:
                              '1px 0px 1px 0px rgba(0,0,0,0.1),-1px 0px 1px 0px rgba(0,0,0,0.1),0px 1px 1px 0px rgba(0,0,0,0.1),0px -1px 1px 0px rgba(0,0,0,0.1)',
                          }}>
                          <Text ta='center'>{item?.item_name}</Text>
                          {customerRole.includes(+cookie.role) ? (
                            <Flex gap={4} mt={4} p={4}>
                              <Button
                                compact
                                radius={4}
                                w={'100%'}
                                variant='gradient'
                                gradient={{ from: '#fda223', to: '#fe7720' }}
                                onClick={() => addItemForUser(item?.item_id)}>
                                <IconCirclePlus size={16} />
                              </Button>
                              <Button
                                compact
                                radius={4}
                                w={'100%'}
                                variant='gradient'
                                gradient={{ from: '#fda223', to: '#fe7720' }}>
                                <IconCircleMinus size={16} />
                              </Button>
                            </Flex>
                          ) : (
                            <Button
                              compact
                              radius={4}
                              w={'100%'}
                              variant='gradient'
                              gradient={{ from: '#fda223', to: '#fe7720' }}
                              onClick={() => additionalItemBookingApproval(item?.item_id, 1)}>
                              <IconCirclePlus size={16} />
                            </Button>
                          )}
                        </Box>
                      </Grid.Col>
                    );
                  })
                ) : (
                  <>
                    <h1>Hello</h1>
                  </>
                )}
              </Grid>
            </Box>
          </Grid.Col>
        )} */}

        {data?.data?.journey_started && !data.data.journey_ended && (
          <Grid.Col
            mah={`calc(100vh - 170px)`}
            lg={data?.data?.journey_started && !data.data.journey_ended ? 8 : 12}
            md={data?.data?.journey_started && !data.data.journey_ended ? 7 : 12}
            sm={6}>
            {data?.data?.journey_started && !data?.data?.journey_ended && (
              <>
                <Text pb={4} fz={14}>
                  Service provider will arrive within 30 mins*
                </Text>
                <Text fz={13} color='dimmed' mb={10}>
                  Service provider arrival depends upon multiple factors hence we can&apos;t guarantee but we will try
                  our best. AidBy is not responsible for any late arrival.
                </Text>
                <Box w={'100%'} style={{ overflow: 'hidden' }}>
                  <GoogleMapLive
                    currentLat={currentLatitude}
                    currentLng={currentLongitude}
                    bookingId={bookingId}
                    decodedPath={pathList}
                  />
                </Box>
              </>
            )}
          </Grid.Col>
        )}
      </Grid>

      <ModalOrDrawer
        opened={cancelModal}
        onClose={() => setCancelModal(false)}
        drawerProps={{
          dz: 'sm',
          drawerBackButton: true,
          bs: '0px -4px 10px 0px rgba(0,0,0,0.1)',
          drawerOverlayProps: { opacity: 0, blur: 0 },
        }}
        modalProps={{ modalCloseButton: true }}>
        <CancelModal
          refetch={fetchData}
          opened={cancelModal}
          bookingId={bookingId}
          bookingData={bookingData}
          close={() => {
            setCancelModal(false);
            // close();
          }}
        />
      </ModalOrDrawer>
    </>
  );
}
