import {
  ActionIcon,
  Avatar,
  Box,
  Button,
  Card,
  Divider,
  Flex,
  Grid,
  Group,
  LoadingOverlay,
  Paper,
  Stack,
  Text,
  Textarea,
  Title,
  useMantineTheme,
} from '@mantine/core';
import { useDisclosure, useMediaQuery } from '@mantine/hooks';
import { IconSend, IconVideo } from '@tabler/icons-react';
import dayjs from 'dayjs';
import { useContext, useEffect, useRef, useState } from 'react';
import 'react-chat-elements/dist/main.css';
import { useCookies } from 'react-cookie';
import { useForm } from 'react-hook-form';
import { useNavigate } from 'react-router-dom';
import { ThemeContext } from '../../../context/themeContext';
import {
  HeaderAvatarIcon,
  UserListScrollContianer,
  UserListScrollContianerMobile,
} from '../../../layouts/authLayout/component/styles';
import { useConversationList } from '../../../services/chat.api';
import { sendNotification } from '../../../services/websocket.api';
import { customerRole, providerRole } from '../../../utils';
import useAgoraRtm from '../../../utils/hooks/useAgoraRtm';
import JoinVideoCall from '../MyBooking/components/JoinVideoModal';
import WebVideo from '../WebVideo';
import { MessageListScroll, useAvatarStyles, useChatCardStyles, useChatStyles, useScrollStyles } from './style';
import { VideoContext, useVideoInfo } from './videoContext';
import ModalOrDrawer from '../Customer/Profile/components/ModalOrDrawer';

const Chat = () => {
  const {
    register,
    handleSubmit,
    reset,
    getValues,
    formState: { errors },
  } = useForm({
    mode: 'all',
  });

  const { classes: chatClass } = useChatStyles();
  const { classes: avatarClass } = useAvatarStyles();
  const { classes: scrollbarStyles } = useScrollStyles();
  const { classes: chatCardStyles } = useChatCardStyles();
  const [cookies, setCookie, removeCookie] = useCookies(['user_Info', 'user_Overview', 'bookingDetails', 'role']);

  const {
    messages,
    sendChannelMessage,
    loading,
    chatUserData,
    getPreviousChat,
    chatSave,
    fetchNextMessage,
    isMessageFetching,
    isMessageLoading,
    hasMessageNextPage,
    isMessageRefetchError,
    userFetchLoading,
    messageRefetch,
    setConversationId,
    currentMessage,
  } = useAgoraRtm(cookies?.user_Info?.name); // client as RtmClient
  const { classes: AvatarStyle } = HeaderAvatarIcon();
  const navigate = useNavigate();
  const [showVideoCall, setShowVideoCall] = useState(false);
  const [opened, { open, close }] = useDisclosure(false);
  const viewport = useRef<HTMLDivElement>(null);

  const isMobile = useMediaQuery('(max-width: 576px)');
  const isTablet = useMediaQuery('(max-width: 768px)');
  const theme = useMantineTheme();
  const {
    setChatType,
    setChannelId,
    setChatRecieverId,
    channelId,
    chatRecieverId,
    chatType,
    videoStart,
    setVideoStart,
    setSelectedConversationId,
  } = useContext(ThemeContext);

  const [currentSelectedIndex, setCurrentSelectedIndex] = useState<any>();
  const [conversationList, setConversationList] = useState<any>();
  const [selectedChatUser, setSelectedChatUser] = useState<any>();
  const [userLoading, setUserLoading] = useState<any>(false);
  const mantinetheme = useMantineTheme();
  const laptop = useMediaQuery(`(max-width: ${mantinetheme.breakpoints.lg})`);
  const {
    data: userData,
    isLoading,
    refetch: refetchConversation,
    isRefetchError,
    hasNextPage,
    isFetching,
    fetchNextPage,
  } = useConversationList(cookies?.user_Info?.id);
  const [reachBottom, setReachBottom] = useState<any>(false);

  // ********* New functionality using react infinite query **********
  useEffect(() => {
    fetchUserListScroll(true);
  }, [userData]);

  // Fetch conversation user list.
  const fetchUserListScroll = async (chatListedUser: boolean) => {
    const userList: any[] = [];
    setUserLoading(true);
    userData?.pages?.map((page: any) => {
      page?.conversation?.data.map((item: any, id: number) => {
        userList.push(item);
      });
    });

    let userIndex = -1;
    if (userList?.length > 0) {
      if (customerRole.includes(cookies?.user_Info?.type)) {
        userIndex = userList?.findIndex((item: any) => +item?.service_provider === +chatRecieverId);
      } else {
        userIndex = userList?.findIndex((item: any) => +item?.user === +chatRecieverId);
      }
      console.log('conversationUser', userIndex, userList[userIndex]?.conversation_id);
      // setCurrentSelectedIndex(userIndex);
      setCurrentSelectedIndex(userList[userIndex]?.conversation_id);
      // setSelectedConversationId(userList[userIndex]?.conversation_id);

      setConversationId(userList[userIndex]?.conversation_id);
      if (userIndex >= 0 && chatListedUser) {
        getPreviousChat(userList[userIndex]);
      }
    }
    setUserLoading(false);
    setConversationList(userList);
  };

  // Add infinite scroll on user conversation list.
  const scrollHandler = async (e: any) => {
    const totalHeight = e.target.clientHeight + e.target.scrollTop;
    // console.log('height', e.target.scrollHeight - 50, isFetching, isLoading, hasNextPage, isRefetchError, hasNextPage);
    if (totalHeight >= e.target.scrollHeight - 50 && !isFetching && !isLoading && hasNextPage && !isRefetchError) {
      fetchNextPage();
    }
  };
  // ********** End new functionality ***********

  // ** This is old function using direct api call.
  // const fetchUserList = async (chatListedUser: boolean) => {
  //   setUserLoading(true);
  //   try {
  //     const userList: any = await getConversationsList(cookies?.user_Info?.id);
  //     let userIndex = -1;
  //     if (userList?.conversation?.data?.length > 0) {
  //       if (customerRole.includes(cookies?.user_Info?.type)) {
  //         userIndex = userList?.conversation?.data?.findIndex(
  //           (item: any) => +item?.service_provider === +chatRecieverId,
  //         );
  //       } else {
  //         userIndex = userList?.conversation?.data?.findIndex((item: any) => +item?.user === +chatRecieverId);
  //       }
  //       setCurrentSelectedIndex(userIndex);
  //       if (userIndex >= 0 && chatListedUser) {
  //         getPreviousChat(userList?.conversation?.data[userIndex]);
  //       }
  //     }
  //     setUserLoading(false);
  //     setConversationList(userList?.conversation?.data);
  //   } catch (error: any) {
  //     console.log(error);
  //     setUserLoading(false);
  //   }
  // };

  const handleClick = (id: number) => {
    setCurrentSelectedIndex(id);
  };

  // send new text message handle functionality
  const onMessageSubmit = (data: any) => {
    if (data.message.trim().length === 0) return;
    sendChannelMessage(data.message);
    reset({ message: '' });
  };

  const sendVideoCall = () => {
    if (!videoStart) {
      open();
    }
  };

  // join video call functionality.
  const joinCall = async () => {
    close();
    setShowVideoCall(true);
    // sendChannelMessage('Please join video call by clicking above video icon.');
    const notificationParams = {
      to_user: chatRecieverId,
      message: `You have a request to join video call by ${cookies?.user_Info?.name}.`,
      type: 'chat',
    };
    await sendNotification(notificationParams);
  };

  // end video call functionality.
  const endVideoCall = () => {
    setShowVideoCall(false);
    setVideoStart(false);
  };

  let timeout: any = null;

  useEffect(() => {
    scrollToBottom();
    if (chatSave) {
      updateConversationList();
    }
    if (messages?.length <= 20) {
      // scrollToBottom();
      setReachBottom(false);
    } else {
      setReachBottom((prev: boolean) => !prev);

      // if (!currentMessage) {
      //   scrollToCenter();
      // } else {
      //   scrollToBottom();
      // }
    }

    // console.log('message', messages, currentMessage);

    return () => clearTimeout(timeout);
  }, [messages, chatSave]);

  const scrollToBottom = () => viewport.current!.scrollTo({ top: viewport.current!.scrollHeight, behavior: 'smooth' });

  const scrollToCenter = () => {
    viewport.current!.scrollTo({ top: viewport.current!.scrollHeight / 3, behavior: 'smooth' });
    timeout = setTimeout(() => {
      setReachBottom((prev: boolean) => !prev);
    }, 2000);
  };

  // After click on 'enter' button, new message send from message text input.
  const keyDownEventCheck = (event: any) => {
    if (event?.key === 'Enter') {
      event.preventDefault();
      if (getValues().message.trim().length === 0) return;
      sendChannelMessage(getValues().message);
      reset({ message: '' });
    }
  };

  // Fetch previous chat list of selected user.
  const fetchPreviousChat = (chatInfo: any, index: number) => {
    console.log('conversationId', chatInfo, index);
    removeCookie('bookingDetails', { path: '/' });
    // setSelectedConversationId(chatInfo?.conversation_id);

    setConversationId(chatInfo?.conversation_id);
    if (customerRole.includes(cookies?.user_Info?.type)) {
      setChatRecieverId(+chatInfo?.service_provider);
    } else {
      setChatRecieverId(+chatInfo?.user);
    }
    setChannelId(chatInfo?.service_provider + chatInfo?.user);
    // getPreviousChat(chatInfo);

    // Refetch message list is call if new user add in conversation-list & after send message by
    // SP/Customer, click on new user profile from conversation list, then need to show prev chat.

    // messageRefetch();
    setChatType('userChat');
    setSelectedChatUser(chatInfo);
  };

  // If chat with new user, then update conversation list.
  const updateConversationList = () => {
    let listedUser: boolean;
    if (customerRole.includes(cookies?.user_Info?.type)) {
      listedUser = conversationList?.some((element: any) => +element?.service_provider === chatRecieverId);
    } else {
      listedUser = conversationList?.some((element: any) => +element?.user === chatRecieverId);
    }
    console.log('listed user', chatRecieverId, conversationList, listedUser);
    // If it inot listed user, means new user, then update conversation list.
    if (!listedUser) {
      // fetchUserList(listedUser);
      refetchConversation();
      fetchUserListScroll(listedUser);
    }
  };

  const messageScrollHandler = async (e: any) => {
    const totalHeight = e.target.clientHeight + e.target.scrollTop;
    // console.log('height', reachBottom, totalHeight, e.target.scrollTop, e.target.scrollHeight);
    if (totalHeight >= e.target.scrollHeight - 20) {
      setReachBottom(true);
    }

    if (
      reachBottom &&
      e.target.scrollTop <= 50 &&
      !isMessageFetching &&
      !isMessageLoading &&
      hasMessageNextPage &&
      !isMessageRefetchError
    ) {
      fetchNextMessage();
    }
  };

  return (
    <>
      <VideoContext.Provider value={useVideoInfo()}>
        <Grid m={0} mt={30} p={0}>
          {!showVideoCall && (isMobile || isTablet) ? (
            <>
              <Grid.Col>
                <Box className={chatCardStyles.mobileHeaderBox}>
                  {/* <ScrollArea scrollbarSize={1}> */}
                  <UserListScrollContianerMobile onScroll={scrollHandler}>
                    <Flex gap={30}>
                      <>
                        {userData?.pages?.map((page: any, index: number) =>
                          !page.conversation.data.length ? (
                            <Flex gap={10} direction='column' mx={25} key={index}>
                              {!userLoading && (
                                <>
                                  <Text color='#868e96'>No conversation list..</Text>
                                  {/* {customerRole.includes(+cookies.role) && (
                                    <Text color='#868e96'>To start conversation, please book service.</Text>
                                  )} */}
                                </>
                              )}
                            </Flex>
                          ) : (
                            page.conversation.data.map((user: any, index: number) => {
                              return (
                                <Box
                                  key={index}
                                  onClick={() => {
                                    handleClick(user?.conversation_id);
                                    fetchPreviousChat(user, index);
                                  }}
                                  style={{ width: '2.5em', cursor: 'pointer' }}>
                                  <Flex direction='column' justify='center' align='center'>
                                    <Avatar classNames={avatarClass} src={user?.image}></Avatar>
                                    <Text
                                      fz='xs'
                                      ta='center'
                                      color='dimmed'
                                      lineClamp={1}
                                      style={{ wordWrap: 'break-word' }}>
                                      {user?.name}
                                    </Text>
                                  </Flex>
                                </Box>
                              );
                            })
                          ),
                        )}
                      </>
                    </Flex>
                  </UserListScrollContianerMobile>
                  {/* </ScrollArea> */}
                </Box>
              </Grid.Col>
            </>
          ) : (
            <>
              {/* UI of chat conversations list. */}
              {!showVideoCall && (
                <Grid.Col span={4} pl={0}>
                  <Box
                    py={20}
                    h='100%'
                    bg={theme.colorScheme === 'light' ? '#ececec' : theme.colors.dark[6]}
                    style={{ borderRadius: '10px' }}>
                    <Title order={2} mx={25}>
                      Messages
                    </Title>
                    {/* <ScrollArea.Autosize mah={`calc(100vh - 240px)`} type='scroll' mt={20} scrollbarSize={2}> */}
                    <UserListScrollContianer onScroll={scrollHandler}>
                      <Stack mt={5} spacing={10} pb={10}>
                        {userData?.pages?.map((page: any, index: number) =>
                          !page.conversation.data.length ? (
                            <Flex gap={10} direction='column' mx={25} key={index}>
                              {!userLoading && (
                                <>
                                  <Text color='#868e96'>No conversation list..</Text>
                                  {/* {customerRole.includes(+cookies.role) && (
                                    <Text color='#868e96'>To start conversation, please book service.</Text>
                                  )} */}
                                </>
                              )}
                            </Flex>
                          ) : (
                            page.conversation.data.map((user: any, index: any) => {
                              return (
                                <Card
                                  key={index}
                                  bg={
                                    user.conversation_id === currentSelectedIndex
                                      ? theme.colorScheme === 'light'
                                        ? theme.colors.orange[0]
                                        : theme.colors.dark[7]
                                      : theme.colorScheme === 'light'
                                      ? '#FDFAF6'
                                      : theme.colors.dark[5]
                                  }
                                  mx={25}
                                  withBorder
                                  onClick={() => {
                                    // handleClick(index);
                                    handleClick(user?.conversation_id);
                                    fetchPreviousChat(user, index);
                                  }}
                                  className={
                                    user.conversation_id === currentSelectedIndex
                                      ? chatCardStyles.active
                                      : chatCardStyles.card
                                  }>
                                  <Grid style={{ flexDirection: 'row', alignItems: 'center' }}>
                                    <Grid.Col span={2}>
                                      <Flex justify='center' align='center' h='100%'>
                                        <Avatar classNames={avatarClass} src={user?.image}></Avatar>
                                      </Flex>
                                    </Grid.Col>
                                    <Grid.Col span={10}>
                                      <Flex direction='column' gap={10}>
                                        <Flex justify='space-between' align='center'>
                                          <Text fz={16} fw={500}>
                                            {user?.name}
                                          </Text>
                                          <Text color='dimmed' fz={12}>
                                            {dayjs(user?.created_at).format('MMM DD, HH:mm')}
                                          </Text>
                                        </Flex>
                                        <Text color='dimmed' lineClamp={1}>
                                          {user?.lastChatSnippet}
                                        </Text>
                                      </Flex>
                                    </Grid.Col>
                                  </Grid>
                                </Card>
                              );
                            })
                          ),
                        )}
                        {isFetching && (
                          <Text ta='center' fz={17} color='#868e96'>
                            Loading...
                          </Text>
                        )}
                      </Stack>
                    </UserListScrollContianer>
                    {/* </ScrollArea.Autosize> */}
                  </Box>
                </Grid.Col>
              )}
            </>
          )}

          {/* The original col span for the grid below was this and i changed it for testing purpose on 16-11-2023.
            <Grid.Col md={showVideoCall ? 7 : 12} lg={showVideoCall ? 7 : 12}>
          */}

          {/* UI of chat section list. */}
          <Grid.Col
            pr={0}
            md={showVideoCall ? 7 : 8}
            lg={showVideoCall ? 7 : 8}
            display={(isMobile || isTablet) && showVideoCall ? 'none' : 'block'}
            span={!showVideoCall ? (isMobile || isTablet ? 12 : 8) : 7}>
            <Box
              style={{
                position: 'relative',
                display: 'flex',
                flexDirection: 'column',
                justifyContent: 'space-between',
                height: '100%',
              }}
              h={`calc(100vh - 200px)`}>
              <Box
                pb={20}
                bg={theme.colorScheme === 'light' ? theme.colors.orange[0] : theme.colors.dark[5]}
                style={{ borderRadius: '10px' }}>
                <Flex pt={20} px={10} align='center' gap={20} style={{ justifyContent: 'space-between' }}>
                  {channelId && (selectedChatUser || chatUserData) ? (
                    <>
                      <Flex direction='row' gap={15}>
                        <Avatar
                          classNames={AvatarStyle}
                          src={selectedChatUser?.image || chatUserData?.data?.user_profile?.image}
                          radius='xl'
                          size={60}
                        />
                        <Text
                          size='md'
                          weight={600}
                          display='flex'
                          style={{ justifyContent: 'center', flexDirection: 'column' }}>
                          {selectedChatUser?.name || chatUserData?.data?.name || 'Please select user'}
                        </Text>
                      </Flex>{' '}
                      <Flex align='center' pr={20} gap={5}>
                        <ActionIcon
                          variant='default'
                          onClick={() => {
                            sendVideoCall();
                          }}
                          style={{ border: 'none', background: 'transparent' }}>
                          <IconVideo size={40} color='gray' strokeWidth={2} />
                        </ActionIcon>
                      </Flex>
                    </>
                  ) : (
                    <Text
                      size='md'
                      weight={600}
                      display='flex'
                      px='md'
                      py={12}
                      ta='center'
                      style={{ justifyContent: 'center', flexDirection: 'column' }}>
                      {'Please select user for chat.'}
                    </Text>
                  )}
                </Flex>
              </Box>
              <Divider my={20} />
              <LoadingOverlay visible={loading || userFetchLoading} overlayBlur={2} loaderProps={{ size: 'lg' }} />
              <Box p='0' h='100%' className={channelId ? '' : chatCardStyles.messageBox}>
                {/* <ScrollArea.Autosize
                  type='never'
                  h={laptop ? window.innerHeight - 250 : window.innerHeight - 350}
                  viewportRef={viewport}> */}
                <MessageListScroll ref={viewport} onScroll={messageScrollHandler}>
                  <Stack justify='flex-end' pt={10}>
                    {isMessageFetching && (
                      <Text ta='center' fz={17} color='#868e96'>
                        Loading...
                      </Text>
                    )}
                    {messages.length ? (
                      messages
                        .sort((a: any, b: any) => Number(a.id) - Number(b.id))
                        .map((data: any, index: number) => {
                          return (
                            <Box
                              ta={
                                data?.user?.name === 'You' || data?.message_from == cookies?.user_Info?.id
                                  ? 'right'
                                  : 'left'
                              }
                              ml={
                                data?.user?.name === 'You' || data?.message_from == cookies?.user_Info?.id ? 'auto' : 0
                              }
                              key={`chat${index + 1}`}
                              bg={theme.colorScheme === 'light' ? '#e2e2e4' : theme.colors.dark[5]}
                              p={10}
                              mb={15}
                              style={{
                                borderRadius: `${
                                  data?.user?.name === 'You' || data?.message_from == cookies?.user_Info?.id
                                    ? '8px 8px 0 8px'
                                    : '8px 8px 8px 0'
                                }`,
                                maxWidth: 'fit-content',
                              }}>
                              <h5 className='font-size-15' style={{ color: data?.user?.color || 'orange' }}>
                                {`${data?.user?.name || data?.name},  ${
                                  data?.user?.time || dayjs(data?.created_at).format('MMM DD, HH:mm')
                                } :`}
                              </h5>
                              <Text
                                color={
                                  theme.colorScheme === 'light' ? 'black' : theme.white
                                }>{` ${data.message}`}</Text>
                            </Box>
                          );
                        })
                    ) : (
                      <>
                        {channelId && !isMessageFetching ? (
                          <Text c='dimmed' ta='center'>
                            No chat found, start your chat ...
                          </Text>
                        ) : (
                          <Text c='dimmed' ta='center'>
                            {!isMessageFetching && 'Please select user and start your conversation ...'}
                          </Text>
                        )}
                      </>
                    )}
                  </Stack>
                </MessageListScroll>
                {/* </ScrollArea.Autosize> */}
              </Box>

              {channelId && (selectedChatUser || chatUserData) && (
                <form onSubmit={handleSubmit(onMessageSubmit)} autoComplete='off'>
                  <Grid>
                    <Grid.Col pl={0} md={12} sm={12}>
                      <Group>
                        <Flex direction={'row'} w='100%' justify='space-between'>
                          <Textarea
                            {...register('message', { required: true })}
                            size='md'
                            placeholder='Type a message...'
                            radius='md'
                            classNames={chatClass}
                            mx='xs'
                            onKeyDown={(event: any) => {
                              keyDownEventCheck(event);
                            }}
                            autosize
                            minRows={1}
                            maxRows={2}
                            w={'100%'}
                          />
                          <Button
                            size='md'
                            type='submit'
                            variant='gradient'
                            gradient={{ to: '#fda223', from: '#fe7720' }}>
                            <IconSend size={30} color='white' />
                          </Button>
                        </Flex>
                      </Group>
                    </Grid.Col>
                  </Grid>
                </form>
              )}
            </Box>
          </Grid.Col>

          {showVideoCall && (
            <Grid.Col span={isMobile || isTablet ? 12 : 5}>
              <Paper mih='100%' shadow='xs' withBorder p={40}>
                <WebVideo endVideoCall={endVideoCall} chatUserData={chatUserData} />
              </Paper>
            </Grid.Col>
          )}
        </Grid>

        <ModalOrDrawer
          opened={opened}
          onClose={close}
          drawerProps={{ dz: 250, drawerBackButton: true }}
          modalProps={{ modalCloseButton: true }}>
          <JoinVideoCall opened={opened} close={close} joinCall={joinCall} />
        </ModalOrDrawer>
      </VideoContext.Provider>
    </>
  );
};
export default Chat;
