import { yupResolver } from '@hookform/resolvers/yup';
import { Controller, useForm } from 'react-hook-form';
import { rateAndReviewSchema } from '../../../../../utils/schema/formSchema';
import ErrorText from '../../../../../components/ErrorText';
import { Button, Flex, Rating, Textarea, Text } from '@mantine/core';

interface Props {
  data?: any;
  onSubmit: any;
  bookingIdOfThisBooking: string;
  type: 'booking' | 'user';
  userId?: string;
  loading?: boolean;
  title: string;
}

export default function RateAndReviewForm({
  data,
  onSubmit,
  type,
  title,
  bookingIdOfThisBooking,
  userId,
  loading,
}: Props) {
  const {
    control,
    handleSubmit,
    formState: { errors },
  } = useForm({
    resolver: yupResolver(rateAndReviewSchema),
    mode: 'all',
  });

  // console.log({ data, onSubmit, type, bookingIdOfThisBooking, userId });

  return (
    <>
      {data === null ? (
        <>
          <form
            onSubmit={handleSubmit((data) =>
              onSubmit({
                ...data,
                booking_id: bookingIdOfThisBooking,
                entity_id: type === 'booking' ? bookingIdOfThisBooking : userId,
                review_type: type === 'booking' ? 'booking' : 'user',
              }),
            )}>
            <Flex direction='column' gap={4}>
              <Text fw={600}>{title}</Text>
              <Controller
                control={control}
                name='rating'
                rules={{ required: true }}
                render={({ field }) => <Rating {...field} fractions={4} size='lg' color='#fda223' />}
              />
              <ErrorText errorProperty={errors.rating} />

              <Controller
                control={control}
                name='review'
                rules={{ required: true }}
                render={({ field }) => <Textarea {...field} spellCheck={false} />}
              />

              <Button
                radius={4}
                type='submit'
                variant='gradient'
                gradient={{ from: '#fda223', to: '#fe7720' }}
                loading={loading}
                loaderPosition='center'>
                <Text>{loading ? null : 'Submit'}</Text>
              </Button>
            </Flex>
          </form>
        </>
      ) : null}
    </>
  );
}
