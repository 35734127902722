import dayjs from 'dayjs';
import utc from 'dayjs/plugin/utc';
import customParseFormat from 'dayjs/plugin/customParseFormat';

dayjs.extend(utc);
dayjs.extend(customParseFormat);

export default function getDifferenceBetweenTwoDateTime(dt1: string, dt2: string) {
  // console.log({ dt1, dt2 });

  const firstDateTime = dayjs(dt1, { format: 'YYYY-MM-DD HH:mm:ss', utc: true }, true);
  const secondDateTime = dayjs(dt2, { format: 'YYYY-MM-DD HH:mm:ss', utc: true }, true);

  // console.log({ firstDateTime, secondDateTime });

  const dayDifference = firstDateTime.diff(secondDateTime, 'd');
  const hourDifference = firstDateTime.diff(secondDateTime, 'h') % 24;
  const minuteDifference = firstDateTime.diff(secondDateTime, 'm') % 60;
  const secondDifference = firstDateTime.diff(secondDateTime, 's') % 60;

  let timeDifference = '';

  timeDifference += dayDifference > 0 ? `${dayDifference}d ` : '';

  timeDifference += hourDifference < 10 ? `0${hourDifference}` : hourDifference;
  timeDifference += ':';

  timeDifference += minuteDifference < 10 ? `0${minuteDifference}` : minuteDifference;
  timeDifference += ':';

  timeDifference += secondDifference < 10 ? `0${secondDifference}` : secondDifference;

  // console.log(timeDifference);
  return timeDifference;
}
