import { Box, Button, Divider, Flex, Grid, ScrollArea, Text } from '@mantine/core';
import { useDisclosure } from '@mantine/hooks';
import React, { useContext, useState } from 'react';
import { HomeContextRefactored } from '../home.context';
import { useProviderProfilesScrollAreaStyles, useProviderProfilesStyles } from '../style';
import FilterButton from './FilterButton';
import NoProfileFound from './NoProfileFound';
import ProfileViewButton from './ProfileViewButton';
import UrgentBookingButton from './UrgentBookingButton';
import UrgentBookingRefactored from './UrgentBookingRefactored';
import getNextAvailableSlotId from '../../../../utils/helpers/getNextAvailableSlotId';
import { GridCard } from '../../Customer/FreelancerList/components/GridCard';
import ListCardRefactored from '../../Customer/FreelancerList/components/ListCardRefactored';
import ModalOrDrawer from '../../Customer/Profile/components/ModalOrDrawer';
import { Outlet, useNavigate } from 'react-router-dom';
import CreateJobs from '../../Customer/CreateJobs';

interface Props {
  profiles: any;
}

export default function ProviderProfiles({ profiles }: Props) {
  const {
    selectedServiceName,
    setShowFilter,
    showFilter,
    profilesViewType,
    setProfilesViewType,
    selectedChildServiceId,
    withoutRangeSearch,
    setWithoutRangeSearch,
    showJobSection,
    setShowJobSection,
  } = useContext(HomeContextRefactored);
  const { classes, cx } = useProviderProfilesStyles();
  const { classes: providerProfilesScrollAreaClasses } = useProviderProfilesScrollAreaStyles();
  const [urgentBookingOpened, { open: openUrgentBooking, close: closeUrgentBooking }] = useDisclosure(false);
  const navigate = useNavigate();
  const [urgentBookingLoading, setUrgentBookingLoading] = useState<boolean>(false);
  const [nextImmediateAvailableSlotId, setNextImmediateAvailableSlotId] = useState<string>('');

  const handleUrgentBooking = async () => {
    setUrgentBookingLoading(true);
    try {
      const slotId = await getNextAvailableSlotId();
      setNextImmediateAvailableSlotId(slotId);
      setUrgentBookingLoading(false);
      openUrgentBooking();
    } catch (error) {
      console.log(error);
      setUrgentBookingLoading(false);
    }
  };

  const handleProfileView = (type: 'list' | 'grid') => {
    setProfilesViewType(type);
  };

  const handleFilter = () => {
    setShowFilter((state) => !state);
  };

  return (
    <>
      <Box className={cx(classes.container)}>
        <Grid p={0} m={0}>
          <Grid.Col lg={7} md={7} sm={7} p={0} m={0}>
            <Text fw={600} fz={18} className={classes.serviceHeaderText}>
              {selectedServiceName}
            </Text>
          </Grid.Col>
          {profiles.length && !showJobSection ? (
            <>
              <Grid.Col className={classes.dividerGridContainer}>
                <Divider />
              </Grid.Col>
              <Grid.Col lg={5} md={5} sm={5} p={0} m={0}>
                <Flex gap={8}>
                  <FilterButton showFilter={showFilter} onClick={handleFilter} />
                  <ProfileViewButton profilesViewType={profilesViewType} onClick={handleProfileView} />
                </Flex>
              </Grid.Col>
              <Grid.Col p={0} m={0} className={classes.urgentBookingButtonGridContainer}>
                <UrgentBookingButton
                  renderIf={profiles.length}
                  loading={urgentBookingLoading}
                  onClick={handleUrgentBooking}
                />
              </Grid.Col>
            </>
          ) : (
            <></>
          )}

          <Grid.Col lg={12} p={0} m={0}>
            <Box>
              {profiles.length === 0 ? (
                <>
                  {!showJobSection ? (
                    <>
                      <NoProfileFound renderButtonToDisplayAllProfiles={!withoutRangeSearch} />
                      {/* <Box mt={150}>
                        <Flex justify='center' gap={10}>
                          <Button
                            size='lg'
                            compact
                            variant='outline'
                            radius={4}
                            onClick={() => setShowJobSection(true)}>
                            Create Job
                          </Button>
                          {!withoutRangeSearch ? (
                            <Button
                              size='lg'
                              compact
                              variant='outline'
                              radius={4}
                              onClick={() => setWithoutRangeSearch(true)}>
                              Show all profiles
                            </Button>
                          ) : null}
                        </Flex>
                      </Box> */}
                    </>
                  ) : (
                    <CreateJobs />
                  )}
                </>
              ) : (
                <>
                  <Box pt={8}>
                    <ScrollArea.Autosize
                      classNames={providerProfilesScrollAreaClasses}
                      scrollbarSize={2}
                      offsetScrollbars>
                      {profiles.filter((item: any) => item.backgrounnd_verified).length ? (
                        <Text fw={550} fz={16}>
                          Verified User
                        </Text>
                      ) : null}
                      <Grid p={0} m={0} py={8}>
                        {profiles
                          .filter((item: any) => item.backgrounnd_verified)
                          .map((profile: any, index: number) => {
                            return (
                              <React.Fragment key={index}>
                                {profilesViewType === 'grid' && (
                                  <Grid.Col lg={6} md={12} sm={6} xs={6} py={4} px={4} m={0}>
                                    <GridCard {...profile} />
                                  </Grid.Col>
                                )}

                                {profilesViewType === 'list' && (
                                  <Grid.Col py={4} px={0} m={0}>
                                    <ListCardRefactored {...profile} />
                                  </Grid.Col>
                                )}
                              </React.Fragment>
                            );
                          })}
                        {profiles.filter((item: any) => !item.backgrounnd_verified).length ? (
                          <Grid.Col py={5} px={4}>
                            <Divider />
                            <Text fw={550} fz={16} my={5}>
                              Unverified User
                            </Text>
                            <Divider />
                          </Grid.Col>
                        ) : null}
                        {profiles
                          .filter((item: any) => !item.backgrounnd_verified)
                          .map((profile: any, index: number) => {
                            return (
                              <React.Fragment key={index}>
                                {profilesViewType === 'grid' && (
                                  <Grid.Col lg={6} md={12} sm={6} xs={6} py={4} px={4} m={0}>
                                    <GridCard {...profile} />
                                  </Grid.Col>
                                )}

                                {profilesViewType === 'list' && (
                                  <Grid.Col py={4} px={0} m={0}>
                                    <ListCardRefactored {...profile} />
                                  </Grid.Col>
                                )}
                              </React.Fragment>
                            );
                          })}
                      </Grid>
                    </ScrollArea.Autosize>
                  </Box>
                </>
              )}
            </Box>
          </Grid.Col>
        </Grid>

        <ModalOrDrawer
          opened={urgentBookingOpened}
          onClose={closeUrgentBooking}
          drawerProps={{
            dz: 210,
            bs: '0px -4px 12px 0px rgba(0,0,0,0.1)',
            drawerBackButton: true,
            drawerOverlayProps: { opacity: 0, blur: 0 },
          }}
          modalProps={{ modalCloseButton: true }}>
          <UrgentBookingRefactored
            serviceTypeId={selectedChildServiceId}
            slotId={nextImmediateAvailableSlotId}
            onClose={closeUrgentBooking}
          />
        </ModalOrDrawer>
      </Box>
    </>
  );
}
