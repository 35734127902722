import { Badge, Flex, Text } from '@mantine/core';
import { memo, useCallback, useEffect, useRef, useState } from 'react';
import getDateTimeDifferenceFromCurrentDateTime from '../../../../../utils/helpers/getDateTimeDifferenceFromCurrentDateTime';

interface Props {
  data: any;
}

const TimeElapsed = memo(function TimeElapsed({ data }: Props) {
  const [timeElapsed, setTimeElapsed] = useState('00:00:00');

  useEffect(() => {
    const intervalId: NodeJS.Timer = setInterval(() => {
      const result = getDateTimeDifferenceFromCurrentDateTime(data);
      setTimeElapsed(result);
    }, 1000);
    return () => clearInterval(intervalId);
  }, []);

  return (
    <>
      <Badge w='fit-content'>
        <Flex gap={4}>
          <Text fw={700}>Time Elapsed:</Text>
          <Text fw={500}>{timeElapsed}</Text>
        </Flex>
      </Badge>
    </>
  );
});

export default TimeElapsed;
