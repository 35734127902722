import { Avatar, Box, Button, Flex, Group, Paper, Text, Tooltip } from '@mantine/core';
import { IconArrowUpRight, IconCalendarCheck, IconClockHour5, IconFileInvoice } from '@tabler/icons-react';
import dayjs from 'dayjs';
import { Link } from 'react-router-dom';
import { convertTimeFormat, serviceIconHandler } from '../../../../utils/helpers';
import { makeUpperCase } from '../../../../utils/helpers/makeFirstLetterUpper';
import BookingStatusOld from './BookingStatusOld';
import { useBookingCardStyles } from './style';

interface Props {
  opened: boolean;
  isThereMap?: boolean;
  openHandler: (id: string, allData: any) => void;
  title: string;
  rate: string;
  date: Date;
  time_to: string;
  time_from: string;
  id: string;
  avatar: string;
  allData: any;
  type: 'current' | 'history';
  setActiveCardIndex: React.Dispatch<React.SetStateAction<number>>;
  selectedIndex: number;
  isThisCurrentlyActive: boolean;
}

export default function BookingCard({
  avatar,
  date,
  id,
  rate,
  time_to,
  time_from,
  title,
  openHandler,
  opened,
  isThereMap,
  allData,
  type,
  setActiveCardIndex,
  selectedIndex,
  isThisCurrentlyActive,
}: Props) {
  const { classes: bookingCardClasses, cx } = useBookingCardStyles();

  return (
    <>
      <Paper
        withBorder
        p='sm'
        color='red'
        className={cx({
          [bookingCardClasses.card]: !isThisCurrentlyActive,
          [bookingCardClasses.activeCard]: isThisCurrentlyActive,
        })}>
        <Flex
          gap={15}
          mb={15}
          className={opened ? bookingCardClasses.serviceNameIconFlexIfOpened : bookingCardClasses.serviceNameIconFlex}
          justify='space-between'>
          <Flex gap={4}>
            <Avatar src={serviceIconHandler(title)} styles={(theme) => ({ image: { objectFit: 'contain' } })}></Avatar>
            <Text fw='bold' className={bookingCardClasses.serviceNameText}>
              {makeUpperCase(title)}
            </Text>
          </Flex>
          <Box>
            {type === 'history' && (
              <>
                <Tooltip label='Get invoice'>
                  <Link
                    to={`/invoice?booking-id=${id}`}
                    target='_blank'
                    style={{ textDecoration: 'none', color: '#616161' }}>
                    <IconFileInvoice />
                  </Link>
                </Tooltip>
              </>
            )}
          </Box>
        </Flex>
        <Flex justify='space-between' mb={15}>
          <BookingStatusOld data={allData} fromModal={false} />
          <Text fw='bold'>${rate}/hr</Text>
        </Flex>
        <Flex justify='space-between' mb={20} className={bookingCardClasses.dateTimeFlex}>
          <Group spacing={2}>
            <IconClockHour5 color='gray' className={opened ? bookingCardClasses.iconOpened : bookingCardClasses.icon} />
            <Text
              c='dimmed'
              size='sm'
              className={
                opened
                  ? isThereMap
                    ? bookingCardClasses.dateTimeTextIfThereIsMap
                    : bookingCardClasses.dateTimeTextIfOpened
                  : bookingCardClasses.dateTimeText
              }>
              {convertTimeFormat(time_from)} - {convertTimeFormat(time_to)}
            </Text>
          </Group>

          <Group spacing={2}>
            <IconCalendarCheck
              color='gray'
              className={opened ? bookingCardClasses.iconOpened : bookingCardClasses.icon}
            />
            <Text
              c='dimmed'
              size='sm'
              className={
                opened
                  ? isThereMap
                    ? bookingCardClasses.dateTimeTextIfThereIsMap
                    : bookingCardClasses.dateTimeTextIfOpened
                  : bookingCardClasses.dateTimeText
              }>
              {dayjs(date).format(`MMM DD, YYYY`)}
            </Text>
          </Group>
        </Flex>

        <Flex>
          <Button
            variant='subtle'
            rightIcon={<IconArrowUpRight size={20} />}
            onClick={() => {
              openHandler(id, allData);
              setActiveCardIndex(selectedIndex);
            }}
            compact
            size='sm'>
            View Booking
          </Button>
        </Flex>
      </Paper>
    </>
  );
}
