import { useQuery } from '@tanstack/react-query';
import { toastMessageUpdate } from '../utils/helpers/tosterMessage';
import { axiosTokenInst } from './axios.config';
import { error } from 'console';

export const userOverview = async (userId: any) => {
  return await axiosTokenInst
    .get(`/user_profiles/${userId}`)
    .then((response) => {
      return response;
    })
    .catch((error: Error) => {
      // console.log('api err', error);
      // toastMessageUpdate('error', error?.message);
      throw error;
    });
};

export const createUserOverview = async (userDetails: any) => {
  return await axiosTokenInst
    .post(`/user_profiles`, userDetails)
    .then((response: any) => {
      toastMessageUpdate('success', response?.message);
      return response.data;
    })
    .catch((error: Error) => {
      // console.log('api err', error);
      toastMessageUpdate('error', error?.message);
      return error;
    });
};

export const updateUserOverview = async (userDetails: any) => {
  return await axiosTokenInst
    .post(`/user_profiles/update`, userDetails, {
      headers: {
        'Content-Type': 'multipart/form-',
      },
    })
    .then((response: any) => {
      toastMessageUpdate('success', response?.message);
      return response.data;
    })
    .catch((error: any) => {
      toastMessageUpdate('error', error?.data?.message);
      return error;
    });
};

export const useUserOverview = (userId: string) => {
  return useQuery({
    enabled: +userId > 0 ? true : false,
    queryKey: ['user-overview', userId],
    queryFn: async () => {
      if (userId === '') return;
      else if (+userId > 0) {
        return await userOverview(userId);
      }
    },
  });
};

type useFetchUserOverviewArgument = {
  userId: string;
  enableCondition: boolean;
};

export const useFetchUserOverview = ({ userId, enableCondition }: useFetchUserOverviewArgument) => {
  return useQuery({
    queryKey: ['fetch-user-overview-by-id', userId],
    queryFn: async () => {
      const data = await userOverview(userId);
      return data;
    },
    enabled: enableCondition,
    staleTime: 5 * 60 * 1000,
    cacheTime: 5 * 60 * 1000,
  });
};
