import { Box, Button, Flex, Text } from '@mantine/core';
import { useShowContactInfoStyles } from '../style';
import { IconBrandWhatsapp, IconMail, IconMessage, IconMessageChatbot, IconPhone } from '@tabler/icons-react';
import { useCookies } from 'react-cookie';
import { customerRole } from '../../../../../utils';
import { useContext } from 'react';
import { ThemeContext } from '../../../../../context';
import { useNavigate } from 'react-router-dom';

interface Props {
  data: any;
  bookingData: any;
  renderIf: boolean;
}

export default function ShowContactInfo({ data, bookingData, renderIf }: Props) {
  if (data === undefined || renderIf === false) {
    return null;
  }

  const { classes } = useShowContactInfoStyles();
  const [cookie, setCookie] = useCookies(['role', 'peerId', 'bookingDetails', 'user_Info']);
  const { setChatType, setChannelId, setChatRecieverId, notificationRecieved } = useContext(ThemeContext);
  const navigate = useNavigate();

  let emailAddress;
  let phoneNumber;

  const userHasContactInfo = Object.hasOwn(data?.data, 'user');

  if (userHasContactInfo) {
    emailAddress = data?.data?.user?.email;
    phoneNumber = data?.data?.user?.mobile;
  }

  const sendData = () => {
    if (customerRole.includes(cookie?.user_Info?.type)) {
      // setChatRecieverId(+bookingData?.service_to);
      setChatRecieverId(+bookingData?.service_from);
    } else {
      // setChatRecieverId(+bookingData?.service_from);
      setChatRecieverId(+bookingData?.service_to);
    }
    setCookie('peerId', bookingData?.service_from + bookingData?.service_to, { path: '/' });
    setCookie('bookingDetails', bookingData, { path: '/' });
    // setChannelId(bookingData?.booking_id);
    setChannelId(bookingData?.service_from + bookingData?.service_to);
    setChatType('bookingChat');
    navigate('/chat');
  };

  return (
    <>
      <Flex direction='column' w={'100%'}>
        <Text fw={600}>Contact Info</Text>
        {userHasContactInfo ? (
          <>
            <Flex direction='column' align='start' mt={12}>
              {/* {phoneNumber && (
                <Button
                  pl={0}
                  component='a'
                  href={`tel:${phoneNumber}`}
                  compact
                  variant='subtle'
                  leftIcon={<IconPhone />}>
                  {phoneNumber}
                </Button>
              )} */}
              {/* {emailAddress && (
                <Button
                  target='_blank'
                  pl={0}
                  mt={7}
                  component='a'
                  href={`mailto:${emailAddress}`}
                  compact
                  variant='subtle'
                  leftIcon={<IconMail />}>
                  {emailAddress}
                </Button>
              )} */}
              {/* {phoneNumber && (
                <Button
                  pl={0}
                  mt={7}
                  component='a'
                  href={`sms:${phoneNumber}`}
                  compact
                  variant='subtle'
                  leftIcon={<IconMessage />}>
                  Send SMS
                </Button>
              )} */}
              <Button pl={0} mt={7} compact variant='subtle' onClick={sendData} leftIcon={<IconMessageChatbot />}>
                Chat online
              </Button>
              {/* {phoneNumber && (
                <Button
                  target='_blank'
                  pl={0}
                  mt={7}
                  component='a'
                  href={`https://wa.me/${phoneNumber}`}
                  compact
                  variant='subtle'
                  leftIcon={<IconBrandWhatsapp />}>
                  Contact on WhatsApp
                </Button>
              )} */}
            </Flex>
          </>
        ) : (
          <>
            <Box className={classes.noContactInfoContainer}>
              <Text>No contact info</Text>
            </Box>
          </>
        )}
      </Flex>
    </>
  );
}
