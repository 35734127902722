import { Flex, Text } from '@mantine/core';
import Loading from './Loading';

interface Props {
  data: any;
  isLoading: boolean;
}

export default function BookingLocation({ data, isLoading }: Props) {
  return (
    <>
      <Flex direction='column' gap={4}>
        <Text fw={600}>Booking Location</Text>
        <Text c='dimmed'>
          {isLoading ? (
            <Loading />
          ) : (
            <>
              {data === undefined ? (
                <Text fz={14} c='dimmed'>
                  This user does not exist
                </Text>
              ) : (
                <Text>{data?.data?.['has_address']?.['address']}</Text>
              )}
            </>
          )}
        </Text>
      </Flex>
    </>
  );
}
